import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { PATH } from '@data/core/paths';

type ProtectedRouteProps = {
  admin?: boolean;
  redirectPath?: PATH;
};

export function ProtectedRoute({ admin, redirectPath = PATH.LOGIN }: ProtectedRouteProps) {
  const { isAuthenticated, isAdmin } = useAuth();
  const location = useLocation();

  const isAllowed = admin ? isAdmin : isAuthenticated;

  return isAllowed ? <Outlet /> : <Navigate replace to={redirectPath} state={{ from: location }} />;
}

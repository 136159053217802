import {
  Box,
  Button,
  Typography,
  Stack,
  Divider,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Link,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import i18n from '../../libs/language';
import ChevronLeftIcon from '@assets/svg/chevron-left.svg';
import { useService } from '@hooks/use-service';
import { verifySpeciesInsurance } from '@services/integration/insurance/la-segunda/la-segunda-species';
import { CardSiloInsurance } from './card-silo-insurance';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatTons } from '@utils/helper';
import { BaseTable } from '@ui/table';
import { GridColDef } from '@mui/x-data-grid-pro';
import { coins } from './selects-insurance-data';
import { useState } from 'react';
import { useEffectOnUpdate } from '@hooks/core';
import { silobagInsuranceListService } from '@services/integration/insurance/insurance-list';
import { PATH } from '@data/core/paths';

type TabletDataType = {
  sum_assured: number;
  id: string | number;
  speciesName: string;
  quantity: number;
  totalInputWeight: number;
  price: number;
};

type PriceData = {
  [key: number | string]: number;
};

export function SiloInsurancePage() {
  const navigate = useNavigate();
  const { siloIDs } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [typeCoin, setTypeCoin] = useState(coins[1].id);
  const [tableData, setTableData] = useState<TabletDataType[]>([]);
  const [totals, setTotals] = useState({
    summ_assured: 0,
    tons: 0,
  });
  const [priceData, setPriceData] = useState<PriceData>({});
  const [validationPrices, setValidationPrices] = useState<boolean>(false);
  const TOTAL_VALUE = 'Total';

  const { summary: speciesData } = useService(verifySpeciesInsurance, {
    fetchOnMount: true,
  });

  const { summary: data, isLoading } = useService(silobagInsuranceListService, {
    fetchOnMount: [{ query: siloIDs ?? '' }],
  });
  const handlePriceChange = (id: number | string, value: number) => {
    const newPriceData = { ...priceData, [id]: value };
    setPriceData(newPriceData);
  };

  const oneMonthQuotes = data?.one_month_quotes.map((quote, index: number) => ({
    ...quote,
    one_month_id: index + 1, // Genera un id único para cada objeto en oneMonthQuotes
  }));

  const threeMonthQuotes = data?.three_month_quotes.map((quote, index: number) => ({
    ...quote,
    three_month_id: index + 1,
  }));

  const sixMonthQuotes = data?.six_month_quotes.map((quote, index: number) => ({
    ...quote,
    six_month_id: index + 1,
  }));

  const specieDataId = speciesData?.map((specie) => specie.id);

  const silobagInfo = data?.silobag_info;
  //silobags with specieDataId
  const selectedSilobags = silobagInfo?.filter(
    (silobag) => specieDataId && specieDataId.includes(silobag.silobag_species_types.id)
  );
  const selectedSilobagsIds = selectedSilobags ? selectedSilobags?.map((silobag) => silobag.id) : [];

  const discartedSilobags = silobagInfo?.filter(
    (silobag) => specieDataId && !specieDataId.includes(silobag.silobag_species_types.id)
  );

  //Tomo los selectedsSilobags y les doy el formato adecuado para incluirlo en la tabla
  const formatDataTable = (): TabletDataType[] => {
    if (!Array.isArray(selectedSilobags) || selectedSilobags.length === 0) {
      return [];
    }

    const groupedData: TabletDataType[] = Object.values<TabletDataType>(
      selectedSilobags.reduce(
        (acc, silobag) => {
          const speciesId = silobag.silobag_species_types.id;
          const speciesName =
            silobag.silobag_species_types.silobag_species_types_translations?.[0]?.description;

          if (!acc[speciesId]) {
            acc[speciesId] = {
              id: speciesId,
              speciesName,
              quantity: 0,
              totalInputWeight: 0,
              price: 0,
              sum_assured: 0,
            };
          }

          acc[speciesId].quantity += 1;
          acc[speciesId].totalInputWeight += formatTons(+silobag.input_weight) ?? 0;

          return acc;
        },
        {} as Record<string | number, TabletDataType>
      )
    ).map(({ id, speciesName, quantity, totalInputWeight, price }: TabletDataType) => ({
      id,
      speciesName,
      quantity,
      totalInputWeight,
      price,
      sum_assured: (priceData[id] || 0) * totalInputWeight,
    }));

    const totalQuantity = groupedData.reduce((sum, item) => sum + item.quantity, 0);
    const totalInputWeight = groupedData.reduce((sum, item) => sum + item.totalInputWeight, 0);
    const totalSumAssured = groupedData.reduce((sum, item) => sum + item.sum_assured, 0);

    groupedData.push({
      id: TOTAL_VALUE,
      speciesName: TOTAL_VALUE,
      quantity: totalQuantity,
      totalInputWeight,
      price: 0,
      sum_assured: totalSumAssured,
    });

    return groupedData.map((row) => ({
      ...row,
      sum_assured: row.id !== TOTAL_VALUE ? (priceData[row.id] || 0) * row.totalInputWeight : row.sum_assured,
    }));
  };
  useEffectOnUpdate(() => {
    const formattedData = formatDataTable();
    setTableData(formattedData);
    //Busco el valor total de la suma asegurada para poder enviarlo al formulario de cotización
    const totalValueRow = formattedData.find((row) => row.id === TOTAL_VALUE);
    if (totalValueRow) {
      setTotals({ ...totals, summ_assured: totalValueRow.sum_assured, tons: totalValueRow.totalInputWeight });
    }
  }, [silobagInfo, priceData]);
  //Validar que todos los prices sean !== 0 && que el objeto no este en su estado inicial que es vacio
  useEffectOnUpdate(() => {
    //la tabla tiene una row de totales que no se debe contar para hacer la validacion por eso el - 1
    const allNonZero =
      Object.keys(priceData).length === tableData.length - 1 && Object.values(priceData).every(Boolean);
    setValidationPrices(allNonZero);
  }, [priceData, typeCoin]);
  //Resetear campos de la tabla en caso de que el tipo de moneda cambie
  useEffectOnUpdate(() => {
    setPriceData({});
  }, [typeCoin]);

  const fontWeightFormat = (rowId: number | string) => (rowId === TOTAL_VALUE ? 600 : 300);
  //Columnas de la tabla
  const columns: GridColDef[] = [
    {
      field: 'speciesName',
      headerName: i18n.t('app.silobag_data.species'),
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const rowId = params.row.id;
        return <Typography sx={{ fontWeight: fontWeightFormat(rowId) }}>{params.value}</Typography>;
      },
    },
    {
      field: 'quantity',
      headerName: 'Siloreales',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const rowId = params.row.id;
        return <Typography sx={{ fontWeight: fontWeightFormat(rowId) }}>{params.value}</Typography>;
      },
    },
    {
      field: 'totalInputWeight',
      headerName: 'Toneladas',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const rowId = params.row.id;
        return <Typography sx={{ fontWeight: fontWeightFormat(rowId) }}>{params.value}</Typography>;
      },
    },
    {
      field: 'price',
      headerName: 'Precio Pizarra',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const rowId = params.row.id;
        return rowId !== TOTAL_VALUE ? (
          <TextField
            size="small"
            sx={{ width: 120 }}
            type="number"
            value={priceData[rowId] || ''}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              handlePriceChange(rowId, value);
            }}
          />
        ) : null;
      },
    },
    {
      field: 'sum_assured',
      headerName: 'Suma asegurada',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Typography sx={{ fontWeight: fontWeightFormat(rowId) }}>
            {params.value} {coins.find((coin) => coin.id === typeCoin)?.name || 'USD'}
          </Typography>
        );
      },
    },
  ];

  const urlCotizations = 'https://www.cac.bcr.com.ar/es/precios-de-pizarra';
  return (
    <Box
      style={{
        width: '98%',
        margin: 'auto',
        padding: '10px',
      }}
    >
      <Button
        startIcon={<img src={ChevronLeftIcon} style={{ width: 10, height: 10 }} />}
        style={{ color: '#22A7F0' }}
        onClick={() => navigate(`${PATH.SILOBAG}`)}
      >
        {i18n.t('app.return')}
      </Button>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant="h5" style={{ marginTop: '20px', fontWeight: 500 }}>
          {i18n.t('app.quote_silo_insurance')}
        </Typography>
        <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ marginTop: '20px' }}>
          <Link
            href={urlCotizations}
            target="_blank"
            sx={{
              fontWeight: 600,
              color: 'black',
              fontSize: '13px',
              textDecoration: 'none',
              '&:hover': {
                color: '#22a7f0',
              },
            }}
          >
            Consultar cotización de precio pizarra{' '}
          </Link>
          <FormControl size="small" required style={{ width: '144px' }}>
            <InputLabel>Moneda</InputLabel>
            <Select
              label="Moneda"
              sx={{ backgroundColor: 'white' }}
              value={typeCoin}
              onChange={(e) => setTypeCoin(+e.target.value)}
            >
              {coins.map((coin) => (
                <MenuItem key={coin.id} value={coin.id}>
                  {coin.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Box sx={{ marginTop: 5, bgcolor: 'white', padding: 1, borderRadius: '10px' }}>
        {discartedSilobags && discartedSilobags.length > 0 && (
          <Typography style={{ color: 'red', fontSize: '15px' }}>
            No se incluyeron los siguientes SiloReales:{' '}
            <span style={{ fontWeight: 600 }}>
              {discartedSilobags.map((silobag) => silobag.id).join(', ')}
            </span>{' '}
            no hay cotizaciones disponibles para ese tipo de especie
          </Typography>
        )}
        <BaseTable
          rows={tableData}
          columns={columns}
          initialPageSize={10}
          noInternalBorders
          loading={isLoading}
        />
      </Box>
      {/* Cards Insurances */}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ bgcolor: 'white', marginTop: '20px', padding: '25px', borderRadius: '10px' }}>
          {/* SIX MONTH */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Divider sx={{ width: '38%', margin: '0 20px' }} />
            <Typography variant="h5" style={{ fontWeight: 500, textAlign: 'center' }}>
              {i18n.t('app.silobag_insurance.six_month_coverage')}
            </Typography>
            <Divider sx={{ width: '38%', margin: '0px 20px' }} />
          </Box>
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            spacing={6}
            justifyContent={'center'}
            style={{ marginTop: '20px' }}
          >
            {sixMonthQuotes && sixMonthQuotes?.length > 0
              ? sixMonthQuotes.map((item) => (
                  <CardSiloInsurance
                    key={item.six_month_id}
                    logoUrl={item.logo_url_white}
                    item={item}
                    silobagIds={selectedSilobagsIds}
                    vigency={6}
                    totals={totals}
                    validationPrices={validationPrices}
                    typeCoin={typeCoin}
                  />
                ))
              : i18n.t('app.silobag_insurance.no_data_six_month')}
          </Stack>
          {/* THREE MONTH */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Divider sx={{ width: '38%', margin: '0 20px' }} />
            <Typography variant="h5" style={{ fontWeight: 500, textAlign: 'center' }}>
              {i18n.t('app.silobag_insurance.tree_month_coverage')}
            </Typography>
            <Divider sx={{ width: '38%', margin: '0px 20px' }} />
          </Box>
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            spacing={6}
            justifyContent={'center'}
            style={{ marginTop: '20px' }}
          >
            {threeMonthQuotes && threeMonthQuotes?.length > 0
              ? threeMonthQuotes.map((item) => (
                  <CardSiloInsurance
                    key={item.three_month_id}
                    logoUrl={item.logo_url_white}
                    item={item}
                    silobagIds={selectedSilobagsIds}
                    vigency={3}
                    totals={totals}
                    validationPrices={validationPrices}
                    typeCoin={typeCoin}
                  />
                ))
              : i18n.t('app.silobag_insurance.no_data_three_month')}
          </Stack>
          {/* ONE MONTH */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Divider sx={{ width: '38%', margin: '0 20px' }} />
            <Typography variant="h5" style={{ fontWeight: 500, textAlign: 'center' }}>
              {i18n.t('app.silobag_insurance.month_coverage')}
            </Typography>
            <Divider sx={{ width: '38%', margin: '0px 20px' }} />
          </Box>
          <Stack
            direction={isSmallScreen ? 'column' : 'row'}
            spacing={6}
            justifyContent={'center'}
            style={{ marginTop: '20px' }}
          >
            {oneMonthQuotes && oneMonthQuotes?.length > 0
              ? oneMonthQuotes.map((item) => (
                  <CardSiloInsurance
                    key={item.one_month_id}
                    logoUrl={item.logo_url_white}
                    item={item}
                    silobagIds={selectedSilobagsIds}
                    vigency={1}
                    totals={totals}
                    validationPrices={validationPrices}
                    typeCoin={typeCoin}
                  />
                ))
              : i18n.t('app.silobag_insurance.no_data_month')}
          </Stack>
        </Box>
      )}
    </Box>
  );
}

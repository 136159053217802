import { PATH } from '@data/core/paths';
import { Grid, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import i18n from '../../../libs/language';

export function CompleteRegisterForm() {
  const navigate = useNavigate();
  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" color="text.primary" sx={{ margin: 0, maxWidth: '390px' }}>
          {i18n.t('app.public_pages.register_success')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          sx={{ width: '400px', color: 'white' }}
          size="medium"
          disableElevation
          onClick={() => navigate(PATH.LOGIN)}
        >
          {i18n.t('app.public_pages.go_to_login')}
        </Button>
      </Grid>
    </Grid>
  );
}

import './s3Init';
import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Stack,
  Menu,
  MenuItem,
  Fade,
  CircularProgress,
  Typography,
  Divider,
  Tooltip,
} from '@mui/material';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';
import { useParams } from 'react-router-dom';
import ChevronRightIcon from '@assets/svg/core/chevron-right-blue.svg';
import ChevronRightIconDisabled from '@assets/svg/core/chevron-right-disabled.svg';
import ChevronLeftIcon from '@assets/svg/core/chevron-left-blue.svg';
import ChevronLeftIconDisabled from '@assets/svg/core/chevron-left-disabled.svg';
import UploadIcon from '@assets/svg/core/upload.svg';
import SiloID from '@assets/svg/siloreal/product-silo-id.svg';
import SiloIDIconDisabled from '@assets/svg/siloreal/product-silo-id-disabled.svg';
import SiloMetrix from '@assets/svg/siloreal/product-silo-metrix.svg';
import SiloMetrixDisabled from '@assets/svg/siloreal/product-silo-metrix-disabled.svg';
import SiloSat from '@assets/svg/siloreal/product-silo-sat.svg';
import SiloSatDisabled from '@assets/svg/siloreal/product-silo-sat-disabled.svg';
import downloadPdfIcon from '@assets/svg/downloadIcon.svg';
import LabIcon from '@assets/svg/core/lab.svg';
import ShareICON from '@assets/svg/share.svg';
import shareDisabledIcon from '@assets/svg/share-disabled.svg';
import { DataSection, MyModal } from '@features/silobags/silobag-detail/section-data/data-detail';
import { HistorySection } from '@features/silobags/silobag-detail/section-history/silobag-history';
import { DeviceAlertsSection } from '@features/silobags';
import { SilobagSilosat } from '@features/silobags/silobag-detail/section-satellite/silobag-silosat';
import { ExtractionSection } from '@features/silobags/silobag-detail/section-extraction/silobag-extraction';
import { SilobagMetrics } from '@features/silobags/silobag-detail/section-metric/silobag-metric';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/core/paths';
import { useLocation } from 'react-router-dom';
import { ExtractionAction } from '@features/silobags/silobag-detail/exctraction-modal';
import { CloseSilobag } from '@features/silobags/silobag-detail/close-silobag';
import { useRef, useState, useEffect, ChangeEventHandler } from 'react';
import AWS from 'aws-sdk';
import { uploadFileService } from '@services/domain/base/silobag/file/file-upload';
import { useGlobalStore } from '@global-store/use-store';
import { configButtonStylesOutlined } from '@styles/generic-styles';
import { GenerateExtractionsExcelData } from '@utils/export-files';
import { sharePermissionListService } from '@services/domain/base/silobag/share/share-list';
import { SilobagShareModal } from '@features/silobags/silobag-share/silobag-share';
import i18n from '../../libs/language';
import { SILOBAG_STATUS, SILOBAG_STATUS_COLORS, SILOBAG_STATUSES_ID } from '@data/to-refactor/constants';
import EyeIcon from '@assets/svg/core/eye-hollow-blue.svg';
import EyeIconDisabled from '@assets/svg/core/eye-hollow-disabled.svg';
import { BaseTable } from '@ui/table';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatDateTime } from '@utils/helper';
import { ToastAction } from '@ui/toast/toast-action';
import { syncEntitiesCorvusService } from '@services/integration/corvus/sync-corvus';
import { CmcModal } from '@features/silobags/silobag-detail/cmc-blockchain-modal/cmc-modal';
import { extractionListService } from '@services/domain/base/silobag/extraction/extraction-list';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { serviceGetCmcs } from '@services/domain/base/silobag/file/cmc';
import { LabModal } from '@features/silobags/silobag-detail/modal/laboratory/laboratory-detail-modal';

const columns: GridColDef[] = [
  {
    field: 'created_at',
    headerName: i18n.t('app.date'),
    minWidth: 120,
    renderCell: (params) => {
      const created_at = params?.row.created_at;
      return <span>{formatDateTime(created_at, 4)}</span>;
    },
  },
  {
    field: 'status_id',
    headerName: i18n.t('app.status'),
    minWidth: 150,
    renderCell: (params) => {
      const estado = params?.row.status_id;
      return (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Box
            width={'10px'}
            height={'10px'}
            borderRadius={'50%'}
            style={{ backgroundColor: SILOBAG_STATUS_COLORS[(estado as 1) ?? 'default'] }}
          ></Box>
          <Typography>{SILOBAG_STATUS[i18n.locale as 'es'][(estado as 1) ?? 'default']}</Typography>
        </Stack>
      );
    },
  },
  {
    field: 'fullname',
    headerName: i18n.t('app.user'),
    minWidth: 150,
    renderCell: (params) => {
      const fullname = params?.row.users?.fullname;
      return <span>{fullname}</span>;
    },
  },
  {
    field: 'observations',
    headerName: i18n.t('app.observations'),
    minWidth: 190,
    renderCell: (params) => {
      const observations = params?.row.observations;
      return <span>{observations}</span>;
    },
  },
];

type SummarySilobagExtractions = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['silobag_extractions'][0];

export function SilobagDetails() {
  const { siloID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { awsTempCredentials, corvusIntegrated, country_id } = useGlobalStore();
  const siloListId = location.state?.siloListId;
  const pathSilobags = location.state?.pathSilobags;
  const pathEditSilobags = location.state?.pathEditSilobag;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [modalExtraction, setModalExtraction] = useToggle(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useToggle(false);
  const [typeSnackbar, setTypeSnackbar] = useState<'success' | 'error'>('success');
  const [openCloseModal, setOpenCloseModal] = useToggle(false);
  const [isClosed, setIsClosed] = useState<boolean | undefined>(false);
  const [openCmcModal, setOpenCmcModal] = useToggle(false);
  // VERIFICAR SI LA SB FUE COMPARTIDA CON EXITO
  const [openSilobagShareSnackbar, setOpenSilobagShared] = useToggle(false);
  const [silobagShared, setSilobagShared] = useState<{
    message: string;
    state: boolean;
    type: 'success' | 'error';
  }>({ message: '', state: false, type: 'success' });
  const [toastState, setToastState] = useToggle(false);

  useEffectOnUpdate(() => {
    setToastState(silobagShared.state);
  }, [silobagShared]);

  // Obtengo si se editó la silobolsa con éxito
  const { state } = location;
  // Utilizamos useState para manejar el estado del valor editSiloSuccess
  const [editSiloSuccess, setEditSiloSuccess] = useState(state?.newOrEditSilobagSuccessfull);

  const editSiloSuccessRef = useRef(editSiloSuccess);

  useEffect(() => {
    editSiloSuccessRef.current = editSiloSuccess;
    const timer = setTimeout(() => {
      setEditSiloSuccess(null);
    }, 3000);

    return () => clearTimeout(timer);
  }, [editSiloSuccess]);

  //TODO: opcional
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [modalShare, setModalShare] = useToggle(false);
  const [openModal, setOpenModal] = useToggle(false);
  const [validateSB, setValidateSB] = useState(false);
  const [extractionSuccess, setExtractionSuccess] = useState(false);
  const queryParamSiloID = siloID ?? 'id-not-found';
  const { trigger, summary, isLoading, isError } = useService(silobagDataDetailService, {
    fetchOnMount: [{ query: queryParamSiloID }],
  });

  useEffectOnUpdate(() => {
    trigger({ query: queryParamSiloID });
  }, [siloID]);

  useEffect(() => {
    if (isError) {
      navigate(PATH.NOT_FOUND);
    }
  }, [isError]);

  useEffectOnUpdate(() => {
    if (extractionSuccess) {
      trigger({ query: queryParamSiloID });
      setExtractionSuccess(false);
    }
  }, [extractionSuccess]);

  //Silobag Permissions Service for Share Silobag
  const {
    trigger: getSiloPermission,
    summary: dataSiloPermission,
    isLoading: isLoadingSiloPermission,
  } = useService(sharePermissionListService);

  //Extractions Service and hook
  const {
    trigger: getExtracions,
    summary: dataExtractions,
    isLoading: isLoadingExtractions,
  } = useService(extractionListService);

  useEffectOnUpdate(() => {
    if (!isLoadingExtractions && dataExtractions) {
      GenerateExtractionsExcelData(dataExtractions, siloID);
    }
  }, [!isLoadingExtractions]);

  //Get All CMCs
  const { trigger: getCmcs, summary: dataCmcs, isLoading: isLoadingCmcs } = useService(serviceGetCmcs);

  const currentIndex = siloListId?.indexOf(siloID);
  const handleNextClick = () => {
    if (siloListId && currentIndex !== -1 && currentIndex < siloListId.length - 1) {
      const nextSiloID = siloListId[currentIndex + 1];
      navigate(`${PATH.SILOBAG}/${nextSiloID}`, { state: { siloListId, pathSilobags } });
    }
  };
  const handlePreviousClick = () => {
    if (siloListId && currentIndex !== -1 && currentIndex > 0) {
      const previousSiloID = siloListId[currentIndex - 1];
      navigate(`${PATH.SILOBAG}/${previousSiloID}`, { state: { siloListId, pathSilobags } });
    }
  };

  AWS.config.update({
    region: 'us-east-1',
    accessKeyId: awsTempCredentials.AccessKeyId,
    secretAccessKey: awsTempCredentials.SecretAccessKey,
    sessionToken: awsTempCredentials.SessionToken,
  });

  const BUCKET = 'iofbucket';

  const s3 = new AWS.S3();

  const handleSuccessUpload = () => {
    setTypeSnackbar('success');
    setSnackbarMessage('Se guardó el archivo con éxito.');
    setOpenSnackbar(true);
    trigger({ query: summary?.id ?? 'id-not-found' });
  };

  const handleErrorUpload = () => {
    setTypeSnackbar('error');
    setSnackbarMessage('Hubo un error al guardar el archivo.');
    setOpenSnackbar(true);
  };

  const {
    trigger: uploadFile,
    isError: isErrorUploading,
    isLoading: isLoadingUploading,
    isSuccess: isSuccessUploading,
  } = useService(uploadFileService);

  useEffectOnUpdate(() => {
    if (isSuccessUploading && !isLoadingUploading) {
      handleSuccessUpload();
    }
  }, [isSuccessUploading]);

  useEffect(() => {
    setIsClosed(!summary?.active);
  }, [summary]);

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);

    if (isErrorUploading) {
      handleErrorUpload();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const configButtonStyles = {
    fontSize: 14,
    borderRadius: 100,
    textTransform: 'none',
  };

  const LOGO = {
    SiloID: <img src={SiloID} style={{ width: 24, height: 24 }} />,
    SiloIDDisabled: <img src={SiloIDIconDisabled} style={{ width: 24, height: 24 }} />,
    SiloMetrix: <img src={SiloMetrix} style={{ width: 24, height: 24 }} />,
    SiloMetrixDisabled: <img src={SiloMetrixDisabled} style={{ width: 24, height: 24 }} />,
    SiloSat: <img src={SiloSat} style={{ width: 24, height: 24 }} />,
    SiloSatDisabled: <img src={SiloSatDisabled} style={{ width: 24, height: 24 }} />,
  };

  const handleOpenCloseSilobagModal = () => {
    setOpenCloseModal(true);
  };

  const handleFileInputChange: ChangeEventHandler<HTMLInputElement> | undefined = async (e) => {
    const file = e?.target?.files?.[0];
    const fileName = file?.name;

    const date = new Date().toISOString();
    const params = {
      Body: file,
      Bucket: BUCKET,
      Key: `files/DEV/${siloID}/${date}.${fileName}`, // TODO: CAMBIAR 'env' POR VARIABLE DE ENTORNO DEV/PROD/QA DEPENDIENDO DEL AMBIENTE EN EL QUE SE ENCUENTRE
    };

    const { Location } = await s3.upload(params).promise();

    const silobag_cmcs_content_body = {
      silobag_id: siloID ?? 'id-not-found',
      file_path: Location,
    };

    uploadFile({ body: silobag_cmcs_content_body });
  };

  //funcion modal Silobag Share
  const handleModalShare = (siloId: string, isValidatedSb: boolean) => {
    setValidateSB(isValidatedSb);
    getSiloPermission({ query: siloId });
    setModalShare(true);
  };

  const handleButtonClick = () => {
    // Simula un clic en el input para abrir la ventana de selección de archivos.
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseModal = () => setOpenModal(false);
  //SYNC ENTITIES EN CORVUS

  const {
    trigger: syncEntitiesCorvus,
    isLoading: isLoadingSyncEntities,
    isError: errorSyncEntites,
    isSuccess: successSyncEntities,
  } = useService(syncEntitiesCorvusService);

  useEffectOnUpdate(() => {
    if (successSyncEntities && !isLoadingSyncEntities) {
      navigate(`${PATH.SILOBAG}/corvus/${siloID}`);
    }
  }, [successSyncEntities, isLoadingSyncEntities]);

  const linkCMCInitial = summary?.cmcs[0]?.file_path;

  const isValidatedSb = summary?.silobag_data_status_id === 2;
  const showEyeIcon = summary && summary?.poe.length > 0 ? EyeIcon : EyeIconDisabled;

  const isValidSilobagStatus =
    summary?.silobag_data_status_id === SILOBAG_STATUSES_ID.VALID_SILOBAG_STATUS_ID;
  const isSiloViewActive = summary?.v_silobags_services_statuses?.sat_view_active;
  const isSiloMetrixActive = summary?.v_silobags_services_statuses?.silo_metrix_active;

  const silobagStatusLogo = isValidSilobagStatus ? LOGO.SiloID : LOGO.SiloIDDisabled;
  const siloViewLogo = isSiloViewActive ? LOGO.SiloSat : LOGO.SiloSatDisabled;
  const siloMetrixLogo = isSiloMetrixActive ? LOGO.SiloMetrix : LOGO.SiloMetrixDisabled;

  const [openLabModal, setOpenLabModal] = useToggle(false);
  const handleOpenLabModal = () => setOpenLabModal(true);
  const handleCloseLabModal = () => setOpenLabModal(false);

  const insuranceValidation =
    summary?.write_permission === false || country_id !== 1 || summary?.silobag_data_status_id === 1;
  // TODO: Tipar v_is_silobag_available_for_closure
  const availableMobileClose = summary?.v_is_silobag_available_for_closure.result;

  return (
    <>
      {errorSyncEntites && (
        <ToastAction
          severity={'error'}
          message="No es posible sincronizar, error con el servicio de corvus"
        />
      )}
      {isLoading ? (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <CircularProgress style={{ height: 60, width: 60 }} />
        </Stack>
      ) : (
        <>
          {editSiloSuccessRef.current && (
            <ToastAction
              key={'success'}
              message={i18n.t('app.silobag_detail.edit_silobag')}
              severity={'success'}
            />
          )}
          <MyModal open={openModal} onClose={handleCloseModal}>
            <Box>
              <Typography variant="h5">{i18n.t('app.silobag_detail.general_evaluation')}</Typography>
            </Box>

            <Divider />
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography fontWeight={500}>{i18n.t('app.silobag_detail.state_initial')}:</Typography>
              <Box
                width={'10px'}
                height={'10px'}
                borderRadius={'50%'}
                style={{ backgroundColor: SILOBAG_STATUS_COLORS[(summary?.status_id as 1) ?? 'default'] }}
              />
              <Typography>
                {SILOBAG_STATUS[i18n.locale as 'es'][(summary?.status_id as 1) ?? 'default']}
              </Typography>
            </Stack>
            <Box maxWidth={'100%'} overflow={'auto'}>
              <BaseTable columns={columns} rows={summary?.poe ?? []} initialPageSize={5} />
            </Box>

            <Box width={'100%'} display={'flex'} justifyContent={'end'}>
              <Button variant="text" onClick={() => setOpenModal(false)}>
                {i18n.t('app.close')}
              </Button>
            </Box>
          </MyModal>
          {silobagShared.state ? (
            <ToastAction
              message={silobagShared.message}
              severity={silobagShared.type}
              openToast={toastState}
              setOpenToast={setToastState}
            />
          ) : null}
          <Stack component="section" style={{ width: '98%', marginTop: 15, margin: '0 auto' }} spacing={2}>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert onClose={handleClose} severity={typeSnackbar} variant="filled" sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
            <Box style={{ display: 'flex', justifyContent: 'space-between', gap: 75 }}>
              <Box style={{ flex: 1 }}>
                <Stack direction={'row'} marginTop={1}>
                  <Button
                    startIcon={<img src={ChevronLeftIcon} style={{ width: 10, height: 10 }} />}
                    style={{ color: '#22A7F0', display: window.history.length <= 3 ? 'none' : '' }}
                    onClick={() => {
                      //TODO: Analizar este flujo para no tener que hacer el condicional  y que el navigate reconozca el nivel
                      if (pathSilobags || pathEditSilobags) {
                        navigate(PATH.SILOBAG);
                      } else {
                        navigate(-1);
                      }
                    }}
                  />
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <h1
                      style={{
                        whiteSpace: 'nowrap',
                        fontSize: 28,
                      }}
                    >
                      {summary?.name}
                    </h1>
                    {silobagStatusLogo}
                    {siloViewLogo}
                    {siloMetrixLogo}
                    <Stack direction={'row'} display={'flex'} alignItems={'center'}>
                      <Typography color={(summary && summary?.v_current_poe_status?.hex) ?? '#C5BFBF'}>
                        {summary &&
                          SILOBAG_STATUS[i18n.locale as 'es'][
                            (summary?.v_current_poe_status?.status_id as 1) ?? 'default'
                          ]}
                      </Typography>
                      <IconButton
                        onClick={() => setOpenModal(true)}
                        disabled={summary && summary.poe.length > 0 ? false : true}
                      >
                        <img src={showEyeIcon} />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Box></Box>
                </Stack>
              </Box>

              <Button
                startIcon={
                  <img
                    src={
                      currentIndex === 0 || siloListId?.length == undefined
                        ? ChevronLeftIconDisabled
                        : ChevronLeftIcon
                    }
                    style={{ width: 10, height: 10 }}
                  />
                }
                style={{
                  color: currentIndex === 0 || siloListId?.length == undefined ? '#D3D3D3' : '#22A7F0',
                }}
                disabled={currentIndex === 0 || siloListId?.length == undefined}
                onClick={handlePreviousClick}
              >
                {i18n.t('app.silobag_detail.previous')}
              </Button>

              <Button
                endIcon={
                  <img
                    src={
                      currentIndex === siloListId?.length - 1 || siloListId?.length == undefined
                        ? ChevronRightIconDisabled
                        : ChevronRightIcon
                    }
                    style={{ width: 10, height: 10 }}
                  />
                }
                style={{
                  color:
                    currentIndex === siloListId?.length - 1 || siloListId?.length == undefined
                      ? '#D3D3D3'
                      : '#22A7F0',
                }}
                disabled={currentIndex === siloListId?.length - 1 || siloListId?.length == undefined}
                onClick={handleNextClick}
              >
                {i18n.t('app.silobag_detail.next')}
              </Button>
            </Box>

            <Stack direction="row" flexWrap="wrap" marginBottom={50}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'start', md: 'center' }}
                spacing={1}
                justifyContent="start"
                sx={{ flex: 1 }}
                mr={{ xs: 1, md: 0 }}
              >
                <Stack
                  direction={'row'}
                  display={'flex'}
                  alignItems="center"
                  spacing={1}
                  justifyContent="start"
                  sx={{ flex: 1 }}
                  width={{ xs: '95%', md: '100%' }}
                >
                  <Tooltip title={i18n.t('app.silobag_detail.tooltip_charge_files')}>
                    <Button
                      size="large"
                      component="label"
                      variant="outlined"
                      startIcon={<img src={UploadIcon} />}
                      sx={{
                        ...configButtonStyles,
                        fontWeight: 600,
                        paddingLeft: 1.7,
                        width: '200px',
                        '&:hover': {
                          border: '1px solid #6baa00',
                        },
                        height: '100%',
                      }}
                      disabled={summary?.write_permission === false ? true : false}
                      onClick={handleButtonClick}
                    >
                      {i18n.t('app.silobag_detail.charge_files')}
                      <input type="file" style={{ display: 'none' }} onChange={handleFileInputChange} />
                    </Button>
                  </Tooltip>
                  <Button
                    startIcon={<img src={downloadPdfIcon} alt="download" />}
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant="outlined"
                    sx={{ ...configButtonStylesOutlined, height: '100%', width: '180px' }}
                    size="large"
                    style={{ fontWeight: 600, width: '180px' }}
                  >
                    {i18n.t('app.silobag_list.download_data')}
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseAnchor}
                    TransitionComponent={Fade}
                  >
                    <Tooltip title={i18n.t('app.silobag_detail.donwload_certificate_history')}>
                      <Box>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setOpenCmcModal(true);
                            getCmcs({
                              query: { id: siloID ?? 'id-not-found', blockchain: true, silobagHistory: true },
                            });
                            setAnchorEl(null);
                          }}
                          sx={{ color: '#26C4B7' }}
                          disabled={summary?.silobag_data_status_id === 1}
                        >
                          Documentos SiloID
                        </MenuItem>
                      </Box>
                    </Tooltip>
                    <Tooltip title={i18n.t('app.silobag_detail.download_extractions')}>
                      <Box>
                        <MenuItem onClick={getExtracions} sx={{ color: '#26C4B7' }}>
                          {i18n.t('app.silobag_detail.register_extractions')}
                        </MenuItem>
                      </Box>
                    </Tooltip>
                  </Menu>
                  <Tooltip title={i18n.t('app.silobag_detail.show_lab')}>
                    <Box>
                      <Button
                        startIcon={<img src={LabIcon} alt="download" />}
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpenLabModal}
                        variant="outlined"
                        sx={{ ...configButtonStylesOutlined, height: '100%', minWidth: 'max-content' }}
                        size="large"
                        style={{ fontWeight: 600, width: '180px' }}
                      >
                        {i18n.t('app.lab.detail_sample_modal.title')}
                      </Button>
                    </Box>
                  </Tooltip>
                </Stack>
                <Button
                  variant="contained"
                  sx={{
                    ...configButtonStyles,
                    color: '#FFF',
                    width: { xs: '95%', md: '180px' },
                    display: isClosed ? 'none' : 'block',
                  }}
                  onClick={() => setModalExtraction(true)}
                  disabled={isClosed || summary?.write_permission === false ? true : false}
                >
                  {i18n.t('app.silobag_detail.perform_extraction')}
                </Button>
                <Tooltip
                  title={i18n.t('app.silobag_detail.sync_corvus')}
                  disableHoverListener={corvusIntegrated}
                  disableInteractive
                >
                  <Box display="inline-block">
                    <Button
                      sx={{
                        ...configButtonStyles,
                        width: { xs: '95%', md: '150px' },
                        color: '#FFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() => syncEntitiesCorvus({ body: { daysBefore: 365 } })}
                      variant="contained"
                      disabled={!corvusIntegrated || !summary?.write_permission}
                    >
                      {isLoadingSyncEntities ? (
                        <CircularProgress size={20} sx={{ color: 'white' }} />
                      ) : (
                        'Modulo Corvus'
                      )}
                    </Button>
                  </Box>
                </Tooltip>
                <Button
                  variant="contained"
                  sx={{
                    ...configButtonStyles,
                    width: { xs: '95%', md: '200px' },
                    backgroundColor: '#961EFA',
                    color: '#FFF',
                    display: isClosed ? 'none' : 'block',
                  }}
                  disabled={insuranceValidation || !availableMobileClose}
                  onClick={() =>
                    navigate(`${PATH.SILOBAG}/insurance/${siloID}`, {
                      state: { siloID: siloID },
                    })
                  }
                >
                  {i18n.t('app.quote_silo_insurance')}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    ...configButtonStyles,
                    width: { xs: '95%', md: '100px' },
                    display: isClosed ? 'none' : 'block',
                  }}
                  onClick={handleOpenCloseSilobagModal}
                  disabled={summary?.write_permission === false ? true : false}
                >
                  {i18n.t('app.silobag_detail.close')}
                </Button>

                <IconButton
                  sx={{
                    background: 'rgba(107, 170, 0, 0.25)',
                  }}
                  onClick={() => handleModalShare(siloID ?? 'id-not-found', isValidatedSb)}
                  disabled={summary?.full_permission === false ? true : false}
                >
                  <img
                    src={summary?.full_permission === false ? shareDisabledIcon : ShareICON}
                    style={{ objectFit: 'contain' }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Stack spacing={3.5}>
              <DataSection
                data={summary!}
                isLoading={isLoading}
                isClosed={isClosed}
                getData={trigger}
                siloListId={siloListId}
                siloID={siloID ?? 'id-not-found'}
              />
              <HistorySection data={summary} Poe={summary?.poe} isLoading={isLoading} />
              {summary?.silobag_data_status_id === 2 ? (
                <SilobagSilosat
                  silosat={summary?.v_filtered_sat_view_images}
                  statuses={summary?.v_silobags_services_statuses}
                  existence={summary?.v_silobags_existence_statuses}
                />
              ) : null}
              <ExtractionSection
                active={summary?.active}
                data={summary?.silobag_extractions as SummarySilobagExtractions | undefined}
                siloName={summary?.name}
                writePermission={summary?.write_permission}
                siloTrigger={trigger}
                setTypeSnackbar={setTypeSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                remainingStock={summary?.remaining_stock}
                setExtractionSuccess={setExtractionSuccess}
                siloID={siloID ?? 'id-not-found'}
              />
              <DeviceAlertsSection
                isClosed={isClosed}
                data={summary?.silobags_devices}
                writePermission={summary?.write_permission}
                eventHistory={summary?.historicAlerts}
                silobagName={summary?.name}
                isLoading={isLoading}
                trigger={trigger}
              />
              <SilobagMetrics siloId={siloID ?? 'id not found'} silobag={summary} />
            </Stack>
            {modalExtraction && (
              <ExtractionAction
                type="new"
                open={modalExtraction}
                setOpen={setModalExtraction}
                silobagOrExtractionId={siloID}
                siloName={summary?.name}
                remainingStock={summary?.remaining_stock}
                setSnackbarMessage={setSnackbarMessage}
                setTypeSnackbar={setTypeSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                siloTrigger={trigger}
                setExtractionSuccess={setExtractionSuccess}
              />
            )}
            {openCmcModal && (
              <CmcModal
                open={openCmcModal}
                setOpen={setOpenCmcModal}
                // TODO: Lo ve Victor cuando cambie el tipado de cmcs -> TIC-3122
                data={dataCmcs!}
                siloId={siloID ?? 'not-found'}
                isLoadingData={isLoadingCmcs}
                // TODO: Lo ve Victor cuando cambie el tipado de cmcs -> TIC-3122
                triggerData={getCmcs}
                linkCMCInitial={linkCMCInitial}
                getSiloData={trigger}
              />
            )}
            <Snackbar
              open={openSilobagShareSnackbar}
              autoHideDuration={3000}
              onClose={() => setOpenSilobagShared(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert
                onClose={() => setOpenSilobagShared(false)}
                severity={typeSnackbar}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
            {modalShare && (
              <SilobagShareModal
                open={modalShare}
                setOpen={setModalShare}
                siloId={siloID ?? ''}
                siloName={summary?.name}
                data={dataSiloPermission}
                isLoadingData={isLoadingSiloPermission}
                getData={getSiloPermission}
                setSilobagShared={setSilobagShared}
                hasInvalidStatus={!validateSB}
                CountrysideName={summary?.lots.countrysides.name}
              />
            )}
            {!isLoading && summary ? (
              <CloseSilobag
                open={openCloseModal}
                setOpen={setOpenCloseModal}
                siloData={summary}
                setTrigger={trigger}
              />
            ) : null}
            {openLabModal ? (
              <LabModal
                open={openLabModal}
                onClose={handleCloseLabModal}
                onSuccess={() => trigger({ query: queryParamSiloID })}
                siloID={summary?.id ?? ''}
                siloVariety={summary?.variety}
                siloSpecies={summary?.silobag_species_types.silobag_species_types_translations[0].description}
              />
            ) : null}
          </Stack>
        </>
      )}
    </>
  );
}

import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import SearchIcon from '@assets/svg/search.svg';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { useDebounce } from '@hooks/core';
import { useGlobalStore } from '@global-store/use-store';
import { BaseTable } from '@ui/table';
import i18n from '../../libs/language';
import { useEffectOnUpdate, useToggle } from '@hooks/core';
import RemoveSearchIcon from '@assets/svg/core/cross.svg';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/core/paths';
import ChevronRight from '@assets/svg/chevron-right.svg';
import { silobagHistoryEventCategoriesService } from '@services/domain/base/silobag/list/silobag-history-event-categories';
import { listActivityReportService } from '@services/domain/page/report/activity/report-activity-info';
import viewIcon from '@assets/svg/core/eye-hollow-blue.svg';
import { ExtractionAction } from '@features/silobags/silobag-detail/exctraction-modal';
import { EditSBModal } from '@features/silobags/silobag-detail/section-history/edit-silobag-modal';
import { PoeModal } from '@features/silobags/silobag-detail/section-history/poe-silobag-modal';
import { listSilobagHistoryService } from '@services/domain/base/silobag/history/get-silobag-history';
import imgIcon from '@assets/svg/img.svg';
import { CarouselModalPhotoGallery } from '@ui/carousel/carousel';
import { EVENTS_IDS } from '@utils/helper';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { Selector } from '@ui/selector/selector';
import { AddPrefixToEachKey, Nullable } from '@typed/extra/utils';
import { basicListUsersService } from '@services/domain/user/base/user-list-basic';

type ActivityReportSummary = Awaited<ReturnType<typeof listActivityReportService>>['summary']['activities'];

type SilobagHistoryEventProps = Awaited<ReturnType<typeof silobagHistoryEventCategoriesService>>['summary'];
type SilobagHistoryEventTypes = SilobagHistoryEventProps[0];
type BkColor = SilobagHistoryEventTypes['bk_color'];
type TextColor = SilobagHistoryEventTypes['text_color'];
type EventID =
  | SilobagHistoryEventTypes['id']
  | SilobagHistoryEventTypes['silobags_history_event_types_translations'][0]['event_type_id'];
type Description =
  | SilobagHistoryEventTypes['description']
  | SilobagHistoryEventTypes['silobags_history_event_types_translations'][0]['description'];

type EventData = {
  bk_color: BkColor;
  text_color: TextColor;
  description: Description;
  id: EventID;
};

type LanguageRecord = Record<string, EventData>;

type ImgCoreProps = Nullable<{
  url?: string;
  label?: string;
}>;
type ImgCommonProps = Nullable<{
  lat?: string;
  lon?: string;
  accuracy?: string;
  timestamp?: string;
  azimuth?: string;
}>;
type Img = ImgCoreProps &
  (
    | AddPrefixToEachKey<ImgCommonProps, 'init_'>
    | AddPrefixToEachKey<ImgCommonProps, 'main_'>
    | AddPrefixToEachKey<ImgCommonProps, 'end_'>
  );
type Images = Img[];

type SilobagHistoryResponse = Awaited<ReturnType<typeof listSilobagHistoryService>>['summary']['response'];

export function ActivityReportBoard() {
  const { organizationId, filtersReportActivity, setFiltersReportActivity, preferedLanguageId } =
    useGlobalStore();

  const [translatedEventTypes, setTranslatedEventTypes] = useState<EventData[] | undefined>([]);

  const { typeId, countrysideId, userId, paginationModel } = filtersReportActivity;
  const { page, pageSize } = paginationModel;
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [openExtractionModal, setOpenExtractionModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPoeModal, setOpenPoeModal] = useState(false);
  const [openModalImages, setOpenModalImages] = useToggle(false);
  const [imgs, setImgs] = useState<Images>([]);
  const [eventTypeId, setEventTypeId] = useState<number | undefined>(undefined);
  const payload = {
    query: {
      organization_id: organizationId,
      countryside_id: parseInt(countrysideId, 10),
      event_type_id: parseInt(typeId, 10),
      user_id: parseInt(userId, 10),
      name: search,
      take: pageSize,
      page,
    },
  };

  const {
    trigger: getActivity,
    summary: activityData,
    isLoading: isActivityDataLoading,
  } = useService(listActivityReportService, { fetchOnMount: [payload] });
  const activityList = activityData?.activities;
  const queryCountrysideList = { organization_id: organizationId };
  const {
    summary: csList,
    isLoading: isCsListLoading,
    trigger: getCsList,
  } = useService(listCountrysideService, { fetchOnMount: [{ query: queryCountrysideList }] });
  useEffectOnUpdate(() => getCsList({ query: queryCountrysideList }), [organizationId]);

  const {
    summary: usersList,
    trigger: getUsersList,
    isLoading: isUsersListLoading,
  } = useService(basicListUsersService, {
    fetchOnMount: [{ query: { organization_id: organizationId } }],
  });

  const { summary: eventTypesList, isLoading: isEventTypesListLoading } = useService(
    silobagHistoryEventCategoriesService,
    { fetchOnMount: true }
  );

  const {
    trigger: triggerSilobagHistoryService,
    summary: historyData,
    isLoading: loadingHistory,
  } = useService(listSilobagHistoryService);

  const response = historyData?.response;

  const initSelection = {
    typeId: '',
    countrysideId: '',
    userId: '',
    paginationModel: {
      pageSize: 50,
      page: 0,
    },
  };

  // Debouncing logic
  const fetchWithQueryParams = () => (
    getActivity(payload), getUsersList({ query: { organization_id: organizationId } })
  );
  useDebounce(fetchWithQueryParams, 500, [search, filtersReportActivity, organizationId]);

  const removeFilter = () => {
    setSearch('');
    setFiltersReportActivity(initSelection);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { paginationModel: _, ...restFilters } = filtersReportActivity;
  const hasFilters = !!search || Object.values(restFilters).some((s) => s !== '');

  const activityUserIds = activityData?.activities?.map((activity) => activity.user_id) || [];

  const filteredUserOrganization =
    organizationId === undefined ? usersList : usersList?.filter((user) => activityUserIds.includes(user.id));

  const closeAllModals = () => {
    setOpenExtractionModal(false);
    setOpenEditModal(false);
    setOpenPoeModal(false);
    setOpenModalImages(false);
  };
  //Logica del detalle de la tabla de activity
  const showImgs = (silobagEventTypeId: number) => {
    //Action type 1 = Foto dispositivo
    const photoDevice = 1;

    const showSiloMetrixImg = (
      response: SilobagHistoryResponse | undefined, //
      setImgs: Dispatch<SetStateAction<Images>>,
      actionTypeID: number
    ) => {
      console.log(response, setImgs, actionTypeID);
      const devices = response?.silobags_devices;

      if (!(devices?.silobags_devices_actions.length ?? -1 > 0)) {
        setImgs([]);
        return;
      }

      const images: Images = [];
      devices?.silobags_devices_actions
        .filter((da) => da.silobags_action_types_id === actionTypeID)
        .forEach((da) =>
          images.push({
            url: da.image,
            label: devices?.devices?.lance_number?.length > 0 ? devices?.devices?.lance_number : '',
            init_lat: da.lat,
            init_lon: da.lon,
            init_accuracy: da.accuracy,
            init_timestamp: da.timestamp,
            init_azimuth: da.azimuth,
          })
        );
      setImgs(images);
    };

    if (silobagEventTypeId === EVENTS_IDS.ADD_SILOMETRIX && historyData) {
      showSiloMetrixImg(response, setImgs, photoDevice);
      setOpenModalImages(true);
    }
    //Action type 11 = Foto desvinculacion dispositivo
    const photoDesvinculateDevice = 11;
    if (silobagEventTypeId === EVENTS_IDS.DESVINCULATE_SILOMETRIX && historyData) {
      showSiloMetrixImg(response, setImgs, photoDesvinculateDevice);
      setOpenModalImages(true);
    }
    //Alta en Campo
    if (silobagEventTypeId === EVENTS_IDS.REGISTER_IN_FIELD && historyData) {
      const initImage = {
        url: response?.silobags_versions?.data?.init_image,
        init_lat: response?.silobags_versions?.data?.init_lat,
        init_lon: response?.silobags_versions?.data?.init_lon,
        init_accuracy: response?.silobags_versions?.data?.init_accuracy,
        init_timestamp: response?.silobags_versions?.data?.init_timestamp,
        init_azimuth: response?.silobags_versions?.data?.init_azimuth,
      };
      const mainImage = {
        url: response?.silobags_versions?.data?.main_image,
        main_lat: response?.silobags_versions?.data?.main_lat,
        main_lon: response?.silobags_versions?.data?.main_lon,
        main_accuracy: response?.silobags_versions?.data?.main_accuracy,
        main_timestamp: response?.silobags_versions?.data?.main_timestamp,
        main_azimuth: response?.silobags_versions?.data?.main_azimuth,
      };
      const endImage = {
        url: response?.silobags_versions?.data?.end_image,
        end_lat: response?.silobags_versions?.data?.end_lat,
        end_lon: response?.silobags_versions?.data?.end_lon,
        end_accuracy: response?.silobags_versions?.data?.end_accuracy,
        end_timestamp: response?.silobags_versions?.data?.end_timestamp,
        end_azimuth: response?.silobags_versions?.data?.end_azimuth,
      };
      setImgs([initImage, mainImage, endImage]);
      setOpenModalImages(true);
    }
  };

  const showDetail = (silobagEventTypeId: number) => {
    closeAllModals();

    setEventTypeId(undefined);

    if (silobagEventTypeId === EVENTS_IDS.EXTRACTION) {
      setOpenExtractionModal(true);
      return;
    }

    if (silobagEventTypeId === EVENTS_IDS.EDITION_SILO) {
      setOpenEditModal(true);
      return;
    }

    if (silobagEventTypeId === EVENTS_IDS.POE) {
      setOpenPoeModal(true);
      return;
    }
  };

  useEffectOnUpdate(() => {
    if (eventTypeId && response) {
      showImgs(eventTypeId);
    }
  }, [response, eventTypeId]);

  const columns: GridColDef<ActivityReportSummary[number]>[] = [
    {
      field: 'created_at',
      headerName: i18n.t('app.date'),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const createdDate = params.row.created_at;
        const dateToDisplay = dayjs(createdDate).format('DD/MM/YYYY');
        return <Box>{dateToDisplay}</Box>;
      },
    },
    {
      field: 'type',
      headerName: i18n.t('app.type'),
      sortable: false,
      flex: 3,
      minWidth: 240,
      renderCell: (params) => {
        const [translation] =
          params.row.silobags_history_event_types.silobags_history_event_types_translations;
        const text = translation.description;

        const backgroundColor = params.row.silobags_history_event_types.bk_color;
        const textColor = params.row.silobags_history_event_types.text_color;

        return (
          <Chip
            label={text}
            style={{
              height: '32px',
              backgroundColor,
              color: textColor,
              borderRadius: '8px',
              fontWeight: 500,
            }}
          />
        );
      },
    },
    {
      field: 'silobag_id',
      headerName: 'ID ' + i18n.t('app.silobag'),
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box component="div" style={{ display: 'flex', alignItems: 'center' }}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            direction={'row'}
            spacing={1}
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 500,
                fontSize: '13px',
                color: '#05AADF',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => navigate(`${PATH.SILOBAG}/${params.row.silobag_id}`)}
            >
              {params.row.silobag_id}
            </Typography>
            <img src={ChevronRight} alt="go-to-silo" style={{ paddingTop: 5 }} />
          </Stack>
        </Box>
      ),
    },
    {
      field: 'silobag_name',
      headerName: i18n.t('app.silobag'),
      sortable: false,
      flex: 1,
      renderCell: (params) => <Box>{params.row.silobags.name}</Box>,
    },
    {
      field: 'countryside_name',
      headerName: i18n.t('app.countryside'),
      sortable: false,
      flex: 1,
      renderCell: (params) => <Box>{params.row.silobags.countrysides.name}</Box>,
    },
    {
      field: 'user_fullname',
      headerName: i18n.t('app.user'),
      sortable: false,
      flex: 1,
      renderCell: (params) => <Box>{params.row.users?.fullname}</Box>,
    },
    {
      field: 'silobag_event_type_id',
      headerName: 'Detalle',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const silobagEventTypeId = params.row.silobag_event_type_id;
        //Funcion para verificar si tiene Detalle el evento por ahora solo se agregaran estos eventos
        const hasDetail = (silobagEventId: number) => {
          if (silobagEventId == EVENTS_IDS.EXTRACTION) return true;
          if (silobagEventId == EVENTS_IDS.EDITION_SILO) return true;
          if (silobagEventId == EVENTS_IDS.POE) return true;
          return false;
        };
        //Funcion para verificar si tiene imagenes el evento por ahora solo se agregaran estos eventos
        const hasImages = (silobagEventId: number) => {
          if (silobagEventId == EVENTS_IDS.REGISTER_IN_FIELD) return true;
          if (silobagEventId == EVENTS_IDS.ADD_SILOMETRIX) return true;
          if (silobagEventId == EVENTS_IDS.DESVINCULATE_SILOMETRIX) return true;
          return false;
        };
        return (
          <>
            <Stack direction={'row'} spacing={0.5}>
              {hasDetail(silobagEventTypeId) && (
                <IconButton
                  sx={{
                    color: '#22A7F0',
                    width: 24,
                    height: 24,
                    padding: '4px',
                    borderRadius: '4px',
                    '&:hover': {
                      background: '#22a7f02a',
                    },
                  }}
                  onClick={() => {
                    triggerSilobagHistoryService({ query: params.row.id });
                    showDetail(silobagEventTypeId);
                  }}
                >
                  <img src={viewIcon} alt="eye" style={{ width: 18, height: 18 }} />
                </IconButton>
              )}
              {hasImages(silobagEventTypeId) && (
                <IconButton
                  sx={{
                    color: '#22A7F0',
                    width: 24,
                    height: 24,
                    padding: '4px',
                    borderRadius: '4px',
                    '&:hover': {
                      background: '#22a7f02a',
                    },
                  }}
                  onClick={() => {
                    triggerSilobagHistoryService({ query: params.row.id });
                    setEventTypeId(silobagEventTypeId);
                  }}
                >
                  <img src={imgIcon} alt="img" style={{ width: 18, height: 18 }} />
                </IconButton>
              )}
            </Stack>
          </>
        );
      },
    },
  ];

  // INDEXACION DE OBJETO (JUAN)
  useEffect(() => {
    const translatedList = eventTypesList?.map((event: SilobagHistoryEventTypes) => {
      const translation = event?.silobags_history_event_types_translations.at(0);

      const LANGUAGE: LanguageRecord = {
        es: {
          bk_color: event.bk_color,
          text_color: event.text_color,
          description: translation?.description || event.description,
          id: translation?.event_type_id || event.id,
        },
        pt: {
          bk_color: event.bk_color,
          text_color: event.text_color,
          description: translation?.description || event.description,
          id: translation?.event_type_id || event.id,
        },
      };

      const DEFAULT_LANGUAGE = {
        bk_color: event.bk_color,
        text_color: event.text_color,
        description: event.description,
        id: event.id,
      };

      return LANGUAGE[preferedLanguageId] ?? DEFAULT_LANGUAGE;
    });
    setTranslatedEventTypes(translatedList);
  }, [eventTypesList, preferedLanguageId]);

  const deleteCountrysideFilter = () => {
    setFiltersReportActivity({ ...filtersReportActivity, countrysideId: '' });
  };

  const deleteUserFilter = () => {
    setFiltersReportActivity({ ...filtersReportActivity, userId: '' });
  };
  return (
    <Stack>
      <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
        {i18n.t('app.report_activity.title')}
      </Box>
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          {/* SEARCH BAR */}
          <FormControl
            style={{
              width: '25%',
              height: '100%',
              color: 'white',
              backgroundColor: 'white',
            }}
          >
            <TextField
              size="medium"
              variant="outlined"
              placeholder={i18n.t('app.silobag_list.search_by_name_id')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="search-icon" />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <IconButton onClick={() => setSearch('')}>
                    <img src={RemoveSearchIcon} alt="remove-search-icon" style={{ width: 12, height: 12 }} />
                  </IconButton>
                ) : null,
                //inputProps: { style: { fontSize: 13, height: 22 } },
              }}
            />
          </FormControl>

          {/* SELECTORS */}
          {/* TYPE */}
          <FormControl size="medium" sx={{ backgroundColor: 'white', width: '25%' }}>
            <InputLabel>{i18n.t('app.type')}</InputLabel>
            <Select
              label={i18n.t('app.type')}
              value={filtersReportActivity.typeId}
              disabled={isEventTypesListLoading}
              onChange={(e) => {
                setFiltersReportActivity({ ...filtersReportActivity, typeId: e.target.value as string });
              }}
            >
              <MenuItem value="">{i18n.t('app.type')}</MenuItem>
              {translatedEventTypes?.map((event: EventData) => (
                <MenuItem key={event.id} value={event.id}>
                  <Chip
                    label={event?.description}
                    size="small"
                    style={{
                      height: '24px',
                      background: event.bk_color,
                      color: event.text_color,
                      borderRadius: '8px',
                      fontWeight: 500,
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* COUNTRYSIDE */}
          <FormControl
            size="small"
            style={{
              color: 'white',
              width: '25%',
            }}
          >
            <Selector
              label={i18n.t('app.countrysides')}
              id="countryside_id"
              value={filtersReportActivity.countrysideId}
              onChange={(e) => {
                setFiltersReportActivity({
                  ...filtersReportActivity,
                  countrysideId: e.target.value as string,
                });
              }}
              options={csList}
              selectedList={filtersReportActivity.countrysideId}
              renderValue={() =>
                csList
                  ?.filter((countryside) => filtersReportActivity.countrysideId == countryside?.id.toString())
                  .map((matchedCountryside) => matchedCountryside?.name)
              }
              deleteFilter={deleteCountrysideFilter}
              disabled={isCsListLoading}
            />
          </FormControl>
          {/* USER */}
          <FormControl
            size="small"
            style={{
              color: 'white',
              width: '25%',
            }}
          >
            <Selector
              label={i18n.t('app.user')}
              id="user_id"
              value={filtersReportActivity.userId}
              onChange={(e) => {
                setFiltersReportActivity({
                  ...filtersReportActivity,
                  userId: e.target.value as string,
                });
              }}
              options={filteredUserOrganization}
              selectedList={filtersReportActivity.userId}
              renderValue={() =>
                filteredUserOrganization
                  //TO DO: TIPAR SERVICIO DE USUARIOS DE ESTA PAGINA
                  ?.filter((user) => filtersReportActivity.userId == user?.id.toString())
                  .map((matchedUser) => matchedUser?.fullname)
              }
              deleteFilter={deleteUserFilter}
              disabled={isUsersListLoading || filteredUserOrganization?.length === 0}
              propToShow="fullname"
            />
          </FormControl>
          {/* CLEAR FILTERS BUTTON */}
          <Button
            sx={{
              fontSize: 12,
              fontWeight: 500,
              paddingInline: 2,
              textTransform: 'uppercase',
              visibility: hasFilters ? '' : 'hidden',
              color: '#FF0000',
              '&:hover': { background: '#FF000022' },
            }}
            onClick={removeFilter}
            startIcon={<img src={DeleteIcon} alt="delete-icon" style={{ width: 14, height: 14 }} />}
          >
            {i18n.t('app.clear_filters')}
          </Button>
        </Stack>

        {/* BOARD */}
        <BaseTable
          rowCount={activityData?.count ?? 0}
          columns={columns}
          rows={activityList ?? []}
          initialPageSize={filtersReportActivity.paginationModel?.pageSize ?? 50}
          loading={isActivityDataLoading}
          paginationMode="server"
          paginationModel={filtersReportActivity.paginationModel}
          setPaginationModel={(fn) => {
            const paginationModel = fn(filtersReportActivity.paginationModel);
            setFiltersReportActivity({ ...filtersReportActivity, paginationModel });
          }}
        />
      </Stack>
      {openModalImages && !loadingHistory && (
        <CarouselModalPhotoGallery
          open={openModalImages}
          onClose={setOpenModalImages}
          images={imgs}
          title="Imagenes"
          sbId={response?.silobag_id}
        />
      )}
      {openPoeModal && !loadingHistory && (
        <PoeModal
          open={openPoeModal}
          setOpen={setOpenPoeModal}
          data={response?.poe ? [response.poe] : []}
          poeId={response?.poe_id}
        />
      )}
      {openExtractionModal && !loadingHistory && (
        <ExtractionAction
          open={openExtractionModal}
          setOpen={setOpenExtractionModal}
          fromHistory
          type="view"
          extractionData={response?.silobags_extractions_versions?.data}
          remainingStock={response?.remaining_stock}
          silobagOrExtractionId={response?.silobags_extractions_versions?.silobag_extraction_id}
          siloName={response?.silobags.name}
        />
      )}
      {openEditModal && !loadingHistory && (
        <EditSBModal
          open={openEditModal}
          setOpen={setOpenEditModal}
          dataSb={response?.previous_silobags_versions.data}
          modifiedDataSb={response?.silobags_versions.data}
        />
      )}
    </Stack>
  );
}

import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';
import { SilobagClosePayload, SilobagImagesResponse } from './silobag-images.types';

export async function silobagImagesService({ query: rawQuery }: SilobagClosePayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<SilobagImagesResponse>(ENDPOINT.IMAGES_SILOBAGS, { query });

  return {
    response,
    summary: data,
  };
}

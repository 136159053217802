import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/backend';
import { WebSilobagsData, WebSilobagsPayload } from './silobag-web.types';

export async function listWebSilobagsService(organizationId: WebSilobagsPayload) {
  const { response, data } = await BACKEND.get<WebSilobagsData>(ENDPOINT.WEB_SILOBAGS, {
    params: '/' + organizationId,
  });

  return {
    response,
    summary: data,
  };
}

import { useService } from '@hooks/use-service';
import { Box, Button, Chip, Fade, Modal, Stack, Typography } from '@mui/material';
import { ToastAction } from '@ui/toast/toast-action';
import dayjs from 'dayjs';
import { PropsWithChildren, useEffect, useState } from 'react';
import i18n from '../../../../libs/language';
import { closeSilobagService } from '@services/domain/base/silobag/base/silobag-close';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SummarySilobagDetail = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];
type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];

type CloseSilobagProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  siloData: SummarySilobagDetail;
  setTrigger: (query: SilobagDetailParams) => void;
};
type ChipState = {
  label: string;
  color: 'success' | 'error';
};

export function CloseSilobag({ open, setOpen, siloData, setTrigger }: CloseSilobagProps) {
  const { id: siloID, input_weight: initial_weight, remaining_stock, total_extracted_weight } = siloData;
  const [chipState, setChipState] = useState<ChipState>({ label: 'superavit', color: 'success' });
  const [percentage, setPercentage] = useState<number>(0);

  const closeOnSuccess = () => {
    handleClose();
    setTrigger({ query: siloData.id });
    setOpen(false);
  };

  const { trigger: closeSilobagTrigger, isLoading: isCloseSilobagLoading } = useService(closeSilobagService, {
    onSuccess: () => closeOnSuccess(),
  });
  const [toastOpen, setToastOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const submit = () => {
    const date = new Date();
    const exDate = dayjs(date).format('YYYY-MM-DD');

    const body = {
      weight: 0,
      last_extraction: true,
      extraction_date: exDate,
    } as const;

    closeSilobagTrigger({ query: siloID, body });
    setToastOpen(true);
  };

  useEffect(() => {
    if (parseFloat(initial_weight) - parseFloat(total_extracted_weight) > 0) {
      setChipState({ label: 'Deficit', color: 'error' });
    } else {
      setChipState({ label: 'Superavit', color: 'success' });
    }

    const percentage = (parseFloat(remaining_stock!) / parseFloat(initial_weight)).toFixed(2);
    const roundedPercentage = parseFloat(percentage) * 100;
    setPercentage(roundedPercentage);
  }, [siloID]);

  return (
    <>
      <MyModal open={open} onClose={handleClose}>
        <Box>
          <Typography variant="h5">{i18n.t('app.silobag_list.close')}</Typography>
        </Box>
        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="body2">
            {i18n
              .t('app.silobag_list.original_stock', {
                // TODO: initial_weight es string, no tiene sentido esta conversion -> se hizo console log y se ve que es un string en el mundo de ejecucion (javascript)
                initial_weight: Number(initial_weight).toLocaleString(),
              })
              .split(/(<b>.*?<\/b>)/g)
              .map((part, index) =>
                part.startsWith('<b>') && part.endsWith('</b>') ? (
                  <b key={index}>{part.slice(3, -4)}</b>
                ) : (
                  part
                )
              )}
          </Typography>
        </Stack>
        <Stack direction={'row'} flex={1} alignItems={'center'} justifyContent={'center'} spacing={6}>
          <Typography variant="h5" fontWeight={'500'}>
            {Number(remaining_stock).toLocaleString()} kg
          </Typography>
          <Typography> {i18n.t('app.silobag_list.weight_differential')}</Typography>
          <Typography variant="h5" fontWeight={'500'}>
            {Number(percentage).toFixed(2)} %{' '}
            <Chip size="small" label={chipState.label} color={chipState.color} />
          </Typography>
        </Stack>
        <Box display={'flex'} justifyContent={'center'}>
          <Typography variant="body2">
            {i18n
              .t('app.silobag_list.relation', {
                initial_weight: Number(initial_weight).toLocaleString(),
              })
              .split(/(<b>.*?<\/b>)/g)
              .map((part, index) =>
                part.startsWith('<b>') && part.endsWith('</b>') ? (
                  <b key={index}>{part.slice(3, -4)}</b>
                ) : (
                  part
                )
              )}
          </Typography>
        </Box>
        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {i18n.t('app.silobag_list.question_close')}
          </Typography>
          <Typography variant="body2">{i18n.t('app.silobag_close_modal.description')}</Typography>
        </Stack>
        <Stack direction="row" flex={1} justifyContent={'center'} spacing={12}>
          <Button onClick={handleClose} disabled={isCloseSilobagLoading}>
            {i18n.t('app.cancel')}
          </Button>
          <Button
            variant="contained"
            style={{ color: '#fff' }}
            onClick={submit}
            disabled={isCloseSilobagLoading}
          >
            {i18n.t('app.silobag_close_modal.confirmation')}
          </Button>
        </Stack>
      </MyModal>
      {toastOpen && <ToastAction message="Silobolsa cerrada con éxito" severity="success" />}
    </>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

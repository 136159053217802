import { Modal, Fade, Box, Typography, Button, Grid } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import AppGridContainer from '@ui/app-grid-container';
import { BaseTable } from '@ui/table';
import { PropsWithChildren } from 'react';
import i18n from '../../../../libs/language';
import dayjs from 'dayjs';
import { listLotsService } from '@services/domain/base/lot/lot-list';
import { useGlobalStore } from '@global-store/use-store';
import { useService } from '@hooks/use-service';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SummarySilobagDetail = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];

type PoeModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  dataSb?: Partial<SummarySilobagDetail>;
  modifiedDataSb?: Partial<SummarySilobagDetail>;
};

export function EditSBModal({ open, setOpen, dataSb, modifiedDataSb }: PropsWithChildren<PoeModalProps>) {
  const countrysideName = dataSb ? dataSb.countrysides?.name : undefined;
  const countrysideNameModified = modifiedDataSb ? modifiedDataSb.countrysides?.name : undefined;

  const { organizationId } = useGlobalStore();

  //Lots Service
  const queryLotListService = { id: organizationId };
  const { summary: lots } = useService(listLotsService, { fetchOnMount: [{ query: queryLotListService }] });

  const renderCellBold = (params: GridCellParams) => (
    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{params.value as React.ReactNode}</div>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: i18n.t('app.name'),
      flex: 1,
      minWidth: 50,
      renderCell: renderCellBold,
    },
    {
      field: 'original',
      headerName: i18n.t('app.original'),
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'modified',
      headerName: i18n.t('app.modified'),
      flex: 1,
      minWidth: 200,
    },
  ];

  const NAME = 0;
  const ORIGINAL_VALUE = 2;
  const MODIFIED_VALUE = 1;

  let nextId = 1;

  const firstRow = [
    // ESTABLECIMIENTO
    [i18n.t('app.countryside'), countrysideNameModified, countrysideName],
    // NOMBRE
    [i18n.t('app.name'), modifiedDataSb?.name, dataSb?.name],
    // ESPECIE
    [
      i18n.t('app.silobag_data.species'),
      modifiedDataSb?.silobag_species_types?.name,
      dataSb?.silobag_species_types?.name,
    ],
    // VARIEDAD
    [i18n.t('app.silobag_data.variety'), modifiedDataSb?.variety, dataSb?.variety],
    // PRODUCTO
    [
      i18n.t('app.silobag_data.product'),
      modifiedDataSb?.silobag_product_types?.name,
      dataSb?.silobag_product_types?.name,
    ],
    // COSECHA
    [
      i18n.t('app.silobag_data.harvest'),
      modifiedDataSb?.silobag_harvests?.name,
      dataSb?.silobag_harvests?.name,
    ],
    // LOTE STOCK
    [i18n.t('app.silobag_data.lot_stock'), modifiedDataSb?.lots?.name, dataSb?.lots?.name],
  ].map((row) => ({
    id: nextId++,
    name: row[ORIGINAL_VALUE] !== row[MODIFIED_VALUE] ? row[NAME] + ' 🔵' : row[NAME],
    original: row && row[ORIGINAL_VALUE] === 'null' ? '' : row && row[ORIGINAL_VALUE],
    modified: row && row[MODIFIED_VALUE],
  }));

  const originLotsRows = (modifiedDataSb?.silobags_origin_lots ?? []).flatMap((lot, index: number) => {
    if (!lot) return [];

    const isNewOriginLot = !dataSb?.silobags_origin_lots?.[index];

    const originalOriginLot = !isNewOriginLot ? dataSb?.silobags_origin_lots?.[index]?.lot_id : '';
    const modifiedOriginLot = modifiedDataSb?.silobags_origin_lots?.[index]?.lot_id;

    const originalOriginLotName = lots?.find((elem) => elem.id === originalOriginLot)?.name ?? '';
    const modifiedOriginLotName = lots?.find((elem) => elem.id === modifiedOriginLot)?.name ?? '';

    const originalWeight =
      dataSb?.silobags_origin_lots?.find((elem) => elem.lot_id === originalOriginLot)?.weight ?? '';
    const modifiedWeight =
      modifiedDataSb?.silobags_origin_lots?.find((elem) => elem.lot_id === modifiedOriginLot)?.weight ?? '';

    const returnedData = [
      [
        i18n.t('app.silobag_data.lot_origin') + ' ' + (index + 1),
        modifiedOriginLotName,
        originalOriginLotName,
      ],
      [i18n.t('app.silobag_data.weight_lot') + ' ' + (index + 1), modifiedWeight, originalWeight],
    ];

    // Aplanamos directamente cada elemento
    return returnedData.map((row) => ({
      id: nextId++,
      name:
        (row && row[ORIGINAL_VALUE] === 'null') ||
        (row && row[ORIGINAL_VALUE] === '' && row[MODIFIED_VALUE] !== '')
          ? row[NAME] + ' 🟢'
          : row && row[ORIGINAL_VALUE] !== row[MODIFIED_VALUE]
          ? row[NAME] + ' 🔵'
          : row
          ? row[NAME]
          : '',
      original: row && row[ORIGINAL_VALUE] === 'null' ? '' : row && row[ORIGINAL_VALUE],
      modified: row && row[MODIFIED_VALUE],
    }));
  });

  const lastRows = [
    // LARGO SILOBOLSA
    [
      i18n.t('app.silobag_data.silobag_size'),
      modifiedDataSb?.size ? `${modifiedDataSb?.size} m` : null,
      dataSb?.size ? `${dataSb?.size} m` : null,
    ],
    // FECHA EMBOLSADO
    [
      i18n.t('app.silobag_data.bagging_date2'),
      modifiedDataSb?.bagging_date ? dayjs.utc(modifiedDataSb?.bagging_date).format('DD/MM/YYYY') : null,
      dataSb?.bagging_date ? dayjs.utc(dataSb?.bagging_date).format('DD/MM/YYYY') : null,
    ],
    // PESO LOTE
    [
      i18n.t('app.silobag_data.weight_lot'),
      modifiedDataSb?.input_weight ? `${modifiedDataSb?.input_weight} kg` : null,
      dataSb?.input_weight ? `${dataSb?.input_weight} kg` : null,
    ],
    // HUMEDAD EMBOLSADO
    [
      i18n.t('app.silobag_data.humidity_bagging'),
      modifiedDataSb?.initial_humidity ? `${modifiedDataSb?.initial_humidity} %` : null,
      dataSb?.initial_humidity ? `${dataSb?.initial_humidity} %` : null,
    ],
    // LATITUD
    [
      i18n.t('app.silobag_data.lat'),
      parseFloat(modifiedDataSb?.main_lat ?? '0').toFixed(6),
      parseFloat(dataSb?.main_lat ?? '0').toFixed(6),
    ],
    // LONGITUD
    [
      i18n.t('app.silobag_data.long'),
      parseFloat(modifiedDataSb?.main_lon ?? '0').toFixed(6),
      parseFloat(dataSb?.main_lon ?? '0').toFixed(6),
    ],
    // OBSERVACIONES
    [
      i18n.t('app.observations'),
      modifiedDataSb?.observations ? `${modifiedDataSb?.observations}` : null,
      dataSb?.observations ? `${dataSb?.observations}` : null,
    ],
  ].map((row) => ({
    id: nextId++,
    name: row[ORIGINAL_VALUE] !== row[MODIFIED_VALUE] ? row[NAME] + ' 🔵' : row[NAME],
    original: row && row[ORIGINAL_VALUE] === 'null' ? '' : row && row[ORIGINAL_VALUE],
    modified:
      (row && row[MODIFIED_VALUE] === 'null') || row[MODIFIED_VALUE] === null
        ? ''
        : row && row[MODIFIED_VALUE],
  }));

  const additionalRows = [
    // RESPONSABLE DE EMBOLSADO
    [
      i18n.t('app.silobag_data.bagging_responsible'),
      modifiedDataSb?.responsible_for_bagging ? `${modifiedDataSb?.responsible_for_bagging}` : '',
      dataSb?.responsible_for_bagging ? `${dataSb?.responsible_for_bagging}` : '',
    ],
    // MARCA SILOBOLSA
    [
      i18n.t('app.silobag_data.silobag_brand'),
      modifiedDataSb?.silobag_brand ? `${modifiedDataSb?.silobag_brand}` : '',
      dataSb?.silobag_brand ? `${dataSb?.silobag_brand}` : '',
    ],
    // PUREZA VARIETAL
    [
      i18n.t('app.silobag_data.varietal_purity'),
      modifiedDataSb?.varietal_purity ? `${modifiedDataSb?.varietal_purity}` : '',
      dataSb?.varietal_purity ? `${dataSb?.varietal_purity}` : '',
    ],
    // GRANO PARTIDO
    [
      i18n.t('app.silobag_data.split_grain'),
      modifiedDataSb?.split_grain_percentage ? `${modifiedDataSb?.split_grain_percentage}` : '',
      dataSb?.split_grain_percentage ? `${dataSb?.split_grain_percentage}` : '',
    ],
    // COEFICIENTE DE LLENADO
    [
      i18n.t('app.silobag_data.fillying_coefficient_no_metrics'),
      modifiedDataSb?.fillying_coefficient ? `${modifiedDataSb?.fillying_coefficient}` : '',
      dataSb?.fillying_coefficient ? `${dataSb?.fillying_coefficient}` : '',
    ],
    // FUENTE DE HUMEDAD
    [
      i18n.t('app.silobag_data.source_humidity'),
      modifiedDataSb?.humidity_sources ? `${modifiedDataSb?.humidity_sources?.description}` : '',
      dataSb?.humidity_sources ? `${dataSb?.humidity_sources?.description}` : '',
    ],
    // FUENTE DE PESO
    [
      i18n.t('app.weight_sources'),
      modifiedDataSb?.weight_sources ? `${modifiedDataSb?.weight_sources.description}` : '',
      dataSb?.weight_sources ? `${dataSb?.weight_sources.description}` : '',
    ],
    // CAMION CARGA SOBRE
    [
      i18n.t('app.silobag_data.side_charge'),
      modifiedDataSb?.side_charge_id ? `${modifiedDataSb?.side_charge_id === 2 ? 'Camión' : 'Lote'}` : '',
      dataSb?.side_charge_id ? `${dataSb?.side_charge_id === 2 ? 'Camión' : 'Lote'}` : '',
    ],
    // TIENE ROTURAS --> TODO: CHEQUEAR CUANDO VICTOR ACTUALICE SILOBAG_HISTORY
    // [
    //   i18n.t('app.silobag_data.have_breaks'),
    //   modifiedDataSb.have_breaks ? `${modifiedDataSb.have_breaks}` : '',
    //   dataSb.have_breaks ? `${dataSb.have_breaks}` : '',
    // ],
  ].map((row) => ({
    id: nextId++,
    name: row[ORIGINAL_VALUE] !== row[MODIFIED_VALUE] ? row[NAME] + ' 🔵' : row[NAME],
    original: row && row[ORIGINAL_VALUE] === 'null' ? '' : row && row[ORIGINAL_VALUE],
    modified:
      (row && row[MODIFIED_VALUE] === 'null') || row[MODIFIED_VALUE] === null
        ? ''
        : row && row[MODIFIED_VALUE],
  }));

  const fullRows = [...firstRow, ...originLotsRows, ...lastRows, ...additionalRows];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MyModal onClose={handleClose} open={open}>
      <Box>
        <Typography variant="h6">{i18n.t('app.silobag_detail.silobag_version')}</Typography>
      </Box>

      <AppGridContainer spacing={5}>
        <Grid item xs={12}>
          <Grid item xs={12} md={12}>
            <BaseTable rows={fullRows} columns={columns} initialPageSize={14} />
          </Grid>
        </Grid>
      </AppGridContainer>

      <Box display={'flex'} justifyContent={'end'}>
        <Button onClick={handleClose}>{i18n.t('app.close')}</Button>
      </Box>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '60%',
    overflowX: 'hidden',
    maxHeight: '95vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

import { ModalIOF } from '@ui/modal/modal';
import { configButtonStylesContained, configButtonStylesOutlined } from '@styles/generic-styles';
import i18n from '../../../libs/language';
import { Stack, Typography, Box, Button, CircularProgress } from '@mui/material';
import { formatNumberWithSeparators } from '@utils/helper';
import { useService } from '@hooks/use-service';
import { postInsuranceExternalLinks_DEPRECATED } from '@services/silobag-insurance/insurance-external-link';

// TODO: esta interfaz se movió de otro lugar acá por comodidad para borrar archivos, pero habría que revisarla y migrarla al nuevo tipado.
export interface InsuranceCoverage_DEPRECATED {
  code: string;
  description: string;
}

// TODO: esta interfaz se movió de otro lugar acá por comodidad para borrar archivos, pero habría que revisarla y migrarla al nuevo tipado.
export interface InsuranceQuote_DEPRECATED {
  background_color: string;
  enabled: boolean;
  logo_url: string;
  logo_url_white: string;
  includes: InsuranceCoverage_DEPRECATED[];
  price: number;
  sum_assured: number;
  duration: number;
  description: string;
  siloMetrix: boolean;
  service_id: number;
  form_url: string;
}

//RUS Insurance Modal
type InsuranceRusModalProps = {
  data: InsuranceQuote_DEPRECATED;
  silobagId?: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
};
export function InsuranceRusModal(props: InsuranceRusModalProps) {
  const { open, setOpen, data, silobagId } = props;
  const { trigger, isLoading } = useService(postInsuranceExternalLinks_DEPRECATED, {
    onSuccess: () => {
      setOpen(false);
      window.open(data.form_url, '_blank');
    },
  });

  const dataWithSiloId = {
    ...data,
    silobag_id: silobagId ? silobagId[0] : '',
  };

  const body = {
    link_type_id: 1,
    link: data.form_url,
    data: {
      ...dataWithSiloId,
    },
  };

  const handleContractInsurance = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    trigger({ ...body });
  };

  return (
    <ModalIOF open={open} ModalIsOpen={setOpen}>
      <ModalIOF.HeaderCenter>
        <ModalIOF.Title>
          <Stack direction="row" spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <img src={data.logo_url} alt="Insurance Logo" style={{ width: '200px' }} />
          </Stack>
        </ModalIOF.Title>
      </ModalIOF.HeaderCenter>
      <ModalIOF.Body>
        <Stack direction="column" spacing={2} sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontWeight: 500 }}>
            {i18n.t('app.silobag_insurance.crop')} en silo bolsa{' '}
            <span style={{ textTransform: 'lowercase' }}>{data.description}</span>
          </Typography>
          <Typography sx={{ fontWeight: 700, fontSize: '30px' }}>
            ${formatNumberWithSeparators(data.price)}{' '}
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            {i18n.t('app.silobag_insurance.duration_policy')} {data.duration} meses.
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>
            {i18n.t('app.silobag_insurance.assured_production')}{' '}
            <span style={{ textTransform: 'lowercase' }}>:</span>
          </Typography>
          <Box sx={{ marginTop: '10px' }}>
            <ul style={{ width: '450px', margin: '0 auto', textAlign: 'left' }}>
              {data.includes.map((item, index) => (
                <li
                  style={{
                    fontWeight: 600,
                    margin: 0,
                    fontSize: '15px',
                  }}
                  key={index}
                >
                  {item.description.slice(0, -1)}
                </li>
              ))}
            </ul>
          </Box>
        </Stack>
      </ModalIOF.Body>
      <Stack direction={'row'} justifyContent={'end'}>
        <ModalIOF.Button
          close
          variant="outlined"
          size="medium"
          sx={configButtonStylesOutlined}
          onClick={() => setOpen(false)}
        >
          {i18n.t('app.close')}
        </ModalIOF.Button>
        <Button
          variant="contained"
          size="medium"
          sx={configButtonStylesContained}
          onClick={handleContractInsurance}
        >
          {isLoading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : (
            i18n.t('app.silobag_insurance.contract')
          )}
        </Button>
      </Stack>
    </ModalIOF>
  );
}

export default {
  config: {
    delimiter: '.',
    separator: ',',
  },
  app: {
    '': '',
    //Terminos globales y usados multiples veces
    actions: 'Ações',
    alerts: 'Alertas',
    register_silobag: 'Registrar Silo Bolsa',
    atention: 'Atenção!',
    battery: 'Bateria',
    lots: 'Talhões',
    lot: 'Talhão',
    cancel: 'Cancelar',
    closed: 'fechado',
    close: 'Fechar',
    copy: 'Copiar',
    continue: 'Continuar',
    organization: 'Organização',
    data: 'Dados',
    companies: 'Empresas',
    countryside: 'Fazenda',
    countrysides: 'Fazendas',
    cuit: 'CNPJ',
    name: 'Nome',
    original: 'Original',
    state: 'Estado',
    date: 'Data',
    user: 'Usuário',
    role: 'Permissões',
    phone: 'Telefone',
    save: 'Salvar',
    new_silobag: 'Novo Silo Bolsa',
    edit_silobag: 'Editar Silo Bolsa',
    no_user_exists_with_email: 'Não existe usuário com o email:',
    images: 'Imagens',
    modified: 'Modificação',
    no_images: 'Sem imagens',
    observations: 'Observações',
    georeference: 'Georreferência',
    tons: 'Toneladas',
    silobags: 'Silos bolsa',
    return: 'Voltar',
    event: 'Evento',
    stock_movement: 'Movimento de estoque',
    detail: 'Detalhe',
    view_files_attached: 'Ver arquivos anexados',
    without_observations: 'Sem observações',
    method_notifications: 'Método de notificação',
    quality_intermediate: 'Qualidade Intermediária',
    quality_grave: 'Qualidade Grave',
    security: 'Segurança',
    support_calendly: 'Você tem uma sugestão? Agende uma reunião conosco',
    support_whatsapp: 'Suporte por Whatsapp',
    support_problem: 'Tenho um problema',
    ubication: 'Localização',
    rows_per_page: 'Linhas por página',
    no_grid_data: 'Não há dados para exibir',
    status: 'Estado',
    no_data: 'Não há dados',
    must_field: 'Este campo é obrigatório.',
    no_active_siloid_alerts: 'Neste momento você não possui alertas ativos',
    silobag: 'Silo Bolsa',
    silobag_plural: 'Silos Bolsas',
    quote_silo_insurance: 'Cotar SiloSeguro',
    quote_silo_warrant: 'Cotar warrant',
    coming_soon: 'Em breve',
    soon: 'Em breve',
    type: 'Tipo',
    clear_filters: 'Limpar',
    under_construction: 'Em construção.',
    no_connection: 'Não tem conexão com a internet',
    activate: 'Ativar',
    active: 'Ativo',
    system_management: 'Sistema de gestão',
    validated_tons: 'Toneladas validadas',
    siloreales: 'Siloreales',

    company_name: 'Razão Social',
    yes: 'Sim',
    no: 'Não',

    missing_tons:
      'Os silos bolsa sem validação não tiveram seu cadastro realizado ou finalizado em campo utilizando o app',
    register_date: 'Data Alta',
    field_weigth: 'Peso no campo',
    total_extracted_weigth: 'Peso Total estraído',
    slobag_brand: 'Marca deste Silo Bolsa',
    humidity_sources: 'Fonte de umidade',
    fillying_coefficient: 'Coeficiente de preenchimento teórico',
    calculated_coefficient: 'Coeficiente de enchimento calculado',
    silobag_status: 'Estado do SB',
    silobag_data_statuses: 'Validado',
    silobag_lab_protocols: 'Amostras de qualidade',
    lab_protocols_created_at: 'Data da última amostra',
    silobag_id: 'ID SB',
    silobag_name: 'Nome SB',
    confirm: 'Confirmar',
    poe_round: 'Vistorias',
    days: 'dias',
    download: 'Baixar',
    downloading: 'Baixando',
    download_all: 'Baixar todos',
    species: 'Cultura',
    choose: 'Selecionar',
    tooltip_sb_selected: 'Selecione pelo menos 1 silo bolsa para exportar',
    tooltip_sb_share_selected: 'Selecione pelo menos 1 silo bolsa para compartilhar',
    see_details: 'Ver detalhes',
    edit_siloreal: 'Editar Siloreal',
    download_certificate_silo: 'Baixar certificado do siloID',
    tooltip_sb_optimo: 'SiloReal em ótimo estado',
    /**--------------------------------------------------------------------------------------------------- */
    navbar: {
      administrator: 'Administrator',
      silobags: 'Silos Bolsa',
      search_silo: 'Encontrar silo',
      organizations: 'Organizações',
      all_organizations: 'Todas as organizações',
      change_password: 'Alterar senha',
      logout: 'Fechar Sessão',
      weather: 'Clima',
      dashboard: 'Dashboard',
      spanish: 'Espanhol',
      language: 'Linguagem',
      map: 'Mapa',
      reports: 'Relatórios',
      stock: 'Movimentos de estoque',
      state_stock: 'Estatus de estoque',
      existence_stock: 'Existência de estoque',
      devices: 'Dispositivos',
      device: 'Dispositivo',
      alerts: 'Alertas',
      activity: 'Atividade',
      portuguese: 'Português',
      snack_bar_msj: 'A preferência de idioma foi alterada!',
      alert_value: 'Valor alerta',
      see_all: 'Ver tudo',
      searchbar: {
        searching: 'Buscando silos bolsa...',
        no_results: 'Não existem silos bolsa com estes dados.',
      },
      personalized: 'Personalizado',
      general_position: 'Posição Geral',
      commercial_position: 'Posição Comercial',
      stock_withdrawals: 'Retiradas do Estoque',
    },
    //Pagina de Dashboard
    dashboard_page: {
      welcome_to_field: 'Bem-vindo ao campo inteligente e conectado',
      hi: 'Olá',
      silobags_actives: 'Siloreales',
      siloreal_alerted: 'Siloreais alertados',
      validated_tons: 'Toneladas Monitoradas',
      non_validated: 'Sem validar',
      non_monitored_validated: 'Toneladas sem validar',
      view_map: 'Visualizar mapa',
      financing: '¡Novas formas de financiamento!',
      know_more: '¡Quero saber mais!',
      maybe_later: 'Talvez mais tarde',
      tons_for_species: 'Toneladas por cultura',
      tons_for_countrysides: 'Toneladas por fazenda',
      silobag_lance_sensor: 'Sensor de lança de silobag',
      see_map: 'Ver no mapa',
      countrysides_tooltip: 'São mostrados apenas fazendas com estoque validado em campo',
    },

    // --- ADMIN PAGE --- //
    administrator: {
      title: 'Administrador',
      visualizer: 'Visualizador',
      permission: 'Permissões',
      error_unexpected: 'Ocorreu um erro inesperado.',
      notification: 'Notificações',
      customize_alerts: 'Personalizar alertas',
      Logo_max_size: 'O logotipo não pode exceder o tamanho de 1mb',
      add_logo: 'Adicionar logotipo',
      walkthrough: 'Vistorias',
      new_company: 'Nova empresa',
      new_countryside: 'Nova fazenda',
      new_user: 'Novo usuário',
      new_company_toast: 'A empresa foi criada com sucesso.',
      edit_company_toast: 'A empresa foi editada com sucesso',
      not_valid_action: 'A ação não é permitida pois possui SiloIDs vinculados a esta entidade.',
      new_countryside_toast: 'Estabelecimento salvo com sucesso.',
      edit_countryside_toast: 'Estabelecimento editado com sucesso',
      new_user_toast: 'O usuário foi criado com sucesso',
      edit_user_toast: 'O usuário foi editado com sucesso',
      deleted_company: 'A empresa foi eliminada com sucesso',
      deleted_countryside: 'O estabelecimento foi eliminado com sucesso.',
      deleted_user: 'O usuário foi excluído com sucesso.',
      error_delete_countryside: 'A ação não é permitida pois possui SiloIDs vinculados a esta entidade',
      invalid_email: 'E-mail com formato inválido',
      repeted_mail: 'O e-mail já está registrado',
    },
    // --- ADMIN PAGE --- // (integration modal)
    integration_modal: {
      integrations: 'Integrações',
      share_data: 'Compartilhe dados com:',
      apply: 'Ler os',
      terms_conditions: 'termos e Condições',
      system_gestion: 'Sistema de gestão',
      system: 'Sistema',
      login: 'Entrar',
      Albor_username: 'Nome de usuário Albor',
      Albor_password: 'Senha Albor',
      Albor_intergration: 'Integração com Albor',
      Albor_loading: 'Está se conectando ao sistema...',
      Albor_loading2: 'Não feche a janela até que o carregamento esteja completo.',
      albor_title: 'Faça login com sua conta Albor',
      albor_countryside: 'Estabelecimento importado de Albor',
      countryside_warning: 'Localização do estabelecimento importada automaticamente e deve ser corrigida',
      albor_subtitle:
        'Utilize o mesmo endereço de e-mail e senha usados para iniciar sessão na sua conta do Albor.',
      albor_subtitle2: 'para iniciar sessão na sua conta do Albor.',
      albor_joined: 'Sua conta foi conectada com sucesso ao Albor.',
      albor_joined3:
        'La ubicación de los establecimientos se va a tomar directamente de la dirección de Albor por lo que es importante revisar si alguna presenta errores.',
      save: 'Salvar',
      close: 'Fechar',
      integration: 'integração',
      now_you_can:
        'Agora você pode tokenizar seus grãos em silos bolsa e acessar novos instrumentos de financiamento graças à nossa nova integração com Agrotoken.',
      more_info: 'Para mais informações, entre em contato com support@siloreal.com',
    },
    // --- ADMIN PAGE --- // (inspection round modal)
    inspection_round_modal: {
      title: 'Vistoria',
      round_days: 'Dias entre as vistorias',
      question: 'Pergunta',
      ask_for_photo: 'Solicitar foto',
      add_question: 'Adicionar pergunta',
    },

    new_edit_silobag: {
      only_numbers: 'Somente números inteiros são aceitos.',
      numbers_decimals: 'Somente números inteiros e decimais são aceitos.',
      less_1000_kg: 'O peso inserido é menor que 1000 KG.',
      only_coords: 'Somente coordenadas válidas são aceitas.',
    },

    company_modal: {
      correct_street: 'Por favor, digite uma rua correta.',
      street: 'Rua',
      Pais: 'País',
      city: 'Cidade',
      zip_code_correct: 'Por favor, insira um CEP correto',
      zip_code: 'CEP',
      cuit_correct: 'Por favor, insira um CNPJ correto.',
      cuit_without_spaces: 'CNPJ sem hífens ou espaços',
      departament_correct: 'Por favor, insira um departamento correto.',
      departament: 'Departamento',
      legal_name_correct: 'Por favor, insira um nome legal correto.',
      legal_name: 'Nome jurídico',
      new_company: 'Nova Empresa',
      correct_number: 'Por favor, digite um número correto.',
      number: 'Número',
      correct_floor: 'Por favor, insira um andar correto.',
      floor: 'Andar',
      locality: 'Bairro',
      email_format: 'Formato de e-mail inválido.',
    },

    user_modal: {
      users: 'Usuários',
      active: 'Ativo',
      correct_dni: 'insira um CPF correto',
      dni: 'CPF',
      ingress_role: 'insira uma função',
      correct_fullname: 'Por favor, insira um nome e sobrenome corretos.',
      fullname: 'Nome e sobrenome',
      phone_correct: 'Por favor, digite um número de telefone correto',
      user_platform: 'Usuário da plataforma',
      permission: 'Permissões',
    },

    countryside_modal: {
      open_map: 'Ver no mapa',
      add_lot: 'Adicionar Talhões',
      city: 'Cidade',
      company: 'Empresa',
      discard: 'Descosindere',
      enter_latitude: 'Por favor, insira uma latitude correta.',
      enter_longitude: 'Por favor, insira um comprimento correto.',
      latitude: 'Latitude',
      longitude: 'Longitude',
      lote_no_save: 'talhão sem guardar',
      polygon: 'Polígono',
      geo_position: 'Posição geográfica',
      have_lote_no_save: 'Você tem um lote não salvo. Deseja continuar e descartar o lote?',
      lots: 'Talhões',
    },
    org_data_admin: {
      org_logo_changed: 'O logotipo da organização foi alterado com sucesso.',
      org_data_changed: 'A organização foi editada com sucesso',
    },
    //PAGINA LISTADO DE SILOBOLSAS
    silobag_list: {
      your_silobags: 'Seus Silos Bolsa',
      your_siloreales: 'Seus Siloreales',
      export_data: 'Exportar dados',
      download_data: 'Baixáveis',
      export_all: 'Exportar Tudo',
      export_stock_validate: 'Exportar estoque validado',
      export_view: 'Exportar vista',
      export_selects: 'Exportar selecionados',
      selected: 'Selecionados',
      export_extraction: 'Exportar extrações',
      download_cma: 'Baixar CMA',
      make_extraction: 'Realizar extração',
      register_silobag: 'Registro silo bolsa',
      massive_actions: 'Ações massivas',
      masive_register: 'Alta massiva',
      quality_samples: 'Amostras de qualidade',
      massive_close: 'Fechamento massivo',
      massive_extraction: 'Extrações massivas',
      close_silobags: 'Fechamentos',
      original_stock:
        'O estoque original é de <b>{{initial_weight}} kg</b> e será fechado com uma diferença de:',
      weight_differential: 'Peso diferencial',
      relation: 'em relação aos <b>{{initial_weight}} kg</b> declarados em seu registro',
      question_close: 'Deseja seguir com o fechamento deste silo bolsa?',
      close: 'Fechamento de silo bolsa',
      silobags: 'Silo bolsas',
      your_stock_total: 'Sobre seu estoque total',
      charge_page: {
        quality: 'Carregar planilha amostras',
        extraction: 'Carregar planilha extrações',
        register: 'Carregar planilha altas',
        close: 'Carregar planilha fechamentos',
      },
      download_page: {
        quality: 'Baixar planilha amostras',
        extraction: 'Baixar planilha extrações',
        register: 'Baixar planilha altas',
        close: 'Baixar planilha fechamentos',
      },
      search_by_name_id: 'Pesquisar por nome ou ID',
      new_silobag: 'A silobolsa foi criada com sucesso.',
      non_validated_tons: 'toneladas sem validar',
      non_validated_tons_aclaration:
        'Los silobolsas sin validar no han sido completados o generados desde el campo utilizando la app',
      bagged_tons: 'Toneladas ensacadas',
      extracted_tons: 'Toneladas extraídas',
      tonnage_difference: 'Diferença de toneladas',
    },
    //TERMINOS DE DATA DE SILOBOLSA
    silobag_data: {
      search_by_name: 'Pesquisar por nome',
      specie: 'Cultura',
      species: 'Culturas',
      view_silo_close: 'Ver silo fechado',
      view_non_validated_sb: 'Ver silos não validados',
      none: 'Nenhum',
      product: 'Produto',
      harvest: 'Safra',
      level_alert: 'Nível de alerta',
      range_storage: 'Período de armazenamento',
      delete_filters: 'Limpar filtros',
      register_date: 'Registro',
      bagging_date: 'Embalagem',
      bagging_date2: 'Data de embolsamento',
      initial_humidity: 'Umidade origem',
      close_date: 'Fechando',
      remaining_stock: 'Estoque campo',
      data_main: 'Dados principais',
      additional_data: 'Dados adicionais',
      silobag_init: 'Início silo bolsa',
      silobag_main_photo: 'Foto principal silo bolsa',
      silobag_end: 'Fim silo bolsa',
      silobag_extra_photo: 'Fotos Extras',
      variety: 'Variedade',
      lot_stock: 'talhão Estoque',
      lot_origin: 'talhão Origem',
      weight_lot: 'Peso talhão',
      silobag_size: 'Tamanho silo bolsa',
      weight_bagging: 'Peso embalagem',
      humidity_bagging: '% Umidade embalagem',
      lat: 'Latitude',
      long: 'Longitude',
      bagging_responsible: 'Responsável embalagem',
      silobag_brand: 'Marca silo bolsa',
      split_grain: '% Avariados',
      varietal_purity: '% Impureza',
      fillying_coefficient: 'Coeficiente de enchimento (kg/m)',
      fillying_coefficient_no_metrics: 'Coeficiente de enchimento',
      source_humidity: 'Fonte de umidade',
      source_weight: 'Peso da fonte',
      have_breaks: 'Tem quebras?',
      side_charge: 'Silo Bolsa sobre',
      total_extracted: 'Total estraído',
      movement: 'Movimento',
      movements: 'Movimentos',
      quality_serious: 'Estado crítico',
      quality_min: 'Atenção',
      optimun: 'Ótimo',
      defective: 'Avariados',
      history: 'Histórico',
      devices: 'Dispositivos',
      extractions: 'Extrações',
      metrics: 'Métricas',
      satellite_images: 'Imagens satelitais',
      election_images: 'Escolha as imagens',
      selected_days: 'Dias selecionados',
      existence_silosat: 'Existência',
      existence_images: 'Não há imagens selecionadas',
      size: 'Largura',
      calendar_functionality: 'Funcionamento do calendário:',
      red: 'Vermelho: Prova de inexistência no campo',
      green: 'Verde: Prova de existência em campo',
      white_gray_border: 'Branco com borda cinza: Imagem não analisada',
      no_icon_days: 'Os dias que não têm ícone são aqueles que não têm imagens disponíveis',
      field_selection: 'É necessário selecionar um estabelecimento antes.',
      value_range: 'São aceitos apenas valores entre 0 e 100, e até 2 decimais',
      only_nums: 'São aceitos apenas números.',
      no_image: 'Sem imagem carregada',
      advanced_filters: 'Filtros avançados',
      inconsistency: 'Inconsistência',
      silo_with_movement: 'Silo com movimento',
    },

    silobag_devices: {
      new_lance: 'Nova lança',
      instalation_lances: 'Instalação de lanças',
      without_lances: 'Sem lanças',
      with_lances: 'Com lanças',
      number_lance: 'N° Lança/Nome',
      position: 'Posição',
      init: 'Início',
      init_middle: 'Início-Médio',
      middle: 'Médio',
      middle_end: 'Médio-Fim',
      end: 'Fim',
      photo_device: 'Foto do dispositivo',
      confirm_disassociation_lance: 'Confirma a desassociação da lança?',
      last_update: 'Última atualização',
      alert_actives: 'Alertas ativas',
      history: 'Histórico',
      history_alerts: 'Histórico de alertas',
      actions_massive: 'Ações massivas',
    },

    silobag_share: {
      share_silobag: 'Compartilhar silobolsa',
      ingress_users: 'Ingresse os usuários com quem deseja compartilhar',
      user_access: 'Usuário com acesso',
      type_permission: 'Tipo de permissão',
      user_with_permission: 'Usuário com permissão herdada',
      view: 'Visualizar',
      view_description: 'Você pode visualizar, mas não editar ou compartilhar dados com outros',
      edit: 'Editar',
      edit_description: 'Você pode editar mas ñao compartilhar dados com outros',
      access_complete: 'Acesso completo',
      access_complete_description: 'Você pode editar e compartilhar dados com outros',
      share: 'Compartilhar',
      permissions_share: 'Não possui permissão para compartilhar os seguintes silobolsas:',
      press_enter_or_space: 'Após escrever o e-mail, pressione "Enter" ou "Espaço".',
      silobag_already_shared: 'O silobag já está compartilhado ou o usuário não existe.',
      silobag_shared: 'Silobag compartilhado com sucesso',
      permissions_updated: 'Permissões atualizadas com sucesso.',
      delete_permission: 'Remover Acesso',
      share_silobag_title: 'Compartilhar',
      cancel_message: 'A operação de compartilhamento de silos bolsas foi cancelada',
    },
    silobags_extractions: {
      silos: 'silos',
      choose_two_silobags: 'Selecione até dois silobags',
      extraction_silobag: 'Extração de silobolsa',
      date_withdrawal: 'Data de retirada',
      type_extraction: 'Tipo de extração',
      truck: 'Caminhão',
      consolidated: 'Consolidado',
      weight_truck: 'Peso Caminhão',
      gross_weight: 'Peso Bruto',
      gross_consolidated: 'Peso Liquido',
      transport: 'Transporte',
      freight_broker: 'Agente de frete',
      carrier: 'Transportador',
      driver: 'Motorista',
      chassis_patent: 'Patente chassis',
      chassis_attached: 'Patente carreta',
      others: 'Outros',
      ctg: 'CRG (Código de Rastrerabilidade de grãos)',
      extractions: 'Extrações',
      tara: 'Tara',
      gross: 'Bruto',
      liquid: 'Liquido',
      generic_error_msg: 'Houve um erro ao extrair o peso da silobolsa.',
      extraction_more_than_doble: 'O peso de extração é mais que o dobro do peso inicial.',
      freight_brok: 'Agente de frete',
      cp: 'CEP',
      contract: 'Contrato',
      destiny: 'Destino',
      extraction_date: 'Data de extração.',
    },
    silobag_detail: {
      general_evaluation: 'Avaliação geral',
      next: 'Próximo',
      previous: 'Anterior',
      charge_files: 'Carregar arquivos',
      perform_extraction: 'Realizar extração',
      close: 'Fechar',
      silo_close: 'Silo fechado',
      silobag_version: 'Versão do silobag',
      register_extractions: 'Registro de extrações',
      view_in_map: 'Ver no mapa',
      galery_images: 'Galeria de imagens',
      view_files: 'Ver arquivos',
      refresh: 'Atualizar',
      weight_extractions: 'Peso extrações',
      difference: 'Diferença',
      difference_percentage: "Diferença '%'",
      edit_silobag: 'O silobolsa foi editado com sucesso',
      new_extraction: 'Extração concluída com sucesso.',
      edit_extraction: 'A extração do silobag foi atualizada com sucesso.',
      delete_extraction: 'A extração foi removida com sucesso.',
      first_CMC: 'CMC inicial',
      current_CMA: 'CMC atual',
      silosat_date: 'Intervalo aceitável para certificação',
      different_date: 'Inconsistências detectadas na data de embolsamento',
      certification_silosat:
        'Certifica-se que o embolsamento ocorreu entre {{firstVisibleDate}} e {{lastVisibleDate}}',
      different_context:
        'A data declarada durante o registro do silo bolsa não está no intervalo considerado aceitável para a certificação.',
      silosat_size: 'Tamanho SiloSat',
      different_size: 'Diferença no comprimento declarado',
      different_context_size:
        'O comprimento declarado durante o registro do silo bolsa não coincide com o registrado no SiloSat. Caso deseje certificar este silo bolsa, é necessário corrigir esta diferença.',
      warning_silosat: 'Inconsistência detectada no SiloSat',
      validated_silosat: 'Este comprimento verificado pelo SiloSat',
      state_initial: 'Estado inicial',
      tooltip_charge_files: 'Inserir arquivos relevantes para o Siloreal',
      donwload_certificate_history: 'Baixar histórico SiloReal completo',
      download_extractions: 'Baixar planilha de extrações',
      sync_corvus: 'É necessário vincular o Corvus para poder acessar o módulo',
      show_lab: 'Ver e adicionar amostras de qualidade',
      observation_tooltip: 'Não há observações inseridas',
      files_tooltip: 'Não há arquivos anexados',
    },
    silo_sat: {
      images_sat: 'Imagens satelitais',
      without_images: 'Sem imagens satelitais',
      enabling: 'Sem SiloSat. Completar o registro em campo para passar para o próximo estágio.',
      unavailable:
        'Não disponível. Houve algum erro no processo de ativação. Entre em contato com o time de suporte.',
      processing: 'Em ativação. Estamos processando a informação para completar a ativação do serviço.',
      active: 'Ativo',
      available: 'Disponível',
    },
    silobag_metric: {
      metrics: 'Métricas',
      meditions_registered: 'Medições registradas',
      co2: 'Dióxido de Carbono',
      heg: 'Umidade de Equilibrio',
    },
    //PAGINAS DE REPORTES
    report_stock: {
      banner: {
        banner_title: 'Saldo Total da organização',
      },

      stock_report_title: 'Relatórios de Movimentação de Estoque',
      init_date: 'Data de início',
      close_date: 'Data de fechamento',

      initial_balance: 'Saldo Inicial',
      incomes: 'Rendas',
      income: 'Renda',
      outcomes: 'Saídas',
      outcome: 'Saída',
      final_balance: 'Saldo Final',
      balance: 'Saldo',
      detail: 'Detalhe',
      see_detail: 'Ver Detalhe',
      type: 'Tipo',
      tons_graphic: 'Gráfico em toneladas',
      balance_graphic: 'Gráfico de equilíbrio',
      initial_description: 'Montante no início do período selecionado.',
      incomes_description: 'Montante filtrado de entradas.',
      outcomes_description: 'Montante filtrado de saídas.',
      final_description: 'Saldo Inicial + Entradas - Saídas.',
      movement: 'Movimento',
    },

    report_devices: {
      delayed: 'Demorado',
      delete_filters: 'Remover filtros',
      devices_reports_title: 'Relatório de dispositivos',
      installed: 'Instalado',
      last_two_weeks: '14 dias mais recente',
      not_installed: 'Não Instalado',
      not_reporting: 'Não reportando',
      report: 'Relatório',
      reporting: 'Reportando',
      search_device_name: 'Pesquise por nome do dispositivo',
      silo_id: 'Silo ID',
      silobag_name: 'Nome Silo',
      status: 'Instalação',
      totals: 'Totais',
      without_metrics: 'Sem métricas',
      last_transmission: 'Última transmissão',

      deveui_modal: {
        gateway: 'O estado de conexão indica se o Gateway relatou nas últimas duas horas',
        connected: 'Conectado',
        desconnected: 'Desconectado',
        device_detail: 'Detalhe do dispositivo',
        associated_gateway: 'Gateway associado',
        status_connection_gateway: 'Status da conexão do gateway',
        other: 'Outros',
        warning_last_transmission:
          'Os dados mostrados correspondem à última transmissão recebida do sensor. É possível que esta informação não represente o estado atual do mesmo.',
        warning_battery:
          'A métrica sobre a bateria indica o nível de carga da última transmissão. Se o sensor continua enviando dados, mesmo sem estar instalada, é recomendado desligá-la para preservar sua duração.',
      },
    },

    report_activity: {
      title: 'Relatório de Atividade',
    },

    public_pages: {
      login: 'Iniciar sessão',
      register: 'Registro',
      forgot_password: 'Você esqueceu sua senha?',
      user: 'Usuário',
      password: 'Senha',
      password_confirm: 'Confirme a senha',
      email: 'E-mail',
      register_question: 'Não tem uma conta? Registro',
      ingress: 'Entrar',
      recover_password: 'Recuperar senha',
      register_company: 'Registro de empresa',
      legal_name: 'Nome jurídico',
      cuit: 'CNPJ',
      fullname: 'Nome completo',
      phone: 'Telefone',
      accept_terms: 'Aceito os termos e condições',
      create_account: 'Criar conta',
      reset_password: 'Redefinir senha',
      register_success: 'Registro bem-sucedido',
      go_to_login: 'Ir para o login',
      verify_register_title: 'Enviamos um e-mail para você verificar sua conta',
      verify_register_description: 'Por favor, verifique seu e-mail para continuar',
      verify_register_code: 'Código de verificação',
      no_received_code: 'Você não recebeu o código?',
      no_received_code_decription: 'Verifique a caixa de spam ou clique no botão para reenviar',
      policy_privacy: 'Política de privacidade',
      terms_conditions: 'Termos e condições',
      contact: 'Contato',
      error_register:
        'Ocorreu um erro ao tentar registrar a empresa.Por favor verifique se todos os campos foram preenchidos corretamente.',
      terms_conditions_error: 'Você deve aceitar os termos e condições para continuar',
    },
    register_silobag_modal_info: {
      missing_countrysides:
        'Um estabelecimento deve ser criado antes de criar silobolsas. Veja na aba Gerenciado > Estabelecimentos > +Novo Estabelecimento.',
    },
    silobag_edit_modal: {
      explanation: 'Você está editando os dados de um silobolsa para deixar rastreabilidade no histórico.',
      confirmation: '¿Tem certeza de que deseja confirmar as alterações?',
    },
    silobag_close_modal: {
      silobags: 'Fechamento de Silo bolsas',
      question: 'Deseja continuar com o fechamento dos silos?',
      description: 'Lembre-se de que esta ação não é reversível',
      confirmation: 'Confirmar fechamento',
      permissions: 'Você não tem permissão para fechar silos bolsa',
      silobags_closed: 'Silos bolsa fechados com sucesso',
    },
    silobag_history: {
      extraction: 'Ver Extração',
      resumen: 'Resumo',
      download_file: 'Baixar arquivo',
      no_images: 'Não há imagens para exibir',
      poe_warning: 'A verificação é inválida porque foi realizada a mais de 50 metros do silo bolsa',
      poe_invalid: 'Verificação inválida porque o silo bolsa não foi registrado no campo',
      poe_warning_description:
        'A verificação foi realizada a {{totalDistance}} km do silo bolsa, o máximo para que seja válida é 50 metros.',
      compass: 'Bússola',
      gps_accuracy: 'Precisão GPS',
      reason: 'Razão',
      detail_poe: 'Detalhe da vistorias',
      general_evaluation: 'Avaliação geral',
      description_election: 'Descrição da escolha ou observações',
      breakage: 'Quebras',
      reason_breakage: 'Razão da quebra',
      quality_revision: {
        calado: 'Calado',
        sample_type: 'Tipo de amostra',
        silo_position: 'Posição no silo',
        value: 'Valor',
      },
      photo_poe: 'Foto vistorias',
      photo_breakage: 'Foto quebras',
      photo_question: 'Foto pergunta',
      photo_breakage_resolved: 'Quebra resolvida',
      photo_revition_quality: 'Revisão qualidade',
      photo_detail_siloid: 'Foto detalhe SiloID',
      photo_extra: 'Foto extra',
      Optimum: 'Ótimo',
      precaution: 'Precaução',
      high_risk: 'Risco elevado',
      Critical: 'Crítico',
      descriptions: {
        Optimum: 'Não há riscos sobre o silobolsa, está em boas condições de armazenamento.',
        precaution: 'Há um leve risco sobre o silobolsa. Está em condições aceitáveis de armazenamento.',
        high_risk:
          'Há um risco elevado sobre o silobolsa e está em estado de precaução. Está em condições não aceitáveis de armazenamento.',
        Critical: 'O silobolsa está em estado crítico, requer atenção imediata.',
      },
    },
    silobag_action: {
      error_creat_silobag: 'Erro ao criar o silobolsa',
      invalid_form_message: 'Existem erros no formulário. Por favor, preencha todos os campos obrigatórios',
      close_success: 'Todos os silobolsas foram fechados com sucesso',
      close_warning: 'Vários silobolsas foram fechados, mas alguns não foram fechados devido a erros.',
      close_error: 'Os silobolsas não foram fechados devido a erros.',
      upload_success: 'Todos os silobolsas foram carregados com sucesso',
      upload_warning: 'Vários silobolsas foram carregados, mas alguns não foram carregados devido a erros.',
      upload_error: 'Os silobolsas não foram carregados devido a erros.',
      error_messages: {
        20001:
          'A data está em um formato incorreto. Sugerimos sua revisão e correção. Se o excel está em espanhol ou português o formato é DD/MM/AAAA. Se está em inglês o formato é MM/DD/AAAA',
        20002: 'Cultura não encontrada',
        20003: 'Tipo de produto não encontrado',
        20004: 'Organização não encontrada',
        20005: 'Estabelecimento não encontrado',
        20006:
          'Lote de stock não encontrado, se deseja agregá-lo, acesse a seção de administrador na plataforma web',
        20007: 'Fonte de umidade não encontrada. As opções disponíveis são: "Medição manual" ou "Outra"',
        20008: 'Camion carga sobre não encontrado, as opções corretas são: "Lote" ou "Camino"',
        20009:
          'Fonte de peso não encontrada. As opções disponíveis são: "Corvus", "Bazuca", "Manual" ou "Coeficiente"',
        20010: 'O campo de peso embolsado não pode estar vazio',
        20011: 'O peso embolsado deve ser um número inteiro',
        20012: 'A umidade inicial deve ser um número',
        20013: 'O valor da umidade deve ser um número entre 0 e 100',
        20014: 'A pureza varietal deve ser um número',
        20015: 'O valor da porcentagem de impureza deve ser um número entre 0 e 100',
        20016: 'O valor de porcentagem de defeituosos deve ser um número',
        20017: 'O valor de porcentagem de defeituosos deve ser um número entre 0 e 100',
        20018: 'O coeficiente de enchimento deve ser um número',
        20019: 'Safra não encontrada',
      },
    },

    siloreal_tutorial: {
      welcome_to_siloreal: 'Bem Vindo à SiloReal',
      step: 'Passo',
      first_title: 'Dashboard',
      first_description:
        'Você poderá visualizar de forma inteligente todos os seus silos bolsa e seus alertas, e assim tomar melhores decisões para seu negócio.',
      map: 'Mapa',
      second_decription:
        'Com a georreferência você poderá localizar facilmente e em tempo real todos os seus ativos',
      third_decription:
        'Substitua suas planilhas por uma lista com filtros para ver seu estoque em campo atualizado.',
      fourth_title: 'Detalhe por silobolsa',

      fourth_decription:
        'Você poderá dar identidade a cada um dos seus silos bolsa, tendo toda a rastreabilidade em um mesmo lugar.',
      fifth_decription:
        'Tenha o controle de tudo o que acontece através da plataforma e faça a gestão dos silos bolsa com passos simples, administrando suas organizações, fazendas e usuários. ',
      finish: 'Terminar',
      next: 'Seguinte',
      back: 'Voltar',
    },
    notification_page: {
      custom_notifications: 'Personalizar Notificações',
      username: 'Nome de Usuário',
      active_all: 'Ativar todas',
      poe_expired: 'Recorrido: Expirado',
      poe_expired_description:
        'Alerta gerado pelo usuário ao encontrar um silobag em condições de alerta durante um recorrido',
      poe_result: 'Recorrido: Resultado',
      poe_result_description:
        'Alerta gerado pelo vencimento do último recorrido realizado (passaram mais dias entre os recorridos do que o valor configurado)',
      exist_silo_sat: 'Existência SiloSat',
      exist_silo_sat_description: 'Alerta gerado ao não detectar um silobag com imagem de satélite',
      co2: 'Dióxido de Carbono (CO2)',
      co2_description: 'Alerta gerado ao exceder o valor de CO2 permitido para a espécie do silobag',
      mov: 'Movimento (MOV)',
      mov_description: 'Alerta gerado ao detectar movimento de uma sonda',
      heg: 'Umidade (HEG)',
      heg_description: 'Alerta gerado ao exceder o valor de umidade permitido para a espécie do silobag',
      bat: 'Bateria (BAT)',
      bat_description: 'Alerta gerado ao detectar um nível de bateria inferior a 20%',
      quality: 'Qualidade',
      security: 'Segurança',
      tecnical: 'Técnico',
      origin: 'Origem',
      silo_id_tooltip: 'Alertas de resultado de vencimento de percurso',
      silo_sat_tooltip: 'Alertas de existência de serviço satelital',
      silo_metrix_tooltip: 'Alertas de métricas obtidas das lanças do silobolsa',
      view_detail: 'Ver detalhe',
      desactive_all: 'Desativar todas',
      toast_success: 'Configuração de notificações atualizada',
      validate_phone: 'Validar número de telefone',
      validate_text:
        'Para poder habilitar as notificações SMS ou WhatsApp é necessário validar seu número de telefone.',
      send_sms: 'Enviar SMS',
      phone_validated: 'Número de telefone validado com sucesso',
      phone_invalidated: 'Número de telefone inválido',
      ingress_valid_format: 'Insira um número de telefone válido ',
      warning_modal_title: 'Atenção!',
      warning_modal_text:
        'Para receber as notificações o telefone deverá ser verificado pelo usuário primeiro.',
      confirm_success: ' Número de telefone verificado corretamente.',
      validation_modal_title: 'Enviamos um SMS para validar seu número de telefone',
      validation_modal_text: 'Por favor, insira seu código de verificação',
      warning_phone: 'Ao editar o telefone, será necessário validá-lo novamente.',
      confirm_error: 'Erro ao validar o número de telefone. Formato inválido.',
      button_validate_phone: 'Validar número de telefone',
      confirm_error_code: 'Erro ao validar o número de telefone. Código inválido.',
    },
    not_found: {
      return_dashboard: 'Retornar ao Dashboard',
      return_login: 'Retornar ao Login',
      message: 'Infelizmente, a página que você está procurando não está disponível.',
    },
    state_stock_page: {
      report_state_stock: 'Relatório de Estatus de estoque',
      search_by_name_silo_countryside: 'Buscar SiloID, silo bolsa ou estabelecimento',
      date_bagging: 'Data de Embalagem',
      date_poe: 'Ultima Visita',
      result: 'Resultado',
      humidity: 'Umidade',
      aproved: 'Aprovado',
      caution: 'Cuidado',
      risk_elevate: 'Risco Elevado',
      critical: 'Crítico',
      former: 'Antigo:',
      expired: 'Expirado',
      certificate_silo_sat: 'Certificação SiloSat',
      no_certificate_silo_sat: 'Sem certificação SiloSat',
      lance_id: 'ID Lança',
      poe: 'Recorrida',
      position: 'Posição',
      last_metric: 'Medição anterior: ',
      no_last_metric: 'Sem medição anterior',
    },

    map_menu: {
      ubication_copied: 'O local foi copiado com sucesso',
    },

    existence_report_stock: {
      existence_stock_report_title: 'Relatório de existência de estoque',
      poe_expired: ' Vistorias expirados',
      visibles: 'Visível',
      not_visibles: 'Não Visível',
      without_images: 'Sem imagens',
      with_movement: 'Com movimento',
      without_movement: 'Sem movimento',
      missing_tons_title: '¿Estás faltando toneladas?',
      days_since_last_poe: 'Dias desde a última vistoria',
      result: 'Resultado',
      certainty: 'Certeza',
      last_seven_days: 'Últimos 7 días',
      movement: 'Movimento',
      last_measurement: 'Métrica mais recente',
      is_visualized: 'Se visualiza',
      not_visualized: 'Não visualiza',
      without_results: 'Sem Estado',
    },
    silobag_insurance: {
      tons_quoted: 'Toneladas Cotadas',
      month_coverage: 'Cobertura mensal',
      tree_month_coverage: 'Cobertura trimestral',
      six_month_coverage: 'Cobertura semestral',
      thirds_complete: 'Terceiros Completos',
      sum_assured: 'Soma Assegurada',
      view_detail: 'Ver Detalhe',
      contract: 'Contratar',
      month: 'Mês',
      duration_policy: 'Duração da apólice:',
      assured_production: 'Asseguramos a sua produção em',
      crop: 'Cultivo de',
      no_data_month:
        'Não há dados de cotação mensal para este silobag, apenas para espécies disponíveis (Milho, Soja, Trigo, Girassol, Cevada)',
      no_data_three_month:
        'Não há dados de cotação trimestral para este silobag, apenas para espécies disponíveis (Milho, Soja, Trigo, Girassol, Cevada)',
      no_data_six_month:
        'Não há dados de cotação semestral para este silobag, apenas para espécies disponíveis (Milho, Soja, Trigo, Girassol, Cevada)',
      your_plan: 'seu plano',
      with: 'com',
      disabled_close_mobile: 'Há pelo menos um SiloReal já fechado em campo: {{ids}}',
      validation: 'Selecione um silobag para ver as opções de seguro',
    },
    integration_corvus: {
      title: 'Integração com Corvus',
      login_corvus: 'Faça login com sua conta Corvus',
      login_corvus_description:
        'Utilize o mesmo endereço de usuario e senha usados para iniciar sessão na sua conta do Corvus.',
      ingress: 'Entrar',
      step: 'Passo',
      vinculate_silobag_corvus: 'Vincular Silobag a AGDP',
      confirm_downloads_corvus: 'Confirme os downloads de Corvus',
      weight_corvus: 'Peso Corvus (kg)',
      warning: 'Lembre- se que se o link for confirmado, o peso que será mantido será o peso corvus',
      vinculate_corvus: 'Vincular Silobolsa à AGDP',
      confirm_corvus: 'Confirme os downloads do Corvus',
      detail_corvus_title: 'Corvus - detalhe de ligação',
      corvus_weight_certified: 'Peso Corvus Certificado',
      bagging_weight_original: 'Peso de embalagem original: ',
      with: 'com',
      error_login: 'Erro ao fazer login no Corvus, verifique os dados inseridos',
      user: 'Usuário',
      password: 'Senha',
      desvinculate_corvus: 'Desvincular Corvus',
      title_desvinculate: 'Desvinculação completa de downloads',
      success_desvinculate: 'Desvinculação realizada com sucesso!',
      text_desvinculate:
        'Serão desvinculadas todas as descargas deste siloreal, eliminando a certificação do peso embolsado e mostrando o peso declarado antes da vinculação.',
      note_desvinculate: ' Esta alteração será registrada no histórico como ',
      edit_silobag: 'Edição de silobolsa',
      wet_weight: 'Peso úmido',
      wet: 'Úmido',
      wet_dry: 'Peso seco',
      dry: 'Seco',
    },

    metrics_descriptions: {
      co2: 'Medição de dióxido de carbono (CO2) exibida pelo sensor de lança',
      temp: 'A temperatura mostra a medição pelo sensor de lança',
      heg: 'Umidade de equilíbrio calculada segundo INTA com os valores de umidade relativa, temperatura e espécie',
      hum: 'Umidade intergranária mostrada pela medição do sensor de lança',
      bat: 'Bateria %',
    },
    water_mark: {
      compass: 'Bússola',
      gps: 'Precisão GPS',
      missing_date: 'Data no disponível',
    },
    modal_cmc: {
      register_cmc: 'Registrar SiloID',
      type: 'Tipo',
      date: 'Data',
      download_cmc_initial: 'Baixar SiloID Inicial',
      generate_cmc_update: 'Gerar SiloID Atualizado',
      copy_document_hash: 'Copiar hash do documento',
      copy_transaction_hash: 'Copiar hash da transação',
      download_certificate: 'Baixar certificado',
    },
    countryside_share: {
      share_countryside: 'Compartilhar estabelecimento',
      share_countryside_success: 'Estabelecimento compartilhado com sucesso',
      share_countryside_error: 'O estabelecimento já está compartilhado ou o usuário não existe',
    },
    lab: {
      laboratory: 'Laboratório',
      template_download: {
        tooltip: {
          init: 'É preciso selecionar pelo menos 1 silo bolsa',
          continue: 'Só é possível prosseguir se as culturas selecionadas possuem planilhas disponíveis',
        },
      },
      warning_modal: {
        attention: 'Atenção',
        unavailable:
          'Há {{silosQty}} silo bolsa selecionado cuja cultura não possui um modelo de planilha para carregar as amostras de qualidade.',
        available:
          'Por enquanto as culturas com planilhas disponíveis para realizar a amostra de qualidade são:',
        continue: '¿Deseja continuar?',
      },
      upload: {
        success_toast: 'Planilha importada corretamente',
      },
      download_modal: {
        title: 'Planilhas para amostras de qualidade',
      },
      detail_sample_modal: {
        title: 'Amostra de qualidade',
        acquisition_date: 'Data amostra',
        analysis_date: 'Data de análise',
        analysis_number: 'Nro. laude',
        button: {
          download: 'Baixar nova planilha',
          upload: 'Carregar planilha',
        },
        column_header: {
          field: 'Item',
          result: 'Resultado',
          ref: 'Referência (base e tolerância)',
        },
        no_data: 'Sem dados de amostras',
      },
      success: {
        upload: 'Planilha importada corretamente',
        download: 'Planilha exportada corretamente',
      },
      error: {
        err10019: 'Planilha já importada',
        err10020: 'Esta planilha não corresponde ao silo bolsa selecionado.',
        invalid_file_type: 'Tipo de arquivo inválido.',
        default: 'Ocorreu um erro desconhecido.',
      },
      input_error: {
        required_field: 'Este campo é obrigatório.',
        invalid_value: 'Este valor é inválido.',
        only_numbers: 'São permitidos apenas números.',
        out_of_range: 'Valor fora dos limites permitidos.',
      },
    },
    mobile_close_modal: {
      closure_details_origin: 'Detalhes do fechamento em campo',
      grain_loss: 'Houve alguma perda de grãos no local?',
      area_cleaned: 'A área estava limpa e livre de resíduos plásticos?',
      plastic_recycled: 'O plástico do silo bolsa foi reciclado por uma empresa certificada?',
      closure_distance: 'O fechamento foi realizado a {{distance}} metros do silo bolsa',
      photo_of_area: 'Foto da área',
    },
    alert: {
      state: {
        pending: 'Pendente',
        review: 'Em revisão',
        resolve: 'Solucionado',
        delete: 'Excluir',
      },
      history: {
        footnote: 'Múltiplos status de alertas alterados.',
      },
      modal: {
        title: 'Alteração de status',
        placeholder: {
          main: 'Comentário...',
        },
        single: {
          question: 'Tem certeza de que deseja alterar o status do alerta a ',
        },
        massive: {
          question: 'Tem certeza de que deseja alterar todos os status dos alertas a ',
          warning_title: 'Atenção!',
          warning1:
            '• Você está prestes a realizar uma alteração de status de todos os alertas do menu de alertas ativos.',
          warning2: '• Esta ação pode levar até 2 minutos para ser concluída.',
        },
      },
    },
    delete_extraction: {
      delete: ' Excluir extração',
      confirmation_msg: 'Tem certeza que deseja excluir esta extração?',
    },
    report_position_general: {
      title: 'Relatório de Posição Geral',
    },
    error_screen: {
      something_went_wrong: 'Algo deu errado...',
      error_detected: 'Um erro interno foi detectado durante a operação do sistema.',
      alternatives: 'Aqui estão algumas ações que podem ser úteis para você:',
      support_contact: 'Ou você pode entrar em contato com a equipe de suporte para obter ajuda.',
      button: {
        dashboard: 'Ir para o painel',
        reload: 'Recarregar',
        logout: 'Sair',
        support: 'Suporte',
      },
      toast: {
        copy_error_success: 'Erro copiado com sucesso.',
      },
    },
    nav_bar_bell: {
      button: {
        resolve_all: 'Solucionar todos',
        history: 'Histórico',
      },
      tooltip: {
        only_own_org_resolve:
          'Só é possível resolver alertas em massa da sua organização. Selecione a sua organização através do seletor para continuar.',
        only_own_org_history: 'Só é possível visualizar o histórico da sua organização.',
        no_own_org_alerts: 'Não há alertas ativos na sua organização',
      },
      modal: {
        history_title: 'Histórico de resolução',
        warning_message: 'Tem certeza de que deseja resolver todos os alertas ativos?',
      },
      table: {
        date_header: 'Data e hora',
        user_header: 'Usuário',
        alerts_resolved_qty_header: 'Número de alertas resolvidos',
      },
    },
    silobags_filters: {
      only_actives: 'Somente ativos',
    },
    image_gallery: {
      title: 'Galeria de imagens',
      no_images: 'Não há imagens disponíveis',
      coords_non_disponible: 'Coordenadas não disponíveis',
    },
  },
};

import { PropsWithChildren, useState } from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Box, Modal, Fade, Typography, Grid, Button } from '@mui/material';
import i18n from '../../../libs/language';
import AppGridContainer from '@ui/app-grid-container';
import { BaseTable } from '@ui/table';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { configButtonStylesContained } from '@styles/generic-styles';
import { useEffectOnUpdate } from '@hooks/core';
import { useService } from '@hooks/use-service';

import { ToastAction } from '@ui/toast/toast-action';
import { useGlobalStore } from '@global-store/use-store';
import { massiveCloseService_DEPRECATED } from '@services/silobag/silobag';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];
type SilobagModalMassiveCloseProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  silobagData: SummarySilobagList;
  setIsMassiveClose: (isMassiveClose: boolean) => void;
};

type TitleProps = {
  fullSiloCount: number;
};

type TableSectionProps = {
  fullRows: FullRows;
  onDeleteRow: (id: number) => void;
};

type InitialViewProps = {
  noPermissionRows: PermissionRows;
  onContinue: () => void;
  onCancel: () => void;
  fullSiloCount: number;
  noPermissionCount: number;
};

type ActionButtonsProps = {
  handleClose: () => void;
};

const commonButtonStyles = {
  fontWeight: 600,
  textTransform: 'none',
  display: 'flex',
  color: '#FF0000',
};

const VIEW_TYPE = {
  INITIAL: 'initial',
  FINAL: 'final',
};

const formatNumberWithThousandsSeparator = (num: number | null | undefined) =>
  num != null && !isNaN(num) ? num.toLocaleString('en-US') : 'N/A';

const formatPercentage = (num: number | null | undefined) =>
  num != null && !isNaN(num) ? num.toFixed(2) : 'N/A';

const renderCellBold = (params: GridCellParams) => (
  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{params.value as React.ReactNode}</div>
);

// Columnas de la tabla
const columns = (onDeleteRow: (id: number) => void, excludeActions = false): GridColDef[] => {
  const baseColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: excludeActions ? 400 : 100,
      renderCell: renderCellBold,
      headerAlign: excludeActions ? 'center' : 'left',
      align: excludeActions ? 'center' : 'left',
    },
    {
      field: 'name',
      headerName: i18n.t('app.name'),
      flex: 1,
      width: excludeActions ? 400 : 100,
      headerAlign: excludeActions ? 'center' : 'left',
      align: excludeActions ? 'center' : 'left',
    },
  ];

  if (!excludeActions) {
    baseColumns.push(
      {
        field: 'weight_bagging',
        headerName: i18n.t('app.silobag_data.weight_bagging'),
        flex: 1,
        width: 200,
        renderCell: (params: GridCellParams) =>
          `${formatNumberWithThousandsSeparator(params.value as number)} kg`,
      },
      {
        field: 'weight_extractions',
        headerName: i18n.t('app.silobag_detail.weight_extractions'),
        flex: 1,
        width: 220,
        renderCell: (params: GridCellParams) =>
          `${formatNumberWithThousandsSeparator(params.value as number)} kg`,
      },
      {
        field: 'difference',
        headerName: i18n.t('app.silobag_detail.difference'),
        flex: 1,
        width: 200,
        renderCell: (params: GridCellParams) =>
          `${formatNumberWithThousandsSeparator(params.value as number)} kg`,
      },
      {
        field: 'percentage',
        headerName: i18n.t('app.silobag_detail.difference_percentage'),
        flex: 1,
        width: 100,
        renderCell: (params: GridCellParams) => `${formatPercentage(params.value as number)}%`,
      },
      {
        field: 'actions',
        headerName: 'Acciones',
        width: 150,
        renderCell: (params: GridCellParams) => (
          <Button
            sx={commonButtonStyles}
            startIcon={<img src={DeleteIcon} alt="delete-icon" />}
            onClick={() => onDeleteRow(params.id as number)}
          ></Button>
        ),
      }
    );
  }

  return baseColumns;
};
// Sección de la tabla
const TableSection = ({ fullRows, onDeleteRow }: TableSectionProps) => (
  <Grid item xs={12}>
    <Grid item xs={12} md={12}>
      <BaseTable rows={fullRows} columns={columns(onDeleteRow)} initialPageSize={16} />
    </Grid>
  </Grid>
);

// Título de la sección
const Title = ({ fullSiloCount }: TitleProps) => (
  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
    {i18n.t('app.silobag_close_modal.silobags')} ({fullSiloCount})
  </Typography>
);

// Descripción
const DescriptionSection = () => (
  <Grid item xs={12} textAlign="center">
    <Typography variant="body1">
      <strong>{i18n.t('app.silobag_close_modal.question')}</strong>
    </Typography>
    <Typography variant="body2">{i18n.t('app.silobag_close_modal.description')}</Typography>
  </Grid>
);

// Botones de acción  (Cancelar y Confirmar)
const ActionButtons = ({
  handleClose,
  fullRows,
  createMassiveCloseSilobag,
}: ActionButtonsProps & {
  fullRows: FullRows;
  createMassiveCloseSilobag: (id: Pick<FullRows[0], 'id'>[]) => void;
}) => {
  const handleConfirmation = () => {
    const ids = fullRows.map((row) => ({ id: row.id }));
    createMassiveCloseSilobag(ids);
    handleClose();
  };

  return (
    <Box display={'flex'} justifyContent={'end'}>
      <Button onClick={handleClose} sx={{ color: 'black' }}>
        {i18n.t('app.cancel')}
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ ...configButtonStylesContained, background: '#FF0000', color: '#FFFFFF' }}
        disabled={fullRows.length === 0}
        onClick={handleConfirmation}
      >
        {i18n.t('app.silobag_close_modal.confirmation')}
      </Button>
    </Box>
  );
};

// Vista inicial
const InitialView = ({
  noPermissionRows,
  onContinue,
  onCancel,
  noPermissionCount,
  fullSiloCount,
}: InitialViewProps) => (
  <Box>
    <Title fullSiloCount={noPermissionCount} />
    <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: 2 }}>
      {i18n.t('app.silobag_close_modal.permissions')}
    </Typography>
    <BaseTable rows={noPermissionRows} columns={columns(() => {}, true)} initialPageSize={16} />
    <Box display={'flex'} justifyContent={'end'} mt={2}>
      <Button onClick={onCancel} size="small" sx={{ color: 'black' }}>
        {i18n.t('app.cancel')}
      </Button>
      <Button
        variant="contained"
        size="small"
        sx={{ ...configButtonStylesContained }}
        disabled={fullSiloCount === 0}
        onClick={onContinue}
      >
        {i18n.t('app.continue')}
      </Button>
    </Box>
  </Box>
);

type FullRows = {
  id: string;
  name: string;
  weight_bagging: number;
  weight_extractions: number;
  difference: number;
  percentage: number;
  silobag_data_statuses: { id: number | undefined };
}[];

type PermissionRows = {
  id: string;
  name: string;
  weight_bagging: number;
  weight_extractions: number;
  difference: number;
  percentage: number;
}[];

export function SilobagModalMassiveClose({
  open,
  setOpen,
  silobagData,
  setIsMassiveClose,
}: SilobagModalMassiveCloseProps) {
  const [view, setView] = useState(VIEW_TYPE.INITIAL);
  const [fullRows, setFullRows] = useState<FullRows>([]);
  const [showToast, setShowToast] = useState(false);
  const { filters, setFilters } = useGlobalStore();

  // Filas de la tabla con silobags sin permisos
  const noPermissionRows: PermissionRows =
    silobagData
      ?.filter(({ read_permission, write_permission }) => read_permission && !write_permission)
      .map(({ id, name, input_weight, total_extracted_weight }) => {
        const weightBagging = parseFloat(input_weight);
        const weightExtractions = parseFloat(total_extracted_weight);
        const difference = weightBagging - weightExtractions;
        const percentage = (difference / weightBagging) * 100;

        return {
          id,
          name,
          weight_bagging: weightBagging,
          weight_extractions: weightExtractions,
          difference,
          percentage,
        };
      }) || [];

  const noPermissionCount = noPermissionRows?.length;

  // Filas de la tabla con silobags con permisos
  const fullRowsData: FullRows =
    silobagData
      ?.filter(({ read_permission, write_permission }) => read_permission && write_permission)
      .map(({ id, name, input_weight, total_extracted_weight }) => {
        const weightBagging = parseFloat(input_weight);
        const weightExtractions = parseFloat(total_extracted_weight);
        const difference = weightBagging - weightExtractions;
        const percentage = (difference / weightBagging) * 100;
        return {
          id,
          name,
          weight_bagging: weightBagging,
          weight_extractions: weightExtractions,
          difference,
          percentage,
          silobag_data_statuses: { id: undefined },
        };
      }) || [];

  const fullSiloCount = fullRowsData?.length;
  const hasCompleteStatus = fullRowsData.some((silo) => silo.silobag_data_statuses?.id === 2);

  useEffectOnUpdate(() => {
    setFullRows(fullRowsData);
  }, [silobagData]);

  // Servicio para cerrar silobags
  const { trigger: createMassiveCloseSilobag, isSuccess: isMassiveClose } = useService(
    massiveCloseService_DEPRECATED,
    {
      onSuccess: () => {
        setShowToast(true);
        if (hasCompleteStatus) {
          setFilters({ ...filters, silobag_data_statuses: 1 });
        }
        setFullRows([]);
      },
    }
  );

  // Cierra el modal
  const handleClose = () => {
    setView(VIEW_TYPE.INITIAL);
    setOpen(false);
  };

  // Cambia la vista a la final
  const handleContinue = () => {
    setView(VIEW_TYPE.FINAL);
  };

  // Elimina la fila de la tabla
  const handleDeleteRow = (id: number | string) => {
    setFullRows((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  useEffectOnUpdate(() => {
    if (isMassiveClose) {
      setIsMassiveClose(true);
    }
  }, [isMassiveClose]);

  useEffectOnUpdate(() => {
    if (!open) {
      setView(VIEW_TYPE.INITIAL);
      setShowToast(false);
    }
  }, [open]);

  return (
    <>
      <MyModal onClose={handleClose} open={open}>
        {view === VIEW_TYPE.INITIAL && noPermissionRows.length > 0 ? (
          <InitialView
            noPermissionRows={noPermissionRows}
            onContinue={handleContinue}
            onCancel={handleClose}
            fullSiloCount={fullSiloCount}
            noPermissionCount={noPermissionCount}
          />
        ) : (
          <>
            <Title fullSiloCount={fullSiloCount} />
            <AppGridContainer spacing={5}>
              <TableSection fullRows={fullRows} onDeleteRow={handleDeleteRow} />
              <DescriptionSection />
            </AppGridContainer>
            <ActionButtons
              handleClose={() => setOpen(false)}
              fullRows={fullRows}
              createMassiveCloseSilobag={createMassiveCloseSilobag}
            />
          </>
        )}
      </MyModal>
      {showToast ? (
        <ToastAction message={i18n.t('app.silobag_close_modal.silobags_closed')} severity="success" />
      ) : null}
    </>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  if (!open) return null;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    overflowX: 'hidden',
    maxHeight: '95vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

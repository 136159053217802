import { Box, Stack, CircularProgress, Button, Typography } from '@mui/material';
import { ModalIOF } from '@ui/modal/modal';
import { configButtonStylesContained } from '@styles/generic-styles';
import i18n from '../../../../libs/language';
import { useNavigate } from 'react-router-dom';
import { useService } from '@hooks/use-service';
import { deleteHeavyLogsCorvusService } from '@services/integration/corvus/delete-heavy-logs';
import { PATH } from '@data/core/paths';
import { getSilobagInfoCorvusService } from '@services/integration/corvus/get-silobag-info-corvus';

type SilobagInfoCorvusSummary = Awaited<ReturnType<typeof getSilobagInfoCorvusService>>['summary'];
type HeavyLogsInfo = SilobagInfoCorvusSummary['heavyLogs'];

type CorvusModalConfirmProps = {
  open: boolean;
  isLoading: boolean;
  setOpen: (open: boolean) => void;
  siloId?: string;
  heavyLogs: HeavyLogsInfo;
};

export const CorvusModalConfirm = (props: CorvusModalConfirmProps) => {
  const navigate = useNavigate();
  const { siloId, heavyLogs, open, setOpen, isLoading } = props;

  const { trigger: deleteAllHeavyLogs, isSuccess: isSuccessDelete } = useService(
    deleteHeavyLogsCorvusService,
    {
      onSuccess: () => {
        setTimeout(() => {
          setOpen(false);
          navigate(`${PATH.SILOBAG}/${siloId}`);
        }, 1000);
      },
    }
  );

  const desvinculateCorvusAction = async () => {
    const heavylogsIdsToDelete = heavyLogs.map((item) => item.heavy_log_id);
    const bodyToDelete = {
      heavyLogs: heavylogsIdsToDelete.map((id: number) => ({ heavyLogId: id })),
    };
    deleteAllHeavyLogs({ body: bodyToDelete, query: siloId ?? 'id-not-found' });
  };
  return (
    <ModalIOF open={open} ModalIsOpen={setOpen}>
      {isSuccessDelete ? (
        <Box>
          <ModalIOF.Body>
            <Typography textAlign={'center'} fontSize={25} fontWeight={500}>
              {i18n.t('app.integration_corvus.success_desvinculate')}
            </Typography>
          </ModalIOF.Body>
        </Box>
      ) : (
        <Box>
          <ModalIOF.HeaderCenter>
            <ModalIOF.Title>{i18n.t('app.integration_corvus.title_desvinculate')}</ModalIOF.Title>
          </ModalIOF.HeaderCenter>
          <ModalIOF.Body>
            <Stack direction="column" spacing={2} sx={{ textAlign: 'center' }}>
              <Box>
                <p>{i18n.t('app.integration_corvus.text_desvinculate')}</p>
                <p>
                  <span style={{ fontWeight: 600 }}>Nota:</span>{' '}
                  {i18n.t('app.integration_corvus.note_desvinculate')}{' '}
                  <span style={{ fontWeight: 600 }}>{i18n.t('app.integration_corvus.edit_silobag')}</span>
                </p>
              </Box>
            </Stack>
          </ModalIOF.Body>
          <Stack direction={'row'} justifyContent={'end'}>
            <Button
              variant="outlined"
              color="error"
              sx={{
                border: 'none',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'inherit',
                  border: 'none',
                  color: 'black',
                },
              }}
              onClick={() => setOpen(false)}
            >
              {i18n.t('app.close')}
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{
                ...configButtonStylesContained,
                marginLeft: '20px',
                backgroundColor: '#DA0E0B',
                '&:hover': {
                  backgroundColor: '#BB0805',
                },
              }}
              onClick={desvinculateCorvusAction}
            >
              {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : i18n.t('app.confirm')}
            </Button>
          </Stack>
        </Box>
      )}
    </ModalIOF>
  );
};

export default {
  config: {
    delimiter: '.',
    separator: ',',
  },
  app: {
    '': '',
    //Terminos globales y usados multiples veces
    actions: 'Acciones',
    alerts: 'Alertas',
    register_silobag: 'Alta Silobolsa',
    atention: 'Atención!',
    battery: 'Bateria',
    lots: 'Lotes',
    lot: 'Lote',
    cancel: 'Cancelar',
    closed: 'Cerrado',
    close: 'Cerrar',
    continue: 'Continuar',
    copy: 'Copiar',
    organization: 'Organización',
    data: 'Datos',
    companies: 'Compañías',
    countryside: 'Establecimiento',
    countrysides: 'Establecimientos',
    cuit: 'CUIT',
    name: 'Nombre',
    original: 'Original',
    state: 'Provincia',
    role: 'Rol',
    phone: 'Teléfono',
    save: 'Guardar',
    date: 'Fecha',
    user: 'Usuario',
    new_silobag: 'Nuevo Silobolsa',
    edit_silobag: 'Editar Silobolsa',
    no_user_exists_with_email: 'No existe usuario con email:',
    images: 'Imágenes',
    modified: 'Modificado',
    no_images: 'Sin imágenes cargadas',
    observations: 'Observaciones',
    georeference: 'Georeferenciar',
    tons: 'Toneladas',
    validated_tons: 'Toneladas Validadas',
    silobags: 'Silobolsas',
    return: 'Volver',
    event: 'Evento',
    stock_movement: 'Movimiento de stock',
    detail: 'Detalle',
    view_files_attached: 'Ver archivos adjuntos',
    without_observations: 'Sin observaciones',
    method_notifications: 'Método de notificación',
    quality_intermediate: 'Calidad Intermedia',
    quality_grave: 'Calidad Grave',
    security: 'Seguridad',
    support_calendly: '¿Tenés una sugerencia? Agendá una reunión con nosotros',
    support_whatsapp: 'Soporte por Whatsapp',
    support_problem: 'Tengo un problema',
    ubication: 'Ubicación',
    rows_per_page: 'Filas por página',
    no_grid_data: 'No hay datos para mostrar',
    status: 'Estado',
    no_data: 'No hay datos',
    must_field: 'Este campo es obligatorio.',
    no_active_siloid_alerts: 'En este momento no tiene alertas activas en sus SiloIDs',
    no_active_alerts: 'En este momento no tiene alertas activas.',
    silobag: 'Silobolsa',
    silobag_plural: 'Silobolsas',
    quote_silo_insurance: 'Cotizá tu SiloSeguro',
    quote_silo_warrant: 'Cotizá tu warrant',
    coming_soon: 'Próximamente',
    soon: 'Próximamente',
    type: 'Tipo',
    clear_filters: 'Borrar',
    under_construction: 'En construcción',
    no_connection: 'No tiene conexión a internet',
    activate: 'Activar',
    active: 'Activo',
    system_management: 'Sistema de gestión',
    company_name: 'Razón Social',
    yes: 'Sí',
    no: 'No',
    missing_tons:
      'Los silobolsas sin validar no han sido completados o generados desde el campo utilizando la app.',
    missing_tons_title: '¿Te faltan toneladas?',
    siloreales: 'Siloreales',
    register_date: 'Fecha Alta',
    field_weigth: 'Peso en campo',
    total_extracted_weigth: 'Peso Total Extraido',
    slobag_brand: 'Marca del silobolsa',
    humidity_sources: 'Fuente de la humedad',
    weight_sources: 'Fuente del peso',
    fillying_coefficient: 'Coeficiente de llenado teorico',
    calculated_coefficient: 'Coeficiente de llenado calculado',
    silobag_status: 'Estado del SB',
    silobag_data_statuses: 'Validado',
    silobag_lab_protocols: 'Muestras de calidad',
    lab_protocols_created_at: 'Fecha ultima muestra',
    silobag_id: 'ID SB',
    silobag_name: 'Nombre SB',
    confirm: 'Confirmar',
    poe_round: 'Recorrida',
    days: 'días',
    download: 'Descargar',
    downloading: 'Descargando',
    download_all: 'Descargar todo',
    species: 'Especie',
    choose: 'Elegir',
    tooltip_sb_selected: 'Seleccione al menos 1 silobolsa para exportar',
    tooltip_sb_share_selected: 'Seleccione al menos 1 silobolsa para compartir',
    see_details: 'Ver detalle',
    edit_siloreal: 'Editar Siloreal',
    download_certificate_silo: 'Descargar certificado de SiloID',
    tooltip_sb_optimo: 'Siloreal en estado Optimo',
    /**-------------------------------------------------------------------------------------------------- */
    navbar: {
      administrator: 'Administrador',
      silobags: 'Silobolsas',
      search_silo: 'Buscar silo',
      organizations: 'Organizaciones',
      all_organizations: 'Todas las organizaciones',
      change_password: 'Cambiar Contraseña',
      logout: 'Cerrar Sesión',
      weather: 'Clima',
      dashboard: 'Dashboard',
      spanish: 'Español',
      language: 'Idioma',
      map: 'Mapa',
      reports: 'Reportes',
      stock: 'Movimientos de stock',
      state_stock: 'Estado de stock',
      existence_stock: 'Existencia de stock',
      devices: 'Dispositivos',
      device: 'Dispositivo',
      alerts: 'Alertas',
      activity: 'Actividad',
      portuguese: 'Portugués',
      snack_bar_msj: 'Se cambio el idioma de preferencia!',
      alert_value: 'Valor alerta',
      see_all: 'Ver todas',
      searchbar: {
        searching: 'Buscando silobolsas...',
        no_results: 'No hay datos con silobolsas relacionados.',
      },
      personalized: 'Personalizados',
      general_position: 'Posición General',
      commercial_position: 'Posición Comercial',
      stock_withdrawals: 'Retiros de Stock',
    },
    //Pagina de Dashboard
    dashboard_page: {
      welcome_to_field: 'Bienvenido al campo inteligente y conectado',
      hi: 'Hola',
      silobags_actives: 'Siloreales',
      siloreal_alerted: 'Siloreales alertados',
      validated_tons: 'Toneladas Validadas',
      non_validated: 'Sin validar',
      non_monitored_validated: 'Toneladas sin validar',
      view_map: 'Ver Mapa',
      financing: '¡Nuevas formas de financiamiento!',
      know_more: '¡Quiero saber más!',
      maybe_later: 'Quizas más tarde',
      tons_for_species: 'Toneladas por especie',
      tons_for_countrysides: 'Toneladas por Establecimiento',
      silobag_lance_sensor: 'sensor lanza de silobolsa',
      see_map: 'Ver en Mapa',
      countrysides_tooltip: 'Solo se muestran los establecimientos con stock validado en campo.',
    },

    // --- ADMIN PAGE --- //
    administrator: {
      title: 'Administrador',
      visualizer: 'Visualizador',
      permission: 'Permiso',
      error_unexpected: 'Ha ocurrido un error inesperado.',
      notification: 'Notificaciones',
      customize_alerts: 'Personalizar alertas',
      logo_max_size: 'El logo no puede superar el tamaño de 1mb',
      add_logo: 'Agregar logo',
      walkthrough: 'Recorrida',
      new_company: 'Compañía',
      new_countryside: 'Establecimiento',
      new_user: 'Usuario',
      new_company_toast: 'Se creó la compañia de forma exitosa.',
      edit_company_toast: 'Se editó la compañia con éxito',
      not_valid_action: 'No se permite la acción ya que posee SiloIDs vinculados a esta entidad',
      new_countryside_toast: 'Establecimiento guardado con éxito',
      edit_countryside_toast: 'Establecimiento editado exitosamente',
      deleted_company: 'La compañía se eliminó de manera exitosa',
      deleted_countryside: 'El establecimiento se elimino de manera exitosa.',
      deleted_user: 'El usuario se eliminó de forma exitosa.',
      new_user_toast: 'Se creó el usuario con éxito',
      edit_user_toast: 'Se editó el usuario con éxisto',
      error_delete_countryside: 'No se permite la acción ya que posee SiloIDs vinculados a esta entidad',
      invalid_email: 'Email con formato invalido',
      repeted_mail: 'El email ya se encuentra registrado',
    },
    // --- ADMIN PAGE --- // (integration modal)
    integration_modal: {
      integrations: 'Integraciones',
      share_data: 'Compartir datos con:',
      apply: 'Aplican',
      terms_conditions: 'términos y condiciones',
      integration: 'integración',
      system_gestion: 'Sistema de gestión',
      system: 'Sistema',
      login: 'Ingresar',
      Albor_username: 'Nombre de usuario Albor',
      Albor_password: 'Contraseña Albor',
      Albor_intergration: 'Integración con Albor',
      Albor_loading: 'Se está conectando al sistema...',
      Albor_loading2: 'No cierre la ventana hasta que se haya completado la carga.',
      albor_title: 'Inicie sesión con su cuenta de Albor',
      albor_countrysides: 'Establecimiento importado desde albor',
      countryside_warning: 'Ubicación  del establecimiento importado automáticamente y se debe corregir',
      albor_subtitle: 'Utilice el mismo correo electrónico y contraseña utilizada',
      albor_subtitle2: 'para iniciar sesión en su cuenta de Albor.',
      albor_joined: 'Su cuenta ha sido conectada exitosamente con Albor.',
      albor_joined3:
        'La ubicación de los establecimientos se va a tomar directamente de la dirección de Albor por lo que es importante revisar si alguna presenta errores.',
      save: 'Guardar',
      close: 'Cerrar',
      now_you_can:
        'Ahora podes tokenizar tus granos en silobolsa y acceder a nuevos instrumentos de financiamiento gracias a nuestra nueva integración con Agrotoken.',
      more_info: 'Para mas información contactate con soporte@siloreal.com',
    },
    // --- ADMIN PAGE --- // (inspection round modal)
    inspection_round_modal: {
      title: 'Recorrida de inspección',
      round_days: 'Cantidad de días entre recorridas',
      question: 'Pregunta',
      ask_for_photo: 'Pide foto',
      add_question: 'Agregar pregunta',
    },

    company_modal: {
      correct_street: 'Por favor, ingrese una calle correcta',
      street: 'Calle',
      country: 'País',
      city: 'Partido',
      zip_code_correct: 'Por favor, ingrese un Código postal correcto',
      zip_code: 'Codigo Postal',
      cuit_correct: 'Por favor, ingrese un cuit correcto.',
      cuit_without_spaces: 'CUIT sin guiones ni espacios',
      departament_correct: 'Por favor, ingrese un departamento correcto.',
      departament: 'Departamento',
      legal_name_correct: 'Por favor, ingrese un nombre Legal correcto.',
      legal_name: 'Nombre Legal',
      new_company: 'Nueva Compañía',
      correct_number: 'Por favor, ingrese un numero correcto',
      number: 'Número',
      correct_floor: 'Por favor, ingrese un piso correcto',
      floor: 'Piso',
      locality: 'Localidad',
      email_format: 'Formato de correo inválido.',
    },

    user_modal: {
      users: 'Usuarios',
      active: 'Activo',
      correct_dni: 'Por favor, ingrese un DNI correcto',
      dni: 'DNI',
      ingress_role: 'Por favor, ingrese un Rol',
      correct_fullname: 'Por favor, ingrese un nombre y apellido correcto.',
      fullname: 'Nombre y apellido',
      phone_correct: 'Por favor, ingrese un número de Teléfono correcto',
      user_platform: 'Usuario de la plataforma',
      permission: 'Permisos',
    },

    countryside_modal: {
      open_map: 'Abrir Mapa',
      add_lot: 'Agregar Lote',
      city: 'Ciudad',
      company: 'Compañia',
      discard: 'Descartar',
      enter_latitude: 'Por favor, ingrese una latitud correcta',
      enter_longitude: 'Por favor, ingrese una longitud correcta.',
      latitude: 'Latitud',
      longitude: 'Longitud',
      lote_no_save: 'Lote sin guardar',
      polygon: 'Polígono',
      geo_position: 'Posición geográfica',
      have_lote_no_save: 'Tiene un lote sin guardar. ¿Desea continuar y descartar el lote?',
      lots: 'Lotes',
      permission: 'Permiso',
    },
    //TODO DE SILOBAGS
    //----------------------------------------------------------
    //PAGINA LISTADO DE SILOBOLSAS
    silobag_list: {
      your_silobags: 'Tus Silobolsas',
      your_siloreales: 'Tus Siloreales',
      export_data: 'Exportar Datos',
      download_data: 'Descargables',
      export_all: 'Exportar Todo',
      export_stock_validate: 'Exportar Stock Validado',
      export_view: 'Exportar vista',
      export_selects: 'Exportar seleccionados',
      selected: 'Seleccionados',
      export_extraction: 'Exportar Extracciones',
      download_cma: 'Descargar CMC',
      make_extraction: 'Realizar Extracción',
      register_silobag: 'Alta Silobolsa',
      massive_actions: 'Acciones Masivas',
      masive_register: 'Altas de silobolsas',
      quality_samples: 'Muestras de calidad',
      massive_close: 'Cierres',
      massive_extraction: 'Extracciones masivas',
      close_silobags: 'Cierre masivo',
      original_stock:
        'El stock original es de <b>{{initial_weight}} kg</b> por lo que se va a cerrar el silo con una diferencia de:',
      weight_differential: 'Peso diferencial',
      relation: 'respecto de los <b>{{initial_weight}} kg</b> declarados en el alta',
      question_close: '¿Desea continuar con el cierre del silo?',
      close: 'Cierre de Silobolsa',
      silobags: 'Silobolsas',
      your_stock_total: ' Sobre tu stock total',
      charge_page: {
        quality: 'Cargar planilla muestras',
        extraction: 'Cargar planilla extracciones',
        register: 'Cargar planilla altas',
        close: 'Carga masiva de cierres',
      },
      download_page: {
        quality: 'Descargar planilla muestras',
        extraction: 'Descargar planilla extracciones',
        register: 'Descargar planilla altas',
        close: 'Descargar planilla de cierres',
      },
      search_by_name_id: 'Buscar por nombre de silo',
      new_silobag: 'Se creó la silobolsa con éxito.',
      non_validated_tons: ' toneladas sin validar',
      non_validated_tons_aclaration:
        'Los silobolsas sin validar no han sido completados o generados desde el campo utilizando la app',
      bagged_tons: 'Toneladas embolsadas',
      extracted_tons: 'Toneladas extraídas',
      tonnage_difference: 'Diferencia de toneladas',
    },
    org_data_admin: {
      org_logo_changed: 'Se cambio el logo de la organización con éxito.',
      org_data_changed: 'Se edito con exito la Organizacion',
    },

    new_edit_silobag: {
      only_numbers: 'Solo se aceptan números enteros',
      numbers_decimals: 'Solo se aceptan números enteros y decimales',
      less_1000_kg: 'El peso ingresado de menor a 1000 KG.',
      only_coords: 'Solo se aceptan coordenadas válidas',
    },

    //TERMINOS DE DATA DE SILOBOLSA
    silobag_data: {
      search_by_name: 'Buscar por nombre',
      specie: 'Especie',
      species: 'Especies',
      view_silo_close: 'Ver silos Cerrados',
      view_non_validated_sb: 'Ver silos sin validar',
      product: 'Producto',
      harvest: 'Cosecha',
      level_alert: 'Nivel de alerta',
      range_storage: 'Rango Embolsado',
      delete_filters: 'Borrar Filtros',
      register_date: 'Alta',
      close_date: 'Cierre',
      bagging_date: 'Embolsado',
      bagging_date2: 'Fecha embolsado',
      initial_humidity: 'Humedad Origen',
      remaining_stock: 'Stock campo',
      data_main: 'Datos Principales',
      additional_data: 'Datos Adicionales',
      silobag_init: 'Inicio del silobolsa',
      silobag_main_photo: 'Foto principal del silobolsa',
      silobag_end: 'Cierre del silobolsa',
      silobag_extra_photo: 'Fotos Extras',
      variety: 'Variedad',
      lot_stock: 'Lote Stock',
      lot_origin: 'Lote Origen',
      weight_lot: 'Peso Lote',
      silobag_size: 'Largo silobolsa',
      weight_bagging: 'Peso embolsado',
      humidity_bagging: ' % Humedad embolsado',
      lat: 'Latitud',
      long: 'Longitud',
      bagging_responsible: 'Responsable embolsado',
      silobag_brand: 'Marca silobolsa',
      split_grain: '% Grano Partido',
      varietal_purity: '% Pureza Varietal',
      fillying_coefficient: 'Coeficiente de llenado (kg/m)',
      fillying_coefficient_no_metrics: 'Coeficiente de llenado',
      source_humidity: 'Fuente Humedad',
      source_weight: 'Fuente Peso',
      have_breaks: '¿Tiene roturas?',
      side_charge: 'Camión carga sobre',
      total_extracted: 'Total extraído',
      movement: 'Movimiento',
      movements: 'Movimientos',
      quality_serious: 'Calidad Grave',
      quality_min: 'Calidad Leve',
      optimun: 'Optimo',
      defective: 'Defectuoso',
      history: 'Historial',
      devices: 'Dispositivos',
      extractions: 'Extracciones',
      metrics: 'Métricas',
      satellite_images: 'Imágenes Satelitales',
      election_images: 'Elección de imágenes',
      selected_days: 'Día seleccionado',
      existence_silosat: 'Existencia',
      existence_images: 'No hay imagenes seleccionadas',
      size: 'Largo',
      calendar_functionality: 'Funcionamiento del calendario:',
      red: 'Rojo: Prueba de inexistencia en campo',
      green: 'Verde: Prueba de existencia en campo',
      white_gray_border: 'Blanco con borde gris: Imagen sin analizar',
      no_icon_days: 'Los días que no cuenten con icono son aquellos que no tienen imágenes disponibles',
      field_selection: 'Este campo requiere tener seleccionado un establecimiento en primer lugar.',
      none: 'Ninguno',
      value_range: 'Solo se aceptan valores ente 0 y 100, y hasta 2 decimales.',
      only_nums: 'Solo se aceptan números.',
      no_image: 'Sin imagen cargada',
      advanced_filters: 'Filtros avanzados',
      inconsistency: 'Inconsistencia',
      silo_with_movement: 'Silo con movimiento',
    },

    silobag_devices: {
      new_lance: 'Nueva Lanza',
      instalation_lances: 'Instalación de Lanzas',
      without_lances: 'Sin Lanzas',
      with_lances: 'Con Lanzas',
      number_lance: 'N° Lanza/Nombre',
      position: 'Posición',
      init: 'Inicio',
      init_middle: 'Inicio-medio',
      middle: 'Medio',
      middle_end: 'Medio-fin',
      end: 'Fin',
      photo_device: 'Foto dispositivo',
      confirm_disassociation_lance: 'Confirmar desvinculación de lanza',
      last_update: 'Última actualización',
      alert_actives: 'Alertas Activas',
      history: 'Historial',
      history_alerts: 'Historial de alertas',
      actions_massive: 'Acciones Masivas',
    },
    silobag_share: {
      share_silobag: 'Compartir Silobolsa',
      ingress_users: 'Ingresar usuarios a compartir',
      user_access: 'Usuarios con acceso',
      type_permission: 'Tipo de permiso',
      user_with_permission: 'Usuarios con permiso heredado',
      view: 'Ver',
      view_description: 'Puede ver pero no editar ni compartir datos con otros',
      edit: 'Editar',
      edit_description: 'Puede editar  pero no compartir datos con otros',
      access_complete: 'Acceso completo',
      access_complete_description: 'Puede editar y compartir datos con otros',
      share: 'Compartir',
      permissions_share: 'No posee los permisos para compartir los siguientes silobolsas:',
      press_enter_or_space: 'Después de escribir el email, presiona "Enter" o "Space"',
      silobag_already_shared: 'La silobolsa ya está compartido o el usuario no existe.',
      silobag_shared: 'Silobolsa compartido correctamente',
      permissions_updated: 'Se actualizaron los permisos exitosamente.',
      delete_permission: 'Quitar Acceso',
      share_silobag_title: 'Compartir',
      cancel_message: 'Se canceló la operación de compartida de silobolsas',
    },
    silobags_extractions: {
      silos: 'silos',
      choose_two_silobags: 'Seleccione hasta dos silobolsas',
      extraction_silobag: 'Extracción de Silobolsa',
      date_withdrawal: 'Fecha de retiro',
      type_extraction: 'Tipo de extracción',
      truck: 'Camión',
      consolidated: 'Consolidado',
      weight_truck: 'Peso camión',
      gross_weight: 'Peso bruto',
      gross_consolidated: 'Peso consolidado',
      transport: 'Transporte',
      freight_broker: 'Intermediario flete',
      carrier: 'Transportista',
      driver: 'Chofer',
      chassis_patent: 'Patente chasis',
      chassis_attached: 'Patente acoplado',
      others: 'Otros',
      ctg: 'CTG (Código de Transporte de Granos)',
      extractions: 'Extracciones',
      gross: 'Bruto',
      liquid: 'Neto',
      tara: 'Tara',
      generic_error_msg: 'Hubo un error al extraer el peso de la silobolsa',
      extraction_more_than_doble: 'El peso de extracción es mayor al doble del peso inicial.',
      freight_brok: 'Int. flete',
      cp: 'CP',
      contract: 'Contrato',
      destiny: 'Destino',
      extraction_date: 'Fecha de extracción',
    },
    silobag_history: {
      extraction: 'Ver Extracción',
      resumen: 'Resumen',
      download_file: 'Descargar archivo',
      no_images: 'No hay imágenes',
      poe_warning: 'La recorrida es inválida porque se realizó a más de 50 metros del silobolsa',
      poe_invalid: 'Recorrida inválida porque el silobolsa no tiene realizado alta en campo',
      poe_warning_description:
        'La recorrida se realizo a {{totalDistance}} km del silobolsa, el maximo para que sea valida es 50 metros.',
      compass: 'Brújula',
      gps_accuracy: 'Precisión GPS',
      reason: 'Motivo',
      detail_poe: 'Detalle de recorrida',
      general_evaluation: 'Evaluación general',
      description_election: 'Descripción de elección u observaciones',
      breakage: 'Rotura',
      reason_breakage: 'Motivo de rotura',
      quality_revision: {
        calado: 'Calado',
        sample_type: 'Tipo de calado',
        silo_position: 'Posición en silo',
        value: 'Valor',
      },
      photo_poe: 'Foto recorrida',
      photo_breakage: 'Foto rotura',
      photo_question: 'Foto pregunta',
      photo_breakage_resolved: 'Rotura Resuelta',
      photo_revition_quality: 'Revision Calidad',
      photo_detail_siloid: 'Foto detalle SiloID',
      photo_extra: 'Foto extra',
      Optimum: 'Optimo',
      precaution: 'Precaución',
      high_risk: 'Riesgo elevado',
      critical: 'Critico',
      descriptions: {
        Optimum: 'No hay riesgos sobre el silobolsa, se encuentra en buenas condiciones de almacenamiento.',
        precaution:
          'Hay un leve riesgo sobre el silobolsa. Se encuentra en condiciones aceptables de almacenamiento.',
        high_risk:
          'Hay un riesgo elevado sobre el silobolsa y está en estado de precaución. Se encuentra con condiciones no aceptables de almacenamiento.',
        critical: 'El silobolsa se encuentra en estado crítico, requiere atención inmediata.',
      },
    },
    silobag_detail: {
      general_evaluation: 'Evaluación general',
      next: 'Siguiente',
      previous: 'Anterior',
      charge_files: 'Carga de Archivos',
      perform_extraction: 'Realizar Extracción',
      close: 'Cerrar',
      silobag_version: 'Versión del Silobolsa',
      silo_close: 'Silo Cerrado',
      register_extractions: 'Registro de Extracciones',
      view_in_map: 'Ver en Mapa',
      galery_images: 'Galería de Imágenes',
      view_files: 'Ver Archivos',
      refresh: 'Refrescar',
      weight_extractions: 'Peso de extracciones',
      difference: 'Diferencia',
      difference_percentage: "Diferencia '%'",
      silosat_date: ' Rango certificable',
      edit_silobag: 'Se editó la silobolsa con éxito.',
      new_extraction: 'Extracción realizada con éxito.',
      edit_extraction: 'La extracción de silobolsa se actualizó de forma correcta.',
      delete_extraction: 'La extracción fue eliminada con éxito',
      first_CMC: 'CMC Inicial',
      current_CMA: 'CMC actualizado',
      different_date: 'Inconsistencias detectadas en fecha de embolsado',
      certification_silosat:
        'Se certifica que el embolsado sucedió entre el {{firstVisibleDate}} y el {{lastVisibleDate}}',
      different_context:
        'La fecha declarada durante el alta del silobolsa no se encuentra dentro del rango certificable.',
      silosat_size: 'Largo SiloSat',
      different_size: 'Diferencia largo declarado',
      different_context_size:
        'El largo declarada durante el alta del silobolsa no coincide con el registrado en SiloSat, en caso de desear certificar este silobolsa es necesario corregir esta diferencia',
      warning_silosat: 'Insconsistencia detectada en SiloSat',
      validated_silosat: 'Este largo verificado por SiloSat',
      state_initial: 'Estado Inicial',
      tooltip_charge_files: 'Adjuntar archivos relevantes para el siloreal ',
      donwload_certificate_history: 'Descargar historial completo del SiloReal',
      download_extractions: 'Descargar Excel con extracciones',
      sync_corvus: 'Debes vincular Corvus para poder ingresar al modulo',
      show_lab: 'Ver y adjuntar muestras de calidad',
      observation_tooltip: 'No hay observaciones cargadas',
      files_tooltip: 'No hay archivos cargados',
    },
    silo_sat: {
      images_sat: 'Imágenes Satelitales. Completar alta en campo para pasar al siguiente estado.',
      without_images: 'Sin imágenes satelitales',
      enabling: 'Sin silosat. Completar alta en campo para pasar al siguiente estado.',
      unavailable: 'No disponible. Algo fallo en el proceso de activacion, contactarse con soporte',
      processing:
        'En activación. Estamos procesando la información para completar la activación del servicio.',
      active: 'Activo',
      available: 'Disponible',
    },
    silobag_metric: {
      metrics: 'Métricas',
      meditions_registered: 'mediciones registradas',
      co2: 'Dióxido de Carbono',
      heg: 'Humedad de Equilibrio',
    },
    //PAGINAS DE REPORTES
    report_stock: {
      banner: {
        banner_title: 'Saldo Total de la organización',
      },
      stock_report_title: 'Reportes de Movimientos de Stock',
      init_date: 'Fecha de inicio',
      close_date: 'Fecha de cierre',
      initial_balance: 'Saldo Inicial',
      incomes: 'Ingresos',
      income: 'Ingreso',
      outcomes: 'Egresos',
      outcome: 'Egreso',
      final_balance: 'Saldo Final',
      balance: 'Saldo',
      detail: 'Detalle',
      see_detail: 'Ver Detalle',
      type: 'Tipo',
      tons_graphic: 'Gráfico en Toneladas',
      balance_graphic: 'Grafico Balance',
      initial_description: 'Monto al inicio del período seleccionado.',
      incomes_description: 'Monto filtrado de ingresos.',
      outcomes_description: 'Monto filtrado de extracciones.',
      final_description: 'Saldo Inicial + Ingresos - Egresos.',
      movement: 'Movimiento',
    },

    report_devices: {
      delayed: 'Demorado',
      delete_filters: 'Eliminar Filtros',
      devices_reports_title: 'Reporte de Dispositivos',
      installed: 'Instalados',
      last_transmission: 'Última transmisión',
      last_two_weeks: 'Últimos 14 días',
      not_installed: 'No Instalado',
      not_reporting: 'No reportando',
      report: 'Reporte',
      reporting: 'Reportando',
      search_device_name: 'Buscar por nombre de dispositivo',
      silo_id: 'Silo ID',
      silobag_name: 'Nombre Silo',
      status: 'Instalación',
      totals: 'Totales',
      without_metrics: 'Sin métricas',

      deveui_modal: {
        gateway: 'El estado de conexion indica si el Gateway reporto en las ultimas  dos horas',
        connected: 'Conectado',
        desconnected: 'Desconectado',
        device_detail: ' Detalle de dispositivo',
        associated_gateway: 'Gateway asociado',
        status_connection_gateway: 'Estado conexión Gateway',
        other: 'Otros',
        warning_last_transmission:
          'Los datos mostrados corresponden a la ultima transmision recibida de la lanza. Es posible que esta informacion no refleje el estado actual de la lanza.',
        warning_battery:
          'La metrica de bateria indica el nivel de carga de la ultima transmision. Si la lanza continua enviando datos, aun sin estar instalada, es recomendable apagarla para preservar la duracion de la bateria.',
      },
    },

    report_activity: {
      title: 'Reporte de Actividad',
    },

    public_pages: {
      login: 'Iniciar Sesión',
      register: 'Registrarse',
      forgot_password: '¿Olvidaste tu contraseña?',
      user: 'Usuario',
      password: 'Contraseña',
      password_confirm: 'Confirmar Contraseña',
      email: 'Correo Electronico',
      register_question: '¿Todavia no tienes cuenta? Registrate',
      ingress: 'Ingresar',
      recover_password: 'Recuperar Contraseña',
      register_company: 'Registra tu Empresa',
      legal_name: 'Razon Social',
      cuit: 'CUIT',
      fullname: 'Nombre y Apellido',
      phone: 'Telefono',
      accept_terms: 'Acepto los términos y condiciones',
      create_account: 'Crear Cuenta',
      reset_password: 'Resetear Contraseña',
      register_success: 'Te has registrado con exito!',
      go_to_login: 'Ir al Login',
      verify_register_title: 'Te enviamos un e-mail para verificar tu cuenta',
      verify_register_description: 'Porfavor revisa tu casilla y verifica tu cuenta para continuar',
      verify_register_code: 'Codigo de verificación',
      no_received_code: '¿No recibiste el codigo?',
      no_received_code_decription: 'Revisa la casilla de spam o Clickea el boton para Reenviar',
      policy_privacy: 'Politica de Privacidad',
      terms_conditions: 'Términos y Condiciones',
      contact: 'Contacto',
      error_register:
        'Ha ocurrido un error al intentar registrar la empresa. Por favor, revise que todos los campos hayan sido completados correctamente.',
      terms_conditions_error: 'Para continuar, debe aceptar los términos y condiciones',
    },
    register_silobag_modal_info: {
      missing_countrysides:
        'Se tiene que crear un establecimiento antes de crear silobolsas. Ver dentro de pestaña Administrados > Establecimientos > +Nuevo Establecimiento.',
    },
    silobag_edit_modal: {
      explanation: 'Estás editando los datos de un silobolsa por lo que dejará trazabilidad en el historial.',
      confirmation: '¿Estás seguro de que quiere confirmar los cambios?',
    },
    silobag_close_modal: {
      silobags: 'Cierre de Silobolsas',
      question: '¿Desea continuar con el cierre de los silos?',
      description: 'Recuerde que esta acción no es reversible',
      confirmation: 'Confirmar cierre',
      permissions:
        'No posee los permisos para cerrar los siguientes silobolsas, o ya se encuentran cerrados:',
      silobags_closed: 'Silobolsas cerrados con éxito',
    },
    silobag_action: {
      error_creat_silobag: 'Error al crear el silobolsa',
      invalid_form_message:
        'Existen errores en el formulario. Por favor, completa todos los campos requeridos',
      close_success: ' Todos los silobolsas se cerraron con éxito',
      close_warning: 'Se han cerrado varios silobolsas, pero algunos no se han cerrado debido a errores.',
      close_error: 'No se han cerrado los silobolsas debido a errores.',
      upload_success: 'Todos los silobolsas se han subido correctamente',
      upload_warning: 'Se han subido varios silobolsas, pero algunos no se han subido debido a errores.',
      upload_error: 'No se han subido los silobolsas debido a errores.',
      error_messages: {
        20001:
          'La fecha tiene un formato incorrecto. Revisar y corregir. Si el Excel está en español el formato es DD/MM/AAAA. En inglés es MM/DD/AAAA',
        20002: 'Especie no encontrada',
        20003: 'Tipo de producto no encontrado',
        20004: 'Compañía no encontrada',
        20005: 'Establecimiento no encontrado',
        20006:
          'Lote de stock no encontrado, si desea agregarlo, dirija a la sección de administrador de la app Web',
        20007: 'Fuente de Humedad no encontrada, las opciones correctas son: "Medicion Manual" u "otra"',
        20008: 'Camion carga sobre no encontrado, las opciones correctas son: "Lote" o "Camino"',
        20009:
          'Fuente del peso no encontrada, las opciones correctas son: "Corvus","Monotolva", "Manual" o "Coeficiente"',
        20010: 'El campo de peso embolsado no puede estar vacío',
        20011: 'El peso embolsado debe ser un número entero',
        20012: 'La humedad inicial debe ser un número',
        20013: 'El valor de la humedad debe estar entre 0 y 100',
        20014: 'La pureza varietal debe ser un número',
        20015: 'El valor del porcentaje de impureza debe estar entre 0 y 100',
        20016: 'El valor del porcentaje de defectuoso debe ser un número',
        20017: 'El valor del porcentaje de defectuoso debe estar entre 0 y 100',
        20018: 'El coeficiente de llenado debe ser un número',
        20019: 'Cosecha no encontrada',
      },
    },

    siloreal_tutorial: {
      welcome_to_siloreal: 'Bienvenido a SiloReal',
      step: 'Paso',
      first_description:
        'Vas a poder visualizar de forma inteligente todos tus silobolsas con sus alertas y asi tomar mejores decisiones para tu negocio.',
      map: 'Mapa',
      second_decription: 'Con la localización en tiempo real podras ubicar fácilmente todos los activos',
      third_decription: 'Reemplaza tus plantillas por un listado con filtros para ver tu stock actualizado.',
      fourth_title: 'Detalle por silobolsa',
      fourth_decription:
        'Podes darle identidad a cada uno de tus silobolsas, teniendo toda la trazabilidad en un mismo lugar.',
      fifth_decription:
        'Toma el control de todo lo que sucede en la plataforma y gestionalo en simples pasos, administrando tus usuarios, compañias y establecimientos.',
      finish: 'Finalizar',
      next: 'Siguiente',
      back: 'Volver',
    },
    notification_page: {
      custom_notifications: 'Personalizar Notificaciones',
      username: 'Nombre de Usuario',
      active_all: 'Activar todas',
      poe_expired: 'Recorrida: Vencida',
      poe_expired_description:
        'Alerta generada por el usuario al encontrar un silobolsa en condiciones de alerta al realizar una recorrida',
      poe_result: 'Recorrida: Resultado',
      poe_result_description:
        'Alerta generada por el vencimiento de la ultima recorrida realizada(transcurrieron mas días entre recorridas que el valor configurado)',
      exist_silo_sat: 'Existencia SiloSat',
      exist_silo_sat_description: 'Alerta generada al no detectar un silobolsa con imagen satelital',
      co2: 'Dióxido de Carbono (CO2)',
      co2_description:
        'Alerta generada al superar el valor umbral de CO2 permitido para la especie del silobolsa',
      mov: 'Movimiento (MOV)',
      mov_description: 'Alerta generada al detectar movimiento de una lanza',
      heg: 'Humedad (HEG)',
      heg_description:
        'Alerta generada al superar el valor umbral de humedad permitido para la especie del silobolsa',
      bat: 'Batería (BAT)',
      bat_description: 'Alerta generada al detectar un nivel de batería inferior al 20%',
      quality: 'Calidad',
      security: 'Seguridad',
      tecnical: 'Técnico',
      origin: 'Origen',
      silo_id_tooltip: 'Alertas de resultado de recorrida de vencimiento',
      silo_sat_tooltip: 'Alertas de existencia de servicio satelital',
      silo_metrix_tooltip: 'Alertas de metricas obtenidas de las lanzas del silobolsa',
      view_detail: 'Ver Detalle',
      desactive_all: 'Desactivar todas',
      toast_success: 'Notificaciones actualizadas correctamente',
      validate_phone: 'Validar numero de telefono',
      validate_text:
        'Para poder habilitar las notificaciones sms o whatsapp es necesario que valides tu numero de telefono.',
      send_sms: 'Enviar SMS',
      phone_validated: 'Numero de telefono validado',
      phone_invalidated: 'Numero de telefono no validado',
      ingress_valid_format: 'Ingrese un formato de telefono valido ',
      warning_modal_title: 'Atención!',
      warning_modal_text:
        'Para poder recibir las notificaciones tenga en cuenta que primeramente el telefono debera estar validado por el usuario.',
      confirm_success: 'El telefono se valido correctamente',
      validation_modal_title: 'Te enviamos un sms para validar tu numero de telefono',
      validation_modal_text: 'Por favor ingresa tu codigo de verificacion',
      warning_phone: 'Al Editar el teléfono, debera validarlo nuevamente.',
      confirm_error: ' Error al validar el telefono. Formato invalido',
      button_validate_phone: 'Validar Telefono',
      confirm_error_code: 'Error al verificar el codigo, Codigo incorrecto',
    },
    not_found: {
      return_dashboard: 'Volver al Dashboard',
      return_login: 'Volver al Login',
      message: 'Lamentablemente la página que estas buscando no se encuentra disponible',
    },
    state_stock_page: {
      report_state_stock: 'Reporte de estado de stock',
      search_by_name_silo_countryside: 'Buscar por SiloID, silobolsa o establecimiento',
      date_bagging: 'F.Embolsado',
      date_poe: 'Ultima Visita',
      result: 'Resultado',
      humidity: 'Humedad',
      aproved: 'Optimo',
      caution: 'Precaución',
      risk_elevate: 'Riesgo Elevado',
      critical: 'Crítico',
      former: 'Anterior:',
      expired: 'Vencido',
      certificate_silo_sat: 'Certificación SiloSat',
      no_certificate_silo_sat: 'Sin certificación SiloSat',
      lance_id: 'ID Lanza',
      poe: 'Recorrida',
      position: 'Posición',
      last_metric: 'Medición Anterior: ',
      no_last_metric: 'No hay medición anterior',
    },
    map_menu: {
      ubication_copied: 'La ubicación se copió exitosamente',
    },

    existence_report_stock: {
      existence_stock_report_title: 'Reporte de existencia de stock',
      poe_expired: 'Recorridas vencidas',
      visibles: 'Visibles',
      not_visibles: 'No Visibles',
      without_images: 'Sin imágenes',
      with_movement: 'Con movimiento',
      without_movement: 'Sin movimiento',
      days_since_last_poe: 'Días desde última recorrida',
      result: 'Resultado',
      certainty: 'Certeza',
      last_seven_days: 'Últimos 7 días',
      movement: 'Movimiento',
      last_measurement: 'Última métrica',
      is_visualized: 'Se visualiza',
      not_visualized: 'No se visualiza',
      without_results: 'Sin SiloSat',
    },
    silobag_insurance: {
      tons_quoted: 'Toneladas Cotizadas',
      month_coverage: 'Cobertura mensual',
      tree_month_coverage: 'Cobertura trimestral',
      six_month_coverage: 'Cobertura semestral',
      thirds_complete: 'Terceros Completos',
      sum_assured: 'Suma Asegurada',
      view_detail: 'Ver Detalle',
      contract: 'Contratar',
      month: 'Mes',
      duration_policy: 'Duración de la póliza:',
      assured_production: 'Aseguramos tu producción en',
      crop: 'Cultivo de',
      no_data_month:
        'No hay datos de cotización mensual para este silobolsa solo para las especies disponibles(Maiz, Soja, Trigo, Girasol, Cebada)',
      no_data_three_month:
        'No hay datos de cotización trimestral para este silobolsa solo para las especies disponibles(Maiz, Soja, Trigo, Girasol, Cebada)',
      no_data_six_month:
        'No hay datos de cotización semestral para este silobolsa solo para las especies disponibles(Maiz, Soja, Trigo, Girasol, Cebada)',
      your_plan: 'tu plan',
      with: 'con',
      disabled_close_mobile: 'Tiene al menos un SiloReal con Cierre en Origen: {{ids}}',
      validation: 'Seleccioná un único silobolsa para cotizar tu seguro',
    },
    integration_corvus: {
      title: 'Integración con Corvus',
      login_corvus: 'Inicie sesión con su cuenta de Corvus',
      description:
        'Utilice el mismo usuario y contraseña utilizada para iniciar sesión en su cuenta de Corvus',
      ingress: 'Ingresar',
      step: 'Paso',
      vinculate_silobag_corvus: 'Vincular Silobolsa a AGDP',
      confirm_downloads_corvus: 'Confirmar descargas de Corvus',
      weight_corvus: 'Peso Corvus (kg)',
      warning:
        'Recuerda que en caso de confirmar la vinculación el peso que se mantendrá será el peso corvus',
      vinculate_corvus: ' Vincular Silobolsa a AGDP',
      confirm_corvus: 'Confirmar descargas Corvus',
      detail_corvus_title: 'Corvus - Detalle de Vinculación',
      corvus_weight_certified: 'Peso actual certificado por Corvus',
      bagging_weight_original: 'Peso embolsado original: ',
      error_login: 'Error al iniciar sesión con Corvus, verifique los datos ingresados',
      user: 'Usuario',
      password: 'Contraseña',
      desvinculate_corvus: 'Desvincular Corvus',
      title_desvinculate: 'Desvinculación Completa de Descargas',
      success_desvinculate: '¡Desvinculación Exitosa!',
      text_desvinculate:
        'Se desvincularán todas las descargas de este siloreal, eliminando la certificación del peso de embolsado y mostrando el peso declarado antes de la vinculación.',
      note_desvinculate: 'Este cambio se registrará en el historial como',
      edit_silobag: 'Edición de silobolsa',
      wet_weight: 'Peso húmedo',
      wet: 'Húmedo',
      dry_weight: 'Peso seco',
      dry: 'Seco',
    },
    metrics_descriptions: {
      co2: 'Dióxido de carbono (CO2) muestra la medición por el sensor de la lanza',
      temp: 'Temperatura muestra la medición por el sensor de la lanza',
      heg: 'Humedad de equilibrio que se calcula según el INTA con los valores de humedad relativa, temperatura y especie',
      hum: 'Humedad intergranaria muestra la medición por el sensor de la lanza',
      bat: 'Bateria %',
    },
    water_mark: {
      compass: 'Brújula',
      gps: 'Precisión GPS',
      missing_date: 'Fecha no disponible',
    },
    modal_cmc: {
      register_cmc: 'Registro SiloID',
      type: 'Tipo',
      date: 'Fecha',
      download_cmc_initial: 'Descargar SiloID Inicial',
      generate_cmc_update: 'Generar SiloID Actualizado',
      copy_document_hash: 'Copiar Hash de documento',
      copy_transaction_hash: 'Copiar Hash de transacción',
      download_certificate: 'Descargar Certificado',
    },
    countryside_share: {
      share_countryside: 'Compartir Establecimiento',
      share_countryside_success: 'Permisos de establecimiento compartidos correctamente',
      share_countryside_error: 'No se pudo compartir el establecimiento, verifica el email ingresado',
    },
    lab: {
      laboratory: 'Laboratorio',
      template_download: {
        tooltip: {
          init: 'Debe seleccionar al menos 1 silobolsa',
          continue: 'Solo puedes continuar si alguna especie seleccionada tiene plantillas disponibles',
        },
      },
      warning_modal: {
        attention: 'Atención',
        unavailable:
          'Hay {{silosQty}} silobolsas seleccionados cuya especie no posee un modelo de plantilla para cargar muestras de calidad.',
        available:
          'Por el momento las especies con plantillas disponibles para realizar una muestra de calidad son:',
        continue: '¿Desea continuar?',
      },
      upload: {
        success_toast: 'Se importo la plantilla correctamente',
      },
      download_modal: {
        title: 'Plantillas para muestras de calidad',
      },
      detail_sample_modal: {
        title: 'Muestra de calidad',
        acquisition_date: 'Fecha de toma de muestra',
        analysis_date: 'Fecha de análisis',
        analysis_number: 'Nº análisis',
        button: {
          download: 'Descargar nueva plantilla',
          upload: 'Cargar planilla',
        },
        column_header: {
          field: 'Item',
          result: 'Resultado',
          ref: 'Referencia (base y tolerancia)',
        },
        no_data: 'Sin muestra cargada.',
      },
      success: {
        upload: 'Plantilla importada correctamente.',
        download: 'Plantilla exportada correctamente.',
      },
      error: {
        err10019: 'Plantilla ya importada.',
        err10020: 'La plantilla no corresponde al silobolsa seleccionado.',
        invalid_file_type: 'Tipo de archivo inválido.',
        default: 'Se ha producido un error desconocido.',
      },
      input_error: {
        required_field: 'Este campo es obligatorio.',
        invalid_value: 'Este valor es inválido.',
        only_numbers: 'Solo se permiten números.',
        out_of_range: 'Valor fuera de los límites permitidos.',
      },
    },
    mobile_close_modal: {
      closure_details_origin: 'Detalle cierre en origen',
      grain_loss: '¿Hubo algun tipo de merma de grano sin cargar?',
      area_cleaned: '¿Quedo limpia la zona y sin residuos plásticos?',
      plastic_recycled: '¿Se recicló el plástico del silobolsa con alguna empresa certificada?',
      closure_distance: 'El cierre se realizó a {{distance}} metros del silobolsa',
      photo_of_area: 'Foto de zona',
    },
    alert: {
      state: {
        pending: 'Pendiente',
        review: 'En revisión',
        resolve: 'Resuelto',
        delete: 'Eliminada',
      },
      history: {
        footnote: 'Estado de alerta cambiado de forma masiva.',
      },
      modal: {
        title: 'Cambio de estado',
        placeholder: {
          main: 'Comentario...',
        },
        single: {
          question: '¿Está seguro que desea cambiar el estado de la alerta a ',
        },
        massive: {
          question: '¿Está seguro que desea cambiar todos los estados de las alertas a ',
          warning_title: '¡Atención!',
          warning1: '• Está por hacer un cambio de estado de todas las alertas del menú de alertas activas.',
          warning2: '• Esta acción puede demorar hasta un máximo de 2 minutos.',
        },
      },
    },
    delete_extraction: {
      delete: 'Eliminar Extracción',
      confirmation_msg: '¿Está seguro de querer eliminar la extracción?',
    },
    report_position_general: {
      title: 'Reporte de Posición General',
    },

    error_screen: {
      something_went_wrong: 'Algo salió mal...',
      error_detected: 'Se ha detectado un error interno durante la operación del sistema.',
      alternatives: 'Aqui tienes algunas acciones que podrían resultarte útiles:',
      support_contact: 'O bien puedes ponerte en contacto con el equipo de soporte para obtener ayuda.',
      button: {
        dashboard: 'Ir al dashboard',
        reload: 'Recargar',
        logout: 'Cerrar sesión',
        support: 'Soporte',
      },
      toast: {
        copy_error_success: 'Error copiado satisfactoriamente.',
      },
    },
    nav_bar_bell: {
      button: {
        resolve_all: 'Resolver todas',
        history: 'Historial',
      },
      tooltip: {
        only_own_org_resolve:
          'Solo puede resolver alertas masivamente de su organización. Seleccione su organización en el selector para continuar.',
        only_own_org_history: 'Solo puedes ver el historial de tu organización.',
        no_own_org_alerts: 'No tiene alertas activas en su organización',
      },
      modal: {
        history_title: 'Historial de resolución',
        warning_message: '¿Está seguro que desea resolver todas las alertas activas?',
      },
      table: {
        date_header: 'Fecha y hora',
        user_header: 'Usuario',
        alerts_resolved_qty_header: 'Cantidad de alertas resueltas',
      },
    },
    silobags_filters: {
      only_actives: 'Solo activos',
    },
    image_gallery: {
      title: 'Galería de Imágenes',
      no_images: 'No hay imágenes para mostrar',
      coords_non_disponible: 'Coordenadas no disponibles',
    },
  },
};

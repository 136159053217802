import { TextField, Button, Stack, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { styledTextField } from '@styles/generic-styles';
import { PATH } from '@data/core/paths';
import { ToastAction } from '@ui/toast/toast-action';
import { useService } from '@hooks/use-service';
import { useState } from 'react';
import { useGlobalStore } from '@global-store/use-store';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from '../../../libs/language';
import { recoverCredentialService } from '@services/domain/auth/recover-auth-credential';

type recoverCredentialBody = Parameters<typeof recoverCredentialService>[0]['body'];

export function ForgotPasswordForm() {
  const { setEmail } = useGlobalStore();

  const [formForgotPassword, setFormForgotPassword] = useState<recoverCredentialBody>({
    email: '',
  });
  const navigate = useNavigate();
  const {
    trigger: sendRecover,
    isError,
    isLoading,
  } = useService(recoverCredentialService, { onSuccess: () => navigate(PATH.VERIFY_RESET_ACCOUNT) });

  const handleSendRecover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sendRecover({ body: formForgotPassword });
    if (formForgotPassword.email.length > 0) {
      setEmail(formForgotPassword.email);
    }
  };
  return (
    <Stack
      display={'flex'}
      alignItems={'center'}
      direction={'column'}
      width={{ xs: '100%', md: '50%' }}
      mt={1}
      spacing={{ xs: 2, md: 3 }}
      marginRight={10}
    >
      <Box width={'90%'} display={'flex'} justifyContent={'center'}>
        <Typography variant="h5" fontWeight={300} m={1}>
          {i18n.t('app.public_pages.recover_password')}
        </Typography>
      </Box>
      <Box width={'90%'}>
        <TextField
          required
          id="email"
          label={i18n.t('app.public_pages.email')}
          variant="outlined"
          size="small"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => setFormForgotPassword({ ...formForgotPassword, email: e.target.value })}
        />
      </Box>
      <Stack direction={'row'} width={'90%'}>
        <Button
          variant="outlined"
          sx={{ width: '200px', marginRight: '10px' }}
          disableElevation
          onClick={() => navigate(PATH.LOGIN)}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          sx={{ width: '200px', color: 'white' }}
          disableElevation
          onClick={handleSendRecover}
          type="submit"
        >
          {isLoading ? <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} /> : 'Enviar'}
        </Button>
      </Stack>
      {isError && <ToastAction message="Verifica que el correo electronico sea correcto" severity="error" />}
    </Stack>
    // <Grid
    //   container
    //   justifyContent="center"
    //   alignItems="center"
    //   direction="column"
    //   spacing={3}
    //   component="form"
    // >
    //   <Grid item xs={12}>
    //     <h1 style={{ fontWeight: '300', margin: 0 }}>Recuperar Contraseña</h1>
    //   </Grid>
    //   <Grid item xs={12}>
    //     <TextField
    //       required
    //       id="email"
    //       label="Correo Electronico"
    //       variant="outlined"
    //       size="small"
    //       sx={styledTextField}
    //       onChange={(e) => setFormForgotPassword({ ...formForgotPassword, email: e.target.value })}
    //     />
    //   </Grid>
    //   <Grid item xs={6}>
    //     <Button
    //       variant="outlined"
    //       sx={{ width: '200px', marginRight: '10px' }}
    //       disableElevation
    //       onClick={() => navigate(PATH.LOGIN)}
    //     >
    //       Cancelar
    //     </Button>
    //     <Button
    //       variant="contained"
    //       sx={{ width: '200px', color: 'white' }}
    //       disableElevation
    //       onClick={handleSendRecover}
    //       type="submit"
    //     >
    //       {isLoading ? <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} /> : 'Enviar'}
    //     </Button>
    //   </Grid>
    //   {isError && <ToastAction message="Verifica que el correo electronico sea correcto" severity="error" />}
    // </Grid>
  );
}

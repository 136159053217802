import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { ResponseReportPowerbi } from './get-report-powerbi.types';



export async function getReportPowerbiService() {
  const { response, data } = await BACKEND.get<ResponseReportPowerbi>(ENDPOINT.GET_POWERBI);
  return {
    response,
    summary: data,
  };
}
import { Box, Fade, Modal, Typography, Button, Stack, Paper } from '@mui/material';
import { PropsWithChildren } from 'react';
import i18n from '../../../libs/language';
import AgroTokenLogo from '../../../assets/svg/integrations-logos/agrotoken-logo.svg';
import AlbortLogo from '../../../assets/svg/integrations-logos/albor-logo.svg';
import CorvusLogo from '../../../assets/svg/integrations-logos/corvus-logo.svg';
import ChevronRightIcon from '@assets/svg/core/chevron-right-blue.svg';
import { updateIntegrationSettingsService_DEPRECATED } from '@services/admin/integration';
import { useService } from '@hooks/use-service';
import { useGlobalStore } from '@global-store/use-store';
import { AlborIntegration } from './integrations/albort-integration';
import { CorvusIntegration } from './integrations/corvus-integration';
import { ReactNode, useState } from 'react';

type IntegrationConfig = {
  name: string;
  logo: string;
  component?: ReactNode;
  description: string;
  isActive: boolean | undefined;
};

type IntegrationModalProps = {
  open: boolean;
  close: () => void;
  refreshAlborList: (state: boolean) => void;
};

export function IntegrationsModal({ open, close, refreshAlborList }: IntegrationModalProps) {
  const { userOrganizationId, corvusIntegrated } = useGlobalStore();

  const { trigger: updateIntegrationSettings } = useService(updateIntegrationSettingsService_DEPRECATED);

  const [activeIntegration, setActiveIntegration] = useState<IntegrationConfig | null>(null);

  const handleClose = () => {
    if (activeIntegration?.name === 'Albor') {
      updateIntegrationSettings(userOrganizationId ?? 0, { albor_integration: false });
      refreshAlborList(true);
    }
    close();
    setActiveIntegration(null);
  };

  const handleActivateIntegration = (integration: IntegrationConfig) => {
    setActiveIntegration(integration);
  };

  const integrations: IntegrationConfig[] = [
    {
      name: 'Agrotoken',
      logo: AgroTokenLogo,
      description: i18n.t('app.system_management'),
      isActive: false,
    },
    {
      name: 'Corvus - AGDP',
      logo: CorvusLogo,
      component: <CorvusIntegration />,
      description: i18n.t('app.system_management'),
      isActive: corvusIntegrated,
    },
    {
      name: 'Albor',
      logo: AlbortLogo,
      component: <AlborIntegration />,
      description: i18n.t('app.system_management'),
      isActive: false,
    },
  ];

  return (
    <MyModal open={open} onClose={handleClose}>
      <Box sx={{ padding: '10px', bgcolor: 'background.paper', borderRadius: 2, maxWidth: 800, mx: '20px' }}>
        {activeIntegration ? (
          <>
            <Button onClick={() => setActiveIntegration(null)}>{i18n.t('app.return')}</Button>
            <Box>{activeIntegration.component}</Box>
            <Stack direction={'row'} justifyContent={'right'}>
              <Button sx={{ marginTop: '10px' }} onClick={handleClose}>
                {i18n.t('app.close')}
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Box>
              <Typography variant="h5" fontWeight={500}>
                {i18n.t('app.integration_modal.integrations')}
              </Typography>
              <Box sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {i18n.t('app.integration_modal.share_data')}
                </Typography>
              </Box>
              <Stack
                direction={'row'}
                justifyContent={'space-evenly'}
                sx={{ marginTop: '10px' }}
                flexWrap="nowrap"
                gap={1}
              >
                {integrations.map((integration) => {
                  return (
                    <Paper key={integration.name} elevation={2} sx={{ padding: '10px', width: '160px' }}>
                      <Stack spacing={1} direction={'column'}>
                        <Box>
                          <img
                            src={integration.logo}
                            alt={`${integration.name} Logo`}
                            style={{ width: '100%', height: '50px' }}
                          />
                        </Box>
                        <Typography textAlign={'center'} fontWeight={500}>
                          {integration.name}
                        </Typography>
                        {integration.description && (
                          <Typography textAlign={'center'} sx={{ color: '#BDBEBC', fontSize: '12px' }}>
                            {integration.description}
                          </Typography>
                        )}
                        <Button
                          color="info"
                          sx={{
                            textTransform: 'none',
                            fontSize: '14px',
                            padding: 0,
                          }}
                          onClick={() => handleActivateIntegration(integration)}
                          disabled={integration.isActive || integration.name === 'Agrotoken'}
                        >
                          {integration.isActive ? i18n.t('app.active') : i18n.t('app.activate')}{' '}
                          <span style={{ marginLeft: '5px' }}>
                            <img
                              src={
                                integration.isActive || integration.name === 'Agrotoken'
                                  ? undefined
                                  : ChevronRightIcon
                              }
                            />
                          </span>
                        </Button>
                      </Stack>
                    </Paper>
                  );
                })}
              </Stack>
              <Stack direction="row" justifyContent={'space-between'} gap={2} mt={2}>
                <Box style={{ color: '#a7a7a7', marginTop: '15px', fontSize: '14px' }}>
                  {i18n.t('app.integration_modal.apply')}{' '}
                  <a
                    href="https://iof-company.notion.site/T-rminos-y-Condiciones-Generales-9377d150dc764653b412fd68f80af8af"
                    target="_blank"
                    style={{
                      color: '#00A896',
                    }}
                  >
                    {i18n.t('app.integration_modal.terms_conditions')}
                  </a>{' '}
                  de {i18n.t('app.integration_modal.integration')}
                </Box>
                <Button sx={{ marginTop: '10px' }} onClick={handleClose}>
                  {i18n.t('app.close')}
                </Button>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
  keepMounted?: boolean;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    maxHeight: '90vh',
    overflowY: 'auto',
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

import { BaseTable } from '@features/ui';
import { Grid, IconButton, Chip, Stack, Box, Tooltip } from '@mui/material';
import { GridColDef, GridPaginationModel, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { formatDate, DateMonth, formatDateTime } from '@utils/helper';

import checkIcon from '@assets/svg/check.svg';

import siloIdIcon from '@assets/svg/SiloBagPage/silo-id.svg';
import SiloIdIconDisabled from '@assets/svg/SiloBagPage/silo-id-disabled.svg';

import siloMetrix from '@assets/svg/SiloBagPage/silo-metrix-active.svg';
import siloMetrixDisabled from '@assets/svg/SiloBagPage/silo-metrix-disabled.svg';

import siloSat from '@assets/svg/SiloBagPage/silo-view-active.svg';
import siloSatDisabled from '@assets/svg/SiloBagPage/silo-view-disabled.svg';

import { PATH } from '@data/core/paths';
import { useNavigate } from 'react-router-dom';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import ChevronRightIcon from '@assets/svg/core/chevron-right-blue.svg';
import { SilobagShareModal } from '../silobag-share/silobag-share';
import { sharePermissionListService } from '@services/domain/base/silobag/share/share-list';
import { useToggle } from '@hooks/core';
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import i18n from '../../../libs/language';
import { ToastAction } from '@ui/toast/toast-action';

import viewIcon from '@assets/svg/core/eye-hollow-blue.svg';
import mapIcon from '@assets/svg/core/location-pin-hollow-blue.svg';
import editIcon from '@assets/svg/core/edit-square-hollow-blue.svg';
import editDisabledIcon from '@assets/svg/core/edit-square-hollow-disabled.svg';
import downloadPdfIcon from '@assets/svg/core/download-hollow-blue.svg';
import downloadDisabledPdfIcon from '@assets/svg/core/dowload-hollow-disabled.svg';
import shareIcon from '@assets/svg/core/share-hollow-blue.svg';
import shareDisabledIcon from '@assets/svg/share-disabled.svg';
import mapIconDisabled from '@assets/svg/core/location-pin-hollow-disabled.svg';
import { silobagCMCService } from '@services/domain/base/silobag/file/cmc';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];
type SummarySilobag = SummarySilobagList[0];
type SilobagListProps = {
  silobagList?: SummarySilobagList;
  isLoading: boolean;
  isActiveList?: boolean;
  rowCount: number;
  paginationModel: GridPaginationModel;
  setPaginationModel: (fn: (model: GridPaginationModel) => GridPaginationModel) => void;
  //sortingModel: GridSortModel;
  setSortingModel: Dispatch<SetStateAction<string>>;
  silobagSelected: React.Dispatch<React.SetStateAction<SummarySilobagList>>;
  setSiloListAll: (val: SummarySilobagList) => void;
};

export function SilobagListTablet({
  silobagList,
  isLoading,
  isActiveList,
  rowCount,
  paginationModel,
  setPaginationModel,
  setSortingModel,
  silobagSelected,
  setSiloListAll,
}: SilobagListProps) {
  const navigate = useNavigate();
  const rows = silobagList !== undefined ? silobagList : [];
  const siloListId = rows.map((row) => row.id);

  const [modalShare, setModalShare] = useToggle(false);
  const [siloName, setSiloName] = useState('');
  const [siloIdShare, setSiloIdShare] = useState('');
  const [validateSB, setValidateSB] = useState(false);
  const [toastState, setToastState] = useToggle(false);
  const [countryside, setCountryside] = useState('');

  // VERIFICAR SI LA SB FUE COMPARTIDA CON EXITO
  const [silobagShared, setSilobagShared] = useState<{
    message: string;
    state: boolean;
    type: 'success' | 'error';
  }>({ message: '', state: false, type: 'success' });

  useEffectOnUpdate(() => {
    setToastState(silobagShared.state);
  }, [silobagShared]);

  const { trigger: getPdf, summary: data, isLoading: isLoadingPdf } = useService(silobagCMCService);

  useEffectOnUpdate(() => {
    if (isLoadingPdf) {
      document.body.style.cursor = 'wait';
    } else if (!isLoadingPdf && data) {
      document.body.style.cursor = 'auto';
      const link = document.createElement('a');
      link.href = data.file_path;
      link.download = `${data.file_path}`;
      link.click();
    }
  }, [isLoadingPdf]);

  const {
    trigger: getSiloPermission,
    summary: dataSiloPermission,
    isLoading: isLoadingSiloPermission,
  } = useService(sharePermissionListService);

  const handleModalShare = (
    siloId: string,
    siloName: string,
    isValidateSB: boolean,
    countrysideName: string
  ) => {
    getSiloPermission({ query: siloId });
    setSiloName(siloName);
    setSiloIdShare(siloId);
    setValidateSB(!isValidateSB);
    setModalShare(true);
    setCountryside(countrysideName);
  };

  const pathSilobags = true;
  const columns: GridColDef<SummarySilobag>[] = [
    {
      field: 'name',
      headerName: 'Silo',
      flex: 1.5,
      minWidth: 220,
      sortable: false,
      renderCell: (params) => {
        const siloId = params.row.silobag_data_statuses.id;
        const siloSatService = params.row.v_silobags_services_statuses?.sat_view_active;
        const siloMetrixService = params.row.v_silobags_services_statuses?.silo_metrix_active;
        const siloIdNavigate = params.row.id;

        return (
          <>
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  textDecoration: 'none',
                  color: 'black',
                  marginBottom: '5px',
                  cursor: 'pointer',
                  fontSize: '.85rem',
                  margin: '0 auto',
                  '&:hover': {
                    color: '#22a7f0',
                  },
                  textOverflow: 'ellipsis',
                }}
                onClick={() =>
                  navigate(`${PATH.SILOBAG}/${siloIdNavigate}`, { state: { siloListId, pathSilobags } })
                }
              >
                <Tooltip title={params.value} placement="top" arrow>
                  <Box
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px',
                    }}
                  >
                    {params.value}
                  </Box>
                </Tooltip>
                <img src={ChevronRightIcon} style={{ width: 8, height: 8, marginLeft: 4 }} />
              </Stack>
              <Stack direction="row">
                <img
                  src={siloId === 2 ? siloIdIcon : SiloIdIconDisabled}
                  alt="siloId"
                  style={{ marginRight: '5px' }}
                />
                <img
                  src={siloSatService === true ? siloSat : siloSatDisabled}
                  alt="siloSat"
                  style={{ marginRight: '5px' }}
                />
                <img src={siloMetrixService === true ? siloMetrix : siloMetrixDisabled} alt="siloMetrix" />
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: 'silobag_id',
      headerName: 'Id',
      flex: 0.8,
      minWidth: 80,
      sortable: false,
      resizable: false,
      renderCell: (params) => {
        const siloId = params.row.id;
        return <Box style={{ fontSize: '.85rem' }}>{siloId}</Box>;
      },
    },
    {
      field: 'silobag_species_types',
      headerName: i18n.t('app.silobag_data.species'),
      sortable: true,
      flex: 1.2,
      minWidth: 135,
      resizable: false,
      valueGetter: (params) => {
        const translation = params.row.silobag_species_types.silobag_species_types_translations.find(
          (t) => t.language_id === (i18n.locale === 'es' ? 1 : 2)
        );
        return translation ? translation.description : params.value.name;
      },
      renderCell: (params) => {
        const imageSpecie = params.row.silobag_species_types.image_url;
        return (
          <Stack>
            <Stack direction="row" alignItems="center">
              <img
                src={imageSpecie}
                alt="imagen de icono"
                style={{ width: 18, height: 18, marginRight: '5px' }}
              />
              <Box style={{ fontSize: '.85rem' }}>{params.value}</Box>
            </Stack>
            <Box style={{ fontSize: '.85rem' }}>{params.row?.variety}</Box>
          </Stack>
        );
      },
    },
    {
      field: 'silobag_product_types',
      headerName: i18n.t('app.silobag_data.product'),
      flex: 1,
      resizable: false,
      minWidth: 100,
      valueGetter: (params) => params.value.name,
      renderCell: (params) => <Box style={{ fontSize: '0.85rem' }}>{params.value}</Box>,
    },
    {
      field: isActiveList ? 'created_at' : 'closing_date',
      minWidth: 100,
      headerName: isActiveList
        ? i18n.t('app.silobag_data.register_date')
        : i18n.t('app.silobag_data.close_date'),
      flex: 1,
      resizable: false,
      renderCell: (params) => {
        const closeData = params.row?.v_silobag_closing_dates?.closing_date.toString();
        return (
          <Box style={{ fontSize: '0.85rem' }}>
            <p>{isActiveList ? formatDateTime(params.value, 4) : formatDateTime(closeData, 4)}</p>
            {closeData !== null ? (
              <p style={{ fontSize: '10px' }}>
                {isActiveList ? '' : `Alta ${formatDate(params?.row?.created_at)}`}
              </p>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: 'bagging_date',
      headerName: i18n.t('app.silobag_data.bagging_date'),
      minWidth: 120,
      resizable: false,
      flex: 1,
      renderCell: (params) => {
        const date = params.value ? formatDate(params.value) : '―';
        const dateWithMonth = date !== '―' ? DateMonth(date) : '―';
        const harverstDate = params.row.silobag_harvests?.name;
        return (
          <Stack>
            <Box>{dateWithMonth}</Box>
            <Box style={{ fontSize: '12px' }}>
              <span style={{ color: '#B7B7B7' }}>{i18n.t('app.silobag_data.harvest')}</span> {harverstDate}
            </Box>
          </Stack>
        );
      },
    },
    {
      field: 'initial_humidity',
      headerName: i18n.t('app.silobag_data.initial_humidity'),
      align: 'center',
      headerAlign: 'left',
      type: 'number',
      flex: 1,
      resizable: false,
      minWidth: 150,
      renderCell: (params) => {
        const valueToDisplay = params.value != null ? params.value + ' %' : '―';
        return <span style={{ fontSize: '0.85rem' }}>{valueToDisplay}</span>;
      },
    },
    {
      field: 'remaining_stock',
      minWidth: 120,
      headerAlign: 'left',
      headerName: i18n.t('app.silobag_data.remaining_stock'),
      type: 'number',
      flex: 1.2,
      align: 'left',
      resizable: false,
      renderCell: (params) => {
        const active = params.row.active;
        const partially_extracted = params.row.partially_extracted;
        let chipLabel = '';
        let chipBgColor = '';
        let chipTextColor = '';
        if ((active === true && partially_extracted === false) || partially_extracted === null) {
          chipLabel = 'Activo';
          chipBgColor = '#73BD0033';
          chipTextColor = '#6BAA00';
        } else if (active == true && partially_extracted === true) {
          chipLabel = 'Baja parcial';
          chipBgColor = '#FD7E1433';
          chipTextColor = '#FD7E14';
        } else if (active === false) {
          chipLabel = 'Cerrado';
          chipBgColor = '#A7A7A733';
          chipTextColor = '#7A7A7A';
        }
        return (
          <Stack>
            <Box style={{ fontSize: '0.85rem' }}>
              {i18n.numberToDelimited(parseFloat(params.value || 0), {
                delimiter: i18n.t('config.delimiter'),
              })}{' '}
              kg
            </Box>
            <Box>
              <Chip
                label={chipLabel}
                size="small"
                sx={{
                  backgroundColor: chipBgColor,
                  color: chipTextColor,
                  fontSize: '10px',
                  height: '17px',
                  borderRadius: '4px',
                }}
              />
            </Box>
          </Stack>
        );
      },
    },
    {
      field: 'total_extracted_weight',
      headerName: i18n.t('app.silobag_data.total_extracted'),
      type: 'number',
      headerAlign: 'left',
      align: 'center',
      width: 120,
      resizable: false,
      renderCell: (params) => (
        <span style={{ fontSize: '0.85rem' }}>
          {i18n.numberToDelimited(params.value, { delimiter: i18n.t('config.delimiter') })} Kg
        </span>
      ),
    },
    {
      field: 'organizations',
      headerName: i18n.t('app.organization'),
      flex: 1,
      minWidth: 115,
      resizable: false,
      sortable: false,
      valueGetter: (params) => params.value.name,
      renderCell: (params) => <span style={{ fontSize: '0.85rem' }}>{params.value}</span>,
    },
    {
      field: 'lots',
      headerName: i18n.t('app.countryside'),
      flex: 1.3,
      resizable: false,
      minWidth: 200,
      valueGetter: (params) => params.value.countrysides.name,
      renderCell: (params) => {
        return (
          <Stack style={{ fontSize: '0.85rem' }}>
            <Box>{params.value}</Box>
            <Box>{params.row.lots.name}</Box>
          </Stack>
        );
      },
    },
    {
      field: 'hex',
      headerName: i18n.t('app.alerts'),
      flex: 0.8,
      sortable: false,
      resizable: false,
      minWidth: 74,
      renderCell: (params) => {
        const hexBgColor = params.value;
        const short_name_alert = params.row.short_name_last_alert;
        const severity = params.row.severity;

        return (
          <Tooltip title={i18n.t('app.tooltip_sb_optimo')} disableHoverListener={severity !== 0}>
            <Box style={{ width: '100%' }}>
              <Chip
                label={
                  short_name_alert == null || severity == 0 ? (
                    <img src={checkIcon} alt="Check Icon" />
                  ) : (
                    short_name_alert
                  )
                }
                sx={{
                  backgroundColor: hexBgColor,
                  color: severity === 1 ? '#000' : '#fff',
                  width: 74,
                  height: 26,
                  fontSize: '10px',
                  fontWeight: 500,
                  borderRadius: '8px',
                }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'id',
      headerName: i18n.t('app.actions'),
      flex: 2,
      sortable: false,
      resizable: false,
      minWidth: 170,
      renderCell: (params) => {
        const siloId = params.value;
        const siloName = params.row.name;
        const writePermission = params.row.write_permission;
        const fullPermission = params.row.full_permission;
        const isGuest: boolean = params.row.is_guest;
        const countrysideName = params.row.lots.countrysides.name;
        const isValidateSB = params?.row?.silobag_data_statuses.id === 2 ? true : false;
        const isCloseSB = params?.row?.active;
        const isValidMap = isCloseSB && isValidateSB;

        return (
          <Stack direction={'row'} gap={0.5}>
            <Tooltip title={i18n.t('app.see_details')}>
              <span
                onClick={() => navigate(`${PATH.SILOBAG}/${siloId}`, { state: { siloListId, pathSilobags } })}
              >
                <IconButton
                  sx={{
                    color: '#22A7F0',
                    width: 24,
                    height: 24,
                    padding: '4px',
                    borderRadius: '4px',
                    '&:hover': {
                      background: '#22a7f02a',
                    },
                  }}
                >
                  <img src={viewIcon} alt="eye" style={{ width: 16, height: 16 }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={i18n.t('app.dashboard_page.see_map')}>
              <IconButton
                sx={{
                  color: '#22A7F0',
                  width: 24,
                  height: 24,
                  padding: '4px',
                  borderRadius: '4px',
                  '&:hover': {
                    background: '#22a7f02a',
                  },
                }}
                disabled={!isValidMap}
                onClick={() => navigate(`${PATH.MAP}/${params.id}`)}
              >
                <img
                  src={isValidMap ? mapIcon : mapIconDisabled}
                  alt="map"
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('app.edit_siloreal')}>
              <IconButton
                onClick={() => navigate(`${PATH.SILOBAG}/${siloId}/edit`, { state: { isGuest } })}
                disabled={!writePermission}
                sx={{
                  color: '#22A7F0',
                  width: 24,
                  height: 24,
                  padding: '4px',
                  borderRadius: '4px',
                  '&:hover': {
                    background: '#22a7f02a',
                  },
                }}
              >
                <img
                  src={writePermission && isActiveList ? editIcon : editDisabledIcon}
                  alt="edit"
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('app.download_certificate_silo')}>
              <IconButton
                onClick={() => getPdf({ query: { id: siloId, blockchain: true, silobagHistory: true } })}
                disabled={!isValidateSB}
                sx={{
                  color: '#22A7F0',
                  width: 24,
                  height: 24,
                  padding: '4px',
                  borderRadius: '4px',
                  '&:hover': {
                    background: '#22a7f02a',
                  },
                }}
              >
                <img
                  src={isValidateSB ? downloadPdfIcon : downloadDisabledPdfIcon}
                  alt="download"
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={i18n.t('app.silobag_share.share_silobag')}>
              <IconButton
                onClick={() => handleModalShare(siloId, siloName, isValidateSB, countrysideName)}
                disabled={!fullPermission}
                sx={{
                  color: '#22A7F0',
                  width: 24,
                  height: 24,
                  padding: '4px',
                  borderRadius: '4px',
                  marginRight: '4px',
                  '&:hover': {
                    background: '#22a7f02a',
                  },
                }}
              >
                <img
                  src={fullPermission ? shareIcon : shareDisabledIcon}
                  alt="share"
                  style={{ width: 16, height: 16 }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const handleRowSelected = (selected: SummarySilobagList | GridRowSelectionModel) => {
    silobagSelected(selected as SummarySilobagList);
  };

  useEffect(() => {
    setSiloListAll(rows);
  }, [rows]);

  return (
    <>
      {silobagShared.state ? (
        <ToastAction
          message={silobagShared.message}
          severity={silobagShared.type}
          openToast={toastState}
          setOpenToast={setToastState}
        />
      ) : null}

      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <BaseTable
          loading={isLoading}
          rowCount={rowCount ?? 0}
          rowHeight={52}
          rows={rows ?? []}
          columns={columns}
          disableColumnResize={false}
          initialPageSize={50}
          initialState={{
            pinnedColumns: {
              right: ['hex', 'id'],
            },
          }}
          checkboxSelection
          onRowSelected={handleRowSelected}
          disableRowSelectionOnClick
          // Server comunication
          sortingMode="server"
          onSortModelChange={(sortModel) => {
            if (!sortModel.length) return '';

            const FIELD_MAPPING: { [key: string]: string } = {
              name: 'silobag_name',
              silobag_species_types: 'species_type_name',
              silobag_product_types: 'product_type_name',
              bagging_date: 'bagging_date',
              initial_humidity: 'initial_humidity',
              remaining_stock: 'remaining_stock',
              total_extracted_weight: 'total_extracted_weight',
              lots: 'countryside_name',
              created_at: 'created_at',
              closing_date: 'closing_date',
              //silobag_id: '',
              //organizations: '',
              //hex: '',
            };

            const sortedFields = sortModel.map((s) => FIELD_MAPPING[s.field] ?? '').filter(Boolean);
            const sortedOrders = sortModel.map((s) => s.sort);
            const sortByFieldQuery = 'orderBy=' + sortedFields.join(',');
            const sortByOrderQuery = 'orderByDirections=' + sortedOrders.join(',');

            setSortingModel(sortByFieldQuery + '&' + sortByOrderQuery);
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          selectFullRow
        />
        {modalShare && (
          <SilobagShareModal
            siloName={siloName}
            siloId={siloIdShare}
            open={modalShare}
            setOpen={setModalShare}
            data={dataSiloPermission}
            getData={getSiloPermission}
            isLoadingData={isLoadingSiloPermission}
            setSilobagShared={setSilobagShared}
            hasInvalidStatus={validateSB}
            CountrysideName={countryside}
          />
        )}
      </Grid>
    </>
  );
}

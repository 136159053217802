import React from 'react';
import {
  Badge,
  Box,
  Chip,
  CircularProgress,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material';
import SiloSatIcon from '../../../../assets/svg/SiloBagPage/view/SiloSatIcon.png';
import { useMediaQuery, Divider } from '@mui/material';
import { formatDateTime } from '@utils/helper';
import SplitLeft from '../../../../assets/svg/SiloBagPage/view/splitscreen_left.svg';
import SplitRight from '../../../../assets/svg/SiloBagPage/view/splitscreen_right.svg';
import { useEffect, useState } from 'react';
import RedEyeIcon from '@assets/svg/core/eye-red.svg';
import EyeIcon from '@assets/svg/core/eye-green.svg';
import discardedIcon from '@assets/svg/discarded.svg';
import {
  BoxCircularBar,
  BoxSiloSatImages,
  BoxSiloSatStatus,
  ImagesContainer,
  SiloSatImagesData,
  SiloSatNoImagesBox,
  customBgWhite,
} from '@styles/generic-styles';
import {
  DateRangeCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Stack, styled } from '@mui/system';
import utc from 'dayjs/plugin/utc';
import i18n from '../../../../libs/language';
import 'dayjs/locale/es';
import InfoIcon from '@assets/svg/info-icon.svg';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

dayjs.extend(utc);
dayjs.locale('es');
enum Status {
  VISIBLE = 1,
  NOT_VISIBLE = 2,
  NOT_ANALYZED = 3,
}

type SummarySilobagSatView = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['v_filtered_sat_view_images'];
type SummarySilobagExistence = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['v_silobags_existence_statuses'];
type SummarySilobagStatuses = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['v_silobags_services_statuses'];

type CustomStepIconProps = {
  active: boolean;
  completed: boolean;
  className: string;
  icon: React.ReactNode;
  index: number;
  sat_view_state: string;
};

const CustomStepIconRoot = styled('div')<{
  ownerState: {
    completed: boolean;
    active: boolean;
    index: number;
    sat_view_state: string;
  };
}>(({ ownerState }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: ownerState.completed ? 'green' : ownerState.active ? 'green' : '#d3d3d3',
  ...(ownerState.index === 0 && {
    backgroundColor: 'skyblue',
  }),
  ...(ownerState.index === 1 &&
    ownerState.sat_view_state === 'Unavailable' && {
      backgroundColor: '#ff6666',
    }),
  color: '#fff',
}));

type SilobagSilosatProps = {
  silosat: SummarySilobagSatView;
  statuses?: SummarySilobagStatuses;
  existence?: SummarySilobagExistence | [];
};

const stateToStepIndex: { [key: string]: number } = {
  Enabling: 0,
  Unavailable: 1,
  Processing: 2,
  Active: 3,
  Completed: 4,
};

const endDate = dayjs();
const initialValue: [Dayjs | null, Dayjs | null] = [null, endDate];

type ServerDayProps = {
  day: Dayjs;
  selected: boolean;
  highlightedDays: string[];
  highdarkedDays: string[];
  notShowDays: string[];
  highcloudDays: string[];
  sortedImages: { [date: string]: SummarySilobagSatView };
  setSelectedImage: (image: SummarySilobagSatView[0] | null) => void;
  outsideCurrentMonth: boolean;
};

const statusColors: Record<number, string> = {
  1: '🟢',
  2: '🔴',
  3: '🔘',
  4: '💭',
};

function CustomStepIcon(props: CustomStepIconProps) {
  const { active, completed, className, index, sat_view_state } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active, index, sat_view_state }} className={className} />
  );
}

function ServerDay(props: ServerDayProps) {
  const {
    setSelectedImage,
    highlightedDays,
    highdarkedDays,
    notShowDays,
    highcloudDays,
    sortedImages,
    ...pickerDayProps
  } = props;
  const dateStr = props.day.format('YYYY-MM-DD');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);

  const isHighlighted = !props.outsideCurrentMonth && highlightedDays.includes(dateStr);
  const isHighDarked = !props.outsideCurrentMonth && highdarkedDays.includes(dateStr);
  const isNotShow = !props.outsideCurrentMonth && notShowDays.includes(dateStr);
  const isHighClouded = !props.outsideCurrentMonth && highcloudDays.includes(dateStr);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>, day: Dayjs) => {
    event.stopPropagation();
    if (isHighlighted || isHighDarked || isNotShow || isHighClouded) {
      setSelectedDay(day);
      setAnchorEl(event.currentTarget);
      if (!open) {
        setOpen(true);
      }
    }

    // Verificar si hay imágenes para el día seleccionado
    const imagesOfTheDay = sortedImages ? sortedImages[day.format('YYYY-MM-DD')] : null;

    if (imagesOfTheDay) {
      setOpen(true);
      setSelectedValue('item0');
      const [selectedImage] = imagesOfTheDay;
      setSelectedImage(selectedImage);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value);
    if (selectedDay && sortedImages) {
      const imagesOfTheDay = sortedImages[selectedDay.format('YYYY-MM-DD')];
      const selectedItemIndex = Number((event.target as HTMLInputElement).value.replace('item', ''));
      const selectedImage = imagesOfTheDay[selectedItemIndex];
      setSelectedImage(selectedImage);
    }
  };

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={
        isNotShow ? '🔘' : isHighDarked ? '🔴' : isHighlighted ? '🟢' : isHighClouded ? '💭' : undefined
      }
    >
      <PickersDay
        {...pickerDayProps}
        onClick={(e) => handleClickOpen(e, props.day)}
        onDaySelect={(day) => setSelectedDay(day)}
        isFirstVisibleCell={false}
        isLastVisibleCell={false}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DialogTitle>
          {`${i18n.t('app.silobag_data.selected_days')}: ${selectedDay?.format('DD/MM/YYYY')}`}
          {selectedDay && sortedImages && sortedImages[selectedDay.format('YYYY-MM-DD')] && (
            <RadioGroup aria-label="items" name="items" value={selectedValue} onChange={handleChange}>
              {sortedImages[selectedDay.format('YYYY-MM-DD')].map((img, index) => {
                const timestamp = dayjs(img.timestamp);
                const formattedTimestamp = `${timestamp.format('HH:mm')} - ${timestamp.format('DD/MM/YYYY')}`;
                const statusColor = statusColors[img.status_id] || '';

                return (
                  <FormControlLabel
                    key={index}
                    value={`item${index}`}
                    control={<Radio />}
                    label={`${formattedTimestamp} ${statusColor}`}
                  />
                );
              })}
            </RadioGroup>
          )}
        </DialogTitle>
      </Popover>
    </Badge>
  );
}

export function SilobagSilosat({ silosat, statuses, existence }: SilobagSilosatProps) {
  const imagesSilosat = silosat;

  const sat_view_state = statuses?.sat_view_state;
  const sat_view_status = existence?.sat_view_status;
  const activeStep: number = stateToStepIndex[sat_view_state ?? -1] ?? 0;

  const [isLoading] = useState(false);
  const matches = useMediaQuery('(min-width:961px)');
  const [highlightedDays, setHighlightedDays] = useState<string[]>([]);
  const [highdarkedDays, setHighdarkedDays] = useState<string[]>([]);
  const [notShowDays, setNotShowDays] = useState<string[]>([]);
  const [highcloudDays] = useState<string[]>([]);
  const [sortedImages, setSortedImages] = useState<{ [date: string]: SummarySilobagSatView }>({});

  // Funciones para filtrar y obtener imágenes más antiguas y recientes
  function filterImages(images: SummarySilobagSatView): SummarySilobagSatView {
    let filteredImages = images.filter((img) => img.status_id === Status.VISIBLE);

    if (filteredImages.length === 0) {
      filteredImages = images.filter((img) => img.status_id === Status.NOT_VISIBLE);
    }

    return filteredImages;
  }

  function getOldestImage(images: SummarySilobagSatView) {
    if (images.length === 0) return null;
    return images.reduce(
      (oldest, img) => (new Date(img.timestamp || '') < new Date(oldest.timestamp || '') ? img : oldest),
      images[0]
    );
  }

  function getNewestImage(images: SummarySilobagSatView) {
    if (images.length === 0) return null;
    return images.reduce(
      (newest, img) => (new Date(img.timestamp || '') > new Date(newest.timestamp || '') ? img : newest),
      images[0]
    );
  }

  const imagesWithStatusOne = filterImages(imagesSilosat);
  const oldestImage = getOldestImage(imagesWithStatusOne);
  const newestImage = getNewestImage(imagesWithStatusOne);

  // Establecemos la segunda imagen en caso de el estado sea unavailable
  const mostRecentStatusOneImage = imagesSilosat
    .filter((img) => img.status_id === 1)
    .sort((a, b) => new Date(b.timestamp || '').getTime() - new Date(a.timestamp || '').getTime())[0];

  const imageWithStatusTwo = mostRecentStatusOneImage
    ? imagesSilosat
        .filter((img) => new Date(img.timestamp || '') > new Date(mostRecentStatusOneImage.timestamp || ''))
        .sort((a, b) => new Date(a.timestamp || '').getTime() - new Date(b.timestamp || '').getTime())
        .find((img) => img.status_id === 2)
    : null;

  const secondImage = sat_view_status === 'Non-Existent' ? imageWithStatusTwo : newestImage;
  const [selectedImage, setSelectedImage] = useState(secondImage);
  const [firstImg, setFirstImg] = useState(oldestImage || null);
  const [secondImg, setSecondImg] = useState(secondImage || null);
  const [firstImgActive, setFirstImgActive] = useState(false);
  const [secondImgActive, setSecondImgActive] = useState(true);
  const handleFirstImgClick = () => {
    if (firstImgActive) return;
    setSelectedImage(firstImg);
    setFirstImgActive(true);
    setSecondImgActive(false);
  };

  const handleSecondImgClick = () => {
    if (secondImgActive) return;
    setSelectedImage(secondImg);
    setSecondImgActive(true);
    setFirstImgActive(false);
  };

  const VISUALIZA: { [key: number]: { id: number; text: string } } = {
    1: { id: 1, text: 'Se visualiza' },
    2: { id: 2, text: 'No Se visualiza' },
    3: { id: 3, text: 'Sin Analizar' },
    4: { id: 4, text: 'Sin Analizar' },
  };

  const [satViewData] = useState(imagesSilosat);
  const [activeImg, setActiveImg] = useState({
    firstImg: oldestImage ? (imagesSilosat || []).findIndex((img) => img.id === oldestImage.id) : 0,
    secondImg: secondImage ? (imagesSilosat || []).findIndex((img) => img.id === secondImage.id) : 0,
  });

  useEffect(() => {
    const imgsByDate = imagesSilosat.reduce((acc: { [date: string]: SummarySilobagSatView }, img) => {
      const imgDateString = dayjs(img.timestamp).format('YYYY-MM-DD');
      if (!acc[imgDateString]) {
        acc[imgDateString] = [];
      }
      acc[imgDateString].push(img);
      return acc;
    }, {});

    const highlightedDays: string[] = [];
    const highdarkedDays: string[] = [];
    const notShowDays: string[] = [];
    const highcloudDays: string[] = [];
    Object.keys(imgsByDate).forEach((date) => {
      const images = imgsByDate[date];
      const dayAndTime = dayjs(images[0].timestamp).format('YYYY-MM-DD');

      const status = images.find((img) => [1, 2, 3].includes(img.status_id))?.status_id;

      switch (status) {
        case 1:
          highlightedDays.push(dayAndTime);
          break;
        case 2:
          highdarkedDays.push(dayAndTime);
          break;
        case 3:
          notShowDays.push(dayAndTime);
          break;
        default:
          highcloudDays.push(dayAndTime);
      }
    });

    setHighlightedDays(highlightedDays);
    setHighdarkedDays(highdarkedDays);
    setNotShowDays(notShowDays);
    setSortedImages(imgsByDate);

    const sortedImgsByDate = Object.keys(imgsByDate)
      .sort()
      .reverse()
      .reduce((acc: { [date: string]: SummarySilobagSatView }, date: string) => {
        acc[date] = imgsByDate[date].sort((a, b) => b.status_id - a.status_id);
        return acc;
      }, {});
    setSortedImages(sortedImgsByDate);
  }, [imagesSilosat]);

  const index = 0;

  useEffect(() => {
    if (selectedImage) {
      const globalImgIDX = imagesSilosat.findIndex((img) => img.id === selectedImage.id);
      if (firstImgActive) {
        setFirstImg(selectedImage);
        setActiveImg((prevState) => ({ ...prevState, firstImg: globalImgIDX }));
      }
      if (secondImgActive) {
        setSecondImg(selectedImage);
        setActiveImg((prevState) => ({ ...prevState, secondImg: globalImgIDX }));
      }
    } else if (oldestImage && newestImage) {
      setFirstImg(oldestImage);
      setSecondImg(newestImage);
      setActiveImg((prevState) => ({
        ...prevState,
        firstImg: imagesSilosat.findIndex((img) => img.id === oldestImage.id),
      }));
      setActiveImg((prevState) => ({
        ...prevState,
        secondImg: imagesSilosat.findIndex((img) => img.id === newestImage.id),
      }));
    }
  }, [selectedImage, imagesSilosat, firstImgActive, secondImgActive, oldestImage, newestImage]);

  const commonBoxStyles = {
    border: '2px solid #2898bd',
    borderRadius: '15px',
    margin: 'border-box',
    boxSizing: 'border-box',
    overflow: 'visible',
    padding: '10px',
  };

  function getStatusStyles(statusId: number) {
    const stylesMap: { [key: number]: { backgroundColor: string; color: string } } = {
      1: { backgroundColor: '#9EC95526', color: '#73BD00' },
      3: { backgroundColor: '#7A7A7A33', color: '#7A7A7A' },
      4: { backgroundColor: '#7A7A7A33', color: '#7A7A7A' },
    };
    const defaultStyle = { backgroundColor: '#FF000026', color: '#FF0000' };

    return stylesMap[statusId] || defaultStyle;
  }

  const firstImgStyles = getStatusStyles(satViewData?.[activeImg.firstImg]?.status_id);
  const secondImgStyles = getStatusStyles(satViewData?.[activeImg.secondImg]?.status_id);
  const steps = [
    i18n.t('app.silo_sat.enabling'),
    sat_view_state === 'Unavailable' ? i18n.t('app.silo_sat.unavailable') : i18n.t('app.silo_sat.available'),
    i18n.t('app.silo_sat.processing'),
    i18n.t('app.silo_sat.active'),
  ].filter(Boolean);

  const shouldDisplayImages =
    (sat_view_state === 'Active' || sat_view_state === 'Completed') && imagesSilosat.length > 0;
  const shouldDisplayStepper = sat_view_state !== 'Active' && sat_view_state !== 'Completed';

  return (
    <Paper elevation={0} sx={{ ...customBgWhite }}>
      {satViewData === undefined ? (
        <Box sx={BoxCircularBar}>
          <CircularProgress sx={{ marginLeft: '10px', color: 'green' }} size={24} />
        </Box>
      ) : (
        <Box>
          <Stack direction="row" alignItems="center">
            <Box component="h1" margin={0}>
              {i18n.t('app.silobag_data.satellite_images')}
            </Box>
            <img src={SiloSatIcon} style={{ width: '24px', height: '24px', marginLeft: '16px' }} />
            <span style={{ color: '#1481bc', marginLeft: '2px' }}>
              SILO<b>SAT</b>
            </span>
          </Stack>
          {shouldDisplayStepper && (
            <Box sx={{ marginTop: 4 }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={(props) => (
                        <CustomStepIcon {...props} index={index} sat_view_state={sat_view_state} />
                      )}
                      sx={{
                        '& .MuiStepLabel-label': {
                          color: index <= activeStep ? 'black' : 'gray',
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      <Typography variant="body2" align="center" sx={{ fontWeight: 'bold' }}>
                        {label.split('.')[0]}
                      </Typography>
                      <Typography variant="body2" align="center" sx={{ marginTop: 1 }}>
                        {label.split('.')[1] || ''}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}
          {shouldDisplayImages && (
            <Box sx={{ marginTop: 4 }}>
              <Grid container spacing={11}>
                {imagesSilosat.length === 0 ? (
                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Box sx={SiloSatNoImagesBox}>
                      <span> {i18n.t('app.silo_sat.without_images')}</span>
                    </Box>
                  </Grid>
                ) : (
                  <>
                    <Grid item lg={5.9} md={10} sm={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} sx={ImagesContainer}>
                          <Box
                            sx={
                              firstImgActive ? { ...commonBoxStyles } : { ...commonBoxStyles, border: 'none' }
                            }
                          >
                            <Box sx={SiloSatImagesData}>
                              <Typography variant="overline">
                                {activeImg &&
                                  activeImg.firstImg !== undefined &&
                                  satViewData &&
                                  satViewData[activeImg.firstImg] &&
                                  formatDateTime(satViewData[activeImg.firstImg].timestamp || '', 3)}
                              </Typography>
                              <Box display="flex" alignItems="center">
                                <Typography variant="overline" sx={{ textTransform: 'none' }}>
                                  {i18n.t('app.silobag_data.existence_silosat')}{' '}
                                </Typography>
                                <Typography
                                  variant="overline"
                                  sx={{
                                    ...BoxSiloSatStatus,
                                    color:
                                      firstImg !== undefined &&
                                      oldestImage !== null &&
                                      VISUALIZA?.[oldestImage.status_id]?.id === 1
                                        ? '#6BAA00'
                                        : '#FF0000',
                                  }}
                                >
                                  {activeImg?.firstImg !== undefined && (
                                    <>
                                      <Chip
                                        icon={
                                          satViewData?.[activeImg.firstImg]?.status_id === 1 ? (
                                            <img src={EyeIcon} style={{ width: '16px', height: '16px' }} />
                                          ) : satViewData?.[activeImg.firstImg]?.status_id === 2 ? (
                                            <img src={RedEyeIcon} style={{ width: '16px', height: '16px' }} />
                                          ) : (
                                            <img
                                              src={discardedIcon}
                                              style={{ width: '16px', height: '16px' }}
                                            />
                                          )
                                        }
                                        label={
                                          VISUALIZA?.[satViewData?.[activeImg.firstImg]?.status_id]?.text ??
                                          '-'
                                        }
                                        sx={{
                                          ...firstImgStyles,
                                          fontSize: '11px',
                                          fontWeight: 'bold',
                                          marginLeft: '5px',
                                        }}
                                      />
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                ...BoxSiloSatImages,
                                boxSizing: 'content-box',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                overflow: 'hidden',
                              }}
                            >
                              {selectedImage === null && firstImgActive ? (
                                <Box
                                  width={'100%'}
                                  height={'100%'}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignContent={'center'}
                                >
                                  No hay imágenes seleccionadas
                                </Box>
                              ) : (
                                <img
                                  src={
                                    !firstImgActive && !firstImgActive && activeImg?.firstImg !== undefined
                                      ? imagesSilosat[activeImg.firstImg].image_url
                                      : firstImgActive
                                      ? firstImg?.image_url
                                      : secondImg?.image_url
                                  }
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '60vh',
                                    aspectRatio: '1/1',
                                    borderRadius: '15px',
                                    margin: '5px',
                                    boxSizing: 'border-box',
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} sx={ImagesContainer}>
                          <Box
                            sx={
                              secondImgActive
                                ? { ...commonBoxStyles }
                                : { ...commonBoxStyles, border: 'none' }
                            }
                          >
                            <Box sx={SiloSatImagesData}>
                              <Typography
                                variant="overline"
                                sx={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {' '}
                                {activeImg &&
                                  activeImg.secondImg !== undefined &&
                                  satViewData &&
                                  satViewData[activeImg.secondImg] &&
                                  formatDateTime(satViewData[activeImg.secondImg].timestamp || '', 3)}
                              </Typography>

                              <Box display="flex" alignItems="center">
                                <Typography variant="overline" sx={{ textTransform: 'none' }}>
                                  {i18n.t('app.silobag_data.existence_silosat')}
                                  {'  '}
                                </Typography>
                                <Typography
                                  variant="overline"
                                  sx={{
                                    ...BoxSiloSatStatus,
                                    color:
                                      activeImg?.secondImg !== undefined &&
                                      VISUALIZA?.[satViewData?.[activeImg.secondImg]?.status_id]?.id === 1
                                        ? '#6BAA00'
                                        : '#FF0000',
                                  }}
                                >
                                  {activeImg?.secondImg !== undefined && (
                                    <>
                                      <Chip
                                        icon={
                                          satViewData?.[activeImg.secondImg]?.status_id === 1 ? (
                                            <img src={EyeIcon} style={{ width: '16px', height: '16px' }} />
                                          ) : satViewData?.[activeImg.secondImg]?.status_id === 2 ? (
                                            <img src={RedEyeIcon} style={{ width: '16px', height: '16px' }} />
                                          ) : (
                                            <img
                                              src={discardedIcon}
                                              style={{ width: '16px', height: '16px' }}
                                            />
                                          )
                                        }
                                        label={
                                          VISUALIZA?.[satViewData?.[activeImg.secondImg]?.status_id]?.text ??
                                          '-'
                                        }
                                        sx={{
                                          ...secondImgStyles,
                                          fontSize: '11px',
                                          fontWeight: 'bold',
                                          marginLeft: '5px',
                                        }}
                                      />
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                ...BoxSiloSatImages,
                                boxSizing: 'content-box',
                                maxHeight: '100%',
                                maxWidth: '%',
                                overflow: 'auto',
                              }}
                            >
                              {selectedImage === null && secondImgActive ? (
                                <Box
                                  width={'100%'}
                                  height={'100%'}
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                >
                                  {i18n.t('app.silobag_data.existence_images')}
                                  {'  '}
                                </Box>
                              ) : (
                                <img
                                  src={
                                    !secondImgActive && activeImg?.secondImg !== undefined
                                      ? imagesSilosat[activeImg.secondImg].image_url
                                      : secondImgActive
                                      ? secondImg?.image_url
                                      : firstImg?.image_url
                                  }
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '60vh',
                                    aspectRatio: '1/1',
                                    borderRadius: '15px',
                                    margin: '5px',
                                    boxSizing: 'border-box',
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    {matches && (
                      <Divider
                        orientation="vertical"
                        flexItem
                        style={{ height: 'auto', marginLeft: '1%', color: 'black' }}
                      />
                    )}
                    <Grid
                      item
                      lg={5.9}
                      md={12}
                      sm={12}
                      sx={{
                        '@media (min-width:0px)': { overflow: 'auto' },
                        '@media (min-width:600px)': { overflow: 'auto' },
                        '@media (min-width:1200px)': { overflow: 'auto' },
                        '@media (min-width:1536px)': { overflow: 'hidden' },
                      }}
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Grid container alignItems="center">
                            <Typography variant="h6" marginBottom={0}>
                              {i18n.t('app.silobag_data.election_images')}
                            </Typography>
                            <Tooltip
                              title={
                                <div>
                                  <Typography variant="body2" fontWeight="bold">
                                    {i18n.t('app.silobag_data.calendar_functionality')}
                                  </Typography>
                                  <ul style={{ paddingLeft: '16px', margin: 0 }}>
                                    <li>
                                      <Typography variant="body2">
                                        {i18n.t('app.silobag_data.red')}
                                      </Typography>
                                    </li>
                                    <li>
                                      <Typography variant="body2">
                                        {i18n.t('app.silobag_data.green')}
                                      </Typography>
                                    </li>
                                    <li>
                                      <Typography variant="body2">
                                        {i18n.t('app.silobag_data.white_gray_border')}
                                      </Typography>
                                    </li>
                                    <li>
                                      <Typography variant="body2">
                                        {i18n.t('app.silobag_data.no_icon_days')}
                                      </Typography>
                                    </li>
                                  </ul>
                                </div>
                              }
                              arrow
                            >
                              <img
                                src={InfoIcon}
                                alt="info"
                                style={{
                                  marginLeft: '8px',
                                  cursor: 'pointer',
                                  width: '24px',
                                  height: '24px',
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Box
                            style={
                              activeImg.firstImg === index || activeImg.secondImg === index
                                ? { backgroundColor: '#f3f2f7', padding: '4px', borderRadius: '5px' }
                                : { padding: '4px' }
                            }
                          >
                            <Stack direction={'row'}>
                              <Box
                                sx={BoxSiloSatStatus}
                                style={
                                  firstImgActive
                                    ? {
                                        backgroundColor: '#6BAA00',
                                        borderTopLeftRadius: '5px',
                                        borderBottomLeftRadius: '5px',
                                      }
                                    : {
                                        backgroundColor: '#C5BFBF',
                                        borderTopLeftRadius: '5px',
                                        borderBottomLeftRadius: '5px',
                                      }
                                }
                                onClick={handleFirstImgClick}
                              >
                                <img
                                  style={{ cursor: 'pointer', margin: '5px', width: '15px' }}
                                  src={SplitLeft}
                                />
                              </Box>
                              <Box
                                sx={BoxSiloSatStatus}
                                style={
                                  secondImgActive
                                    ? {
                                        backgroundColor: '#6BAA00',
                                        borderTopRightRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                      }
                                    : {
                                        backgroundColor: '#C5BFBF',
                                        borderTopRightRadius: '5px',
                                        borderBottomRightRadius: '5px',
                                      }
                                }
                                onClick={handleSecondImgClick}
                              >
                                <img
                                  style={{ cursor: 'pointer', margin: '5px', width: '15px' }}
                                  src={SplitRight}
                                />
                              </Box>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                          <DateRangeCalendar
                            defaultValue={initialValue}
                            loading={isLoading}
                            renderLoading={() => <DayCalendarSkeleton />}
                            currentMonthCalendarPosition={2}
                            slots={{
                              day: (props, slotProps) => (
                                <ServerDay {...props} {...slotProps} setSelectedImage={setSelectedImage} />
                              ),
                            }}
                            slotProps={{
                              day: {
                                highlightedDays,
                                highdarkedDays,
                                highcloudDays,
                                notShowDays,
                                sortedImages,
                              } as ServerDayProps,
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}

import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { OrgAlertListPayload, OrgAlertListRespose } from './org-alert-list.types';

export async function listOrgAlertService({ query: rawQuery }: OrgAlertListPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<OrgAlertListRespose>(ENDPOINT.ALERT_GROUP_ACTIONS, { query });

  return {
    response,
    summary: data,
  };
}

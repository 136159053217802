import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AlborLogo from '@assets/logos/albor.png';
import PlusIcon from '@assets/svg/administrators/plus.svg';
import { CountrysideViewEditModal } from './countryside-view-edit-modal';
import { useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import { useEffect, useState } from 'react';
import { DeleteModal } from '../modal-deletion/delete-modal';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../libs/language';
import { ToastAction } from '@ui/toast/toast-action';
import viewIcon from '@assets/svg/core/eye-hollow-blue.svg';
import editIcon from '@assets/svg/core/edit-square-hollow-blue.svg';
import shareIcon from '@assets/svg/core/share-hollow-blue.svg';
import CountrysideShareModal from './countryside-share-modal';
import DeleteActionIcon from '@assets/svg/core/trash-red.svg';
import { useEffectOnUpdate } from '@hooks/core';
import { listAlborCountrysidesService } from '@services/integration/albor/albor-countryside-list';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { deleteCountrysideService } from '@services/domain/base/countryside/countryside-deletion';

type SummaryCountryside = Awaited<ReturnType<typeof listCountrysideService>>['summary'][0];

type CountrysideListProps = {
  integrationModalClosed: boolean;
  setActionInCountryside: (value: boolean) => void;
  setActionInCompany: (value: boolean) => void;
  actionInCompany: boolean;
};
export function AdminCountrysideList(props: CountrysideListProps) {
  const { integrationModalClosed, setActionInCountryside, setActionInCompany, actionInCompany } = props;
  const { userOrganizationId } = useGlobalStore();
  const queryCountrysideListService = { organization_id: userOrganizationId };
  const { trigger, isLoading, summary } = useService(listCountrysideService, {
    fetchOnMount: [{ query: queryCountrysideListService }],
    onSuccess: () => {
      setActionInCompany(false);
    },
  });

  const { trigger: alborListData, summary: AlborData } = useService(listAlborCountrysidesService, {
    fetchOnMount: true,
  });

  const reFetchCountrysideListData = () => trigger({ query: queryCountrysideListService });
  const reFetchCountrysideListDataAlbor = () => alborListData();

  useEffectOnUpdate(() => {
    if (actionInCompany) {
      trigger({ query: queryCountrysideListService });
    }
  }, [actionInCompany]);

  const [openCountrysideModal, toggleOpenCountrysideModal] = useToggle(false);
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle(false);
  const [openDeleteSnackbar, toggleDeleteSnackbar] = useToggle(false);
  const [openCountrysideShareModal, toggleOpenCountrysideShareModal] = useToggle(false);

  const handleCountrysideModalState = (value: boolean) => toggleOpenCountrysideModal(value);
  const handleDeleteModalState = (value: boolean) => toggleOpenDeleteModal(value);

  useEffect(() => {
    setTimeout(() => {
      toggleDeleteSnackbar(false);
    }, 3000);
  }, [openDeleteSnackbar]);

  const [modalType, setModalType] = useState<'new' | 'view' | 'edit'>('new');
  const enableEditMode = () => setModalType('edit');

  const [targetData, setTargetData] = useState<SummaryCountryside>();

  const [newCountrysideMsg, toggleNewCountrysideMsg] = useToggle(false);

  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.down('lg'));

  const configButtonStyles = {
    color: '#FFF',
    fontSize: 12,
    borderRadius: 100,
    textTransform: 'none',
  };

  const [openSnackbar, setOpenSnackbar] = useToggle(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const alborIds = AlborData?.map((data) => data.iof_countryside_id);

  // Este useEffect reestablece el valor de toggleNewCountrysideMsg para que se reutilice
  useEffect(() => {
    setTimeout(() => {
      toggleNewCountrysideMsg(false);
    }, 3000);
  }, [newCountrysideMsg]);

  useEffect(() => {
    if (integrationModalClosed) {
      reFetchCountrysideListData();
      reFetchCountrysideListDataAlbor();
    }
  }, [integrationModalClosed]);
  return (
    <>
      {openSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" variant="filled" sx={{ width: '100%' }}>
            {i18n.t('app.administrator.not_valid_action')}
          </Alert>
        </Snackbar>
      )}
      {openDeleteSnackbar && (
        <ToastAction message={i18n.t('app.administrator.deleted_countryside')} severity="success" />
      )}
      {newCountrysideMsg && (
        <ToastAction
          message={
            modalType === 'new'
              ? i18n.t('app.administrator.new_countryside_toast')
              : i18n.t('app.administrator.edit_countryside_toast')
          }
          severity="success"
        />
      )}
      <Paper
        sx={{
          borderRadius: 4,
          padding: 2,
          height: 'min-content',
          gridColumn: matchMD ? '' : '1/3',
          gridRow: matchMD ? '' : '3/5',
        }}
        elevation={0}
      >
        <Grid
          item
          xs={10}
          sx={{ display: 'flex', gap: 3, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
        >
          <Box sx={{ fontWeight: '600', fontSize: matchSM ? '18px' : '24px' }}>
            {i18n.t('app.countrysides')}
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              disableElevation
              startIcon={<img src={PlusIcon} alt="plus icon" />}
              sx={configButtonStyles}
              onClick={() => {
                setModalType('new');
                toggleOpenCountrysideModal(true);
              }}
            >
              {i18n.t('app.administrator.new_countryside')}
            </Button>
          </Box>
        </Grid>
        {!isLoading ? (
          <Grid item>
            <TableContainer
              style={{ border: '1px solid #e0e0e0', borderRadius: 5, maxHeight: 500, overflowY: 'auto' }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align={matchSM ? 'center' : 'left'}>{i18n.t('app.name')}</TableCell>
                    <TableCell sx={{ display: matchSM ? 'none' : '' }}>{i18n.t('app.companies')}</TableCell>
                    <TableCell sx={{ display: matchSM ? 'none' : '' }}>{i18n.t('app.state')}</TableCell>
                    <TableCell align="center">{i18n.t('app.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summary?.map((countryside) => (
                    <TableRow key={countryside.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align={matchSM ? 'center' : 'left'}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {countryside.name}
                          {alborIds?.includes(countryside.id) && (
                            <Tooltip title={i18n.t('app.integration_modal.albor_countrysides')}>
                              <img
                                src={AlborLogo}
                                alt="logo"
                                style={{ height: '30px', width: '30px', marginLeft: '5px' }}
                              />
                            </Tooltip>
                          )}
                          {countryside.lat === '-34.5781' && countryside.lon === '-58.4265' && (
                            <Tooltip title={i18n.t('app.integration_modal.countryside_warning')}>
                              <span>⚠️</span>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell sx={{ display: matchSM ? 'none' : '' }}>
                        {countryside.companies?.name}
                      </TableCell>
                      <TableCell sx={{ display: matchSM ? 'none' : '' }}>
                        {countryside.countries_l2?.country_l1_name}
                      </TableCell>
                      <TableCell align="center">
                        <Stack direction="row" spacing={1.5} flexWrap="nowrap" justifyContent="center">
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => {
                              setModalType('view');
                              setTargetData(countryside);
                              toggleOpenCountrysideModal(true);
                            }}
                          >
                            <img style={{ width: 16, height: 16 }} src={viewIcon} />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => {
                              setModalType('edit');
                              setTargetData(countryside);
                              toggleOpenCountrysideModal(true);
                            }}
                          >
                            <img style={{ width: 16, height: 16 }} src={editIcon} />
                          </IconButton>
                          <IconButton style={{ width: 16, height: 16 }}>
                            <img
                              style={{ width: 16, height: 16 }}
                              src={shareIcon}
                              onClick={() => {
                                setTargetData(countryside);
                                toggleOpenCountrysideShareModal(true);
                              }}
                            />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => (setTargetData(countryside), toggleOpenDeleteModal(true))}
                          >
                            <img style={{ width: 16, height: 16 }} src={DeleteActionIcon} />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Paper>
      {openCountrysideModal && (
        <CountrysideViewEditModal
          open={openCountrysideModal}
          type={modalType as 'view'}
          parentFormData={targetData!}
          onSuccessTrigger={reFetchCountrysideListData}
          enableEditMode={enableEditMode}
          setIsOpen={handleCountrysideModalState}
          toggleNewCountrysideMsg={toggleNewCountrysideMsg}
          setActionInCountryside={setActionInCountryside}
        />
      )}
      {openCountrysideShareModal && targetData && (
        <CountrysideShareModal
          open={openCountrysideShareModal}
          setOpen={toggleOpenCountrysideShareModal}
          countryside={targetData}
        />
      )}

      <DeleteModal
        toggleDeleteSnackbar={toggleDeleteSnackbar}
        open={openDeleteModal}
        setIsOpen={handleDeleteModalState}
        setIsError={setOpenSnackbar}
        targetTextRef="el establecimiento"
        targetName={targetData?.name ?? ''}
        targetID={targetData?.id ?? ''}
        service={deleteCountrysideService}
        onSuccessTrigger={reFetchCountrysideListData}
        setAction={setActionInCountryside}
      />
    </>
  );
}

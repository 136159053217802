import { Box, Paper } from '@mui/material';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import { getReportPowerbiService } from '@services/domain/powerbi/get-report-powerbi';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Embed, models } from 'powerbi-client';
import { useState } from 'react';
import i18n from '../../libs/language';

export function ReportPositionGeneral() {
  const { summary } = useService(getReportPowerbiService, { fetchOnMount: true });

  const [report, setReport] = useState<Embed | null>(null);

  useEffectOnUpdate(() => report?.reload(), [report]);

  return (
    <Box style={{ width: '95%', margin: 'auto' }}>
      <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
        {i18n.t('app.report_position_general.title')}
      </Box>
      <Paper
        elevation={0}
        sx={{
          textAlign: 'center',
          flex: 1,
        }}
      >
        {summary?.response ? (
          <PowerBIEmbed
            cssClassName="report-container"
            embedConfig={{
              type: summary?.response.type,
              id: summary?.response.id,
              accessToken: summary?.response.accessToken,
              tokenType: models?.TokenType.Embed,
              filters: [
                {
                  $schema: summary.response.filter?.schema,
                  target: {
                    table: summary.response.filter?.target.table,
                    column: summary.response.filter?.target.column,
                  },
                  operator: 'In',
                  values: summary.response.filter?.values,
                  requireSingleSelection: true,
                  filterType: models.FilterType.Basic,
                },
              ],
              settings: {
                panes: {
                  filters: { visible: false },
                },
              },
            }}
            getEmbeddedComponent={(embeddedReport) => setReport(embeddedReport)}
          />
        ) : null}
      </Paper>
    </Box>
  );
}

import {
  Grid,
  FormControl,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Button,
  Chip,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { stylesInput } from '@styles/generic-styles';
import { SilobagListTablet } from './silobag-tablet';
import { useService } from '@hooks/use-service';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { useDebounce, useEffectOnUpdate, useToggle } from '@hooks/core';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';
import { listLotsService } from '@services/domain/base/lot/lot-list';
import { productListService } from '@services/domain/base/silobag/list/product-list';
import { harvestListService } from '@services/domain/base/silobag/list/harvest-list';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';
import { useEffect, useState, useRef } from 'react';
import SingleInputDateRangePicker from '@ui/date-range/date-range';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../libs/language';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@assets/svg/search.svg';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import es from 'dayjs/locale/es';
import ExpandMore from '@assets/svg/expand-more-blue.svg';
import { Selector } from '@ui/selector/selector';
import { Dayjs } from 'dayjs';

type AlertLevelProp = {
  id: number;
  name: string;
  color: 'error' | 'warning' | 'secondary' | 'primary';
};

const alertLevel: AlertLevelProp[] = [
  { id: 2, name: i18n.t('app.silobag_data.movement'), color: 'error' },
  { id: 4, name: i18n.t('app.silobag_data.quality_serious'), color: 'warning' },
  { id: 3, name: i18n.t('app.silobag_data.quality_min'), color: 'secondary' },
  { id: 1, name: i18n.t('app.silobag_data.optimun'), color: 'primary' },
];

const siloSatSelected = [{ id: 1, name: i18n.t('app.silobags_filters.only_actives'), value: true }];
const siloMetrixSelected = [{ id: 1, name: i18n.t('app.silobags_filters.only_actives'), value: true }];

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];
type queryParametersSbList = Parameters<typeof listSilobagService>[0]['query'];
type SummaryCountrysidesList = Awaited<ReturnType<typeof listCountrysideService>>['summary'];
type SummarySpeciesList = Awaited<ReturnType<typeof speciesListService>>['summary'];

type SiloHeaderData = {
  totalRemainingStock: number;
  silobagQty: number;
  remainingStock: number;
  web_silobags_stock: number;
  filteredInputWeight: number;
  filteredExtractedWeight: number;
  filteredRemainingStock: number;
  totalFilteredSilobags: number;
};

type SilobagFilterProps = {
  updateHeaderData: (newState: SiloHeaderData) => void;
  handleGetSiloListFilter: (newState: SummarySilobagList | undefined) => void;
  handleGetParamsFilter: (newState: string | string[] | Record<string, string>) => void;
  handleGetSiloListAll: (newState: SummarySilobagList | undefined) => void;
  extractionSuccess?: boolean;
  silobagSelected?: (ids: string[]) => void;
  silobagsSelected: (silobags: SummarySilobagList) => void;
  disableFilterExportBtn?: (val: boolean) => void;
  setSiloListAll: (val: SummarySilobagList) => void;
  isSuccess: boolean;
  isSuccessClose: boolean;
  isMassiveSuccess: boolean;
  setIsNonValidatedSBList: (val: boolean) => void;
  setIsCloseList: (val: boolean) => void;
  isCloseList: boolean;
  IsSilobagValidated: boolean;
};

const locales = { es: es };
type LocaleKey = keyof typeof locales;

export function SilobagListFilters({
  updateHeaderData,
  handleGetSiloListFilter,
  handleGetSiloListAll,
  handleGetParamsFilter,
  isSuccess,
  isSuccessClose,
  isMassiveSuccess,
  extractionSuccess,
  silobagsSelected,
  disableFilterExportBtn,
  setSiloListAll,
  setIsNonValidatedSBList,
  setIsCloseList,
  isCloseList,
  IsSilobagValidated,
}: SilobagFilterProps) {
  const { organizationId, filters, setFilters, filteredUrl, setFilteredUrl } = useGlobalStore();

  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState<boolean>(filters.active);
  const [nonValidatedSb, setNonValidatedSb] = useState<number>(filters.silobag_data_status_id as number);
  const [saveSiloFirstResponse, setSaveSiloFirstResponse] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null] | string>([null, null]);
  const [initRange, endRange] = dateRange;
  const initDate = initRange ? (initRange as Dayjs).format('YY-MM-DD') : null;
  const endDate = endRange ? (endRange as Dayjs).format('YY-MM-DD') : null;
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const locale: LocaleKey = 'es';
  const [selectedFilterNames, setSelectedFilterNames] = useState<Record<string, string>>({});
  const [silobagSelected, setSilobagSelected] = useState<SummarySilobagList>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [countrysidesList, setCountrysidesList] = useState<SummaryCountrysidesList>([]);
  const [speciesList, setSpeciesList] = useState<SummarySpeciesList>([]);
  const [sortingModel, setSortingModel] = useState<string>('');
  const [expandAdvancedFilters, setExpandAdvancedFilters] = useToggle(false);
  const handleShowAdvanceFilters = () => {
    setExpandAdvancedFilters((prev: boolean) => !prev); // Alterna entre expandido/colapsado
  };

  const getUrlWithFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('range');
    searchParams.delete('active');
    searchParams.delete('paginationModel');
    return `${searchParams ? `${searchParams.toString()}` : ''}`;
  };
  // Obtener la URL completa con los filtros aplicados y almacenarla en el estado
  useEffect(() => {
    const urlWithFilters = getUrlWithFilters();
    setFilteredUrl(urlWithFilters);
  }, [location.pathname, location.search, organizationId]);

  const updateURLWithFilters = () => {
    const queryParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== '' && key !== 'range' && key !== 'paginationModel') {
        queryParams.append(key, value as string);
      }
    });
    navigate(`?${queryParams.toString()}`);
  };

  function parseSortingModel(sortingModel: string) {
    const params: Record<string, string> = {};
    const byMatch = sortingModel.match(/orderBy=([^&]+)/);
    const dirMatch = sortingModel.match(/orderByDirections=([^&]+)/);
    if (byMatch) params.orderBy = byMatch[1];
    if (dirMatch) params.orderByDirections = dirMatch[1];
    return params;
  }
  
  useEffect(() => {
    updateURLWithFilters();
  }, [filters]);

  //Query Params silobag Service
  const { paginationModel, ...filtersWithoutPagination } = filters;
  const queryParamsSilobagService: queryParametersSbList = {
    ...filtersWithoutPagination,
    organization_id: organizationId,
    active: active,
    silobag_data_status_id: nonValidatedSb,
    take: paginationModel?.pageSize ?? 50,
    page: paginationModel?.page ?? 0,
    ...filtersWithoutPagination,
    ...parseSortingModel(sortingModel),
  };
  //Silobags Service
  const {
    trigger: getSiloData,
    summary: dataSilo,
    isLoading: isLoadingSiloData,
  } = useService(listSilobagService, {
    fetchOnMount: [{ query: queryParamsSilobagService }],
  });

  useDebounce(
    () => {
      getSiloData({ query: queryParamsSilobagService });
      if (filteredUrl.length) setHasFilters(true);
    },
    250,
    [organizationId, filteredUrl, active, filters, sortingModel, nonValidatedSb]
  );

  const silobagList = dataSilo?.data;

  //Countrysides Service
  const queryCountrysidesList = { organization_id: organizationId };
  const {
    trigger: getCountrysides,
    summary: countrysides,
    isLoading: isLoadingCountryside,
  } = useService(listCountrysideService, { fetchOnMount: [{ query: queryCountrysidesList }] });

  //Lots Service
  const queryLotListService = { id: organizationId };
  const {
    trigger: getLots,
    summary: lots,
    isLoading: isLoadingLots,
  } = useService(listLotsService, { fetchOnMount: [{ query: queryLotListService }] });

  // Species Service
  const { summary: species, isLoading: isLoadingSpecies } = useService(speciesListService, {
    fetchOnMount: true,
  });

  // Products Service
  const { summary: products, isLoading: isLoadingProducts } = useService(productListService, {
    fetchOnMount: true,
  });

  // Harvests Service
  const { summary: harvests, isLoading: isLoadingHarvest } = useService(harvestListService, {
    fetchOnMount: true,
  });

  useEffectOnUpdate(() => {
    getCountrysides({ query: queryCountrysidesList });
    getLots({ query: queryLotListService });
  }, [organizationId]);

  useEffectOnUpdate(() => {
    setCountrysidesList(countrysides ?? []);
  }, [countrysides]);

  useEffectOnUpdate(() => {
    setSpeciesList(species ?? []);
  }, [species]);

  useEffect(() => {
    const saveFirstResponse = async () => {
      if (!saveSiloFirstResponse && dataSilo) {
        if (dataSilo.data.length > 0) {
          handleGetSiloListAll(dataSilo.data);
          //TODO: para que se usa ese handle aca ??
          //handleGetParamsFilter(dataSilo.data);
          setSaveSiloFirstResponse(true);
        }
      }
    };

    saveFirstResponse();
  }, [saveSiloFirstResponse, dataSilo]);

  useEffect(() => {
    disableFilterExportBtn?.(!hasFilters);
  }, [hasFilters]);

  //Massive Success
  useEffect(() => {
    if (isSuccess) {
      getSiloData({ query: queryParamsSilobagService });
      setHasFilters(true);
    }
  }, [isSuccess, organizationId, filteredUrl, active, sortingModel, filters]);

  //Extraction Success
  useEffectOnUpdate(() => {
    if (extractionSuccess) {
      getSiloData({ query: queryParamsSilobagService });
      setHasFilters(true);
    }
  }, [extractionSuccess]);

  // Update Silobags and remaining stock
  useEffectOnUpdate(() => {
    updateHeaderData({
      totalRemainingStock: dataSilo?.totalRemainingStock as number,
      silobagQty: dataSilo?.totalFilteredSilobags as number,
      remainingStock: dataSilo?.filteredRemainingStock as number,
      web_silobags_stock: dataSilo?.web_silobags_stock as number,
      totalFilteredSilobags: dataSilo?.totalFilteredSilobags as number,
      filteredInputWeight: dataSilo?.filteredInputWeight as number,
      filteredExtractedWeight: dataSilo?.filteredExtractedWeight as number,
      filteredRemainingStock: dataSilo?.filteredRemainingStock as number,
    });
    handleGetSiloListFilter(silobagList);
  }, [dataSilo]);

  // Handlers Select
  const updateNameFilter = (value: string) => {
    setFilters({ ...filters, name: value });
    setHasFilters(true);
    setSelectedFilterNames((prevNames) => ({ ...prevNames, name: value }));
    handleGetParamsFilter(Object.values(selectedFilterNames));
  };

  const handlerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      updateNameFilter(latestSelectedValue);
    }, 500);
    latestSelectedValue = selectedValue;
  };
  let typingTimer: NodeJS.Timeout;
  let latestSelectedValue: string = '';

  const handlerSilobagProductTypeID = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, silobag_product_type_id: selectedValue as string });
    setHasFilters(true);

    const selectedProductName = products?.find((item: { id: number | string }) => item.id === selectedValue)
      ?.name;
    if (selectedProductName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, product: selectedProductName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
    }
  };

  const handlerSilobagHarvestID = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, silobag_harvest_id: selectedValue as string });
    setHasFilters(true);

    const selectedHarvestName = harvests?.find((item: { id: number | string }) => item.id === selectedValue)
      ?.name;
    if (selectedHarvestName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, harvest: selectedHarvestName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
    }
  };

  const handlerSilobagSpeciesTypeID = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as number[];

    const selectedSpecies = speciesList.filter((item) => selectedValue.includes(item.id));

    const selectedSpeciesName = selectedSpecies.map((item) => item.name);
    setSelectedFilterNames((prevNames) => ({
      ...prevNames,
      specie: selectedSpeciesName.join(', ') ?? '',
    }));

    setFilters({ ...filters, silobag_species_type_id: selectedValue });
    setHasFilters(true);
  };

  const handlerCountrysideID = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value as number[];

    const selectedCountrysides = countrysidesList.filter((item) => selectedValue.includes(item.id));

    const selectedCountrysideName = selectedCountrysides.map((item) => item.name);
    setSelectedFilterNames((prevNames) => ({
      ...prevNames,
      countryside: selectedCountrysideName.join(', ') ?? '',
    }));

    setFilters({ ...filters, countryside_id: selectedValue });
    setHasFilters(true);
  };

  const handlerSiloSatValue = (e: SelectChangeEvent<unknown>) => {
    const selectedValues = e.target.value;

    const selectedSiloSat = siloSatSelected.find((item) => selectedValues === item.id);

    const selectedProductNames = selectedSiloSat?.name;

    setSelectedFilterNames((prevNames) => ({ ...prevNames, siloSatActive: selectedProductNames as string }));

    setFilters({ ...filters, sat_view_active: selectedSiloSat?.value as boolean });
  };

  const handlerSiloMetrixValue = (e: SelectChangeEvent<unknown>) => {
    const selectedValues = e.target.value;

    const selectedSiloMetrix = siloMetrixSelected.find((item) => selectedValues === item.id);

    const selectedProductNames = selectedSiloMetrix?.name;

    setSelectedFilterNames((prevNames) => ({
      ...prevNames,
      siloMetrixActive: selectedProductNames as string,
    }));

    setFilters({ ...filters, is_silo_metrix_active: selectedSiloMetrix?.value as boolean });
  };

  // TODO: REVISAR ESTE HANDLER CUANDO SE UTILICE CORVUS
  const handlerCorvusID = () => {};

  const handleAlertLevel = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, color_id: selectedValue as string });
    setHasFilters(true);

    const selectedAlertLevelName = alertLevel.find(
      (item: { id: number | string }) => item.id === selectedValue
    )?.name;
    if (selectedAlertLevelName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, alertLevel: selectedAlertLevelName }));
      handleGetParamsFilter(selectedAlertLevelName);
    }
  };

  const handleClickSiloClose = () => {
    setActive((prevActive) => !prevActive);
    setFilters({ ...filters, active: !active });
    setHasFilters(true);
    setIsCloseList(!isCloseList);
  };

  const handleClickNonValidatedSilobags = () => {
    const currentNonValidatedSb = filters.silobag_data_status_id;
    const newValue = currentNonValidatedSb === 2 ? 1 : 2;

    setNonValidatedSb(newValue);
    setFilters({ ...filters, silobag_data_status_id: newValue });
    setHasFilters(true);
  };

  useEffect(() => {
    if (filters.silobag_data_status_id === 1) {
      setIsNonValidatedSBList(true);

    } else {
      setIsNonValidatedSBList(false);
    }
  }, [filters]);

  useEffect(() => {
    if (isSuccessClose) {
      setFilters({ ...filters, active: false, silobag_data_status_id: IsSilobagValidated ? 1 : 2, });
      setActive(false);
      setHasFilters(true);
    }
  }, [isSuccessClose,IsSilobagValidated]);

  useEffectOnUpdate(() => {
    if (isMassiveSuccess) {
      setFilters({ ...filters, active: false });
      setActive(false);
      setHasFilters(true);
    }
  }, [isMassiveSuccess]);

  useEffect(() => {
    if (initDate !== null) {
      setFilters({ ...filters, from: initDate, range: dateRange as string });
      setSelectedFilterNames((prevNames) => ({ ...prevNames, initDate: `From: ${initDate}` }));
      setHasFilters(true);
    }
    if (endDate !== null) {
      setFilters({ ...filters, to: endDate, range: dateRange as string });
      setSelectedFilterNames((prevNames) => ({ ...prevNames, endDate: `To: ${endDate}` }));
      setHasFilters(true);
    }
  }, [initDate, endDate]);

  const handleLotsId = (e: SelectChangeEvent<unknown>) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, lot_id: selectedValue as string });
    // TODO: Rearmar esto -> filters.countryside_id ya no es string, es un Arreglo
    const selectedLotName = (filters.countryside_id.length > 0 ? lotByCountrySideId : lots)?.find(
      (item: { id: number | string }) => item.id === selectedValue
    )?.name;
    if (selectedLotName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, lot: selectedLotName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
      setHasFilters(true);
    }
  };

  useEffect(() => {
    handleGetParamsFilter(selectedFilterNames);
  }, [selectedFilterNames]);

  const removeFilter = () => {
    setFilters({
      countryside_id: [],
      lot_id: '',
      silobag_species_type_id: [],
      silobag_product_type_id: '',
      sat_view_active: '',
      is_silo_metrix_active: '',
      silobag_harvest_id: '',
      color_id: '',
      name: '',
      from: '',
      to: '',
      range: '',
      silobag_data_status_id: 2,
      active: true,
      paginationModel: { page: 0, pageSize: 50 },
    });
    setNonValidatedSb(2);
    setActive(true);
    setDateRange([null, null]);
    setSelectedFilterNames({});
    setHasFilters(false);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setIsCloseList(false);
  };

  useEffect(() => {
    silobagsSelected(silobagSelected);
  }, [silobagSelected]);

  useEffectOnUpdate(removeFilter, [organizationId]);

  const lotByCountrySideId =
    lots && lots.length > 0
      ? lots.filter((item) => filters.countryside_id.includes(item.countryside_id))
      : silobagList?.filter((item) => filters.countryside_id.includes(item.lots.countrysides.id));

  const hasNonEmptyFilters = (filters: Record<string, string>): boolean => {
    return Object.values(filters).some((value) => value !== '');
  };

  // TODO: Chequear si se puede simplificar estos delete en 2/3 funciones y usar el mismo delete para los filtros
  const deleteCountrysideFilter = () => {
    setSelectedFilterNames((prevNames) => ({
      ...prevNames,
      countryside: '',
    }));
    setFilters({ ...filters, countryside_id: [] });
  };

  const deleteSpeciesFilter = () => {
    setSelectedFilterNames((prevNames) => ({
      ...prevNames,
      specie: '',
    }));
    setFilters({ ...filters, silobag_species_type_id: [] });
  };

  const deleteAlertLevelFilter = () => {
    setFilters({ ...filters, color_id: '' });
    setSelectedFilterNames((prevNames) => ({ ...prevNames, alertLevel: '' }));
  };

  const deleteLotsFilter = () => {
    setFilters({ ...filters, lot_id: '' });
    setSelectedFilterNames((prevNames) => ({ ...prevNames, lot: '' }));
  };

  const deleteProductFilter = () => {
    setFilters({ ...filters, silobag_product_type_id: '' });
    setSelectedFilterNames((prevNames) => ({ ...prevNames, product: '' }));
  };

  const deleteHarvestFilter = () => {
    setFilters({ ...filters, silobag_harvest_id: '' });
    setSelectedFilterNames((prevNames) => ({ ...prevNames, harvest: '' }));
  };

  const deleteSiloSatFilter = () => {
    setSelectedFilterNames((prevNames) => ({ ...prevNames, siloSatActive: '' }));
    setFilters({ ...filters, sat_view_active: '' });
  };

  const deleteSiloMetrixFilter = () => {
    setSelectedFilterNames((prevNames) => ({ ...prevNames, siloMetrixActive: '' }));
    setFilters({ ...filters, is_silo_metrix_active: '' });
  };
  const deleteCorvusFilter = () => {};

  return (
    <>
      {isLoadingCountryside &&
      isLoadingLots &&
      isLoadingSpecies &&
      isLoadingProducts &&
      isLoadingHarvest &&
      isLoadingSiloData ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress sx={{ marginLeft: '10px', color: 'green' }} size={24} />
        </div>
      ) : (
        <>
          <Grid container style={{ marginTop: '20px', alignItems: 'center' }} spacing={{ xs: 1, md: 1 }}>
            <Grid item xs={12} md={2}>
              <FormControl style={{ width: '98%', fontSize: 14 }}>
                <TextField
                  size="medium"
                  variant="outlined"
                  placeholder={i18n.t('app.silobag_list.search_by_name_id')}
                  style={stylesInput}
                  onChange={handlerName}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt="search-icon" />
                      </InputAdornment>
                    ),
                    inputProps: { style: { fontSize: 14 } },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Selector
                label={i18n.t('app.silobag_data.specie')}
                id="silobag_species_type_id"
                value={filters?.silobag_species_type_id}
                onChange={(e) => handlerSilobagSpeciesTypeID(e)}
                multiple
                options={species}
                // renderValue={() => speciesID.map((item) => item.name).join(', ')}
                renderValue={() =>
                  species
                    ?.filter((specie) => filters?.silobag_species_type_id?.includes(specie.id))
                    .map((matchedSpecie) => matchedSpecie.name)
                    .join(', ')
                }
                selectedList={species?.filter(
                  (specie) => filters?.silobag_species_type_id?.includes(specie.id)
                )}
                deleteFilter={deleteSpeciesFilter}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Selector
                label={i18n.t('app.countrysides')}
                id="countryside_id"
                value={filters?.countryside_id?.map((item) => item)}
                onChange={(e) => handlerCountrysideID(e)}
                multiple
                options={countrysides}
                selectedList={countrysides?.filter(
                  (countryside) => filters?.countryside_id?.includes(countryside.id)
                )}
                renderValue={() =>
                  countrysides
                    ?.filter((countryside) => filters?.countryside_id?.includes(countryside.id))
                    .map((matchedCountryside) => matchedCountryside.name)
                    .join(', ')
                }
                deleteFilter={deleteCountrysideFilter}
                disabled={isLoadingCountryside}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Selector
                label={i18n.t('app.silobag_data.level_alert')}
                id="color_id"
                value={filters.color_id}
                onChange={(e) => handleAlertLevel(e)}
                options={alertLevel}
                // selectedList={filters.color_id}
                deleteFilter={deleteAlertLevelFilter}
                renderValue={(selectedId) => {
                  const selected = alertLevel.find((item) => item.id === selectedId);
                  return (
                    selected && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Chip
                          label=""
                          style={{
                            height: '0.7rem',
                            width: '1rem',
                            marginRight: '0.3rem',
                          }}
                          color={selected.color}
                        />
                        <span>{selected.name}</span>
                      </div>
                    )
                  );
                }}
                withChip
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Stack direction={'column'} spacing={-2}>
                <FormControlLabel
                  label={i18n.t('app.silobag_data.view_silo_close')}
                  control={<Checkbox {...label} color="success" checked={!filters.active} />}
                  labelPlacement="end"
                  onChange={handleClickSiloClose}
                />
                <FormControlLabel
                  label={i18n.t('app.silobag_data.view_non_validated_sb')}
                  control={
                    <Checkbox {...label} color="success" checked={filters.silobag_data_status_id === 1} />
                  }
                  labelPlacement="end"
                  onChange={handleClickNonValidatedSilobags}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={1.5}>
              <Stack direction={'column'} alignItems={'start'}>
                <Button
                  sx={{
                    fontWeight: 600,
                    textTransform: 'none',
                    display:
                      (hasFilters && hasNonEmptyFilters(selectedFilterNames)) || filteredUrl.length > 0
                        ? 'flex'
                        : 'none',
                    color: '#FF0000',
                  }}
                  onClick={removeFilter}
                  startIcon={<img src={DeleteIcon} alt="delete-icon" />}
                >
                  {i18n.t('app.silobag_data.delete_filters')}
                </Button>
                <Button
                  sx={{ color: '#22A7F0', textTransform: 'none' }}
                  endIcon={
                    <img
                      src={ExpandMore}
                      alt="expand-more"
                      style={{
                        transform: expandAdvancedFilters ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  }
                  variant="text"
                  onClick={handleShowAdvanceFilters}
                >
                  {i18n.t('app.silobag_data.advanced_filters')}
                </Button>
              </Stack>
            </Grid>
            <Stack
              direction={'row'}
              width={'100%'}
              sx={{
                paddingLeft: '5px',
                display: expandAdvancedFilters ? 'block' : 'none',
                maxHeight: expandAdvancedFilters ? '200px' : '0',
                transition: 'max-height 0.4s ease, transform 0.4s ease, opacity 0.4s ease',
                transform: expandAdvancedFilters ? 'translateY(0)' : 'translateY(-20px)',
                opacity: expandAdvancedFilters ? 1 : 0,
              }}
            >
              <Grid container style={{ marginTop: '5px', alignItems: 'center' }} spacing={{ xs: 1, md: 1 }}>
                <Grid item xs={12} md={2}>
                  <Selector
                    label={i18n.t('app.lots')}
                    id="lot_id"
                    value={filters.lot_id}
                    onChange={(e) => handleLotsId(e)}
                    options={filters.countryside_id.length > 0 ? lotByCountrySideId : lots}
                    selectedList={filters.lot_id}
                    deleteFilter={deleteLotsFilter}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    label={i18n.t('app.silobag_data.product')}
                    id="silobag_product_type_id"
                    value={filters.silobag_product_type_id}
                    onChange={(e) => handlerSilobagProductTypeID(e)}
                    options={products}
                    selectedList={filters.silobag_product_type_id}
                    deleteFilter={deleteProductFilter}
                  />
                </Grid>
                <Grid item xs={12} md={1.95}>
                  <FormControl variant="outlined" size="medium" sx={{ ...stylesInput }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                      <SingleInputDateRangePicker
                        label={i18n.t('app.silobag_data.range_storage')}
                        name={i18n.t('app.silobag_data.range_storage')}
                        onChange={(newValue) => setDateRange(newValue)}
                        value={filters.range !== '' ? filters.range : dateRange}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    label={i18n.t('app.silobag_data.harvest')}
                    id="silobag_harvest_id"
                    value={filters.silobag_harvest_id}
                    onChange={(e) => handlerSilobagHarvestID(e)}
                    options={harvests}
                    selectedList={filters.silobag_harvest_id}
                    deleteFilter={deleteHarvestFilter}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    disabled
                    label="Corvus"
                    id="corvus"
                    value=""
                    onChange={handlerCorvusID}
                    options={harvests}
                    selectedList={[]}
                    deleteFilter={deleteCorvusFilter}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    disabled
                    label={i18n.t('app.silobag_data.inconsistency')}
                    id="inconsistencia"
                    value=""
                    onChange={handlerCorvusID}
                    options={harvests}
                    selectedList={[]}
                    deleteFilter={deleteCorvusFilter}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    label="SiloSat"
                    id="silo_sat"
                    value={filters.sat_view_active}
                    onChange={(e) => handlerSiloSatValue(e)}
                    options={siloSatSelected}
                    selectedList={filters.sat_view_active}
                    deleteFilter={deleteSiloSatFilter}
                    renderValue={() =>
                      siloSatSelected.find((item) => item.value === filters.sat_view_active)?.name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    label="SiloMetrix"
                    id="silo_metrix"
                    value={filters.is_silo_metrix_active}
                    onChange={(e) => handlerSiloMetrixValue(e)}
                    options={siloMetrixSelected}
                    selectedList={filters.is_silo_metrix_active}
                    deleteFilter={deleteSiloMetrixFilter}
                    renderValue={() =>
                      siloMetrixSelected.find((item) => item.value === filters.is_silo_metrix_active)?.name
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Selector
                    disabled
                    label={i18n.t('app.silobag_data.silo_with_movement')}
                    id="sb_with_mov"
                    value=""
                    onChange={handlerCorvusID}
                    options={harvests}
                    selectedList={[]}
                    deleteFilter={deleteCorvusFilter}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <SilobagListTablet
            silobagList={silobagList}
            isLoading={isLoadingSiloData}
            isActiveList={active}
            rowCount={dataSilo?.totalFilteredSilobags ?? 0}
            paginationModel={filters.paginationModel}
            setPaginationModel={(fn) => {
              const paginationModel = fn(filters.paginationModel);
              setFilters({ ...filters, paginationModel });
            }}
            //sortingModel={sortingModel}
            setSortingModel={setSortingModel}
            silobagSelected={setSilobagSelected}
            setSiloListAll={setSiloListAll}
          />
        </>
      )}
    </>
  );
}

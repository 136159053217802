import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { SILOREAL_LOCALSTORAGE_KEY } from '../data/constants/localstorage-keys';
import i18n from '../libs/language';
import { SILOREAL_WEBSITE_VERSION } from '@data/constants';
import { Countryside } from '@typed/domain/base/countryside.types';
import { Lot } from '@typed/domain/base/lot.types';
import { Species } from '@typed/domain/base/silobag/silobag-species.types';
import { Product } from '@typed/domain/base/silobag/silobag-product.types';
import { Harvest } from '@typed/domain/base/silobag/silobag-harvest.types';
import { Silobag } from '@typed/domain/base/silobag/silobag.types';
import { Color } from '@typed/domain/siloreal-service/silo-metrix/color.types';
import { Pagination } from '@typed/extra/pagination.types';

type SilobagListFilters = {
  countryside_id: Countryside['id'][];
  lot_id: Lot['id'] | string;
  silobag_species_type_id: Species['id'][];
  silobag_product_type_id: Product['id'] | string;
  silobag_harvest_id: Harvest['id'] | string;
  sat_view_active: boolean | string;
  is_silo_metrix_active: boolean | string;
  color_id: Color['id'] | string;
  name: Silobag['name'];
  from: string;
  to: string;
  range: string;
  silobag_data_status_id: Silobag['status_id'] | string;
  active: Silobag['active'];
  paginationModel: { page: Pagination['page']; pageSize: Pagination['take'] };
};

type GlobalStore = {
  preferedLanguageId: string;
  accessToken: string | undefined;
  verifyAccessToken: string | undefined;
  refreshToken: string | undefined;
  permissions: any;
  expirationTokenDate: number;
  email: string | undefined;
  //la del selector variable a cambios TODO: cambiar nombre de variable por selectorOrganizationId
  organizationId: number | undefined;
  // este userOrganizationId es el que se usa para almacenar siempre la organizacion del usuario, el otro organizationId es el que se usa en el selector
  userOrganizationId: number | undefined;
  was_tutorial_shown: boolean | undefined;
  userID: number | undefined;
  //Organizacion tomada en el login
  organizationName: string | undefined;
  //Organizacion seleccionada en el selector
  selectedOrganizationName: string | undefined;
  // Change snake case
  was_lasegunda_integration_popup_shown: boolean | undefined;
  was_rus_popup_shown: boolean | undefined;
  was_agrotoken_integration_popup_shown: boolean | undefined;
  ishowOneModal: boolean;
  userFullname: string | undefined;
  awsTempCredentials: any;
  organizationTaxId: string | undefined;
  organizationLogo: string | null;
  countrysides: any;
  //Filtros de silobags y url de filtros de silobags
  //filters: any;
  filters: SilobagListFilters;
  filteredUrl: string;
  //Filtros de Reporte de Stock
  filtersReportStock: {
    startDate: string;
    endDate: string;
    countrysideId: string;
    countrysideName: string;
    silobagId: string;
    silobagName: string;
  };
  //Filtros de Reporte de Dispositivos!
  filtersReportDevices: {
    lance_number: string;
    countrysideId: number;
    silobagId: string;
    installed: boolean | undefined;
    report: boolean | undefined;
    paginationModel: { page: number; pageSize: number };
  };
  //Filtros de Reporte de Estado de Stock!
  filtersReportStateStock: {
    name: string;
    countrysideId: string;
    paginationModel: { page: number; pageSize: number };
  };
  //Filtros de Reporte de Existencia
  filtersReportExistence: {
    countrysideId: string;
    specieId: string;
    paginationModel: { page: number; pageSize: number };
  };
  //Filtros de Reporte de Actividad
  filtersReportActivity: {
    countrysideId: string;
    userId: string;
    typeId: string;
    paginationModel: { page: number; pageSize: number };
  };
  // typeOfProduct: { id: number; name: string }[];
  // siloSatActive: { id: number; name: string }[];
  // siloMetrixActive: { id: number; name: string }[];
  // countrysidesID: { id: number; name: string }[];
  // speciesID: { id: number; name: string }[];
  country_id: number | undefined;
  isUserOnline: boolean | undefined;
  corvusIntegrated: boolean | undefined;
  setIsUserOnline: (isOnline: boolean) => void;
  setCountryId: (countryId: number) => void;
  setLanguage: (preferedLanguageId: string) => void;
  setCountrysides: (countrysidesList: any) => void;
  setOrganizationLogo: (logoUrl: string | null) => void;
  setAccessToken: (token: string) => void;
  setVerifyAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  setPermissions: (permissions: any) => void;
  setAwsTempCredentials: (awsTempCredentials: any) => void;
  setExpirationTokenDate: (exp: number) => void;
  setEmail: (email: string | undefined) => void;
  setOrganizationId: (organizationId: number | undefined) => void;
  setUserOrganizationId: (userOrganizationId: number | undefined) => void;
  setWasTutorialShow: (was_tutorial_shown: boolean) => void;
  setUserFullname: (userFullname: string) => void;
  setOrganizationName: (organizationName: string) => void;
  setSelectedOrganizationName: (selectedOrganizationName: string | undefined) => void;
  setOrganizationTaxId: (organizationTaxId: string) => void;
  // Change snake case
  setWas_lasegunda_integration_popup_shown: (was_lasegunda_integration_popup_shown: boolean) => void;
  setWas_agrotoken_integration_popup_shown: (was_agrotoken_integration_popup_shown: boolean) => void;
  setIshowOneModal: (showOneModal: boolean) => void;
  setWas_rus_popup_shown: (was_rus_popup_shown: boolean) => void;
  setUserID: (userID: number) => void;
  clearAuthData: () => void;
  // setFilters y setFilteredUrl se usan para guardar los filtros de silobags
  setFilters: (filters: SilobagListFilters) => void;
  setFilteredUrl: (filteredUrl: string) => void;
  // setFiltersReportStock se usa para guardar los filtros de reporte de stock
  setFiltersReportStock: (filters: any) => void;
  // setFiltersReportDevices se usa para guardar los filtros de reporte de dispositivos
  setFiltersReportDevices: (filters: any) => void;
  // setFiltersReportStateStock se usa para guardar los filtros de reporte de estado de stock
  setFiltersReportStateStock: (filters: any) => void;
  // setFiltersReportExistence se usa para guardar los filtros de reporte de existencia
  setFiltersReportExistence: (filters: any) => void;
  //setFiltersReportActivity se usa para guardar los filtros de reporte de actividad
  setFiltersReportActivity: (filters: any) => void;

  // setTypeOfProduct: (types: { id: number; name: string }[]) => void;

  // setSiloSatActive: (siloSatActive: { id: number; name: string }[]) => void;
  // setSiloMetrixActive: (siloMetrixActive: { id: number; name: string }[]) => void;

  // setCountrysidesSelected: (countrysidesSelected: { id: number; name: string }[]) => void;
  // setSpeciesSelected: (speciesSelected: { id: number; name: string }[]) => void;
  setCorvusIntegrated: (isIntegrated: boolean) => void;
};

export const useGlobalStore = create<GlobalStore>()(
  persist(
    (set) => ({
      preferedLanguageId: i18n.locale,
      accessToken: undefined,
      verifyAccessToken: undefined,
      refreshToken: undefined,
      permissions: undefined,
      expirationTokenDate: 0,
      email: undefined,
      organizationId: undefined,
      userOrganizationId: undefined,
      was_tutorial_shown: undefined,
      userID: undefined,
      userFullname: undefined,
      was_lasegunda_integration_popup_shown: undefined,
      was_agrotoken_integration_popup_shown: undefined,
      was_rus_popup_shown: undefined,
      ishowOneModal: false,
      organizationName: undefined,
      awsTempCredentials: undefined,
      organizationTaxId: undefined,
      organizationLogo: null,
      countrysides: undefined,
      country_id: undefined,
      selectedOrganizationName: undefined,
      filters: {
        countryside_id: [],
        lot_id: '',
        silobag_species_type_id: [],
        silobag_product_type_id: '',
        silobag_harvest_id: '',
        sat_view_active: '',
        is_silo_metrix_active: '',
        color_id: '',
        name: '',
        from: '',
        to: '',
        range: '',
        active: true,
        silobag_data_status_id: 2,
        paginationModel: {
          page: 0,
          pageSize: 50,
        },
      },
      filteredUrl: '',
      filtersReportStock: {
        startDate: '',
        endDate: '',
        countrysideId: '',
        countrysideName: '',
        silobagId: '',
        silobagName: '',
      },
      // TODO: Agregar lance_number
      filtersReportDevices: {
        lance_number: '',
        countrysideId: undefined,
        silobagId: '',
        installed: undefined,
        report: undefined,
        paginationModel: {
          page: 0,
          pageSize: 50,
        },
      },
      filtersReportStateStock: {
        name: '',
        countrysideId: '',
        paginationModel: {
          page: 0,
          pageSize: 50,
        },
      },
      filtersReportExistence: {
        countrysideId: '',
        specieId: '',
        paginationModel: {
          page: 0,
          pageSize: 50,
        },
      },
      filtersReportActivity: {
        countrysideId: '',
        userId: '',
        typeId: '',
        paginationModel: {
          page: 0,
          pageSize: 50,
        },
      },
      isUserOnline: false,
      corvusIntegrated: undefined,
      setCorvusIntegrated: (isIntegrated: boolean) => set({ corvusIntegrated: isIntegrated }),
      setIsUserOnline: (isOnline: boolean) => set({ isUserOnline: isOnline }),
      setCountryId: (countryId: number) => set({ country_id: countryId }),
      setLanguage: (newLanguage: string) => set({ preferedLanguageId: newLanguage }),
      setCountrysides: (countrysidesList: any) => set({ countrysides: countrysidesList }),
      setOrganizationLogo: (organizationLogo: string | null) => set({ organizationLogo: organizationLogo }),
      setAccessToken: (token: string) => set({ accessToken: token }),
      setVerifyAccessToken: (token: string) => set({ verifyAccessToken: token }),
      setRefreshToken: (token: string) => set({ refreshToken: token }),
      setPermissions: (permissions: any) => set({ permissions }),
      setAwsTempCredentials: (awsTempCredentials: any) => set({ awsTempCredentials }),
      setExpirationTokenDate: (exp: number) => set({ expirationTokenDate: exp }),
      setEmail: (email: string | undefined) => set({ email: email }),
      setOrganizationId: (organizationId: number | undefined) => set({ organizationId: organizationId }),
      setUserOrganizationId: (userOrganizationId: number | undefined) =>
        set({ userOrganizationId: userOrganizationId }),
      setUserID: (userID: number) => set({ userID: userID }),
      setWasTutorialShow: (was_tutorial_shown: boolean) => set({ was_tutorial_shown: was_tutorial_shown }),
      setUserFullname: (userFullname: string) => set({ userFullname: userFullname }),
      setOrganizationName: (organizationName: string) => set({ organizationName: organizationName }),
      setSelectedOrganizationName: (selectedOrganizationName: string | undefined) =>
        set({ selectedOrganizationName: selectedOrganizationName }),
      setOrganizationTaxId: (organizationTaxId: string) => set({ organizationTaxId: organizationTaxId }),
      setFilters: (filters: SilobagListFilters) => set({ filters: filters }),
      setFilteredUrl: (filteredUrl: string) => set({ filteredUrl: filteredUrl }),
      setFiltersReportStock: (filters: any) => set({ filtersReportStock: filters }),
      setFiltersReportDevices: (filters: any) => set({ filtersReportDevices: filters }),
      setFiltersReportStateStock: (filters: any) => set({ filtersReportStateStock: filters }),
      setFiltersReportExistence: (filters: any) => set({ filtersReportExistence: filters }),
      // setCountrysidesSelected: (countrysidesSelected) => set({ countrysidesID: countrysidesSelected }),
      // setSpeciesSelected: (speciesSelected) => set({ speciesID: speciesSelected }),
      //actividad
      setFiltersReportActivity: (filters: any) => set({ filtersReportActivity: filters }),
      // setTypeOfProduct: (types) => set({ typeOfProduct: types }),
      // setSiloSatActive: (siloSatActive) => set({ siloSatActive: siloSatActive }),
      // setSiloMetrixActive: (siloMetrixActive) => set({ siloMetrixActive: siloMetrixActive }),
      setWas_lasegunda_integration_popup_shown: (was_lasegunda_integration_popup_shown: boolean) =>
        set({ was_lasegunda_integration_popup_shown: was_lasegunda_integration_popup_shown }),
      setWas_rus_popup_shown: (was_rus_popup_shown: boolean) =>
        set({ was_rus_popup_shown: was_rus_popup_shown }),
      setIshowOneModal: (showOneModal: boolean) => set({ ishowOneModal: showOneModal }),
      setWas_agrotoken_integration_popup_shown: (was_agrotoken_integration_popup_shown: boolean) =>
        set({ was_agrotoken_integration_popup_shown: was_agrotoken_integration_popup_shown }),
      clearAuthData: () =>
        set({
          accessToken: undefined,
          refreshToken: undefined,
          permissions: undefined,
          expirationTokenDate: 0,
          awsTempCredentials: undefined,
          // typeOfProduct: [],
          // siloSatActive: [],
          // siloMetrixActive: [],
          // countrysidesID: [],
          // speciesID: [],
          selectedOrganizationName: undefined,
          // Se agrega el clear de los filtros del listado de silobolsa hasta detectar un bug relacionado (la idea no es dejarlo acá)
          filters: {
            // TODO: borrar cuando se detecte y resuelva el bug
            countryside_id: [],
            lot_id: '',
            silobag_species_type_id: [],
            silobag_product_type_id: '',
            silobag_harvest_id: '',
            sat_view_active: '',
            is_silo_metrix_active: '',
            color_id: '',
            name: '',
            from: '',
            to: '',
            range: '',
            silobag_data_status_id: 2,
            active: true,
            paginationModel: {
              page: 0,
              pageSize: 50,
            },
          },
          filteredUrl: '', // TODO: borrar cuando se detecte y resuelva el bug
          ishowOneModal: false,
          filtersReportStock: {
            startDate: '',
            endDate: '',
            countrysideId: '',
            countrysideName: '',
            silobagId: '',
            silobagName: '',
          },
          filtersReportDevices: {
            lance_number: '',
            countrysideId: undefined,
            silobagId: '',
            installed: undefined,
            report: undefined,
            paginationModel: {
              page: 0,
              pageSize: 50,
            },
          },
          filtersReportStateStock: {
            name: '',
            countrysideId: '',
            paginationModel: {
              page: 0,
              pageSize: 50,
            },
          },
          filtersReportExistence: {
            countrysideId: '',
            specieId: '',
            paginationModel: {
              page: 0,
              pageSize: 50,
            },
          },
          filtersReportActivity: {
            countrysideId: '',
            userId: '',
            typeId: '',
            paginationModel: {
              page: 0,
              pageSize: 50,
            },
          },
        }),
    }),

    { name: SILOREAL_LOCALSTORAGE_KEY, version: parseInt(SILOREAL_WEBSITE_VERSION.replaceAll('.', ''), 10) }
  )
);

import { Box, IconButton } from '@mui/material';
import HelpIcon from '../../../assets/svg/helpIcon.svg';
import { useGlobalStore } from '@global-store/use-store';

export function LearnSiloReal() {
  const { preferedLanguageId } = useGlobalStore();
  const urlDictionary: { [key: string]: string } = {
    es: 'https://iof-company.notion.site/Aprend-con-SiloReal-74d12a81d6764fb1a9c08725894741a9',
    pt: 'https://iof-company.notion.site/Aprenda-com-SiloReal-a99fddd47032437f84830f2b46aee9a8',
  };
  const defaultUrl = 'https://iof-company.notion.site/Aprend-con-SiloReal-74d12a81d6764fb1a9c08725894741a9';

  const url = urlDictionary[preferedLanguageId];
  return (
    <Box sx={{ paddingRight: 2 }}>
      <IconButton href={url ?? defaultUrl} target="_blank">
        <img src={HelpIcon} alt="learn-silo-real" />
      </IconButton>
    </Box>
  );
}

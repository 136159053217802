import * as React from 'react';
import { Dayjs } from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import {
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps,
} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> & React.RefAttributes<HTMLInputElement>
) => React.JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = React.forwardRef(
  (props: SingleInputDateRangeFieldProps<Dayjs>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <SingleInputDateRangeField
        size="medium"
        {...props}
        ref={ref}
        sx={{
          '.MuiInputBase-root': { fontSize: '14px' },
        }}
      />
    );
  }
) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

type SingleInputDateRangePickerProps = {
  label: string;
  name: string;
  value?: [Dayjs | null, Dayjs | null] | string;
  //TODO: TIPAR ESE ANY
  onChange: (value: [Dayjs | null, Dayjs | null] | string) => void;
};

//TODO: esto se está exportando por defecto, no debería ser así probablemente
export default function SingleInputDateRangePicker(props: SingleInputDateRangePickerProps) {
  const { label, name, value, onChange } = props;

  return (
    <DateRangePicker
      value={value as [Dayjs, Dayjs]}
      onChange={onChange}
      slots={{ field: WrappedSingleInputDateRangeField }}
      label={label}
      name={name}
      sx={{
        fontSize: '10px',
      }}
    />
  );
}

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { useEffect } from 'react';
import i18n from '../../libs/language';
import { Box } from '@mui/material';
import { stockMovementListService } from '@services/domain/page/report/movement-stock/report-mov-stock-info';

type ReportStockSummaryType = Awaited<ReturnType<typeof stockMovementListService>>['summary'];

am4core.useTheme(am4themes_animated);

export const ChartStock: React.FC<{ data?: ReportStockSummaryType['data'] }> = ({ data }) => {
  const kilogramsToTons = (kilograms: string) => {
    const kilogramsNumber = +kilograms;
    const tons = Math.floor(kilogramsNumber / 1000);
    const decimal = kilogramsNumber / 1000 - tons;
    if (decimal >= 0.5) {
      return tons + 1;
    } else {
      return tons;
    }
  };

  useEffect(() => {
    //Grafica de Ingresos y Egresos
    if (data !== undefined) {
      const chart = am4core.create('chartdiv', am4charts.XYChart);
      const chartTitle = chart.titles.create();
      chartTitle.text = i18n.t('app.report_stock.tons_graphic');
      chartTitle.fontSize = 20;
      const updatedDataIngresandEgress = data?.map((item) => ({
        ...item,
        income: kilogramsToTons(`${item.income}`).toFixed(2),
        egress: kilogramsToTons(`-${item.egress}`).toFixed(2),
      }));

      chart.data = updatedDataIngresandEgress;
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.renderer.grid.template.location = 0;
      // dateAxis.renderer.minGridDistance = 70;

      dateAxis.baseInterval = {
        timeUnit: 'day',
        count: 7,
      };

      const valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = i18n.t('app.tons');
      //valueAxis1.renderer.grid.template.disabled = true;
      valueAxis1.numberFormatter = new am4core.NumberFormatter();
      valueAxis1.numberFormatter.numberFormat = '#.###';

      const series1 = chart.series.push(new am4charts.ColumnSeries());
      series1.dataFields.dateX = 'date';
      series1.dataFields.valueY = 'income';
      series1.yAxis = valueAxis1;
      series1.name = i18n.t('app.report_stock.incomes');
      series1.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]';
      series1.fill = chart.colors.getIndex(10);
      series1.strokeWidth = 0;
      series1.clustered = false;
      series1.columns.template.width = am4core.percent(30);
      series1.numberFormatter = new am4core.NumberFormatter();
      series1.numberFormatter.numberFormat = '#.###';

      const series2 = chart.series.push(new am4charts.ColumnSeries());
      series2.dataFields.dateX = 'date';
      series2.dataFields.valueY = 'egress';
      series2.yAxis = valueAxis1;
      series2.name = i18n.t('app.report_stock.outcomes');
      series2.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]';
      series2.fill = chart.colors.getIndex(1);
      series2.strokeWidth = 0;
      series2.clustered = false;
      series2.toBack();
      series2.columns.template.width = am4core.percent(30);
      series2.numberFormatter = new am4core.NumberFormatter();
      series2.numberFormatter.numberFormat = '#.###';

      chart.cursor = new am4charts.XYCursor();
      chart.legend = new am4charts.Legend();
      chart.legend.position = 'top';

      //Grafica de Balance
      const chartBalance = am4core.create('chartBalance', am4charts.XYChart);
      const chartTitleBalance = chartBalance.titles.create();
      chartTitleBalance.fontSize = 20;
      chartTitleBalance.text = i18n.t('app.report_stock.balance_graphic');
      const updateDataBalance = data.map((item) => ({
        ...item,
        balance: kilogramsToTons(item.balance).toFixed(2),
      }));
      chartBalance.data = updateDataBalance;

      const dateAxisBalance = chartBalance.xAxes.push(new am4charts.DateAxis());

      dateAxisBalance.baseInterval = {
        timeUnit: 'day',
        count: 7,
      };

      const valueAxisBalance = chartBalance.yAxes.push(new am4charts.ValueAxis());
      valueAxisBalance.title.text = i18n.t('app.tons');
      valueAxisBalance.renderer.grid.template.disabled = true;
      valueAxisBalance.numberFormatter = new am4core.NumberFormatter();
      valueAxisBalance.numberFormatter.numberFormat = '#.###';

      const seriesBalance = chartBalance.series.push(new am4charts.LineSeries());
      seriesBalance.dataFields.dateX = 'date';
      seriesBalance.dataFields.valueY = 'balance';
      seriesBalance.yAxis = valueAxisBalance;
      seriesBalance.name = 'Balance';
      seriesBalance.strokeWidth = 2;
      seriesBalance.tensionX = 2;
      seriesBalance.tooltipText = '{name}\n[bold font-size: 20]{valueY}[/]';
      seriesBalance.stroke = chartBalance.colors.getIndex(3);
      seriesBalance.numberFormatter = new am4core.NumberFormatter();
      seriesBalance.numberFormatter.numberFormat = '#.###';

      const bulletBalance = seriesBalance.bullets.push(new am4charts.CircleBullet());
      bulletBalance.circle.radius = 1;
      bulletBalance.circle.strokeWidth = 2;
      bulletBalance.circle.fill = am4core.color('#fff');

      chartBalance.cursor = new am4charts.XYCursor();
      chartBalance.legend = new am4charts.Legend();
      chartBalance.legend.position = 'top';

      return () => {
        chart.dispose();
        chartBalance.dispose();
      };
    }
  }, [data]);

  return (
    <>
      <Box id="chartdiv" style={{ width: '100%', height: '500px' }} />
      <hr />
      <Box
        id="chartBalance"
        style={{ width: '100%', height: '500px', paddingTop: '30px', marginTop: '20px' }}
      />
    </>
  );
};

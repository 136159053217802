import { Stack, Typography, Button, Box } from '@mui/material';
import SiloRealLogo from '../../assets/logos/siloreal-logo-black.webp';
import { useNavigate } from 'react-router-dom';
import { ModalIOF } from '@ui/modal/modal';
import { separatorReports } from '@styles/generic-styles';
import redSojaIcon from '../../assets/svg/icons-species-red/soja-red.svg';
import redMaizIcon from '../../assets/svg/icons-species-red/maiz-red.svg';
import redTrigoIcon from '../../assets/svg/icons-species-red/trigo-red.svg';
import redCebadaIcon from '../../assets/svg/icons-species-red/cebada-red.svg';
import redGirasolIcon from '../../assets/svg/icons-species-red/girasol-red.svg';
import { configButtonStylesContained } from '@styles/generic-styles';
import { PATH } from '@data/core/paths';
import { useGlobalStore } from '@global-store/use-store';
import { useService } from '@hooks/use-service';
import { updateUserDataService } from '@services/domain/user/base/user-edition';

type LaSegundaIntegrationModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export default function LaSegundaIntegrationModal(props: LaSegundaIntegrationModalProps) {
  const { userID, setIshowOneModal } = useGlobalStore();
  const { trigger } = useService(updateUserDataService);
  const navigate = useNavigate();
  const { open, setOpen } = props;
  const laSegundaLogo = 'https://iofbucket.s3.amazonaws.com/logo/logo+la+segunda+rojo.svg';
  return (
    <ModalIOF open={open} ModalIsOpen={setOpen} width="48%">
      <ModalIOF.HeaderCenter>
        <ModalIOF.Title>
          <Stack direction="row" spacing={1} justifyContent={'space-around'} alignItems={'center'}>
            <Box sx={separatorReports}>
              <img
                src={SiloRealLogo}
                alt="SiloReal Logo"
                style={{ width: '140px', padding: 10, marginRight: '10px' }}
              />
            </Box>
            <Box>
              <img src={laSegundaLogo} alt="La Segunda Logo" style={{ width: '140px', padding: 10 }} />
            </Box>
          </Stack>
        </ModalIOF.Title>
      </ModalIOF.HeaderCenter>
      <ModalIOF.Body>
        <Stack direction="column" spacing={2} sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            Nueva Alianza con La Segunda
          </Typography>
          <Stack direction={'row'} justifyContent={'center'}>
            <Box>
              <img src={redSojaIcon} alt="Soja" style={{ width: '50px', margin: '15px 25px 10px 25px' }} />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Soja
              </Typography>
            </Box>
            <Box>
              <img src={redMaizIcon} alt="Maíz" style={{ width: '50px', margin: '15px 25px 10px 25px' }} />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Maíz
              </Typography>
            </Box>
            <Box>
              <img src={redTrigoIcon} alt="Trigo" style={{ width: '50px', margin: '15px 25px 10px 25px' }} />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Trigo
              </Typography>
            </Box>
            <Box>
              <img
                src={redCebadaIcon}
                alt="Cebada"
                style={{ width: '50px', margin: '15px 25px 10px 25px' }}
              />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Cebada
              </Typography>
            </Box>
            <Box>
              <img
                src={redGirasolIcon}
                alt="Girasol"
                style={{ width: '50px', margin: '15px 25px 10px 25px' }}
              />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Girasol
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ margin: '20px 0px' }}>
          <Typography sx={{ margin: 'auto', width: '600px', fontSize: '19px', textAlign: 'center' }}>
            Ahora vas a poder contratar tus coberturas de seguros con{' '}
            <span style={{ fontWeight: 600 }}>La Segunda desde SiloReal</span>
          </Typography>
        </Box>
        <Stack direction="row" spacing={2} justifyContent={'center'}>
          <Button
            color="error"
            sx={{ border: 'none', color: 'black', textTransform: 'none' }}
            onClick={() => {
              trigger({
                query: userID!,
                body: { was_la_segunda_popup_shown: true },
              });
              setOpen(true);
              setIshowOneModal(true);
            }}
          >
            Quizás más tarde
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ ...configButtonStylesContained, padding: '0px 30px' }}
            onClick={() => {
              trigger({
                query: userID!,
                body: { was_la_segunda_popup_shown: true },
              });
              setOpen(true);
              setIshowOneModal(true);
              navigate(PATH.SILOBAG);
            }}
          >
            Ir a Cotizar
          </Button>
        </Stack>
      </ModalIOF.Body>
    </ModalIOF>
  );
}

import { PATH } from '@data/core/paths';
import { useArray, useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Tabs,
  Tab,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  TextField,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Fade,
  Divider,
  Container,
  InputAdornment,
} from '@mui/material';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { ChangeEvent, Fragment, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import i18n from '../../../libs/language';
import RemoveDevIcon from '@assets/svg/core/trash-red.svg';
import CloseIcon from '@assets/svg/core/cross.svg';
import { humidityOptionListService } from '@services/domain/base/silobag/list/humidity-option-list';
import { weightOptionListService } from '@services/domain/base/silobag/list/weight-option-list';
import { harvestListService } from '@services/domain/base/silobag/list/harvest-list';
import { productListService } from '@services/domain/base/silobag/list/product-list';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { listAvailableDeviceService } from '@services/domain/base/organization/available-device-list';
import { useGlobalStore } from '@global-store/use-store';
import { createSilobagService } from '@services/domain/base/silobag/base/silobag-creation';
import { updateSilobagService } from '@services/domain/base/silobag/base/silobag-edition';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SummaryDetailSb = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];
type listAvailableDeviceServiceResponse = Awaited<ReturnType<typeof listAvailableDeviceService>>['summary'];
type QueryUpdateSilobagPayload = Parameters<typeof updateSilobagService>[0];
dayjs.extend(utc);

type QueryCreateSilobagPayload = Parameters<typeof createSilobagService>[0];

type Props = {
  parentFormData?: SummaryDetailSb;
  isParentLoading?: boolean;
  type: 'edit' | 'new';
};

type SelChg = SelectChangeEvent<HTMLInputElement>;
type InputChg = ChangeEvent<HTMLInputElement>;

type Lot = {
  id: number;
  name: string;
  countryside_id: number;
};

type DeviceFromBackend = {
  id?: number;
  device_id?: number;
  position?: number | null;
  lance_number?: string;
  active?: boolean;
};

type DeviceAddedByUser = {
  id?: number | null;
  device_id?: number | null;
  position: number | null;
  lance_number: string | null;
};

// Funcion para comparar objetos anidados
const deepEqual = (obj1: object, obj2: object): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

type FormData = {
  id?: SummaryDetailSb['id'] | null | number;
  countryside_id?: SummaryDetailSb['countryside_id'] | null | string;
  name?: SummaryDetailSb['name'] | null;
  silobag_species_type_id?: SummaryDetailSb['silobag_species_type_id'] | null | string;
  variety?: SummaryDetailSb['variety'] | string;
  silobag_product_type_id?: SummaryDetailSb['silobag_product_type_id'] | undefined | string | null;
  silobag_harvest_id?: SummaryDetailSb['silobag_harvest_id'] | string;
  lot_id?: SummaryDetailSb['lot_id'] | null | string;
  origin_lot_1?: SummaryDetailSb['silobags_origin_lots'][0]['lot_id'] | string;
  origin_lot_1_weight?: SummaryDetailSb['silobags_origin_lots'][0]['weight'];
  origin_lot_2?: SummaryDetailSb['silobags_origin_lots'][0]['lot_id'] | string;
  origin_lot_2_weight?: SummaryDetailSb['silobags_origin_lots'][0]['weight'];
  origin_lot_3?: SummaryDetailSb['silobags_origin_lots'][0]['lot_id'] | string;
  origin_lot_3_weight?: SummaryDetailSb['silobags_origin_lots'][0]['weight'];
  size?: SummaryDetailSb['size'] | undefined | number;
  bagging_date?: SummaryDetailSb['bagging_date'] | Dayjs;
  input_weight: SummaryDetailSb['input_weight'] | undefined | number | null;
  input_humidity?: SummaryDetailSb['initial_humidity'] | undefined;
  initial_humidity?: SummaryDetailSb['initial_humidity'] | undefined | number;
  main_lat?: SummaryDetailSb['main_lat'] | undefined;
  main_lon?: SummaryDetailSb['main_lon'] | undefined;
  observations?: SummaryDetailSb['observations'] | undefined;
  responsible_for_bagging?: SummaryDetailSb['responsible_for_bagging'] | undefined;
  silobag_brand?: SummaryDetailSb['silobag_brand'] | undefined;
  varietal_purity?: SummaryDetailSb['varietal_purity'] | undefined | number;
  split_grain_percentage?: SummaryDetailSb['split_grain_percentage'] | undefined | number;
  fillying_coefficient?: SummaryDetailSb['fillying_coefficient'] | undefined | number;
  humidity_source_id?:
    | SummaryDetailSb['humidity_sources']['humidity_sources_translations'][0]['id']
    | string
    | undefined;
  weight_source_id?:
    | SummaryDetailSb['weight_sources']['weight_sources_translations'][0]['id']
    | string
    | undefined;
  side_charge_id?: SummaryDetailSb['side_charge_id'] | string | undefined;
  lots?: SummaryDetailSb['lots'] | undefined;
  countrysides?: SummaryDetailSb['lots']['countrysides'] | undefined;
  is_guest?: SummaryDetailSb['is_guest'] | undefined;
  confection_date?: SummaryDetailSb['confection_date'] | undefined;
};

type ErrorData = {
  countryside_id: string;
  name: string;
  silobag_species_type_id: string;
  silobag_product_type_id: string;
  lot_id: string;
  origin_lot_1_weight: string;
  origin_lot_2_weight: string;
  origin_lot_3_weight: string;
  origin_lot_1: string;
  origin_lot_2: string;
  origin_lot_3: string;
  size: string;
  input_weight: string;
  initial_humidity: string;
  main_lat: string;
  main_lon: string;
  split_grain_percentage: string;
  fillying_coefficient: string;
  varietal_purity: string;
};

type OriginLot = SummaryDetailSb['silobags_origin_lots'] | null;

export function SilobagNewEditForm({ type, parentFormData, isParentLoading }: Props) {
  const navigate = useNavigate();
  const { siloID } = useParams();
  const { setFilters, filters } = useGlobalStore();
  const location = useLocation();
  const isCorvus = location.state && location.state.isCorvus;
  const isGuest = location.state && location.state.isGuest;
  const siloListId = location.state && location.state.siloListId;
  const { trigger: createSilobag, isLoading: isCreateLoading } = useService(createSilobagService, {
    onSuccess: () => {
      setFilters({ ...filters, silobag_data_status_id: 1 });
      navigate(PATH.SILOBAG, { state: { newOrEditSilobagSuccessfull: true, pathEditSilobag: true } });
    },
  });

  const closeAndRemoveOnSuccessUnlinkingDevice = () => {
    if (unlinkRequestDeviceIDX.current != null) {
      toggleOpenUnlinkDeviceModal(false);

      removeBackendDevice(unlinkRequestDeviceIDX.current);
      getAvailableDevices();

      unlinkRequestDeviceIDX.current = null;
      return;
    }
    navigate(PATH.SILOBAG + '/' + siloID, {
      state: { newOrEditSilobagSuccessfull: true, pathEditSilobag: true },
    });
  };

  const { trigger: editSilobag, isLoading: isEditLoading } = useService(updateSilobagService, {
    onSuccess: closeAndRemoveOnSuccessUnlinkingDevice,
  });

  const {
    trigger: getAvailableDevices,
    summary: availableDevices,
    isLoading: isAvailableDevicesLoading,
  } = useService(listAvailableDeviceService, { fetchOnMount: true });

  const initFormData: FormData = {
    id: type === 'edit' ? parentFormData?.id ?? undefined : undefined,
    countryside_id: type === 'edit' ? parentFormData?.countryside_id ?? '' : '',
    name: type === 'edit' ? parentFormData?.name ?? '' : '',
    silobag_species_type_id: type === 'edit' ? parentFormData?.silobag_species_type_id ?? '' : '',
    variety: type === 'edit' ? parentFormData?.variety ?? null : null,
    silobag_product_type_id: type === 'edit' ? parentFormData?.silobag_product_type_id ?? '' : '',
    silobag_harvest_id: type === 'edit' ? parentFormData?.silobag_harvest_id ?? '' : '',
    lot_id: type === 'edit' ? parentFormData?.lot_id ?? '' : '',
    origin_lot_1: type === 'edit' ? parentFormData?.silobags_origin_lots[0]?.lot_id ?? null : null,
    origin_lot_1_weight: type === 'edit' ? parentFormData?.silobags_origin_lots[0]?.weight ?? '' : '',
    origin_lot_2: type === 'edit' ? parentFormData?.silobags_origin_lots[1]?.lot_id ?? null : null,
    origin_lot_2_weight: type === 'edit' ? parentFormData?.silobags_origin_lots[1]?.weight ?? '' : '',
    origin_lot_3: type === 'edit' ? parentFormData?.silobags_origin_lots[2]?.lot_id ?? null : null,
    origin_lot_3_weight: type === 'edit' ? parentFormData?.silobags_origin_lots[2]?.weight ?? '' : '',
    size: type === 'edit' ? parentFormData?.size ?? null : null,
    bagging_date:
      type === 'edit' && parentFormData?.bagging_date
        ? dayjs.utc(parentFormData?.bagging_date) ?? null
        : null,
    input_weight: type === 'edit' ? parentFormData?.input_weight ?? '' : '',
    initial_humidity: type === 'edit' ? parentFormData?.initial_humidity ?? null : null,
    input_humidity: type === 'edit' ? parentFormData?.initial_humidity ?? null : null,
    main_lat: type === 'edit' ? parentFormData?.main_lat ?? null : null,
    main_lon: type === 'edit' ? parentFormData?.main_lon ?? null : null,
    observations: type === 'edit' ? parentFormData?.observations ?? '' : '',
    responsible_for_bagging: type === 'edit' ? parentFormData?.responsible_for_bagging ?? '' : '',
    silobag_brand: type === 'edit' ? parentFormData?.silobag_brand ?? null : null,
    varietal_purity: type === 'edit' ? parentFormData?.varietal_purity ?? undefined : undefined,
    split_grain_percentage: type === 'edit' ? parentFormData?.split_grain_percentage ?? undefined : undefined,
    fillying_coefficient: type === 'edit' ? parentFormData?.fillying_coefficient ?? undefined : undefined,
    humidity_source_id: type === 'edit' ? parentFormData?.humidity_source_id ?? undefined : undefined,
    weight_source_id: type === 'edit' ? parentFormData?.weight_source_id ?? undefined : undefined,
    side_charge_id: type === 'edit' ? parentFormData?.side_charge_id ?? null : null,
    lots: type === 'edit' ? parentFormData?.lots ?? undefined : undefined,
    countrysides: type === 'edit' ? parentFormData?.lots?.countrysides ?? undefined : undefined,
    is_guest: type === 'edit' ? parentFormData?.is_guest ?? undefined : undefined,
    confection_date: type === 'new' ? new Date() : parentFormData?.confection_date,
  };

  const initErrorData = {
    countryside_id: '',
    name: '',
    silobag_species_type_id: '',
    silobag_product_type_id: '',
    lot_id: '',
    origin_lot_1_weight: '',
    origin_lot_2_weight: '',
    origin_lot_3_weight: '',
    size: '',
    input_weight: '',
    initial_humidity: '',
    main_lat: '',
    main_lon: '',
    split_grain_percentage: '',
    fillying_coefficient: '',
    origin_lot_1: '',
    origin_lot_2: '',
    origin_lot_3: '',
    varietal_purity: '',
  };

  const initDevicesData =
    type === 'new'
      ? []
      : parentFormData?.silobags_devices
          ?.filter((devRel) => devRel.active)
          .map((devRel) => ({
            position: devRel.position,
            id: devRel.id,
            lance_number: devRel.devices.lance_number,
            active: devRel.active,
          })) ?? [];

  const initWithDevices = type === 'edit' ? initDevicesData.length > 0 : false;

  // TODO: Tipar correctamente. Este estado infiere tipos incorrectamente
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [errorData, setErrorData] = useState<ErrorData>(initErrorData);
  const {
    array: devicesFromBackend,
    remove: removeBackendDevice,
    update: updateDeviceFromBackend,
    set: setDevicesFromBackend,
  } = useArray<DeviceFromBackend>(initDevicesData as DeviceFromBackend[]);
  const {
    array: devicesAddedByUser,
    keys: devKeys,
    add: addDeviceByUser,
    remove: removeDeviceAddedByUser,
    update: updateDeviceAddedByUser,
  } = useArray<DeviceAddedByUser>([]);

  const [tabIDX, setTabIDX] = useState(0);
  const [lots, setLots] = useState<Lot[]>([]);
  const [withDevices, toggleWithDevices] = useToggle(initWithDevices);
  const [openUnlinkDeviceModal, toggleOpenUnlinkDeviceModal] = useToggle(false);
  const unlinkRequestDeviceIDX = useRef<number | null>(null);
  const [openEditModal, setOpenEditModal] = useToggle(false);
  const [bodyEdit, setBodyEdit] = useState<FormData>();

  useEffectOnUpdate(() => {
    setFormData(initFormData);
    setErrorData(initErrorData);
    setDevicesFromBackend(initDevicesData);
    toggleWithDevices(initWithDevices);
  }, [parentFormData]);

  const updateFormData = (key: string, value: unknown) => setFormData((prev) => ({ ...prev, [key]: value }));
  const updateErrorData = (key: string, value: unknown) =>
    setErrorData((prev) => ({ ...prev, [key]: value }));

  const submitUnlinkDevice = (deviceRelID: number) => {
    const deviceIDX = devicesFromBackend.findIndex((dev) => dev.id === deviceRelID);
    unlinkRequestDeviceIDX.current = deviceIDX;

    const siloID = formData.id;
    const speciesID = formData.silobag_species_type_id;
    const body = {
      id: siloID as string,
      silobag_species_type_id: speciesID as number,
      silobags_devices: [{ id: deviceRelID, active: false }],
    };
    const query = formData.id as string;
    editSilobag({ query, body });
  };

  const initialFormData = useRef(initFormData);
  const initialDevicesAddedByUser = useRef<DeviceAddedByUser[]>([]);
  const initialDevicesData = useRef<DeviceFromBackend[]>(initDevicesData);

  useEffect(() => {
    if (type === 'edit') {
      initialFormData.current = formData;
      initialDevicesAddedByUser.current = devicesAddedByUser;
      initialDevicesData.current = devicesFromBackend;
    }
  }, [type, formData, devicesAddedByUser, devicesFromBackend]);

  const isFormModified =
    !deepEqual(formData, initialFormData.current) ||
    !deepEqual(devicesFromBackend, initialDevicesData.current);
  const areDevicesValid =
    devicesAddedByUser.length > 0 &&
    devicesAddedByUser.every((dev) => dev.id != null && dev.position != null);

  const isSaveButtonDisabled = isCreateLoading || isEditLoading || (!isFormModified && !areDevicesValid);

  let devices: DeviceFromBackend[] = [];

  if (devicesFromBackend.length > 0 || devicesAddedByUser.length > 0) {
    devices = devicesFromBackend
      .map((dev) => ({ id: dev.id, position: dev.position }) as DeviceFromBackend)
      .concat(
        devicesAddedByUser
          .filter((dev) => dev.id != null)
          .map((dev) => ({ device_id: dev.id!, position: dev.position }))
      );
  }

  const closeEditModal = () => {
    setOpenEditModal(false);
  };

  const submit = () => {
    unlinkRequestDeviceIDX.current = null;
    const errorMsg = i18n.t('app.must_field');
    let error = false;

    if (Object.values(formData).every((value) => value === '' || value === null || value === 0)) return;

    if (formData.countryside_id === '') (error = true), updateErrorData('countryside_id', errorMsg);
    if (formData.name === '') (error = true), updateErrorData('name', errorMsg);
    if (formData.silobag_species_type_id === '')
      (error = true), updateErrorData('silobag_species_type_id', errorMsg);
    if (formData.silobag_product_type_id === '')
      (error = true), updateErrorData('silobag_product_type_id', errorMsg);
    if (formData.lot_id === '') (error = true), updateErrorData('lot_id', errorMsg);
    if (formData.input_weight === '') (error = true), updateErrorData('input_weight', errorMsg);

    if (error) return;

    const initialOriginLots =
      parentFormData?.silobags_origin_lots?.map(({ id, lot_id, weight }) => ({ id, lot_id, weight })) ?? [];

    const formOriginLots = [
      {
        lot_id: formData.origin_lot_1,
        weight:
          formData.origin_lot_1_weight !== '' ? parseInt(formData.origin_lot_1_weight as string, 10) : null,
      },
      {
        lot_id: formData.origin_lot_2,
        weight:
          formData.origin_lot_2_weight !== '' ? parseInt(formData.origin_lot_2_weight as string, 10) : null,
      },
      {
        lot_id: formData.origin_lot_3,
        weight:
          formData.origin_lot_3_weight !== '' ? parseInt(formData.origin_lot_3_weight as string, 10) : null,
      },
    ].filter((lo) => lo.lot_id != null && lo.lot_id !== '');

    const newOrModifiedOriginLots: OriginLot[] = formOriginLots
      .map((currentLot) => {
        const initLotState = initialOriginLots.find((initLot) => initLot.lot_id === currentLot.lot_id);

        if (!initLotState) return { lot_id: currentLot.lot_id, weight: currentLot.weight };
        if (initLotState.weight != currentLot.weight)
          return { id: initLotState.id, weight: currentLot.weight };
        return null;
      })
      .filter(Boolean) as OriginLot[];

    const removedOriginLots = initialOriginLots
      .filter((old) => formOriginLots.every((newL) => newL.lot_id !== old.lot_id))
      .map((old) => ({ id: old.id, active: false }));

    const finalOriginLots = newOrModifiedOriginLots.concat(removedOriginLots as OriginLot);

    const body = {
      ...formData,
      origin_lots: finalOriginLots,
      humidity_sources: formData.humidity_source_id ? formData.humidity_source_id : undefined,
      weight_sources: formData.weight_source_id ? formData.weight_source_id : undefined,
      size: formData.size ? parseInt(formData.size as string, 10) : null,
      initial_humidity: formData.initial_humidity ? parseFloat(formData.initial_humidity as string) : null,
      input_weight: formData.input_weight ? parseFloat(formData.input_weight as string) : null,
      bagging_date: formData.bagging_date ? formData.bagging_date : null,
      varietal_purity: formData.varietal_purity ? parseFloat(formData.varietal_purity as string) : null,
      split_grain_percentage: formData.split_grain_percentage
        ? parseFloat(formData.split_grain_percentage as string)
        : null,
      fillying_coefficient: formData.fillying_coefficient
        ? parseFloat(formData.fillying_coefficient as string)
        : null,
      silobags_devices: devices.length > 0 ? devices : [],
    };

    if (type === 'edit') {
      setBodyEdit(body);
      setOpenEditModal(true);
    }
    if (type === 'new') createSilobag({ body: body } as QueryCreateSilobagPayload);
  };

  const { state: locationState } = useLocation();

  return (
    <>
      {isParentLoading ? (
        <Stack alignItems="center">
          <CircularProgress size={24} />
        </Stack>
      ) : (
        <Stack spacing={2} style={{ width: '98%', margin: '16px auto' }}>
          <Button
            sx={{
              color: '#22A7F0',
              width: 'min-content',
              fontSize: '0.9rem',
              textTransform: 'none',
              padding: 0.3,
              '&:hover': { background: '#22a7f02a' },
            }}
            onClick={() =>
              navigate(locationState?.from ?? -1, {
                state: { pathEditSilobag: true, siloListId: siloListId },
              })
            }
          >
            {'< Volver'}
          </Button>
          <Stack direction="row" alignItems="center">
            <Box style={{ flex: 1, fontSize: '1.5rem', fontWeight: 600 }}>
              {type === 'new' ? i18n.t('app.new_silobag') : i18n.t('app.edit_silobag')}
            </Box>
            <Button
              variant="contained"
              type="submit"
              disabled={isSaveButtonDisabled}
              style={{
                color: '#FFF',
                width: 108,
                height: 40,
                borderRadius: 100,
                textTransform: 'none',
              }}
              onClick={submit}
            >
              {isCreateLoading || isEditLoading ? (
                <CircularProgress style={{ width: 24, height: 24 }} />
              ) : (
                i18n.t('app.save')
              )}
            </Button>
          </Stack>
          <ConfirmEditModal
            open={openEditModal}
            onClose={closeEditModal}
            editSilobag={editSilobag}
            isEditLoading={isEditLoading}
            body={bodyEdit}
          />
          <Stack direction={{ sx: 'column', md: 'row' }} spacing={2}>
            <Stack direction={'column'} width={{ sx: '100%', md: '65%' }} spacing={2}>
              <Paper
                style={{ padding: 16, borderRadius: 8, backgroundColor: '#fff' }}
                sx={{
                  width: '100%',
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabIDX} onChange={(_, newIDX) => setTabIDX(newIDX)}>
                    <Tab label={i18n.t('app.silobag_data.data_main')} />
                    <Tab label={i18n.t('app.silobag_data.additional_data')} />
                  </Tabs>
                </Box>
                <TabPanel currentTabIDX={tabIDX} IDX={0}>
                  <PrincipalForm
                    formData={formData}
                    errorData={errorData}
                    lots={lots}
                    withDevices={withDevices}
                    devicesFromBackend={devicesFromBackend}
                    updateFormData={updateFormData}
                    updateErrorData={updateErrorData}
                    setLots={setLots}
                    toggleWithDevices={toggleWithDevices}
                    type={type}
                    isGuest={isGuest}
                    isCorvus={isCorvus}
                    hasLabProtocol={Boolean(parentFormData?.lab_protocols_history.length)}
                  />
                </TabPanel>
                <TabPanel currentTabIDX={tabIDX} IDX={1}>
                  <AdditionalForm
                    formData={formData}
                    errorData={errorData}
                    updateFormData={updateFormData}
                    updateErrorData={updateErrorData}
                  />
                </TabPanel>
              </Paper>
              <DevicesForm
                isMobile={parentFormData?.silobag_data_status_id as number}
                // TODO: FALTA TIPADO
                hadOriginClosure={parentFormData?.v_is_silobag_available_for_closure?.result}
                isGuest={parentFormData?.is_guest ?? isGuest}
                submitUnlinkDevice={submitUnlinkDevice}
                isUnlinkDeviceLoading={isEditLoading}
                openUnlinkDeviceModal={openUnlinkDeviceModal}
                toggleOpenUnlinkDeviceModal={toggleOpenUnlinkDeviceModal}
                withDevices={withDevices}
                tabIDX={tabIDX}
                availableDevices={availableDevices ?? []}
                isAvailableDevicesLoading={isAvailableDevicesLoading}
                devicesFromBackend={devicesFromBackend}
                updateDeviceFromBackend={updateDeviceFromBackend}
                devicesAddedByUser={devicesAddedByUser}
                updateDeviceAddedByUser={updateDeviceAddedByUser}
                devKeys={devKeys}
                addDeviceByUser={addDeviceByUser}
                removeDeviceAddedByUser={removeDeviceAddedByUser}
              />
            </Stack>

            <Paper
              style={{ padding: 16, borderRadius: 8 }}
              sx={{ width: { xs: '100%', md: '35%' }, marginTop: { xs: 2, md: 0 } }}
            >
              {parentFormData &&
              [parentFormData?.init_image, parentFormData?.end_image, parentFormData?.main_image].filter(
                Boolean
              ).length ? (
                <PhotoCarousel
                  title={i18n.t('app.images')}
                  images={[
                    { url: parentFormData?.init_image, label: i18n.t('app.silobag_data.silobag_init') },
                    { url: parentFormData?.end_image, label: i18n.t('app.silobag_data.silobag_main_photo') },
                    { url: parentFormData?.main_image, label: i18n.t('app.silobag_data.silobag_end') },
                  ]}
                />
              ) : (
                <Stack style={{ height: '100%' }}>
                  <Box style={{ fontSize: '1.5rem', fontWeight: 600 }}>{i18n.t('app.images')}</Box>
                  <Stack style={{ flex: 1, color: '#a7a7a7' }} justifyContent="center" alignItems="center">
                    {i18n.t('app.no_images')}
                  </Stack>
                </Stack>
              )}
            </Paper>
          </Stack>
        </Stack>
      )}
    </>
  );
}

type TabPanelProps = {
  IDX: number;
  currentTabIDX: number;
};
function TabPanel(props: PropsWithChildren<TabPanelProps>) {
  const { children, currentTabIDX, IDX, ...other } = props;

  return (
    <Box role="tabpanel" hidden={currentTabIDX !== IDX} {...other}>
      <Box style={{ display: currentTabIDX !== IDX ? 'none' : '', padding: 3 }}> {children} </Box>
    </Box>
  );
}

type PrincipalFormProps = {
  formData: FormData;
  errorData: ErrorData;
  lots: Lot[];
  withDevices: boolean;
  devicesFromBackend: DeviceFromBackend[];
  updateFormData: (key: string, value: unknown) => void;
  updateErrorData: (key: string, value: unknown) => void;
  setLots: (lots: Lot[]) => void;
  toggleWithDevices: () => void;
  type: 'edit' | 'new';
  isGuest: boolean;
  isCorvus: boolean;
  hasLabProtocol: boolean;
};
function PrincipalForm({
  formData,
  errorData,
  lots,
  withDevices,
  devicesFromBackend,
  updateFormData,
  updateErrorData,
  setLots,
  toggleWithDevices,
  type,
  isGuest,
  isCorvus,
  hasLabProtocol,
}: PrincipalFormProps) {
  const { organizationId } = useGlobalStore();
  const queryCountrysideList = { organization_id: organizationId, write_permission: true };
  const { summary: countrysides, isLoading: isCountrysidesLoading } = useService(listCountrysideService, {
    fetchOnMount: [{ query: queryCountrysideList }],
  });

  const { summary: species, isLoading: isSpeciesLoading } = useService(speciesListService, {
    fetchOnMount: true,
  });

  const { summary: products, isLoading: isProductsLoading } = useService(productListService, {
    fetchOnMount: true,
  });

  const { summary: harvests, isLoading: isHarvestsLoading } = useService(harvestListService, {
    fetchOnMount: true,
  });

  const VALIDATION = {
    ONLY_NUMS: (value: string) => /^[0-9]*$/.test(value),
    FLOAT_NUMS: (value: string) => /^-?[0-9]*((?<=\d)\.(?=\d))?[0-9]*$/.test(value) && value !== '-',
    TYPING_FLOAT_NUMS: (value: string) => /^-?[0-9]*\.?[0-9]*$/.test(value),
    BETWEEN_0_AND_100: (value: string) => /^(\d{0,2}|\d{0,2}[.]|\d{0,2}[.]\d{1,2}|100)$/.test(value),
  };

  const HANDLERS = {
    countryside_id: (e: SelChg) => {
      updateErrorData('countryside_id', '');
      updateFormData('countryside_id', e.target.value);
      updateFormData('lot_id', '');
      updateFormData('origin_lot_1', '');
      updateFormData('origin_lot_2', '');
      updateFormData('origin_lot_3', '');
      updateFormData('origin_lot_1_weight', '');
      updateFormData('origin_lot_2_weight', '');
      updateFormData('origin_lot_3_weight', '');

      /* Reset the other options that are dependant as well */
      updateErrorData('lot_id', '');
      updateFormData('origin_lot_1_weight', '');
      updateFormData('origin_lot_2_weight', '');
      updateFormData('origin_lot_3_weight', '');
    },
    name: (e: InputChg) => {
      if (errorData.name) updateErrorData('name', '');
      updateFormData('name', e.target.value);
    },
    silobag_species_type_id: (e: SelChg) => {
      if (errorData.silobag_species_type_id) updateErrorData('silobag_species_type_id', '');
      updateFormData('silobag_species_type_id', e.target.value);
    },
    variety: (e: InputChg) => updateFormData('variety', e.target.value),
    silobag_product_type_id: (e: SelChg) => {
      if (errorData.silobag_product_type_id) updateErrorData('silobag_product_type_id', '');
      updateFormData('silobag_product_type_id', e.target.value);
    },
    silobag_harvest_id: (e: SelChg) => updateFormData('silobag_harvest_id', e.target.value),
    lot_id: (e: SelChg) => {
      if (errorData.lot_id) updateErrorData('lot_id', '');
      updateFormData('lot_id', e.target.value);
    },
    origin_lot_1: (e: SelChg) => updateFormData('origin_lot_1', e.target.value ? e.target.value : null),
    origin_lot_1_weight: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.ONLY_NUMS(input)) {
        updateErrorData('origin_lot_1_weight', i18n.t('app.new_edit_silobag.only_numbers'));
        return;
      }
      if (errorData.origin_lot_1_weight) updateErrorData('origin_lot_1_weight', '');
      updateFormData('origin_lot_1_weight', input);
    },
    origin_lot_2: (e: SelChg) => updateFormData('origin_lot_2', e.target.value ? e.target.value : null),
    origin_lot_2_weight: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.ONLY_NUMS(input)) {
        updateErrorData('origin_lot_2_weight', i18n.t('app.new_edit_silobag.only_numbers'));
        return;
      }
      if (errorData.origin_lot_2_weight) updateErrorData('origin_lot_2_weight', '');
      updateFormData('origin_lot_2_weight', input);
    },
    origin_lot_3: (e: SelChg) => updateFormData('origin_lot_3', e.target.value ? e.target.value : null),
    origin_lot_3_weight: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.ONLY_NUMS(input)) {
        updateErrorData('origin_lot_3_weight', i18n.t('app.new_edit_silobag.only_numbers'));
        return;
      }
      if (errorData.origin_lot_3_weight) updateErrorData('origin_lot_3_weight', '');
      updateFormData('origin_lot_3_weight', input);
    },
    size: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.ONLY_NUMS(input)) {
        updateErrorData('size', i18n.t('app.new_edit_silobag.only_numbers'));
        return;
      }
      if (errorData.size) updateErrorData('size', '');
      updateFormData('size', input);
    },
    bagging_date: (value: Date | null) => updateFormData('bagging_date', value),
    input_weight: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.ONLY_NUMS(input)) {
        updateErrorData('input_weight', i18n.t('app.new_edit_silobag.only_numbers'));
        return;
      }

      if (errorData.input_weight) updateErrorData('input_weight', '');
      if (parseInt(input, 10) < 1000) {
        updateErrorData('input_weight', i18n.t('app.new_edit_silobag.less_1000_kg'));
      } else {
        updateErrorData('input_weight', ''); // Limpiar el error si el valor es válido
      }
      updateFormData('input_weight', input);
    },
    initial_humidity: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.BETWEEN_0_AND_100(input)) {
        updateErrorData('initial_humidity', i18n.t('app.new_edit_silobag.numbers_decimals'));
        return;
      }
      if (errorData.initial_humidity) updateErrorData('initial_humidity', '');
      updateFormData('initial_humidity', input !== '' ? input : null);
    },
    main_lat: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.TYPING_FLOAT_NUMS(input)) {
        updateErrorData('main_lat', i18n.t('app.new_edit_silobag.only_coords'));
        return;
      }
      if (errorData.main_lat) updateErrorData('main_lat', '');
      updateFormData('main_lat', input !== '' ? input : null);
    },
    main_lon: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.TYPING_FLOAT_NUMS(input)) {
        updateErrorData('main_lon', i18n.t('app.new_edit_silobag.only_coords'));
        return;
      }
      if (errorData.main_lon) updateErrorData('main_lon', '');
      updateFormData('main_lon', input !== '' ? input : null);
    },
    observations: (e: InputChg) => updateFormData('observations', e.target.value),
  };

  useEffectOnUpdate(() => {
    if (formData.is_guest) {
      const newArr = [formData.lots];
      setLots((newArr as Lot[]) ?? []);
      return;
    }
    if (countrysides && formData.countryside_id) {
      const currentCsLots = countrysides.find((c) => c.id === formData.countryside_id)!.lots;
      setLots(currentCsLots ?? []);
    }
  }, [formData.countryside_id, countrysides]);

  return (
    <Stack spacing={3.5} marginTop={3}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
        <FormControl
          fullWidth
          size="small"
          required
          disabled={isCountrysidesLoading}
          error={Boolean(errorData.countryside_id)}
        >
          <InputLabel>{i18n.t('app.countryside')}</InputLabel>
          <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
            <Select
              value={(formData.countryside_id as '') ?? ''}
              id="countryside_id"
              disabled={isCountrysidesLoading || (type === 'edit' && isGuest)}
              label={i18n.t('app.countryside')}
              onChange={HANDLERS.countryside_id}
              style={{ flex: 1 }}
            >
              {type === 'edit' && isGuest ? (
                <MenuItem value={formData.countryside_id ?? ''}>{formData.countrysides?.name}</MenuItem>
              ) : (
                countrysides?.map((cs) => (
                  <MenuItem key={cs.id} value={cs.id}>
                    {cs?.name}
                  </MenuItem>
                ))
              )}
            </Select>
            {isCountrysidesLoading ? <CircularProgress size={24} /> : null}
          </Stack>
          {errorData.countryside_id ? <FormHelperText>{errorData.countryside_id}</FormHelperText> : null}
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.name')}
          helperText={errorData.name}
          error={Boolean(errorData.name)}
          required
          fullWidth
          onChange={HANDLERS.name}
          value={formData.name}
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
        <Stack direction={'row'} width={'100%'} spacing={{ xs: 1, md: 3 }}>
          <FormControl
            fullWidth
            size="small"
            required
            disabled={isSpeciesLoading}
            error={Boolean(errorData.silobag_species_type_id)}
          >
            <InputLabel>{i18n.t('app.silobag_data.specie')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <Select
                value={(formData.silobag_species_type_id as '') ?? ''}
                disabled={isSpeciesLoading || hasLabProtocol}
                label={i18n.t('app.silobag_data.specie')}
                onChange={HANDLERS.silobag_species_type_id}
                style={{ flex: 1 }}
              >
                {species?.map((sp) => (
                  <MenuItem key={sp.id} value={sp.id}>
                    {sp.name}
                  </MenuItem>
                ))}
              </Select>
              {isSpeciesLoading ? <CircularProgress size={24} /> : null}
            </Stack>
            {errorData.silobag_species_type_id ? (
              <FormHelperText>{errorData.silobag_species_type_id}</FormHelperText>
            ) : null}
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.variety')}
            fullWidth
            onChange={HANDLERS.variety}
            value={formData.variety}
          />
        </Stack>
        <Stack direction={'row'} width={'100%'} spacing={{ xs: 1, md: 3 }}>
          <FormControl
            fullWidth
            size="small"
            required
            disabled={isProductsLoading}
            error={Boolean(errorData.silobag_product_type_id)}
          >
            <InputLabel>{i18n.t('app.silobag_data.product')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <Select
                value={(formData.silobag_product_type_id as '') ?? ''}
                disabled={isProductsLoading}
                label={i18n.t('app.silobag_data.product')}
                onChange={HANDLERS.silobag_product_type_id}
                style={{ flex: 1 }}
              >
                {products?.map((prod) => (
                  <MenuItem key={prod.id} value={prod.id}>
                    {prod.name}
                  </MenuItem>
                ))}
              </Select>
              {isProductsLoading ? <CircularProgress size={24} /> : null}
            </Stack>
            {errorData.silobag_product_type_id ? (
              <FormHelperText>{errorData.silobag_product_type_id}</FormHelperText>
            ) : null}
          </FormControl>
          <FormControl fullWidth size="small" disabled={isHarvestsLoading}>
            <InputLabel>{i18n.t('app.silobag_data.harvest')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <Select
                value={(formData.silobag_harvest_id as '') ?? ''}
                disabled={isHarvestsLoading}
                label={i18n.t('app.silobag_data.harvest')}
                onChange={HANDLERS.silobag_harvest_id}
                style={{ flex: 1 }}
              >
                {harvests?.map((harv) => (
                  <MenuItem key={harv.id} value={harv.id}>
                    {harv.name}
                  </MenuItem>
                ))}
              </Select>
              {isHarvestsLoading ? <CircularProgress size={24} /> : null}
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
      {/* LOTE STOCK */}
      <FormControl fullWidth size="small" required error={Boolean(errorData.lot_id)}>
        <InputLabel>{i18n.t('app.silobag_data.lot_stock')}</InputLabel>
        <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
          <Select
            value={formData.lot_id as ''}
            disabled={!formData.countryside_id || (type === 'edit' && isGuest)}
            label={i18n.t('app.silobag_data.lot_stock')}
            onChange={HANDLERS.lot_id}
            onClick={() =>
              !formData.countryside_id
                ? updateErrorData('lot_id', i18n.t('app.silobag_data.field_selection'))
                : null
            }
            onTouchStart={() =>
              !formData.countryside_id
                ? updateErrorData('lot_id', i18n.t('app.silobag_data.field_selection'))
                : null
            }
            style={{ flex: 1 }}
          >
            {type === 'edit' && isGuest ? (
              <MenuItem value={formData.lot_id as number}>{formData.lots?.name}</MenuItem>
            ) : (
              lots?.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  {l?.name}
                </MenuItem>
              ))
            )}
          </Select>
        </Stack>
        {errorData.lot_id ? <FormHelperText>{errorData.lot_id}</FormHelperText> : null}
      </FormControl>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} alignItems="center">
        {[1, 2, 3].map((n) => (
          <Fragment key={n}>
            <FormControl
              fullWidth
              size="small"
              error={Boolean(errorData[('origin_lot_' + n) as 'origin_lot_1'])}
              sx={{ maxWidth: { xs: '100%', md: 275 } }}
            >
              <InputLabel>{i18n.t('app.silobag_data.lot_origin')}</InputLabel>
              <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                <Select
                  value={(formData[('origin_lot_' + n) as 'origin_lot_1'] as '') ?? ''}
                  disabled={!formData.countryside_id || (type === 'edit' && isGuest)}
                  label={i18n.t('app.silobag_data.lot_origin')}
                  onChange={HANDLERS[('origin_lot_' + n) as 'origin_lot_1' | 'origin_lot_2' | 'origin_lot_3']}
                  inputProps={{ style: { maxWidth: 100 } }}
                  style={{ flex: 1 }}
                >
                  <MenuItem value={''}>
                    <em>{i18n.t('app.silobag_data.none')}</em>
                  </MenuItem>
                  {lots
                    ?.filter(
                      (l) =>
                        ![formData.origin_lot_1, formData.origin_lot_2, formData.origin_lot_3].includes(l.id)
                    )
                    .map((l) => (
                      <MenuItem key={l.id} value={l.id}>
                        {l.name}
                      </MenuItem>
                    ))}
                  {lots?.filter((l) =>
                    [formData.origin_lot_1, formData.origin_lot_2, formData.origin_lot_3].includes(l.id)
                  ).length ? (
                    <MenuItem disabled>
                      <em>{i18n.t('app.silobag_list.selected')}</em>
                    </MenuItem>
                  ) : null}
                  {lots
                    ?.filter((l) =>
                      [formData.origin_lot_1, formData.origin_lot_2, formData.origin_lot_3].includes(l.id)
                    )
                    .map((l) => (
                      <MenuItem key={l.id} value={l.id} disabled>
                        {l.name}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              {errorData[('origin_lot_' + n) as 'origin_lot_1'] ? (
                <FormHelperText style={{ maxWidth: 275 }}>
                  {errorData[('origin_lot_' + n) as 'origin_lot_1']}
                </FormHelperText>
              ) : null}
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              label={i18n.t('app.silobag_data.weight_lot')}
              disabled={
                !formData[('origin_lot_' + n) as 'origin_lot_1_weight'] || (isGuest)
              }
              fullWidth
              helperText={errorData[('origin_lot_' + n + '_weight') as 'origin_lot_1_weight']}
              error={Boolean(errorData[('origin_lot_' + n + '_weight') as 'origin_lot_1_weight'])}
              value={formData[('origin_lot_' + n + '_weight') as 'origin_lot_1_weight'] ?? ''}
              onChange={HANDLERS[('origin_lot_' + n + '_weight') as 'origin_lot_1_weight']}
            />
          </Fragment>
        ))}
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} alignItems="center">
        <Stack direction={'row'} spacing={{ xs: 1, md: 3 }}>
          <Box width={'100%'}>
            <TextField
              variant="outlined"
              size="small"
              label={i18n.t('app.silobag_data.silobag_size')}
              helperText={errorData.size}
              error={Boolean(errorData.size)}
              fullWidth
              onChange={HANDLERS.size}
              value={formData.size ?? ''}
              style={{ width: '100%' }}
            />
          </Box>
          <Box width={'100%'}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <Container>
                <Box width={'100%'}>
                  <DatePicker<Date>
                    sx={{ minWidth: '100%' }}
                    label={'Embolsado'}
                    value={formData.bagging_date as Date}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                      day: {
                        sx: {
                          '&:hover': {
                            border: '1px solid #6baa0099',
                          },
                          '&.MuiPickersDay-root.Mui-selected': {
                            color: 'white',
                            backgroundColor: '#6baa00',
                            '&:hover': {
                              border: 'none',
                            },
                          },
                          '&.MuiPickersDay-root:focus.Mui-selected': {
                            backgroundColor: '#6baa00',
                          },
                        },
                      },
                    }}
                    onChange={HANDLERS.bagging_date}
                    timezone="UTC"
                  />
                </Box>
              </Container>
            </LocalizationProvider>
          </Box>
        </Stack>
        <Stack direction={'row'} spacing={{ xs: 1, md: 3 }}>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.weight_bagging')}
            helperText={errorData.input_weight}
            error={Boolean(errorData.input_weight)}
            required
            fullWidth
            onChange={HANDLERS.input_weight}
            value={formData.input_weight}
            style={{ flex: 1 }}
            disabled={type === 'edit'}
            InputProps={{
              endAdornment: <InputAdornment position="start">KG</InputAdornment>,
            }}
          />
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.humidity_bagging')}
            helperText={errorData.initial_humidity}
            error={Boolean(errorData.initial_humidity)}
            fullWidth
            onChange={HANDLERS.initial_humidity}
            value={formData.initial_humidity ?? ''}
            style={{ flex: 1 }}
          />
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
        <Stack direction={'row'} spacing={3}>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.lat')}
            helperText={errorData.main_lat}
            disabled={true}
            error={Boolean(errorData.main_lat)}
            fullWidth
            onChange={HANDLERS.main_lat}
            onBlur={() =>
              !VALIDATION.FLOAT_NUMS(formData.main_lat ?? '') &&
              updateErrorData('main_lat', 'Esta coordenada no es válida.')
            }
            value={formData.main_lat ?? ''}
            style={{ flex: 1 }}
          />
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.long')}
            helperText={errorData.main_lon}
            error={Boolean(errorData.main_lon)}
            disabled={true}
            fullWidth
            onChange={HANDLERS.main_lon}
            onBlur={() =>
              !VALIDATION.FLOAT_NUMS(formData.main_lon ?? '') &&
              updateErrorData('main_lon', 'Esta coordenada no es válida.')
            }
            value={formData.main_lon ?? ''}
            style={{ flex: 1 }}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={3}
          justifyContent={{ xs: 'start', md: 'center' }}
          alignItems={'center'}
          style={{ flex: 1 }}
        >
          <Box>{i18n.t('app.ubication')}</Box>
          <Button
            variant="contained"
            type="button"
            // disabled={!formData.main_lat || !formData.main_lon}
            disabled
            style={{
              color: '#FFF',
              width: 158,
              height: 40,
              borderRadius: 100,
              textTransform: 'none',
            }}
            onClick={() => {}}
          >
            {i18n.t('app.georeference')}
          </Button>
        </Stack>
      </Stack>
      <TextField
        variant="outlined"
        size="small"
        label={i18n.t('app.observations')}
        multiline
        fullWidth
        rows={4}
        onChange={HANDLERS.observations}
        value={formData.observations}
        style={{ height: 115 }}
      />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ xs: 2, md: 5 }}
        alignItems={{ xs: 'start', md: 'center' }}
      >
        <Box style={{ fontSize: '1.1rem', fontWeight: 500 }}>
          {i18n.t('app.silobag_devices.instalation_lances')}
        </Box>
        <FormGroup>
          <RadioGroup row value={withDevices} onChange={toggleWithDevices}>
            <FormControlLabel
              value={false}
              disabled={Boolean(devicesFromBackend.length)}
              control={<Radio />}
              label={i18n.t('app.silobag_devices.without_lances')}
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={i18n.t('app.silobag_devices.with_lances')}
            />
          </RadioGroup>
        </FormGroup>
      </Stack>
    </Stack>
  );
}

type AdditionalFormProps = {
  formData: FormData;
  errorData: ErrorData;
  updateFormData: (key: string, value: unknown) => void;
  updateErrorData: (key: string, value: unknown) => void;
};
function AdditionalForm({ formData, errorData, updateFormData, updateErrorData }: AdditionalFormProps) {
  const { summary: humiditySources, isLoading: isHumSrcLoading } = useService(humidityOptionListService, {
    fetchOnMount: true,
  });

  const { summary: weightSources, isLoading: isWghSrcLoading } = useService(weightOptionListService, {
    fetchOnMount: true,
  });

  const VALIDATION = {
    ONLY_NUMS: (value: string) => /^[0-9]*$/.test(value),
    // TODO: Revisar el tipado de este regex
    BETWEEN_0_AND_100: (value: string) => /^(\d{0,2}|\d{0,2}[.]|\d{0,2}[.]\d{1,2}|100)$/.test(value),
    // TODO: Revisar el tipado de este regex
    TYPING_FLOAT_NUMS: (value: string) => /^(\d*|\d*[.,]|\d*[.,]\d*)$/.test(value),
  };

  const HANDLERS = {
    responsible_for_bagging: (e: InputChg) => {
      updateFormData('responsible_for_bagging', e.target.value);
    },
    silobag_brand: (e: InputChg) => {
      updateFormData('silobag_brand', e.target.value);
    },
    varietal_purity: (e: InputChg) => {
      const input = e.target.value;
      if (!VALIDATION.BETWEEN_0_AND_100(input)) {
        updateErrorData('varietal_purity', i18n.t('app.silobag_data.value_range'));
        return;
      }
      updateErrorData('varietal_purity', '');
      updateFormData('varietal_purity', input);
    },
    split_grain_percentage: (e: InputChg) => {
      if (!VALIDATION.BETWEEN_0_AND_100(e.target.value)) {
        updateErrorData('split_grain_percentage', i18n.t('app.silobag_data.value_range'));
        return;
      }
      updateErrorData('split_grain_percentage', '');
      updateFormData('split_grain_percentage', e.target.value);
    },
    fillying_coefficient: (e: InputChg) => {
      if (!VALIDATION.TYPING_FLOAT_NUMS(e.target.value)) {
        updateErrorData('fillying_coefficient', i18n.t('app.silobag_data.only_nums'));
        return;
      }
      updateErrorData('fillying_coefficient', '');
      updateFormData('fillying_coefficient', e.target.value);
    },
    humidity_source_id: (e: SelChg) => {
      updateFormData('humidity_source_id', e.target.value ?? null);
    },
    weight_source_id: (e: SelChg) => {
      updateFormData('weight_source_id', e.target.value ?? null);
    },
    side_charge_id: (e: SelChg) => {
      updateFormData('side_charge_id', e.target.value ?? null);
    },
  };

  return (
    <Stack spacing={3} marginTop={3}>
      <Stack direction="row" spacing={3}>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.silobag_data.bagging_responsible')}
          fullWidth
          onChange={HANDLERS.responsible_for_bagging}
          value={formData.responsible_for_bagging ?? ''}
        />
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.silobag_data.silobag_brand')}
          fullWidth
          onChange={HANDLERS.silobag_brand}
          value={formData.silobag_brand ?? ''}
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
        <Stack direction="row" spacing={3} width={'100%'}>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.varietal_purity')}
            fullWidth
            helperText={errorData.varietal_purity}
            error={Boolean(errorData.varietal_purity)}
            onChange={HANDLERS.varietal_purity}
            value={formData.varietal_purity ?? ''}
          />
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.silobag_data.split_grain')}
            fullWidth
            helperText={errorData.split_grain_percentage}
            error={Boolean(errorData.split_grain_percentage)}
            onChange={HANDLERS.split_grain_percentage}
            value={formData.split_grain_percentage ?? ''}
          />
        </Stack>

        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.silobag_data.fillying_coefficient')}
          fullWidth
          helperText={errorData.fillying_coefficient}
          error={Boolean(errorData.fillying_coefficient)}
          onChange={HANDLERS.fillying_coefficient}
          value={formData.fillying_coefficient ?? ''}
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} width={'100%'}>
        <Stack direction="row" spacing={2} width={'100%'}>
          <FormControl fullWidth size="small" disabled={isHumSrcLoading}>
            <InputLabel>{i18n.t('app.silobag_data.source_humidity')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              {isHumSrcLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Select
                  value={(formData.humidity_source_id as '') ?? ''}
                  disabled={isHumSrcLoading}
                  label={i18n.t('app.silobag_data.source_humidity')}
                  onChange={HANDLERS.humidity_source_id}
                  style={{ flex: 1 }}
                >
                  {humiditySources?.map((hum) => (
                    <MenuItem key={hum.id} value={hum.id}>
                      {hum.description}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Stack>
          </FormControl>
          <FormControl fullWidth size="small" disabled={isWghSrcLoading}>
            <InputLabel>{i18n.t('app.silobag_data.source_weight')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              {isHumSrcLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Select
                  value={(formData.weight_source_id as '') ?? ''}
                  disabled={isHumSrcLoading}
                  label={i18n.t('app.silobag_data.source_weight')}
                  onChange={HANDLERS.weight_source_id}
                  style={{ flex: 1 }}
                >
                  {weightSources?.map((wgh) => (
                    <MenuItem key={wgh.id} value={wgh.id}>
                      {wgh.description}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Stack>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2} width={'100%'}>
          <FormControl fullWidth size="small" disabled={isWghSrcLoading}>
            <InputLabel>{i18n.t('app.silobag_data.side_charge')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <Select
                value={(formData.side_charge_id as '') ?? ''}
                disabled={isHumSrcLoading}
                label={i18n.t('app.silobag_data.side_charge')}
                onChange={HANDLERS.side_charge_id}
                style={{ flex: 1 }}
              >
                <MenuItem value={1}>{i18n.t('app.lot')}</MenuItem>
                <MenuItem value={2}>Camino</MenuItem>
              </Select>
            </Stack>
          </FormControl>
          <FormControl fullWidth size="small" disabled={isWghSrcLoading}>
            {/* TODO: se debera aplicar tal cual como esta en el mobile, se informo que se abrira otro ticket para esto especificamente */}
            <InputLabel>{i18n.t('app.silobag_data.have_breaks')}</InputLabel>
            <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
              <Select
                //value={formData.side_charge_id ?? ''}
                disabled={isHumSrcLoading}
                label={i18n.t('app.silobag_data.have_breaks')}
                //  onChange={HANDLERS.side_charge_id}
                style={{ flex: 1 }}
              >
                <MenuItem value={1}>Si</MenuItem>
                <MenuItem value={2}>No</MenuItem>
              </Select>
            </Stack>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
}

type Unpacked<T> = T extends (infer A)[] ? A : T;

type DevicesFormProps = {
  submitUnlinkDevice: (id: number) => void;
  isUnlinkDeviceLoading: boolean;
  openUnlinkDeviceModal: boolean;
  toggleOpenUnlinkDeviceModal: (...args: unknown[]) => void;
  withDevices: boolean;
  tabIDX: number;
  availableDevices: listAvailableDeviceServiceResponse;
  isAvailableDevicesLoading: boolean;
  devicesFromBackend: DeviceFromBackend[];
  updateDeviceFromBackend: (idx: number, dev: DeviceFromBackend) => void;
  devicesAddedByUser: DeviceAddedByUser[];
  updateDeviceAddedByUser: (idx: number, dev: DeviceAddedByUser) => void;
  devKeys: `${string}-${string}-${string}-${string}-${string}`[];
  addDeviceByUser: (idx: number, dev: DeviceAddedByUser) => void;
  removeDeviceAddedByUser: (idx: number) => void;
  isGuest: boolean;
  isMobile: number;
  hadOriginClosure?: boolean;
};
function DevicesForm({
  submitUnlinkDevice,
  isGuest,
  isUnlinkDeviceLoading,
  openUnlinkDeviceModal,
  toggleOpenUnlinkDeviceModal,
  withDevices,
  tabIDX,
  availableDevices,
  isAvailableDevicesLoading,
  devicesFromBackend,
  updateDeviceFromBackend,
  devicesAddedByUser,
  updateDeviceAddedByUser,
  devKeys,
  addDeviceByUser,
  removeDeviceAddedByUser,
  isMobile,
  hadOriginClosure,
}: DevicesFormProps) {
  const initAvailableDevices =
    availableDevices.map((dev) => ({
      ...dev,
      selected: false,
    })) ?? [];
  const {
    array: availableDevicesWithSelection,
    update,
    set,
  } = useArray<Unpacked<listAvailableDeviceServiceResponse> & { selected: boolean }>();

  const [targetDeviceToUnlink, setTargetDeviceToUnlink] = useState<DeviceFromBackend | null>(null);

  useEffect(() => {
    set(initAvailableDevices);
  }, [availableDevices]);
  return (
    <Stack
      direction="row"
      columnGap={2}
      rowGap={2}
      style={{ display: !withDevices || tabIDX === 1 ? 'none' : '' }}
      flexWrap="wrap"
    >
      {devicesFromBackend.map((dev, i) => (
        <Paper
          key={dev.id}
          style={{
            padding: 16,
            borderRadius: 8,
            minWidth: 300,
            maxWidth: 300,
            minHeight: 250,
            maxHeight: 250,
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center">
              <Box style={{ flex: 1, color: '#6BAA00', fontSize: '1.1rem', fontWeight: 600 }}>
                Lanza instalada
              </Box>
              <Button
                sx={{
                  color: '#F00',
                  width: 32,
                  minWidth: 32,
                  height: 32,
                  padding: 2,
                  borderRadius: 1,
                  '&:hover': { background: '#FF000022' },
                }}
                onClick={() => {
                  setTargetDeviceToUnlink(dev);
                  toggleOpenUnlinkDeviceModal(true);
                }}
              >
                <img src={RemoveDevIcon} style={{ width: 16, height: 16 }} />
              </Button>
            </Stack>
            <TextField
              variant="outlined"
              size="small"
              label="N° lanza / Nombre"
              fullWidth
              disabled
              defaultValue={dev.lance_number}
            />
            <FormControl fullWidth size="small">
              <InputLabel>Posición</InputLabel>
              <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                <Select
                  label="Posición"
                  // TODO: Revisar este tipado
                  value={dev.position ? (`${dev.position}` as '') : ''}
                  onChange={(e: SelChg) => {
                    updateDeviceFromBackend(i, { ...dev, position: parseInt(e.target.value as string, 10) });
                  }}
                  style={{ flex: 1 }}
                >
                  <MenuItem value={1}>Inicio</MenuItem>
                  <MenuItem value={2}>Inicio-Medio</MenuItem>
                  <MenuItem value={3}>Medio</MenuItem>
                  <MenuItem value={4}>Medio-Fin</MenuItem>
                  <MenuItem value={5}>Fin</MenuItem>
                </Select>
              </Stack>
            </FormControl>
            <Stack rowGap={1}>
              <Box style={{ flex: 1, fontSize: '0.9rem', fontWeight: 500, color: '#666' }}>
                Fotos del dispositivo
              </Box>
              <Box alignSelf="center" style={{ color: '#a7a7a7' }}>
                {i18n.t('app.silobag_data.no_image')}
              </Box>
            </Stack>
          </Stack>
        </Paper>
      ))}
      {devicesAddedByUser.map((dev, i) => (
        <Paper
          key={devKeys[i]}
          style={{
            padding: 16,
            borderRadius: 8,
            minWidth: 300,
            maxWidth: 300,
            minHeight: 250,
            maxHeight: 250,
          }}
        >
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center">
              <Box style={{ flex: 1, fontSize: '1.1rem', fontWeight: 600, color: '#666' }}>
                {i18n.t('app.silobag_devices.new_lance')}
              </Box>
              <Button
                sx={{
                  color: '#a7a7a7',
                  width: 32,
                  minWidth: 32,
                  height: 32,
                  padding: 2,
                  borderRadius: 1,
                  '&:hover': { background: '#a7a7a722' },
                }}
                onClick={() => {
                  const lastSelectedDevIDX = availableDevicesWithSelection.findIndex((d) => d.id === dev.id);
                  update(lastSelectedDevIDX, {
                    ...availableDevicesWithSelection[lastSelectedDevIDX],
                    selected: false,
                  });
                  removeDeviceAddedByUser(i);
                }}
              >
                <img src={CloseIcon} style={{ width: 16, height: 16 }} />
              </Button>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <FormControl fullWidth size="small" required>
                <InputLabel>{i18n.t('app.silobag_devices.number_lance')}</InputLabel>
                <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                  <Select
                    label={i18n.t('app.silobag_devices.number_lance')}
                    // TODO: Revisar este tipado
                    value={dev.id ? (`${dev.id}` as '') : ''}
                    disabled={isAvailableDevicesLoading}
                    onChange={(e: SelChg) => {
                      const input = parseInt(e.target.value as string, 10);

                      if (dev.id != null) {
                        const lastSelectedDevIDX = availableDevicesWithSelection.findIndex(
                          (d) => d.id === dev.id
                        );
                        update(lastSelectedDevIDX, {
                          ...availableDevicesWithSelection[lastSelectedDevIDX],
                          selected: false,
                        });
                      }
                      const targetIDX = availableDevicesWithSelection.findIndex((d) => d.id === input);
                      update(targetIDX, { ...availableDevicesWithSelection[targetIDX], selected: true });

                      updateDeviceAddedByUser(i, { ...dev, id: input });
                    }}
                    style={{ flex: 1 }}
                  >
                    {availableDevicesWithSelection
                      ?.filter(({ selected }) => !selected)
                      .map((dev) => (
                        <MenuItem key={dev.id} value={dev.id}>
                          {dev.lance_number}
                        </MenuItem>
                      ))}
                    {availableDevicesWithSelection?.filter(({ selected }) => selected).length ? (
                      <MenuItem disabled>
                        <em>Seleccionadas</em>
                      </MenuItem>
                    ) : null}
                    {availableDevicesWithSelection
                      ?.filter(({ selected }) => selected)
                      .map((dev) => (
                        <MenuItem key={dev.id} value={dev.id} disabled>
                          {dev.lance_number}
                        </MenuItem>
                      ))}
                  </Select>
                </Stack>
              </FormControl>
              {isAvailableDevicesLoading ? <CircularProgress size={24} /> : null}
            </Stack>

            <FormControl fullWidth size="small" required>
              <InputLabel>{i18n.t('app.silobag_devices.position')}</InputLabel>
              <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
                <Select
                  label={i18n.t('app.silobag_devices.position')}
                  value={dev.position ? (`${dev.position}` as '') : ''}
                  onChange={(e: SelChg) => {
                    updateDeviceAddedByUser(i, { ...dev, position: parseInt(e.target.value as string, 10) });
                  }}
                  style={{ flex: 1 }}
                >
                  <MenuItem value={1}>{i18n.t('app.silobag_devices.init')}</MenuItem>
                  <MenuItem value={2}>{i18n.t('app.silobag_devices.init_middle')}</MenuItem>
                  <MenuItem value={3}>{i18n.t('app.silobag_devices.middle')}</MenuItem>
                  <MenuItem value={4}>{i18n.t('app.silobag_devices.middle_end')}</MenuItem>
                  <MenuItem value={5}>{i18n.t('app.silobag_devices.end')}</MenuItem>
                </Select>
              </Stack>
            </FormControl>
            <Stack rowGap={1}>
              <Box style={{ flex: 1, fontSize: '0.9rem', fontWeight: 500, color: '#666' }}>
                {i18n.t('app.silobag_devices.photo_device')}
              </Box>
              <Box alignSelf="center" style={{ color: '#a7a7a7' }}>
                {i18n.t('app.silobag_data.no_image')}
              </Box>
            </Stack>
          </Stack>
        </Paper>
      ))}
      <Box alignSelf="center">
        <Button
          disabled={
            availableDevicesWithSelection?.every((d) => d.selected) ||
            devicesAddedByUser.length + 1 > (availableDevices?.length ?? Infinity) ||
            isAvailableDevicesLoading ||
            isGuest ||
            (isMobile === 2 && hadOriginClosure === false)
          }
          style={{ fontWeight: 600, fontSize: '2rem', height: 50 }}
          onClick={() => addDeviceByUser(-1, { id: null, position: null, lance_number: null })}
        >
          +
        </Button>
      </Box>
      <UnlinkDeviceModal
        open={openUnlinkDeviceModal}
        targetID={targetDeviceToUnlink?.id ?? -1}
        targetName={targetDeviceToUnlink?.lance_number ?? ''}
        targetTextRef="la lanza"
        onClose={() => toggleOpenUnlinkDeviceModal(false)}
        submitUnlinkDevice={submitUnlinkDevice}
        isUnlinkDeviceLoading={isUnlinkDeviceLoading}
      />
    </Stack>
  );
}

type UnlinkDeviceProps = {
  open: boolean;
  targetID: number;
  targetName: string;
  targetTextRef: string;
  onClose: () => void;
  submitUnlinkDevice: (id: number) => void;
  isUnlinkDeviceLoading: boolean;
};

function UnlinkDeviceModal({
  open,
  onClose,
  targetID,
  targetName,
  targetTextRef,
  submitUnlinkDevice,
  isUnlinkDeviceLoading,
}: UnlinkDeviceProps) {
  return (
    <MyModal open={open} onClose={onClose}>
      <Stack direction="row" spacing={2} alignItems="center" style={{ fontWeight: '600', fontSize: '24px' }}>
        <img style={{ width: 20, height: 20 }} src={RemoveDevIcon} />
        <Box>{i18n.t('app.silobag_devices.confirm_disassociation_lance')}</Box>
      </Stack>
      <Divider />
      <Box>
        ¿Está seguro que desea desvincular {targetTextRef}
        <Box style={{ fontWeight: 600, color: '#F00', display: 'inline-block' }}>&nbsp;{targetName}</Box>?
      </Box>
      <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={1}>
        <Button
          variant="outlined"
          disabled={isUnlinkDeviceLoading}
          style={{
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
          }}
          onClick={onClose}
        >
          {i18n.t('app.cancel')}
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="error"
          disabled={isUnlinkDeviceLoading}
          style={{
            color: '#FFF',
            width: 115,
            borderRadius: 100,
            fontWeight: 600,
            textTransform: 'none',
          }}
          onClick={() => targetID && submitUnlinkDevice(targetID)}
        >
          {isUnlinkDeviceLoading ? (
            <CircularProgress style={{ width: 24, height: 24, color: '#FF0000' }} />
          ) : (
            'Desvincular'
          )}
        </Button>
      </Stack>
    </MyModal>
  );
}

type ConfirmEditionProps = {
  open: boolean;
  onClose: () => void;
  editSilobag: (parameters: QueryUpdateSilobagPayload) => void;
  isEditLoading: boolean;
  body?: FormData;
};

function ConfirmEditModal({ open, onClose, editSilobag, isEditLoading, body }: ConfirmEditionProps) {
  const SaveEdition = () =>
    editSilobag({ query: body?.id as string, body: (body as QueryUpdateSilobagPayload['body'])! });

  return (
    <MyModal open={open} onClose={onClose}>
      <Stack direction="row" spacing={2} alignItems="center" style={{ fontWeight: '600', fontSize: '24px' }}>
        <Box>{i18n.t('app.atention')}</Box>
      </Stack>
      <Divider />
      <Box>{i18n.t('app.silobag_edit_modal.explanation')}</Box>
      <Box> {i18n.t('app.silobag_edit_modal.confirmation')}</Box>
      <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={1}>
        <Button
          variant="outlined"
          style={{
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
          }}
          onClick={onClose}
        >
          {i18n.t('app.cancel')}
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={isEditLoading}
          style={{
            color: '#FFF',
            width: 108,
            height: 40,
            borderRadius: 100,
            textTransform: 'none',
          }}
          onClick={SaveEdition}
        >
          {isEditLoading ? <CircularProgress style={{ width: 24, height: 24 }} /> : i18n.t('app.save')}
        </Button>
      </Stack>
    </MyModal>
  );
}

type PhotoCarouselProps = {
  title: string;
  images: {
    url: string;
    label?: string;
  }[];
};

function PhotoCarousel({ title, images }: PhotoCarouselProps) {
  const [listIDX, setListIDX] = useState(0);

  const SCROLL = {
    toLeft: () => setListIDX((prev) => (prev - 1 + images.length) % images.length),
    toRight: () => setListIDX((prev) => (prev + 1) % images.length),
  };

  /* No se puede dejar porque centra automáticamente la imagen en pantalla
  useEffect(() => {
    const timer = setTimeout(() => {
      SCROLL.toRight();
    }, 5000);
    return () => clearTimeout(timer);
  }, [listIDX]);
  */

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box style={{ fontWeight: '600', fontSize: '24px', flex: 1 }}>
          {title} ({listIDX + 1}/{images.length})
        </Box>
        <Box sx={{ fontWeight: '600', marginRight: '10px' }}>{images[listIDX]?.label ?? ''}</Box>
      </Stack>
      <Box component="section" className="container">
        <Box className="slider-wrapper">
          <Paper
            className="slider-control slider-control-left"
            sx={{ borderRadius: '100%', opacity: 0.3, '&:hover': { opacity: 1 } }}
            onClick={SCROLL.toLeft}
          >
            <Box>{'<'}</Box>
          </Paper>
          <Paper
            className="slider-control slider-control-right"
            sx={{ borderRadius: '100%', opacity: 0.3, '&:hover': { opacity: 1 } }}
            onClick={SCROLL.toRight}
          >
            <Box>{'>'}</Box>
          </Paper>
          <Box
            className="slider keep-scrolling-without-scroll"
            style={{ width: '100%', height: '100%', overflow: 'hidden' }}
          >
            {images.map((image, i) => (
              <img
                ref={(node) => {
                  if (node && listIDX === i) {
                    node.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                  }
                }}
                key={i}
                src={image.url}
                alt={image.label !== undefined ? image.label : ''}
              />
            ))}
          </Box>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            gap={1}
            className="slider-nav"
          >
            {images.map((_, i) => (
              <Button key={i} className={listIDX === i ? 'active' : ''} onClick={() => setListIDX(i)} />
            ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};
function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

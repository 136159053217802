import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { ExtractionCreationPayload, CreatedExtractionData } from './extraction-creation.types';

// deprecated service ref.: silobagExtractionService_DEPRECATED
export async function createExtractionService({ query: rawQuery, body }: ExtractionCreationPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.post<CreatedExtractionData>(ENDPOINT.SILOBAG_EXTRACTION, {
    query,
    body,
  });

  return {
    response,
    summary: data,
  };
}

import React from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import CloseIcon from '@assets/svg/close.svg';
import { stylesInput } from '@styles/generic-styles';

type Option = {
  id: string | number;
  name: string;
  color?: 'error' | 'warning' | 'secondary' | 'primary';
};

// TODO: Hay valores any que deberían ser tipados
type CustomSelectProps = {
  label: string;
  id: string;
  value: string | number | (string | number)[] | boolean;
  onChange: ((event: SelectChangeEvent<unknown>, child: React.ReactNode) => void) | undefined;
  options: Option[] | undefined | unknown[];
  multiple?: boolean;
  disabled?: boolean;
  renderValue?: (selected: unknown) => React.ReactNode;
  placeholder?: string;
  deleteFilter?: () => void;
  selectedList?: Option[] | number | string | boolean;
  withChip?: boolean;
  propToShow?: string;
};

export function Selector({
  label,
  id,
  value,
  onChange,
  options,
  multiple = false,
  disabled = false,
  renderValue,
  placeholder,
  deleteFilter,
  selectedList,
  withChip = false,
  propToShow,
}: CustomSelectProps) {
  const hasSelectedItems = Array.isArray(selectedList) ? selectedList.length > 0 : Boolean(selectedList);
  return (
    <FormControl variant="outlined" size="medium" style={{ width: '98%' }} sx={stylesInput}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        input={<OutlinedInput label={label} />}
        value={value}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
        renderValue={renderValue}
        endAdornment={
          <IconButton onClick={deleteFilter} sx={{ visibility: hasSelectedItems ? 'visible' : 'hidden' }}>
            <img src={CloseIcon} alt="cross-icon" />
          </IconButton>
        }
        sx={{
          '& .MuiSelect-iconOutlined': { display: hasSelectedItems ? 'none' : '' },
        }}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {options?.map((item) => (
          <MenuItem
            key={item.id ?? item[propToShow]}
            value={item.id ?? item[propToShow]}
            style={{ color: item.color }}
          >
            {multiple && Array.isArray(selectedList) && (
              <Checkbox checked={selectedList?.some((selectedItem) => selectedItem.id === item.id)} />
            )}
            {withChip && (
              <Chip
                label=""
                color={item.color}
                style={{
                  height: '0.7rem',
                  width: '1rem',
                  marginRight: '0.5rem',
                }}
              />
            )}
            {item.name ?? item[propToShow]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

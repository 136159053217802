import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import {
  AlertMassiveResolutionPayload,
  AlertMassiveResolutionResponse,
} from './alert-massive-resolution.types';

export async function alertMassiveResolutionService({ query: rawQuery }: AlertMassiveResolutionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.post<AlertMassiveResolutionResponse>(
    ENDPOINT.ALERT_ACTIONS_MASSIVE,
    { query }
  );

  return {
    response,
    summary: data,
  };
}

import { useEffectOnUpdate } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
  Typography,
  OutlinedInput,
  Chip,
  Tooltip,
} from '@mui/material';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import i18n from '../../../libs/language';
import { getPermissionDefaultValue } from '@utils/helper';
import { stylesInput } from '@styles/generic-styles';
import { createUserService } from '@services/domain/user/base/user-creation';
import { updateUserDataService } from '@services/domain/user/base/user-edition';
import ValidIcon from '@assets/svg/SiloBagPage/new_releases.svg';
import warningIcon from '@assets/svg/warning.svg';
import { listUsersService } from '@services/domain/user/base/user-list';
import mixpanel from 'mixpanel-browser';
import { ToastAction } from '@ui/toast/toast-action';

type UserSummary = Awaited<ReturnType<typeof listUsersService>>['summary'][0];

type AdminModalProps = {
  open: boolean;
  type: 'new' | 'view' | 'edit';
  parentFormData?: UserSummary;
  onSuccessTrigger: () => void;
  enableEditMode: () => void;
  setIsOpen: (value: boolean) => void;
  setNewEditUserToast: (value: boolean) => void;
} & (NewUserModalProps | EditUserModalProps);

type NewUserModalProps = {
  type: 'new';
  parentFormData: never;
};
type EditUserModalProps = {
  type: 'view' | 'edit';
  parentFormData: UserSummary;
};

type SelChg = SelectChangeEvent;
type InputChg = ChangeEvent<HTMLInputElement>;

type ErrorMessageCode = {
  data: {
    error: boolean;
    message: {
      code: number;
      message: string;
    };
  };
};
export function UserViewEditModal({
  open,
  type,
  parentFormData,
  onSuccessTrigger,
  enableEditMode,
  setIsOpen,
  setNewEditUserToast,
}: AdminModalProps) {
  const handleClose = () => {
    setIsOpen(false);
    setFormData(initUserData);
    setErrorData(initErrorData);
  };

  const closeOnSuccess = () => {
    setNewEditUserToast(true);
    handleClose();
    onSuccessTrigger();
  };

  const {
    trigger: createUser,
    isLoading: isCreationLoading,
    isError: isErrorUser,
    error: errorUser,
  } = useService(createUserService, {
    onSuccess: () => {
      closeOnSuccess();
      mixpanel.track('DEV@USER_CREATION');
    },
  });

  const {
    trigger: updateUser, //
    isLoading: isUpdateLoading, //
    isError: isErrorUpdateUser,
    error: userUpdateError,
  } = useService(updateUserDataService, {
    onSuccess: () => {
      closeOnSuccess();
      mixpanel.track('DEV@USER_EDITION');
    },
  });

  const errorMessages: { [key: number | string]: string } = {
    20028: i18n.t('app.administrator.invalid_email'),
    P2002: i18n.t('app.administrator.repeted_mail'),
  };

  const readonly = type === 'view';

  const initUserData = {
    fullname: type !== 'new' ? parentFormData.fullname : '',
    email: type !== 'new' ? parentFormData.email : '',
    national_id: type !== 'new' ? parentFormData?.national_id ?? '' : '',
    phone_area: '54',
    phone: type !== 'new' ? parentFormData?.phone?.slice(2) : '',
    permission:
      type !== 'new'
        ? parentFormData.user_organizations_permissions[0].full_permission
          ? 'admin'
          : parentFormData.user_organizations_permissions[0].write_permission
          ? 'user'
          : 'viewer'
        : '',
    active: type !== 'new' ? parentFormData.active : true,
  };
  const initErrorData = {
    fullname: '',
    email: '',
    national_id: '',
    phone: '',
    permission: '',
  };
  const initCountrysidePermission = () => {
    if (type !== 'new') {
      return parentFormData?.v_countrysides_permissions.map((p) => ({
        countryside_id: p.countryside_id,
        full_permission: p.full_permission,
        write_permission: p.write_permission,
        read_permission: p.read_permission,
        edited: false,
      }));
    }
    return [];
  };

  const [formData, setFormData] = useState(initUserData);
  const [errorData, setErrorData] = useState(initErrorData);
  useEffectOnUpdate(() => setFormData(initUserData), [parentFormData, type]);
  const [countrysidePermission, setCountrysidePermission] = useState<
    {
      countryside_id: number;
      full_permission: boolean;
      write_permission: boolean;
      read_permission: boolean;
      edited: boolean;
    }[]
  >(initCountrysidePermission);

  const phoneAreaDetails = {
    54: {
      flag: '🇦🇷',
      placeholder: '+54 ',
    },
    55: {
      flag: '🇧🇷',
      placeholder: '+55 ',
    },
  };

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^[0-9]*$/.test(value),
    FLOAT_NUMS: (value: string) => !/^-?[0-9]*\.?[0-9]*$/.test(value),
    ALPHANUMERIC_EXT: (value: string) => !/^[\w0-9\s-_.áàâãéêíóôõúñç]*$/i.test(value),
    EMAIL: (value: string) => !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value),
  };

  const firstLevelDataChange = (key: string, value: string | boolean) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const firstLevelDataError = (key: string, value: string) =>
    setErrorData((prev) => ({ ...prev, [key]: value }));

  const HANDLERS = {
    fullname: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ALPHANUMERIC_EXT(input)) {
        const errorMsg =
          'Solo se aceptan caracteres alfanuméricos, espacios, guiones (-), guiones bajos (_) y ampersand (&)';
        firstLevelDataError('fullname', errorMsg);
        return;
      }
      firstLevelDataError('fullname', '');
      firstLevelDataChange('fullname', input);
    },
    email: (e: InputChg) => {
      firstLevelDataError('email', '');
      firstLevelDataChange('email', e.target.value);
    },
    national_id: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        firstLevelDataError('national_id', 'Solo se aceptan números.');
        return;
      }
      firstLevelDataError('national_id', '');
      firstLevelDataChange('national_id', input);
    },
    phone_area: (event: SelChg) => {
      firstLevelDataError('phone_area', '');
      firstLevelDataChange('phone_area', event.target.value as '54' | '55');
    },
    phone: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        firstLevelDataError('phone', 'Solo se aceptan números.');
        return;
      }
      firstLevelDataError('phone', '');
      firstLevelDataChange('phone', input);
    },
    permission: (e: SelChg) => {
      firstLevelDataError('permission', '');
      firstLevelDataChange('permission', e.target.value);
    },
    active: (e: InputChg) => firstLevelDataChange('active', e.target.checked),
  };

  const permissionSettings = {
    user: {
      read_permission: true,
      write_permission: true,
      full_permission: false,
    },
    admin: {
      read_permission: true,
      write_permission: true,
      full_permission: true,
    },
    viewer: {
      read_permission: true,
      write_permission: false,
      full_permission: false,
    },
    denied: {
      read_permission: false,
      write_permission: false,
      full_permission: false,
    },
  } as const;
  //funcion para almacenar los permisos de cada establecimiento
  const selectPermissionType = (countryside_id: number, permissionValue: number) => {
    const permissionMap = {
      1: 'viewer',
      2: 'user',
      3: 'admin',
      4: 'denied',
    } as const;
    const permissionType = permissionMap[permissionValue as 1];

    const newPermission = {
      countryside_id,
      ...permissionSettings[permissionType],
      edited: true,
    };

    setCountrysidePermission((prev) => {
      const newPermissions = [...prev];
      const index = newPermissions.findIndex((p) => p.countryside_id === countryside_id);
      if (index === -1) {
        newPermissions.push(newPermission);
      } else {
        newPermissions[index] = newPermission;
      }
      return newPermissions;
    });
  };

  const submit = () => {
    const errorMsg = i18n.t('app.must_field');
    let error = false;

    if (formData.fullname === '') (error = true), firstLevelDataError('fullname', errorMsg);
    if (formData.email === '') (error = true), firstLevelDataError('email', errorMsg);
    if (formData.permission === '') (error = true), firstLevelDataError('permission', errorMsg);

    if (error) return;
    const body = {
      username: formData.email,
      fullname: formData.fullname,
      email: formData.email,
      phone: formData.phone ? formData.phone_area + formData.phone : null,
      national_id: formData.national_id,
      active: formData.active,
      ...permissionSettings[formData.permission as 'user' | 'admin' | 'viewer'],
      user_countrysides_permissions: countrysidePermission
        .filter((p) => p.edited === true)
        .map((p) => ({
          countryside_id: p.countryside_id,
          read_permission: p.read_permission,
          write_permission: p.write_permission,
          full_permission: p.full_permission,
        })),
    };

    if (type === 'new') createUser({ body });
    if (type === 'edit') {
      const userID = parentFormData?.id;
      const bodyWithOrgID = {
        ...body,
        organization_id: parentFormData?.organizations?.id,
      };
      updateUser({ query: userID, body: bodyWithOrgID });
    }
  };

  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isErrorUpdateUser && (
        <ToastAction
          severity="error"
          message={errorMessages[(userUpdateError as ErrorMessageCode).data.message.code]}
        />
      )}
      {isErrorUser && (
        <ToastAction
          severity="error"
          message={
            errorMessages[(errorUser as ErrorMessageCode)?.data?.message?.code] ??
            i18n.t('app.administrator.error_unexpected')
          }
        />
      )}
      <MyModal open={open} onClose={handleClose}>
        <Box sx={{ fontWeight: '600', fontSize: '24px' }}>
          {type !== 'new' ? (readonly ? 'Usuario' : 'Editar Usuario') : i18n.t('app.administrator.new_user')}
        </Box>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.user_modal.fullname')}
          helperText={errorData.fullname}
          error={Boolean(errorData.fullname)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.fullname}
          value={formData.fullname}
        />
        <FormControl size="small" error={isErrorUser}>
          <TextField
            variant="outlined"
            size="small"
            label="E-mail"
            helperText={errorData.email}
            error={Boolean(errorData.email)}
            required
            fullWidth
            disabled={readonly}
            onChange={HANDLERS.email}
            value={formData.email}
          />
        </FormControl>

        <Stack direction="row" spacing={1}>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.user_modal.dni')}
            helperText={errorData.national_id}
            error={Boolean(errorData.national_id)}
            sx={{ width: '500px' }}
            disabled={readonly}
            onChange={HANDLERS.national_id}
            onBlur={() => firstLevelDataError('national_id', '')}
            value={formData.national_id}
          />
          <FormControl size="small" style={{ width: '144px' }}>
            <InputLabel>Área</InputLabel>
            <Select
              label="Área"
              renderValue={() => <div>{phoneAreaDetails[formData.phone_area as '54']?.flag}</div>}
              value={formData.phone_area}
              onChange={HANDLERS.phone_area}
            >
              <MenuItem value={54}>
                🇦🇷 Argentina <span style={{ color: '#a7a7a7' }}>&nbsp;(+54)</span>
              </MenuItem>
              <MenuItem value={55}>
                🇧🇷 Brasil <span style={{ color: '#a7a7a7' }}>&nbsp;(+55)</span>
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.phone')}
            helperText={
              <Stack direction={'row'} alignItems={'center'} spacing={1} fontSize={11.2}>
                {type === 'edit' && (
                  <img src={warningIcon} alt="" style={{ width: 17, height: 17, marginRight: 4 }} />
                )}
                {errorData.phone || (type === 'edit' ? i18n.t('app.notification_page.warning_phone') : '')}
              </Stack>
            }
            error={Boolean(errorData.phone)}
            fullWidth
            disabled={readonly}
            onChange={HANDLERS.phone}
            value={formData.phone}
            InputProps={{
              startAdornment: phoneAreaDetails[formData.phone_area as '54'] ? (
                <Box style={{ width: 44 }}>{phoneAreaDetails[formData.phone_area as '54']?.placeholder}</Box>
              ) : null,
            }}
          />
          {type !== 'new' && (
            <Tooltip
              title={
                parentFormData.phone_verification_at
                  ? i18n.t('app.notification_page.phone_validated')
                  : i18n.t('app.notification_page.phone_invalidated')
              }
            >
              <img
                src={parentFormData.phone_verification_at ? ValidIcon : warningIcon}
                alt=""
                style={type === 'edit' ? { marginTop: '-20px' } : {}}
              />
            </Tooltip>
          )}
        </Stack>
        <FormControl
          fullWidth
          disabled={readonly}
          size="small"
          required
          error={Boolean(errorData.permission)}
        >
          <InputLabel>{i18n.t('app.user_modal.permission')}</InputLabel>
          <Select value={formData.permission} label="Permiso" onChange={HANDLERS.permission}>
            <MenuItem value={'admin'}>{i18n.t('app.administrator.title')}</MenuItem>
            <MenuItem value={'user'}>{i18n.t('app.user_modal.user_platform')}</MenuItem>
            <MenuItem value={'viewer'}>{i18n.t('app.administrator.visualizer')}</MenuItem>
          </Select>
          {errorData.permission ? <FormHelperText>{errorData.permission}</FormHelperText> : null}
        </FormControl>
        <FormControl disabled={readonly} style={{ display: 'inline-block' }}>
          <FormControlLabel
            value={false}
            control={<Checkbox onChange={HANDLERS.active} checked={formData.active} />}
            label={i18n.t('app.user_modal.active')}
            labelPlacement="end"
          />
        </FormControl>
        {type !== 'new' ? (
          <TableContainer
            style={{ border: '1px solid #e0e0e0', borderRadius: 5, minHeight: 325, overflowY: 'auto' }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ maxWidth: 70 }}>{i18n.t('app.countryside')}</TableCell>
                  <TableCell style={{ display: matchSM ? 'none' : '' }}>
                    {i18n.t('app.countryside_modal.company')}
                  </TableCell>
                  <TableCell align="center">{i18n.t('app.administrator.permission')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parentFormData?.v_countrysides_permissions?.map((permission) => (
                  <TableRow
                    key={permission.permission_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <Box style={{ fontWeight: '600' }}>{permission.countrysides.name}</Box>
                    </TableCell>
                    <TableCell align={matchSM ? 'center' : 'left'} style={{ display: matchSM ? 'none' : '' }}>
                      <Box style={{ fontWeight: '600' }}>{permission.countrysides.companies.name}</Box>
                    </TableCell>
                    <TableCell align="center" style={{ display: matchSM ? 'none' : '' }}>
                      <FormControl variant="outlined" size="small" style={{ width: 200 }} sx={stylesInput}>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="Permission_id"
                          input={<OutlinedInput label={i18n.t('app.silobag_share.type_permission')} />}
                          defaultValue={getPermissionDefaultValue(permission)}
                          size="small"
                          sx={{
                            border: 'none',
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '&:before': {
                              borderBottom: 'none',
                            },
                            '&:after': {
                              borderBottom: 'none',
                            },
                          }}
                          onChange={(e) =>
                            selectPermissionType(permission.countryside_id, e.target.value as number)
                          }
                          disabled={type !== 'edit' || permission.is_guest}
                        >
                          <MenuItem value={1}>
                            <Box>
                              <Typography variant="body2" sx={{ color: '#6baa00' }}>
                                <Chip
                                  label={i18n.t('app.silobag_share.view')}
                                  sx={{
                                    bgcolor: '#6BAA0026',
                                    color: '#6baa00',
                                    fontWeight: 600,
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                />
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={2}>
                            <Box>
                              <Typography variant="body2" sx={{ color: '#6baa00' }}>
                                <Chip
                                  label={i18n.t('app.silobag_share.edit')}
                                  sx={{
                                    bgcolor: '#6BAA0026',
                                    color: '#6baa00',
                                    fontWeight: 600,
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                />
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={3}>
                            <Box>
                              <Typography variant="body2" sx={{ color: '#6baa00' }}>
                                <Chip
                                  label={i18n.t('app.silobag_share.access_complete')}
                                  sx={{
                                    bgcolor: '#6BAA0026',
                                    color: '#6baa00',
                                    fontWeight: 600,
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                />
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={4}>
                            <Box>
                              <Typography variant="body1" sx={{ color: 'red' }}>
                                <Chip
                                  label={i18n.t('app.silobag_share.delete_permission')}
                                  sx={{
                                    bgcolor: '#f7cfce',
                                    color: 'red',
                                    fontWeight: 600,
                                    borderRadius: '4px',
                                    width: '100%',
                                  }}
                                />
                              </Typography>
                            </Box>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
        <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
          <Button
            variant="outlined"
            style={{
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
            }}
            onClick={handleClose}
          >
            {i18n.t('app.cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isCreationLoading || isUpdateLoading}
            style={{
              color: '#FFF',
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
            }}
            onClick={readonly ? enableEditMode : submit}
          >
            {isCreationLoading || isUpdateLoading ? (
              <CircularProgress style={{ width: 24, height: 24 }} />
            ) : readonly ? (
              'Editar'
            ) : (
              i18n.t('app.save')
            )}
          </Button>
        </Stack>
      </MyModal>
    </>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
  keepMounted?: boolean;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflowY: 'auto',
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

import { useGlobalStore } from '@global-store/use-store';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import i18n from '../../libs/language';
import SearchIcon from '@assets/svg/search.svg';
import { LoadingBox, stylesInput } from '@styles/generic-styles';
import { useRef, useState } from 'react';
import IconSiloID from '@assets/svg/siloreal/product-silo-id.svg';
import IconSiloSat from '@assets/svg/siloreal/product-silo-sat.svg';
import IconSiloMetrix from '@assets/svg/siloreal/product-silo-metrix.svg';
import { TabletExistenceStock } from '@features/report-existence-stock/tablet-existence-stock';
import { useDebounce } from '@hooks/core';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { useEffectOnUpdate } from '@hooks/core';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';
import { stockExistenceListService } from '@services/domain/page/report/existence-stock/report-existence-stock-info';
import { listWebSilobagsService } from '@services/domain/base/silobag/base/silobag-web';
import { Selector } from '@ui/selector/selector';

export function ReportExitenceStockPage() {
  const { organizationId, filtersReportExistence, setFiltersReportExistence } = useGlobalStore();

  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>('');

  // eslint-disable-next-line
  const { paginationModel, ...restFilters } = filtersReportExistence;
  const hasFilters = !!name || Object.values(restFilters).some((s) => s !== '');

  const {
    trigger: triggerListWebSilobags,
    summary: listWebSilobagsCount,
    isLoading: isLoadingListWebSilobags,
  } = useService(listWebSilobagsService, {
    fetchOnMount: [organizationId],
  });

  //StateStock Service
  const {
    trigger: getStateExistence,
    summary: stateExistenceData,
    isLoading: isLoadingStateExistence,
  } = useService(stockExistenceListService, {
    fetchOnMount: [
      {
        query: {
          organization_id: organizationId!,
          countryside_id: parseInt(filtersReportExistence.countrysideId),
          silobag_species_type_id: parseInt(filtersReportExistence.specieId),
          page: filtersReportExistence.paginationModel.page,
          take: filtersReportExistence.paginationModel.pageSize,
          name: name,
        },
      },
    ],
  });

  // //Countrysides Service
  const queryCountrysideList = { organization_id: organizationId };
  const {
    summary: countrysides,
    isLoading: isLoadingCountryside,
    trigger: getCountrysides,
  } = useService(listCountrysideService, { fetchOnMount: [{ query: queryCountrysideList }] });

  const { summary: species, isLoading: isLoadingSpecies } = useService(speciesListService, {
    fetchOnMount: true,
  });

  useDebounce(
    () =>
      getStateExistence({
        query: {
          organization_id: organizationId!,
          countryside_id: parseInt(filtersReportExistence.countrysideId),
          silobag_species_type_id: parseInt(filtersReportExistence.specieId),
          page: filtersReportExistence.paginationModel.page,
          take: filtersReportExistence.paginationModel.pageSize,
          name: name,
        },
      }),
    500,
    [name]
  );

  //const handlerCountrysideID = (e: SelectChangeEvent) => {
  const handlerCountrysideID = (e: SelectChangeEvent<string>) => {
    const selectedValue = e.target.value;
    setFiltersReportExistence({ ...filtersReportExistence, countrysideId: selectedValue });
  };

  //const handlerSpecieID = (e: SelectChangeEvent) => {
  const handlerSpecieID = (e: SelectChangeEvent<string>) => {
    const selectedValue = e.target.value;
    setFiltersReportExistence({ ...filtersReportExistence, specieId: selectedValue });
  };

  const removeFilter = () => {
    setFiltersReportExistence({ ...filtersReportExistence, countrysideId: '', specieId: '' });
    setName('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  useEffectOnUpdate(() => {
    getStateExistence({
      query: {
        organization_id: organizationId!,
        countryside_id: parseInt(filtersReportExistence.countrysideId),
        silobag_species_type_id: parseInt(filtersReportExistence.specieId),
        page: filtersReportExistence.paginationModel.page,
        take: filtersReportExistence.paginationModel.pageSize,
        name: name,
      },
    });
  }, [filtersReportExistence, organizationId]);

  useEffectOnUpdate(() => {
    getCountrysides({ query: queryCountrysideList });
  }, [organizationId]);

  useEffectOnUpdate(() => {
    triggerListWebSilobags(organizationId);
  }, [organizationId]);

  const deleteCountrysideFilter = () => {
    setFiltersReportExistence({ ...filtersReportExistence, countrysideId: '' });
  };

  const deleteSpecieFilter = () => {
    setFiltersReportExistence({ ...filtersReportExistence, specieId: '' });
  };

  return (
    <>
      {isLoadingStateExistence && isLoadingListWebSilobags ? (
        <Box sx={LoadingBox}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: '95%', margin: 'auto' }}>
          <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
            {i18n.t('app.existence_report_stock.existence_stock_report_title')}
          </Box>
          <Box component="section" position="relative">
            <Paper elevation={0} style={{ padding: '20px 40px', borderRadius: '10px' }}>
              <Stack direction="row" spacing={2}>
                {/* SILOID */}
                <Stack spacing={-1} justifyContent="center">
                  {/* SILOBOLSAS SILO-ID */}
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography color="#49454F" fontSize={40} fontWeight={600}>
                      {stateExistenceData?.counter || 0}
                    </Typography>
                    <Stack spacing={-0.5}>
                      <Typography color={'#49454F'} fontSize={13}>
                        {i18n.t('app.silobags')}
                      </Typography>
                      <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                        <img src={IconSiloID} alt="siloID-icon" style={{ width: 18, height: 18 }} />
                        <Typography color="#6baa00" display="flex" alignItems="center">
                          SILO <b>ID</b>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  {/* RECORRIDAS VENCIDAS */}
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <Typography color="#49454F" fontSize={40} fontWeight={600}>
                      {stateExistenceData?.poe_expired || 0}
                    </Typography>
                    <Typography color={'#49454F'} fontSize={13} lineHeight={1}>
                      {i18n.t('app.existence_report_stock.poe_expired')}
                    </Typography>
                  </Stack>
                </Stack>
                <Box style={{ margin: 'auto' }}>
                  <Divider orientation="vertical" variant="inset" style={{ height: '100px' }} />
                </Box>
                {/* SILOSAT */}
                <Stack spacing={-1}>
                  {/* SILOBOLSAS SILO-SAT */}
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography color="#49454F" fontSize={40} fontWeight={600}>
                      {stateExistenceData?.silobags_with_silo_sat || 0}
                    </Typography>
                    <Stack spacing={-0.5}>
                      <Typography color={'#49454F'} fontSize={13}>
                        {i18n.t('app.silobags')}
                      </Typography>
                      <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                        <img src={IconSiloSat} alt="SiloSat-icon" style={{ width: 18, height: 18 }} />
                        <Typography color="#1481BC" display="flex" alignItems="center">
                          SILO <b>SAT</b>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack direction="row" spacing={6}>
                    {/* VISIBLE */}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography color="#49454F" fontSize={40} fontWeight={600}>
                        {stateExistenceData?.visible_silobags || 0}
                      </Typography>
                      <Typography color={'#49454F'} fontSize={13}>
                        {i18n.t('app.existence_report_stock.visibles')}
                      </Typography>
                    </Stack>
                    {/* NO VISIBLE */}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography color="#49454F" fontSize={40} fontWeight={600}>
                        {stateExistenceData?.not_visible_silobags || 0}
                      </Typography>
                      <Typography color={'#49454F'} fontSize={13} style={{ width: 'max-content' }}>
                        {i18n.t('app.existence_report_stock.not_visibles')}
                      </Typography>
                    </Stack>
                    {/* SIN IMAGENES */}
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography color="#49454F" fontSize={40} fontWeight={600}>
                        {stateExistenceData?.silobags_without_images || 0}
                      </Typography>
                      <Typography color={'#49454F'} fontSize={13} style={{ width: 'max-content' }}>
                        {i18n.t('app.existence_report_stock.without_images')}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Box style={{ margin: 'auto' }}>
                  <Divider orientation="vertical" variant="inset" style={{ height: '100px' }} />
                </Box>
                {/* SILOMETRIX */}
                <Stack spacing={-1}>
                  {/* SILOBOLSAS SILO-METRIX */}
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography color="#49454F" fontSize={40} fontWeight={600}>
                      {stateExistenceData?.silobags_with_silo_metrix || 0}
                    </Typography>
                    <Stack spacing={-0.5}>
                      <Typography color={'#49454F'} fontSize={13}>
                        {i18n.t('app.silobags')}
                      </Typography>
                      <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                        <img src={IconSiloMetrix} alt="siloID-icon" style={{ width: 18, height: 18 }} />
                        <Typography color="#F19300" display="flex" alignItems="center">
                          SILO <b>METRIX</b>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Box component={'div'}>
                    <Stack direction={'row'} spacing={6}>
                      {/* CON MOVIMIENTO */}
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography color="#49454F" fontSize={40} fontWeight={600}>
                          {stateExistenceData?.silobags_with_mov_alerts || 0}
                        </Typography>
                        <Stack>
                          <Typography color={'#49454F'} fontSize={13} style={{ width: 'max-content' }}>
                            {i18n.t('app.existence_report_stock.with_movement')}
                          </Typography>
                        </Stack>
                      </Stack>
                      {/* SIN MOVIMIENTO*/}
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Typography color="#49454F" fontSize={40} fontWeight={600}>
                          {stateExistenceData?.silobags_without_mov_alerts || 0}
                        </Typography>
                        <Stack>
                          <Typography color={'#49454F'} fontSize={13} style={{ width: 'max-content' }}>
                            {i18n.t('app.existence_report_stock.without_movement')}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
            <Box
              style={{
                width: 'max-content',
                zIndex: 99,
                position: 'absolute',
                right: 0,
                top: 10,
              }}
            >
              {listWebSilobagsCount && listWebSilobagsCount?.incomplete_silobags_count > 0 && (
                <Tooltip title={i18n.t('app.missing_tons')} placement="top-end">
                  <Typography color={'#7A7A7A'} textAlign={'end'} sx={{ cursor: 'default', marginRight: 2 }}>
                    {i18n.t('app.missing_tons_title')}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ width: '95%', margin: 'auto' }}>
        <Box>
          <Stack direction="row" spacing={2} style={{ marginTop: 22 }}>
            <FormControl style={{ flex: 1 }}>
              <TextField
                size="medium"
                variant="outlined"
                placeholder={i18n.t('app.state_stock_page.search_by_name_silo_countryside')}
                style={stylesInput}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIcon} alt="search-icon" />
                    </InputAdornment>
                  ),
                  inputProps: { style: { fontSize: 13, height: 22 } },
                }}
                inputRef={inputRef}
              />
            </FormControl>
            <FormControl variant="outlined" size="medium" style={{ flex: 1 }}>
              <Selector
                label={i18n.t('app.countrysides')}
                id="countryside_id"
                value={filtersReportExistence.countrysideId}
                onChange={(e) => handlerCountrysideID(e as SelectChangeEvent)}
                options={countrysides}
                selectedList={filtersReportExistence.countrysideId}
                renderValue={() =>
                  countrysides
                    ?.filter(
                      (countryside) => filtersReportExistence.countrysideId == countryside?.id.toString()
                    )
                    .map((matchedCountryside) => matchedCountryside?.name)
                }
                deleteFilter={deleteCountrysideFilter}
                disabled={isLoadingCountryside}
              />
            </FormControl>
            <FormControl variant="outlined" size="medium" style={{ flex: 1 }}>
              <Selector
                label={i18n.t('app.silobag_data.species')}
                id="species_type_id"
                value={filtersReportExistence.specieId}
                onChange={(e) => handlerSpecieID(e as SelectChangeEvent)}
                options={species}
                selectedList={filtersReportExistence.specieId}
                renderValue={() =>
                  species
                    ?.filter((specie) => filtersReportExistence.specieId == specie?.id.toString())
                    .map((matchedSpecie) => matchedSpecie?.name)
                }
                deleteFilter={deleteSpecieFilter}
                disabled={isLoadingSpecies}
              />
            </FormControl>
            <Button
              sx={{
                fontSize: 12,
                fontWeight: 500,
                paddingInline: 2,
                textTransform: 'uppercase',
                visibility: hasFilters ? '' : 'hidden',
                color: '#FF0000',
                '&:hover': { background: '#FF000022' },
              }}
              onClick={removeFilter}
              startIcon={<img src={DeleteIcon} alt="delete-icon" style={{ width: 14, height: 14 }} />}
            >
              {i18n.t('app.clear_filters')}
            </Button>
          </Stack>
        </Box>
        <Box>
          <TabletExistenceStock
            data={stateExistenceData?.rows ?? []}
            isLoading={isLoadingStateExistence}
            count={stateExistenceData?.counter ?? 0}
            paginationModel={filtersReportExistence?.paginationModel}
            setPaginationModel={(fn) => {
              const paginationModel = fn(filtersReportExistence.paginationModel);
              setFiltersReportExistence({ ...filtersReportExistence, paginationModel });
            }}
          />
        </Box>
      </Box>
    </>
  );
}

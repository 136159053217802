import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import {
  MultiExtractionCreationPayload,
  UpdatedMuliExtractionData,
} from './extraction-multiple-creation.types';

// deprecated service ref.: silobagMultipleExtractionService_DEPRECATED
export async function createMultipleExtractionService({ body }: MultiExtractionCreationPayload) {
  const { response, data } = await BACKEND.post<UpdatedMuliExtractionData>(ENDPOINT.SILOBAG_EXTRACTION, {
    body,
  });

  return {
    response,
    summary: data,
  };
}

import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { ListSilobagHistoryPayload, ListSilobagHistoryResponse } from './get-silobag-history.types';
import { transformIntoQueryParamString } from '@utils/service';

export async function listSilobagHistoryService({ query: rawQuery }: ListSilobagHistoryPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<ListSilobagHistoryResponse>(ENDPOINT.SILOBAGS_HISTORY, {
    query,
  });

  return {
    response,
    summary: data,
  };
}

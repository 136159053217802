import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import { formatTons } from '@utils/helper';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../libs/language';
import { stockLastBalanceService } from '@services/domain/page/report/movement-stock/mov-stock-last-balance';
import { listWebSilobagsService } from '@services/domain/base/silobag/base/silobag-web';

export const BannerStockTotal = () => {
  const { summary: lastBalance, trigger: getLastBalance } = useService(stockLastBalanceService, {
    fetchOnMount: true,
  });
  const { organizationId } = useGlobalStore.getState();

  const { trigger: triggerListWebSilobags, summary: listWebSilobagsCount } = useService(
    listWebSilobagsService,
    {
      fetchOnMount: [organizationId],
    }
  );

  useEffectOnUpdate(() => {
    getLastBalance();
    triggerListWebSilobags(organizationId);
  }, [organizationId]);

  return (
    <Paper elevation={0} style={{ position: 'relative', padding: 24 }}>
      <Box
        style={{
          width: 'max-content',
          zIndex: 99,
          position: 'absolute',
          right: 0,
          top: 10,
        }}
      >
        {listWebSilobagsCount && listWebSilobagsCount?.incomplete_silobags_count > 0 && (
          <Tooltip title={i18n.t('app.missing_tons')} placement="top-end">
            <Typography color={'#7A7A7A'} textAlign={'end'} sx={{ cursor: 'default', marginRight: 2 }}>
              {i18n.t('app.missing_tons_title')}
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Stack>
          <Typography color={'#49454F'} fontSize={20} fontWeight={600}>
            {i18n.t('app.report_stock.banner.banner_title')}
          </Typography>

          <Stack direction="row" alignItems="center">
            <Typography color={'#49454F'} fontSize={40} fontWeight={600}>
              {i18n.numberToDelimited(
                formatTons(parseInt(lastBalance ? lastBalance?.lastBalance : '0')) || 0,
                {
                  delimiter: i18n.t('config.delimiter'),
                }
              )}
            </Typography>
            <Typography component="span" sx={{ fontSize: '20px', marginLeft: '8px' }}>
              {i18n.t('app.tons')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useRouteError } from 'react-router-dom';
import { useAuth } from '@hooks/use-auth';
import { ToastAction } from '@ui/toast/toast-action';
import { MouseEventHandler, useState } from 'react';

import ChevronDown from '@assets/svg/chevron-down.svg';
import CopyIcon from '@assets/svg/core/clipboard-green.svg';

import ErrorManagement from '@assets/screen/exec-error-screen/gestion-de-errores-red.png';
import Tractor from '@assets/screen/exec-error-screen/tractor-to-the-rescue.png';

import i18n from '../libs/language';

type ErrorData = {
  name: string;
  message: string;
  stack: string;
};

export function ErrorScreen() {
  const error = useRouteError() as ErrorData;

  const navigate = useNavigate();
  const { logout } = useAuth();

  const triggerDashboardNavigation = () => navigate('/dashboard');
  const triggerPageReload = () => window.location.reload();
  const triggerCopyErrorToClipboard: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setOpenCopyErrorToast(true);
    const { name, message, stack } = error;
    navigator.clipboard.writeText(`${name}: ${message}.\n${stack}`);
  };

  const md = useMediaQuery('(min-width:520px)');
  const maxFieldSize = md ? '500px' : '300px';
  const tractorSize = md ? 200 : 128;

  const [openCopyErrorToast, setOpenCopyErrorToast] = useState(false);
  const handleCloseCopyErrorToast = () => setOpenCopyErrorToast(false);

  return (
    <Stack pt={4} height="100%" width="100%" justifyContent="center" alignItems="center">
      {openCopyErrorToast ? (
        <ToastAction
          message={i18n.t('app.error_screen.toast.copy_error_success')}
          severity="success"
          onClose={handleCloseCopyErrorToast}
        />
      ) : null}
      <Stack height="100%" width={maxFieldSize} justifyContent="center" alignItems="center" spacing={3}>
        <Stack justifyContent="center" alignItems="center">
          <img src={ErrorManagement} style={{ width: 75, height: 75 }} />
          <Box component="h1">
            <Typography textAlign="center" fontSize={24} fontWeight="bold" color="error">
              {i18n.t('app.error_screen.something_went_wrong')}
            </Typography>
          </Box>
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={1} width="100%">
          <Stack justifyContent="center" alignItems="center">
            <Typography fontSize={12} color="textSecondary" textAlign="center">
              <i>{i18n.t('app.error_screen.error_detected')}</i>
            </Typography>
          </Stack>
          <Paper elevation={1}>
            <Accordion elevation={0} disableGutters sx={{ maxWidth: maxFieldSize }}>
              <AccordionSummary
                expandIcon={<img src={ChevronDown} style={{ width: 10, height: 10 }} />}
                sx={{ px: 2 }}
              >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                  <IconButton
                    size="small"
                    style={{ borderRadius: '8px', alignSelf: 'flex-start' }}
                    onClick={triggerCopyErrorToClipboard}
                  >
                    <img src={CopyIcon} style={{ width: 16, height: 16 }} />
                  </IconButton>
                  <Typography
                    fontSize={14}
                    color="error"
                    width="100%"
                    fontWeight={600}
                    sx={{ textWrap: 'balance' }}
                  >
                    {error.name}: {error.message}.
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Typography fontSize={12} color="error" style={{ whiteSpace: 'nowrap' }}>
                  <i>
                    {error.stack.split('\n').map((s) =>
                      s ? (
                        <Box
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          key={s}
                        >
                          {s}
                          <br />
                        </Box>
                      ) : null
                    )}
                  </i>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Stack>
        <Stack justifyContent="center" alignItems="center" spacing={1} flex={1} width="100%">
          <Typography fontSize={12} color="textSecondary" textAlign="center">
            <i>{i18n.t('app.error_screen.alternatives')}</i>
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" onClick={triggerDashboardNavigation}>
              {i18n.t('app.error_screen.button.dashboard')}
            </Button>
            <Button variant="outlined" onClick={triggerPageReload}>
              {i18n.t('app.error_screen.button.reload')}
            </Button>
            <Button variant="outlined" onClick={logout}>
              {i18n.t('app.error_screen.button.logout')}
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          height="100%"
          width="100%"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          style={{ position: 'relative' }}
        >
          <Stack spacing={2} justifyContent="center" alignItems="center" minWidth="160px">
            <Typography fontSize={12} color="textSecondary" textAlign="center">
              <i>{i18n.t('app.error_screen.support_contact')}</i>
            </Typography>
            <Button
              variant="outlined"
              href="https://api.whatsapp.com/send/?phone=5491122443491&text=%C2%A1Hola%21&type=phone_number&app_absent=0"
              target="_blank"
            >
              {i18n.t('app.error_screen.button.support')}
            </Button>
          </Stack>
          <img src={Tractor} style={{ objectFit: 'contain', width: tractorSize, height: tractorSize }} />
        </Stack>
      </Stack>
    </Stack>
  );
}

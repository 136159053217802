import { useState, forwardRef, Fragment, ReactElement, Ref } from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MobileStepper,
  Paper,
  Slide,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Paso1 from '../../assets/images/tutorial/paso1.webp';
import Paso2 from '../../assets/images/tutorial/paso2.webp';
import Paso3 from '../../assets/images/tutorial/paso3.webp';
import Paso4 from '../../assets/images/tutorial/paso4.webp';
import Paso5 from '../../assets/images/tutorial/paso5.webp';
import { useGlobalStore } from '@global-store/use-store';
import { useService } from '@hooks/use-service';
import i18n from '../../libs/language';
import { updateShowTutorialFlagService } from '@services/domain/user/extra/tutorial-flag-update';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function Tutorial({ wasShown }: { wasShown: boolean }) {
  const { trigger: triggerTutorialFlagUpdate } = useService(updateShowTutorialFlagService);

  const [open, setOpen] = useState(wasShown);
  const [activeStep, setActiveStep] = useState(0);

  const { userID, setIshowOneModal } = useGlobalStore();

  const setWasTutorialShown = useGlobalStore((state) => state.setWasTutorialShow);

  const handleClose = async () => {
    triggerTutorialFlagUpdate({
      query: userID!,
      body: { was_tutorial_shown: true },
    });
    setIshowOneModal(true);
    setWasTutorialShown(true);

    setOpen(false);
  };

  const preventBackDrop = (_e: object, reason: string) => {
    if (reason && reason == 'backdropClick') return;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const tutorial_steps = [
    {
      title: 'Dashboard',
      description: i18n.t('app.siloreal_tutorial.first_description'),
      img: Paso1,
    },
    {
      title: 'Mapa',
      description: i18n.t('app.siloreal_tutorial.second_decription'),
      img: Paso2,
    },
    {
      title: 'Silobolsas',
      description: i18n.t('app.siloreal_tutorial.third_decription'),
      img: Paso3,
    },
    {
      title: i18n.t('app.siloreal_tutorial.fourth_title'),
      description: i18n.t('app.siloreal_tutorial.fourth_decription'),
      img: Paso4,
    },
    {
      title: 'Administrador',
      description: i18n.t('app.siloreal_tutorial.fifth_decription'),
      img: Paso5,
    },
  ];

  const maxSteps = tutorial_steps.length;

  return (
    <Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={preventBackDrop}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={'md'}
        style={{ borderRadius: '20px', zIndex: '999999' }}
      >
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{i18n.t('app.siloreal_tutorial.welcome_to_siloreal')}</span>
          <Button onClick={() => handleClose()}>{i18n.t('app.close')}</Button>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <div style={{ borderRadius: '20px' }}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Paper
                square
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: 100,
                  bgcolor: 'background.default',
                  width: '100%',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#6baa00',
                    color: 'white',
                    borderRadius: '20px',
                    padding: '5px',
                  }}
                >
                  {`${i18n.t('app.siloreal_tutorial.step')} ${activeStep + 1} de 5`}
                </span>
                <Typography style={{ fontWeight: 'bold', margin: '10px 0 10px 0' }}>
                  {tutorial_steps[activeStep].title}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 50,
                  bgcolor: 'background.default',
                  width: '90%',
                  margin: '-2rem auto auto auto',
                  textAlign: 'center',
                }}
              >
                <Typography>{tutorial_steps[activeStep].description}</Typography>
              </Paper>
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '2%',
                  maxHeight: '50vh',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <img src={tutorial_steps[activeStep].img} alt="step" />
              </div>

              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  activeStep !== maxSteps - 1 ? (
                    <Button size="small" onClick={handleNext}>
                      {i18n.t('app.siloreal_tutorial.next')} ▶
                    </Button>
                  ) : (
                    <Button size="small" onClick={handleClose}>
                      {i18n.t('app.siloreal_tutorial.finish')} ▶
                    </Button>
                  )
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    ◀ {i18n.t('app.siloreal_tutorial.back')}
                  </Button>
                }
              />
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

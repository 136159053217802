import { useEffectOnUpdate, useToggle } from '@hooks/core';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Fade,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { AlertCard } from '@ui/alert-card';
import NotificationIcon from '../../../assets/images/dashboard/notifications.svg';
import CountrysideIcon from '../../../assets/svg/dashboard/countryside-icon.svg';
import CloseIcon from '@assets/svg/core/cross.svg';
import ChevronRight from '@assets/svg/core/chevron-right-blue.svg';
import i18n from '../../../libs/language';
import { Fragment, PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { listGlobalAlertService } from '@services/domain/alert/global-alert-list';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';
import { PATH } from '@data/core/paths';
import { alertMassiveResolutionService } from '@services/domain/alert/alert-massive-resolution';
import { useGlobalStore } from '@global-store/use-store';
import { listOrgAlertService } from '@services/domain/alert/org-alert-list';

import dayjs from 'dayjs';
import { AlertMassiveResolutionResponse } from '@services/domain/alert/alert-massive-resolution.types';
import { useService } from '@hooks/use-service';
import { useAuth } from '@hooks/use-auth';

type AlertSideProps = { openFromDashboard?: boolean };
export function AlertSidePanel({ openFromDashboard = false }: AlertSideProps) {
  const [open, setOpen] = useToggle(openFromDashboard);
  const openPanel = () => setOpen(true);
  const handleCloseDrawer = () => setOpen(false);

  const [hideBadge, setHideBadge] = useToggle(false);
  const handleHideBadge = (value: boolean) => setHideBadge(value);

  return (
    <Box sx={{ paddingRight: 0 }}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="global-nav-alert-btn"
        onClick={openPanel}
      >
        <Badge color="error" variant="dot" invisible={hideBadge}>
          <img src={NotificationIcon} />
        </Badge>
      </IconButton>
      <DrawerAlertCard open={open} onClose={handleCloseDrawer} onChangeBadge={handleHideBadge} />
    </Box>
  );
}

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  onChangeBadge?: (b: boolean) => void;
};

export function DrawerAlertCard({ open, onClose, onChangeBadge }: DrawerProps) {
  const {
    trigger: triggerListGlobalAlertService,
    summary: silobagsWithAlerts,
    isLoading,
  } = useService(listGlobalAlertService, { fetchOnMount: true });

  const {
    summary: speciesSummary, //
  } = useService(speciesListService, { fetchOnMount: true });

  const { isAdmin } = useAuth();

  // TODO: (refactor) revisar si el useEffect es necesario realmente
  useEffect(() => {
    if (silobagsWithAlerts?.length === 0) onChangeBadge?.(true);
  }, [silobagsWithAlerts, onChangeBadge]);

  const [openWarningMessageModal, setOpenWarningMessageModal] = useState(false);
  const handleOpenWarningMessageModal = () => setOpenWarningMessageModal(true);
  const handleCloseWarningMessageModal = () => setOpenWarningMessageModal(false);

  const [openAlertMassiveHistoryModal, setOpenAlertMassiveHistoryModal] = useState(false);
  const handleOpenAlertMassiveHistoryModal = () => setOpenAlertMassiveHistoryModal(true);
  const handleCloseAlertMassiveHistoryModal = () => setOpenAlertMassiveHistoryModal(false);

  const {
    organizationId: selectedOrgID, //
    userOrganizationId: userOrgID,
  } = useGlobalStore();

  const isOwnOrganization = selectedOrgID === userOrgID;
  const hasAlertInOwnOrganization = silobagsWithAlerts?.some((s) => s.organization_id === userOrgID);

  return (
    <Drawer anchor="right" open={open} transitionDuration={200} onClose={onClose}>
      <WarningMessageModal
        open={openWarningMessageModal}
        onClose={handleCloseWarningMessageModal}
        trigger={triggerListGlobalAlertService}
      />
      <AlertMassiveHistoryModal
        open={openAlertMassiveHistoryModal}
        onClose={handleCloseAlertMassiveHistoryModal}
      />
      <Stack p={1} direction="row" justifyContent="space-between">
        <Stack
          p={1}
          direction="row"
          divider={<Divider orientation="vertical" style={{ height: '24px' }} />}
          justifyContent="center"
          alignItems="center"
          spacing={1}
          gap={1}
        >
          <h1 style={{ fontSize: 26 }}>{i18n.t('app.alerts')}</h1>
          {isAdmin ? (
            <Tooltip
              title={
                isOwnOrganization
                  ? hasAlertInOwnOrganization
                    ? ''
                    : i18n.t('app.nav_bar_bell.tooltip.no_own_org_alerts')
                  : i18n.t('app.nav_bar_bell.tooltip.only_own_org_resolve')
              }
              arrow
              disableInteractive
            >
              <span>
                <Button
                  disabled={!isOwnOrganization || !hasAlertInOwnOrganization}
                  sx={{
                    height: '24px',
                    color: '#22A7F0',
                    textTransform: 'none',
                    background: openWarningMessageModal ? '#22A7F02A' : 'transparent',
                    '&:hover': { background: '#22A7F02A' },
                  }}
                  onClick={handleOpenWarningMessageModal}
                >
                  {i18n.t('app.nav_bar_bell.button.resolve_all')}
                </Button>
              </span>
            </Tooltip>
          ) : null}
          {isAdmin ? (
            <Tooltip
              title={isOwnOrganization ? '' : i18n.t('app.nav_bar_bell.tooltip.only_own_org_history')}
              arrow
              disableInteractive
            >
              <span>
                <Button
                  disabled={!isOwnOrganization}
                  sx={{
                    height: '24px',
                    color: '#22A7F0',
                    textTransform: 'none',
                    background: openAlertMassiveHistoryModal ? '#22A7F02A' : 'transparent',
                    '&:hover': { background: '#22A7F02A' },
                  }}
                  onClick={handleOpenAlertMassiveHistoryModal}
                >
                  {i18n.t('app.nav_bar_bell.button.history')}
                </Button>
              </span>
            </Tooltip>
          ) : null}
        </Stack>

        <Box>
          <IconButton onClick={onClose} size="large">
            <img src={CloseIcon} style={{ width: 16, height: 16 }} />
          </IconButton>
        </Box>
      </Stack>
      <Divider />
      <Stack
        p={1}
        direction="column"
        spacing={2}
        style={{ width: '95vw', maxWidth: '400px', overflowY: 'auto' }}
      >
        {isLoading ? (
          <Stack alignItems="center" pt={5}>
            <CircularProgress size={32} />
          </Stack>
        ) : (
          <>
            {silobagsWithAlerts && silobagsWithAlerts.length > 0 ? (
              silobagsWithAlerts.map((sb) => (
                <Fragment key={sb.id}>
                  <SilobagAlertCard
                    silobag={sb}
                    species={speciesSummary ?? []}
                    trigger={triggerListGlobalAlertService}
                    onClose={onClose}
                  />
                </Fragment>
              ))
            ) : (
              <Box width="100%" alignItems="center" justifyContent="center">
                <Typography variant="h6" textAlign={'center'}>
                  {i18n.t('app.no_active_siloid_alerts')}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Drawer>
  );
}

type SilobagAlertCardProps = {
  silobag: Awaited<ReturnType<typeof listGlobalAlertService>>['summary'][0];
  species: Awaited<ReturnType<typeof speciesListService>>['summary'];
  trigger: () => void;
  onClose: () => void;
};
export function SilobagAlertCard({ silobag, species, trigger, onClose }: SilobagAlertCardProps) {
  const { lots } = silobag;

  const navigate = useNavigate();

  const [expanded, toggleExpanded] = useToggle(false);

  const { image_url: silobagSpeciesURL } = species.find(
    (species) => species.id === silobag.silobag_species_types.id
  )!;

  const MAX_ALERT_QTY_INDICATOR = 999;
  const MAX_ALERT_STACK = 4; // max. 4 or 5, more than that will break the layout

  const hasManyAlerts = silobag.alerts.length > 1;

  return (
    <Accordion
      elevation={0}
      disableGutters
      TransitionProps={{ timeout: 0 }}
      sx={{
        border: '1px solid #E2E5EC',
        borderRadius: '8px',
        '&::before': { content: 'none' },
      }}
      expanded={expanded}
      onChange={(_, expanded) => toggleExpanded(hasManyAlerts ? expanded : false)}
    >
      <AccordionSummary
        sx={{
          padding: `8px 8px 8px 8px`,
          marginBottom: !expanded ? `${Math.min(silobag.alerts.length, MAX_ALERT_STACK) * 2}px` : '',
          '& .MuiAccordionSummary-content': { margin: 0 },
        }}
        style={{
          cursor: hasManyAlerts ? 'pointer' : 'auto',
        }}
      >
        <Stack justifyContent="space-between" style={{ width: '100%' }}>
          <Stack direction="row" style={{ width: '100%', fontSize: '0.75rem' }} marginBottom={1}>
            <Stack flex={1} spacing={0.3}>
              <Stack direction="row" alignItems="center" spacing={0.3}>
                <img src={CountrysideIcon} style={{ width: '16px', height: '16px' }} />
                <span>{lots.countrysides.name}</span>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.3}
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <img src={silobagSpeciesURL} style={{ width: '16px', height: '16px' }} />
                  <span>{silobag.name}</span>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  px={1}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    '&:hover': { background: '#22A7F02A' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`${PATH.SILOBAG}/${silobag.id}`);
                    onClose();
                  }}
                >
                  <Box>
                    ID{' '}
                    <Box
                      sx={{
                        fontWeight: 600,
                        display: 'inline-block',
                        '&:hover': {
                          color: '#22a7f0',
                        },
                      }}
                    >
                      {silobag.id}
                    </Box>
                  </Box>
                  <Stack direction="row" spacing={0.3} justifyContent="center" alignItems="center">
                    <span style={{ color: '#22a7f0' }}>Ver</span>
                    <img src={ChevronRight} style={{ width: '8px', height: '8px' }} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                style={{
                  background: '#49454F',
                  color: 'white',
                  borderRadius: '8px',
                  fontSize: '.65rem',
                  height: '16px',
                  minWidth: '16px',
                  //width: '16px',
                  padding: '4px 4px',
                }}
                justifyContent="center"
                alignItems="center"
              >
                {silobag.alerts.length < MAX_ALERT_QTY_INDICATOR
                  ? silobag.alerts.length
                  : `+${MAX_ALERT_QTY_INDICATOR}`}
              </Stack>
              <Box>{i18n.t('app.navbar.see_all')}</Box>
            </Stack>
          </Stack>

          <AlertCard
            alert={silobag.alerts[0]}
            deviceName={silobag.alerts[0].devices.lance_number}
            trigger={trigger}
          />
          {!expanded
            ? silobag.alerts
                .slice(1, MAX_ALERT_STACK)
                .map((alert, i) => (
                  <AlertCard
                    key={i}
                    alert={alert}
                    deviceName={alert.devices.lance_number}
                    trigger={trigger}
                    hidden
                    offset={i}
                  />
                ))
            : null}
        </Stack>
      </AccordionSummary>
      <AccordionDetails style={{ margin: 0, padding: '0 8px 8px 8px' }}>
        <Stack direction="column" spacing={1}>
          {silobag.alerts.slice(1).map((alert) => (
            <AlertCard
              key={alert.id}
              alert={alert}
              deviceName={alert.devices.lance_number}
              trigger={trigger}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

type WarningMessageModalProps = ModalProps & {
  trigger: () => void;
};
function WarningMessageModal({ open, onClose, trigger }: WarningMessageModalProps) {
  const { userOrganizationId: userOrgID } = useGlobalStore();

  const onSuccess = () => {
    trigger();
    onClose();
  };

  const {
    trigger: triggerAlertMassiveResolutionService, //
    isLoading: isAlertMassiveResolutionLoading,
  } = useService(alertMassiveResolutionService, { onSuccess });
  const handleResolveAllAlerts = () => triggerAlertMassiveResolutionService({ query: userOrgID! });

  return (
    <MyModal open={open} onClose={onClose}>
      <Stack spacing={8}>
        <Typography fontSize={26}>{i18n.t('app.atention')}</Typography>
        <Typography align="center">{i18n.t('app.nav_bar_bell.modal.warning_message')}</Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            disabled={isAlertMassiveResolutionLoading}
            style={{
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
              alignSelf: 'flex-end',
            }}
            onClick={onClose}
          >
            {i18n.t('app.close')}
          </Button>
          <Button
            disableElevation
            disabled={isAlertMassiveResolutionLoading}
            variant="contained"
            style={{
              color: 'white',
              borderRadius: 25,
              textTransform: 'none',
              fontWeight: 400,
              minWidth: 120,
            }}
            onClick={handleResolveAllAlerts}
          >
            {isAlertMassiveResolutionLoading ? <CircularProgress size={16} /> : i18n.t('app.confirm')}
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
}

type AlertMassiveHistoryModalProps = ModalProps;
function AlertMassiveHistoryModal({ open, onClose }: AlertMassiveHistoryModalProps) {
  const { userOrganizationId: userOrgID } = useGlobalStore();

  const listOrgAlertQuery = { query: userOrgID! };
  const {
    trigger, //
    summary,
    isLoading,
  } = useService(listOrgAlertService, { fetchOnMount: [listOrgAlertQuery] });
  const silobagsWithAlerts = summary?.response as AlertMassiveResolutionResponse['response'];

  useEffectOnUpdate(() => {
    if (open) trigger(listOrgAlertQuery);
  }, [open, userOrgID]);

  return (
    <MyModal open={open} onClose={onClose}>
      <Typography fontSize={26}>{i18n.t('app.nav_bar_bell.modal.history_title')}</Typography>
      <TableContainer style={{ maxHeight: '75vh' }}>
        <Table stickyHeader>
          <TableHead>
            <TableCell style={{ width: '20%' }}>{i18n.t('app.nav_bar_bell.table.date_header')}</TableCell>
            <TableCell style={{ width: '45%' }}>{i18n.t('app.nav_bar_bell.table.user_header')}</TableCell>
            <TableCell>{i18n.t('app.nav_bar_bell.table.alerts_resolved_qty_header')}</TableCell>
          </TableHead>
          <TableBody style={{ overflow: 'auto' }}>
            {silobagsWithAlerts?.map((alert) => (
              <TableRow key={alert.id}>
                <TableCell>{dayjs(alert.created_at).format('DD/MM/YYYY HH:mm')}</TableCell>
                <TableCell>
                  {alert.automatically_resolved ? 'Resolución Automática' : alert.users?.email}
                </TableCell>
                <TableCell>{alert._count.alert_actions}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled={isLoading}
        style={{
          borderRadius: 100,
          textTransform: 'none',
          border: 'none',
          color: 'black',
          alignSelf: 'flex-end',
        }}
        onClick={onClose}
      >
        {i18n.t('app.close')}
      </Button>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

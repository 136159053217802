import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { CMAPayload, CMCPayload, CMCResponse } from './cmc.types';

// deprecated service ref.: cmcsService_DEPRECATED

// Implementado ✅
export async function silobagCMCService({ query: rawquery }: CMCPayload) {
  const { id: siloID, ...rest } = rawquery;
  const query = transformIntoQueryParamString(siloID, rest);

  const { response, data } = await BACKEND.post<CMCResponse>(ENDPOINT.CMCS, { query });

  return {
    response,
    summary: data,
  };
}

// deprecated service ref.: cmcaService_DEPRECATED

// Implementado ✅
export async function silobagCMAService({ query: rawquery }: CMAPayload) {
  const { id: siloID, ...rest } = rawquery;
  const query = transformIntoQueryParamString(siloID, rest);
  const { response, data } = await BACKEND.post<CMCResponse>(ENDPOINT.CMCS, { query });

  return {
    response,
    summary: data,
  };
}


export async function serviceGetCmcs({ query: rawQuery }: CMCPayload) {
  const { id: siloID, ...rest } = rawQuery;
  const query = transformIntoQueryParamString(siloID, rest);
  const { response, data } = await BACKEND.get<CMCResponse>(ENDPOINT.CMCS_SILOBAGS, { query });
  return {
    response,
    summary: data,
  };
}
import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { BasicListUsersPayload, BasicListUsersResponse } from './user-list-basic.types';

export async function basicListUsersService({ query: rawQuery }: BasicListUsersPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<BasicListUsersResponse>(ENDPOINT.REPORT_ACTIVITY_FILTERS, {
    query,
  });

  return {
    response,
    summary: data,
  };
}

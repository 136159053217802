import * as XLSX from 'xlsx';
import { utils, writeFile } from 'xlsx';
import { dataExcelEs, dataCloseSilobag } from '@data/to-refactor/constants';
import i18n from '../libs/language';
import { MassiveUploadedResponse } from '@services/domain/base/silobag/base/massive-upload.types';
import { silobagMassiveUploadService } from '@services/domain/base/silobag/base/massive-upload';

export const invertMap = (obj: { [key: string]: string }): { [key: string]: string } => {
  const inverted: { [key: string]: string } = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      inverted[obj[key]] = key;
    }
  }
  return inverted;
};
export const culturaMap: { [key: string]: string } = {
  Milho: 'Maíz',
  Arroz: 'Arroz',
  Chia: 'Chia',
  Girassol: 'Girasol',
  Soja: 'Soja',
  Sorgo: 'Sorgo',
  Trigo: 'Trigo',
  Feijão: 'Poroto',
  Milheto: 'Milheto',
  Gergelim: 'Sesamo',
  Cevada: 'Cebada',
  Centeio: 'Centeno',
  'Grão de bico': 'Garbanzo',
};

export const produtoMap: { [key: string]: string } = {
  Grão: 'Grano',
  Semente: 'Semilla',
  Consumo: 'Consumo',
};

export const humidityMap: { [key: string]: string } = {
  'Análise em campo': 'Medición manual',
  Outra: 'Otra',
};

export const weightMap: { [key: string]: string } = {
  Corvus: 'Corvus',
  Bazuca: 'Monotolva',
  Manual: 'Manual',
  Coeficiente: 'Coeficiente',
};

export const sidechargeMap: { [key: string]: string } = {
  Talhão: 'Lote',
  Carreador: 'Camino',
};

type SilobagData = Record<string, string>;

type SilobagMassiveUploadPayload = Parameters<typeof silobagMassiveUploadService>[0];
type SilobagMassiveUploadResponse = Awaited<ReturnType<typeof silobagMassiveUploadService>>['summary'];

export const uploadSilobagExcel = async (
  file: File,
  createMassiveSilobag: (arg_0: SilobagMassiveUploadPayload) => SilobagMassiveUploadResponse | void
): Promise<MassiveUploadedResponse> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = async (e) => {
      if (e.target) {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: SilobagData[] = XLSX.utils.sheet_to_json(worksheet, { range: 1 });

        const cleanedData = jsonData.map((item) => {
          const cleanedItem: SilobagData = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              cleanedItem[key] = typeof item[key] === 'string' ? item[key].trim() : item[key];
            }
          }
          return cleanedItem;
        });

        const translatedData = cleanedData.map((item) => {
          return {
            ...item,
            Cultura: culturaMap[item.Cultura] || item.Cultura,
            Produto: produtoMap[item.Produto] || item.Produto,
            'Fonte de umidade': humidityMap[item['Fonte de umidade']] || item['Fonte de umidade'],
            'Fonte de pesagem': weightMap[item['Fonte de pesagem']] || item['Fonte de pesagem'],
            'Local do silo bolsa': sidechargeMap[item['Local do silo bolsa']] || item['Local do silo bolsa'],
          };
        });

        try {
          const response = await createMassiveSilobag({ body: translatedData });
          resolve(response as SilobagMassiveUploadResponse);
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('FileReader onload event did not fire'));
      }
    };

    reader.onerror = (e) => {
      reject(new Error('FileReader encountered an error: ' + e));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const downloadSilobagExcel = () => {
  const language = i18n.locale;
  switch (language) {
    case 'pt':
      window.open(
        'https://iofbucket.s3.us-east-1.amazonaws.com/templates/pt/Planilha%2Bde%2Bcadastro%2Bmassivo%2Bde%2Bsilobolsas%2BV4.xlsx',
        '_blank'
      );
      break;
    case 'es':
      window.open(
        'https://iofbucket.s3.us-east-1.amazonaws.com/templates/es/Planilla%2Bimportaci%C3%B3n%2Bde%2BsilobolsasV4.xlsx',
        '_blank'
      );
      break;
    default: {
      // TODO: posiblemente código huérfano (no se ejecuta nunca) (primer approach para el caso de que no se encuentre el idioma)
      const dataExcel = dataExcelEs;
      const worksheet = utils.aoa_to_sheet(dataExcel);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      writeFile(workbook, 'silobolsas.xlsx');
    }
  }
};

export const uploadCloseSilobagExcel = async (
  file: File,
  createMassiveCloseSilobag: (t: unknown) => void
) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async (e) => {
      if (e.target) {
        try {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json<{ ID: number }>(worksheet);
          // Transformar los datos para cambiar 'id' a 'ID'
          const transformedData = jsonData.map((row) => ({
            ...row,
            id: row['ID'],
            ID: undefined,
          }));

          const response = await createMassiveCloseSilobag(transformedData);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('FileReader onload event did not fire'));
      }
    };

    reader.onerror = (e) => {
      reject(new Error('FileReader encountered an error: ' + e));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const downloadCloseExcel = () => {
  const worksheet = utils.aoa_to_sheet(dataCloseSilobag);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  writeFile(workbook, 'silobolsas.xlsx');
};

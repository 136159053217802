import { Box, Button, Stack, CircularProgress, Typography, Grid } from '@mui/material';
import ChevronLeftIcon from '@assets/svg/core/chevron-left-blue.svg';
import i18n from '../../libs/language';
import { configButtonStylesContained } from '@styles/generic-styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SectionSilobag } from '@features/silobags/silobag-detail/corvus/section-silobag-corvus';
import { SectionCorvus } from '@features/silobags/silobag-detail/corvus/section-corvus';
import { CorvusTable } from '@features/silobags/silobag-detail/corvus/corvus-table';
import { useService } from '@hooks/use-service';
import { getCorvusHeavyLogs } from '@services/integration/corvus/get-heavy-logs';
import { getSilobagInfoCorvusService } from '@services/integration/corvus/get-silobag-info-corvus';
import { postVinculateHeavyLogsCorvusService } from '@services/integration/corvus/post-heavy-logs';
import { deleteHeavyLogsCorvusService } from '@services/integration/corvus/delete-heavy-logs';
import { useDebounce } from '@hooks/core';
import { Dayjs } from 'dayjs';
import { formatDateReverse } from '@utils/helper';
import { PATH } from '@data/core/paths';
import { ToastAction } from '@ui/toast/toast-action';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';
import { useEffectOnUpdate } from '@hooks/core';
import { CorvusModalConfirm } from '@features/silobags/silobag-detail/corvus/corvus-modal-confirm';
type HeavyLogsSummary = Awaited<ReturnType<typeof getCorvusHeavyLogs>>['summary'];
type ParametersHeavyLogs = Parameters<typeof getCorvusHeavyLogs>[0]['query'];

const STEP_TYPE = {
  SELECT_HEAVY_LOGS: 'SELECT_HEAVY_LOGS', // pantalla para seleccionar heavy logs a asociar
  CONFIRM_HEAVY_LOGS: 'CONFIRM_HEAVY_LOGS', // pantalla para confirmar heavy logs seleccionados y vincular corvus
  CORVUS_DETAIL: 'VIEW_CORVUS_DETAILS', // pantalla para ver detalle de corvus asociado y desvincular
};

const WEIGHT_TYPE = {
  WET: 'WET_WEIGHT',
  DRY: 'DRY_WEIGHT',
};

export function SyncCorvusContainer() {
  const { siloID } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(STEP_TYPE.SELECT_HEAVY_LOGS);
  const [params, setParams] = useState<ParametersHeavyLogs>({
    establishmentId: '',
    productId: '',
    description: '',
    onlyUnlinked: undefined,
    startDate: '',
    endDate: '',
  });
  const [hasParams, setHasParams] = useState(false);
  const [heavyLogsSelected, setHeavyLogsSelected] = useState<HeavyLogsSummary>([]);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [weightState, setWeightState] = useState(WEIGHT_TYPE.WET);

  // Get siloID
  const {
    trigger: getSilobagDetail,
    summary: siloData,
    isLoading,
    isError,
  } = useService(silobagDataDetailService, {
    fetchOnMount: [{ query: siloID ?? 'id-not-found' }],
  });

  const silobagWeight = parseFloat(siloData?.input_weight ?? '0');
  //GET heavy logs
  const {
    summary: heavyLogs,
    trigger: getHeavylogs,
    isLoading: isLoadingHeavyLogs,
  } = useService(getCorvusHeavyLogs, { fetchOnMount: [{ query: { onlyUnlinked: 1 } }] });

  const activeState = siloData?.active ?? false;
  //Filters and trigger get heavy logs
  const handleGetHeavyLogs = () => {
    const queryHeavyLogs = {
      establishmentId: params.establishmentId ?? '',
      productId: params.productId ?? '',
      description: params.description ?? '',
      startDate: startDate ? formatDateReverse(startDate.toDate()) : '',
      endDate: endDate ? formatDateReverse(endDate.toDate()) : '',
      onlyUnlinked: 1,
    };
    getHeavylogs({ query: queryHeavyLogs });
    setHasParams(true);
  };
  useDebounce(
    () => {
      if ((heavyLogs && heavyLogs.length > 0) || hasParams) {
        handleGetHeavyLogs();
      }
    },
    500,
    [params, dateRange]
  );
  //Get info silobag en caso de dar error este servicio siginifica que no se encontro la silobolsa y no esta asociada a corvus se tomara step 1 si da ok step 3
  const {
    summary: CorvusInfoResponse,
    trigger: getInfoResponse,
    isLoading: isLoadingInfo,
    isError: isErrorInfo,
    isSuccess: isSuccessInfo,
  } = useService(getSilobagInfoCorvusService, {
    fetchOnMount: [{ query: siloID ?? 'id-not-found' }],
    onSuccess: (data) => {
      setStep(STEP_TYPE.CORVUS_DETAIL);
      setWeightState(data.corvusWeightSource);
    },
  });

  useEffectOnUpdate(() => {
    if (isErrorInfo) {
      setStep(STEP_TYPE.SELECT_HEAVY_LOGS);
      setHeavyLogsSelected([]);
    }
  }, [isErrorInfo]);

  //POST VINCULATE HEAVY LOGS
  const {
    trigger: postHeavylogs,
    isSuccess: SuccessPostHeavyLogs,
    isError: isErrorPostHeavyLogs,
  } = useService(postVinculateHeavyLogsCorvusService, {
    onSuccess: () => {
      setTimeout(() => {
        navigate(`${PATH.SILOBAG}/${siloID}`, { state: { pathEditSilobag: true } });
      }, 700);
    },
  });

  const heavyLogsSelectedIds = heavyLogsSelected.map((item) => item.id);

  const { trigger: deleteHeavylogs, isSuccess: isSuccessDelete } = useService(deleteHeavyLogsCorvusService, {
    onSuccess: () => {
      getInfoResponse({ query: siloID ?? 'id-not-found' });
      getSilobagDetail({ query: siloID ?? 'id-not-found' });
    },
  });

  const handleButton = () => {
    switch (step) {
      case STEP_TYPE.SELECT_HEAVY_LOGS:
        setStep(STEP_TYPE.CONFIRM_HEAVY_LOGS);
        return;
      case STEP_TYPE.CONFIRM_HEAVY_LOGS:
        postHeavylogs({
          query: siloID ?? 'id-not-found',
          body: {
            corvusWeightSource: weightState,
            heavyLogs: heavyLogsSelectedIds.map((id: number) => ({ heavyLogId: id })),
          },
        });
        return;
    }
  };

  const handleWeightType = (type: string) => {
    setWeightState(type);
  };
  //Contenido a renderizar
  const renderContent = () => {
    switch (step) {
      case STEP_TYPE.SELECT_HEAVY_LOGS:
        return (
          <Box>
            <Typography variant="h5" fontWeight={500}>
              {i18n.t('app.integration_corvus.vinculate_corvus')}
            </Typography>
            <Grid container sx={{ marginTop: '30px' }}>
              <Grid item xs={12} md={6}>
                <SectionSilobag data={siloData} isError={isError} />
              </Grid>
              <Grid item xs={12} md={6}>
                <SectionCorvus
                  heavyLogsData={heavyLogsSelected}
                  silobagWeight={silobagWeight}
                  corvusData={CorvusInfoResponse}
                  step={step}
                  isSuccessData={isSuccessInfo}
                  isLoadingInfo={isLoadingInfo}
                  WeightType={WEIGHT_TYPE}
                  weightState={weightState}
                />
              </Grid>
              <Grid item xs={12}>
                <CorvusTable
                  params={params}
                  step={step}
                  setParams={setParams}
                  getHeavyLogs={handleGetHeavyLogs}
                  data={heavyLogs}
                  isLoading={isLoadingHeavyLogs}
                  setHeavyLogsSelected={setHeavyLogsSelected}
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                  deleteHeavyLog={deleteHeavylogs}
                  isLoadingInfo={isLoadingInfo}
                  stepType={STEP_TYPE}
                  weightState={weightState}
                  handleWeightState={handleWeightType}
                  WeightType={WEIGHT_TYPE}
                  activeState={activeState}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case STEP_TYPE.CONFIRM_HEAVY_LOGS:
        return (
          <Box>
            <Typography variant="h5" fontWeight={500}>
              {i18n.t('app.integration_corvus.confirm_corvus')}
            </Typography>
            <Grid container sx={{ marginTop: '30px' }}>
              <Grid item sm={12} md={6}>
                <SectionSilobag data={siloData} isError={isError} />
              </Grid>
              <Grid item sm={12} md={6}>
                <SectionCorvus
                  heavyLogsData={heavyLogsSelected}
                  silobagWeight={silobagWeight}
                  corvusData={CorvusInfoResponse}
                  step={step}
                  isSuccessData={isSuccessInfo}
                  isLoadingInfo={isLoadingInfo}
                  WeightType={WEIGHT_TYPE}
                  weightState={weightState}
                />
              </Grid>
              <Grid item xs={12}>
                <CorvusTable
                  params={params}
                  setParams={setParams}
                  step={step}
                  getHeavyLogs={handleGetHeavyLogs}
                  isLoading={isLoadingHeavyLogs}
                  dataSelected={heavyLogsSelected}
                  setHeavyLogsSelected={setHeavyLogsSelected}
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                  deleteHeavyLog={deleteHeavylogs}
                  isLoadingInfo={isLoadingInfo}
                  stepType={STEP_TYPE}
                  weightState={weightState}
                  handleWeightState={handleWeightType}
                  WeightType={WEIGHT_TYPE}
                  activeState={activeState}
                />
              </Grid>
            </Grid>
          </Box>
        );
      case STEP_TYPE.CORVUS_DETAIL:
        //corvus Vinculado para editar o desvincular
        return (
          <Box>
            <Typography variant="h5" fontWeight={500}>
              {i18n.t('app.integration_corvus.detail_corvus_title')}
            </Typography>
            <Grid container sx={{ marginTop: '30px' }}>
              <Grid item sm={12} md={6}>
                <SectionSilobag data={siloData} isError={isError} />
              </Grid>
              <Grid item sm={12} md={6}>
                <SectionCorvus
                  heavyLogsData={heavyLogsSelected}
                  silobagWeight={silobagWeight}
                  corvusData={CorvusInfoResponse}
                  step={step}
                  isSuccessData={isSuccessInfo}
                  isLoadingInfo={isLoadingInfo}
                  WeightType={WEIGHT_TYPE}
                  weightState={weightState}
                />
              </Grid>
              <Grid item xs={12}>
                <CorvusTable
                  params={params}
                  setParams={setParams}
                  step={step}
                  getHeavyLogs={handleGetHeavyLogs}
                  isLoading={isLoadingHeavyLogs}
                  data={CorvusInfoResponse?.heavyLogs}
                  setHeavyLogsSelected={setHeavyLogsSelected}
                  setDateRange={setDateRange}
                  dateRange={dateRange}
                  deleteHeavyLog={deleteHeavylogs}
                  siloId={siloID}
                  isLoadingInfo={isLoadingInfo}
                  stepType={STEP_TYPE}
                  weightState={weightState}
                  handleWeightState={handleWeightType}
                  WeightType={WEIGHT_TYPE}
                  activeState={activeState}
                />
              </Grid>
            </Grid>
          </Box>
        );
    }
  };

  return isLoading && !isSuccessDelete ? (
    <Stack justifyContent={'center'} alignItems={'center'} sx={{ marginTop: 20 }}>
      <CircularProgress size={50} />
    </Stack>
  ) : (
    <Box sx={{ marginTop: '20px', width: '95%', margin: 'auto' }}>
      {openDeleteModal && CorvusInfoResponse && (
        <CorvusModalConfirm
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          siloId={siloID}
          heavyLogs={CorvusInfoResponse.heavyLogs}
          isLoading={isLoading}
        />
      )}
      {SuccessPostHeavyLogs && <ToastAction message="Se vinculo correctamente" severity="success" />}
      {isErrorPostHeavyLogs && (
        <ToastAction
          message="Ha ocurrido un error verifique los datos de corvus Seleccionados"
          severity="error"
        />
      )}
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Button
          sx={{ color: '#22A7F0' }}
          startIcon={<img src={ChevronLeftIcon} style={{ width: 10, height: 10 }} />}
          onClick={() =>
            step !== STEP_TYPE.CONFIRM_HEAVY_LOGS
              ? navigate(`${PATH.SILOBAG}/${siloID}`, { state: { pathEditSilobag: true } })
              : setStep(STEP_TYPE.SELECT_HEAVY_LOGS)
          }
        >
          {i18n.t('app.silobag_detail.previous')}
        </Button>
        <Box>
          <span style={{ display: step === STEP_TYPE.CORVUS_DETAIL ? 'none' : '' }}>
            {i18n.t('app.integration_corvus.step')} {step === STEP_TYPE.SELECT_HEAVY_LOGS ? 1 : 2} de 2
          </span>
          <Button
            variant="contained"
            sx={{
              ...configButtonStylesContained,
              marginLeft: '20px',
              display: step === STEP_TYPE.CORVUS_DETAIL ? 'none' : '',
            }}
            disabled={heavyLogsSelected.length <= 0}
            onClick={handleButton}
          >
            {step === STEP_TYPE.SELECT_HEAVY_LOGS ? i18n.t('app.silobag_detail.next') : 'Confirmar'}
          </Button>
          {step === STEP_TYPE.CORVUS_DETAIL && (
            <Button
              variant="contained"
              disabled={!activeState}
              sx={{
                ...configButtonStylesContained,
                marginLeft: '20px',
                backgroundColor: '#DA0E0B',
                '&:hover': {
                  backgroundColor: '#BB0805',
                },
              }}
              onClick={() => setOpenDeleteModal(true)}
            >
              {i18n.t('app.integration_corvus.desvinculate_corvus')}
            </Button>
          )}
        </Box>
      </Stack>
      {renderContent()}
    </Box>
  );
}

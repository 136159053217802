import { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import { formatDateTime } from '@utils/helper';
import i18n from '../../../../../libs/language';
import { silobagMeasurementDataService } from '@services/domain/base/silobag/base/measurement-data';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

type measurementsDataType = Awaited<ReturnType<typeof silobagMeasurementDataService>>['summary'];
type SilobaDataDetail = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];

type MetricsProps = {
  silobag?: SilobaDataDetail;
  dataToRender: measurementsDataType;
  shortName: string;
  period: number;
};

type KeyValueMap = {
  [key: string]: string;
};

// Por qué está acá? Evaluar si deberia estar con API_STATS, TITLES y DESCRIPTIONS
const LANCE_POSITIONS: Record<number, string> = {
  1: 'Inicio',
  2: 'Inicio-Medio',
  3: 'Medio',
  4: 'Medio-Fin',
  5: 'Fin',
};

export function Metrics(props: MetricsProps) {
  // TODO: usar ID en vez de SHORTNAME en todas las constantes declaradas para evitar problemas si en el futuro cambia algún SHORTNAME
  const { silobag, dataToRender, shortName, period } = props;

  const API_STATS: KeyValueMap = {
    CO2: 'co2_percent',
    TEMP: 'temperature_celsius',
    HEG: 'heg',
    HUM: 'humidity_percent',
    BAT: 'battery_percent',
  };

  const TITLES: KeyValueMap = {
    CO2: 'CO2 %',
    TEMP: 'Temp. ºC',
    HEG: 'HEG %',
    HUM: 'Humedad %',
    BAT: 'Bateria %',
  };

  const DESCRIPTIONS: KeyValueMap = {
    CO2: i18n.t('app.metrics_descriptions.co2'),
    TEMP: i18n.t('app.metrics_descriptions.temp'),
    HEG: i18n.t('app.metrics_descriptions.heg'),
    HUM: i18n.t('app.metrics_descriptions.hum'),
    BAT: i18n.t('app.metrics_descriptions.bat'),
  };

  const stat = API_STATS[shortName];

  useEffect(() => {
    if (!dataToRender || dataToRender.length === 0) {
      // Data is not ready yet, so don't create the chart
      return;
    }
    const jsonData = dataToRender
      .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
      .map((item) => ({
        ...item,
        dateToShow:
          period > 15
            ? formatDateTime(item.dateToShow, 2).substring(0, 10)
            : formatDateTime(item.dateToShow, 2),
      }));

    // Create charts dynamically for each metric
    const chart = am4core.create(`chartdiv-${stat}`, am4charts.XYChart);

    // Set up data
    chart.data = jsonData;

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 300;
    categoryAxis.dataFields.category = 'dateToShow';
    //categoryAxis.title.text = 'Fecha/Data';

    const title = chart.titles.create();
    title.text = TITLES[shortName];
    title.fontSize = 25;
    title.tooltipText = DESCRIPTIONS[shortName];

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = TITLES[shortName];
    valueAxis.min = 0; // Set minimum value

    // Calculate the maximum value
    const maxValue = Math.max(35, Math.max(...jsonData.map((item) => parseFloat(item[stat as 'heg']!))));
    valueAxis.max = maxValue;

    // Create series dynamically for each eui_id
    const uniqueEuiIds = [...new Set(jsonData.map((item) => item.eui_id))];
    uniqueEuiIds.forEach((euiId) => {
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = stat;
      series.dataFields.categoryX = 'dateToShow';

      const device = silobag?.silobags_devices.find((dev) => dev.devices.eui_id === euiId);
      if (device) {
        const lanceNumber = device.devices.lance_number;
        const lancePosition = LANCE_POSITIONS[device.position!];
        series.name = `${lanceNumber}\n${lancePosition}`; // Use lance_number as series name
      }

      //series.name = euiId;
      series.tooltipText = `{name}: [bold]{valueY}[/]`;
      series.data = jsonData.filter((item) => item.eui_id === euiId);
      series.tensionX = 0.85;
      series.tensionY = 0.8;
      series.strokeWidth = 2;

      // Add a circle marker at a specific value
      const marker = series.bullets.push(new am4charts.CircleBullet());
      marker.circle.radius = 2; // Smaller marker
      marker.circle.strokeWidth = 0; // Solid marker

      // Set the value at which the circle marker should appear
      const markerValue = 150; // Replace with your desired value
      const markerData = series.data.find((item) => item[stat] === markerValue);
      if (markerData) {
        markerData.marker = true;
      }

      series.events.on('validated', () => {
        if (markerData && markerData.marker) {
          const bullet = marker.createChild(am4core.Circle);
          bullet.radius = marker.circle.radius;
          bullet.fill = marker.circle.fill;
          bullet.strokeWidth = marker.circle.strokeWidth;
          bullet.stroke = marker.circle.stroke;
          bullet.isMeasured = marker.circle.isMeasured;
          //   bullet.x = series.xAxis.getX(series.data.indexOf(markerData), 0);
          //   bullet.y = series.yAxis.getY(markerData[stat]);
        }
      });
    });

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();
  }, [dataToRender, period]);

  return (
    <div>
      <div key={stat} id={`chartdiv-${stat}`} style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
}

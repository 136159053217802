import { PATH } from '@data/core/paths';
import { useAuth } from '@hooks/use-auth';
import { Box, Link, Menu, MenuItem, Collapse } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import i18n from '../../../libs/language';

export function MainMenu({ isMobile = false }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCustomOptions, setOpenCustomOptions] = useState(false);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const isSamePath = location.pathname === PATH.SILOBAG;
  const { isAdmin } = useAuth();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setOpenCustomOptions(false);
    setAnchorEl(null);
  };

  const style = {
    color: '#000',
    textDecoration: 'none',
    fontSize: '0.8rem',
    '&.active': {
      textDecoration: 'none',
      fontWeight: 600,
    },
    '&:hover': { color: '#6baa00' },
    '&.Mui-selected': {
      color: '#6baa00',
      backgroundColor: 'rgba(107, 170, 0, 0.12)',
    },
  };

  const PATHS_REPORTS: string[] = [
    PATH.REPORT_STOCK,
    PATH.REPORT_DEVICE,
    PATH.REPORT_ACTIVITY,
    PATH.REPORT_STATE_STOCK,
    PATH.REPORT_SAT_SILOBAGS_EXISTENCE,
  ];

  const isReport = PATHS_REPORTS.includes(location.pathname);

  return (
    <Stack
      direction={{ sm: 'column', md: 'row' }}
      sx={{
        marginLeft: 'auto',
        display: 'flex',
        width: '100vw',
        gridDirection: 'row',
        alignItems: !isMobile ? 'center' : 'start',
        justifyContent: 'space-around',
        minWidth: !isMobile ? 400 : 50,
        maxWidth: 600,
        height: 'auto',
      }}
      spacing={1}
      mt={!isMobile ? 0 : 2}
    >
      <Link component={NavLink} sx={style} to={PATH.DASHBOARD}>
        {i18n.t('app.navbar.dashboard')}
      </Link>
      <Link component={NavLink} sx={style} to={PATH.MAP}>
        {i18n.t('app.navbar.map')}
      </Link>
      <span style={{ pointerEvents: isSamePath ? 'none' : 'auto' }}>
        <Link component={NavLink} sx={style} to={PATH.SILOBAG}>
          {i18n.t('app.navbar.silobags')}
        </Link>
      </span>
      <Box sx={{ ...style, cursor: 'pointer' }} className={isReport ? 'active' : ''}>
        <Box onClick={handleClick}>{i18n.t('app.navbar.reports')}</Box>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose} aria-selected="true">
          <MenuItem
            sx={style}
            selected={location.pathname === PATH.REPORT_STOCK}
            onClick={() => {
              navigate(PATH.REPORT_STOCK);
              handleClose();
            }}
          >
            {i18n.t('app.navbar.stock')}
          </MenuItem>
          <MenuItem
            sx={style}
            selected={location.pathname === PATH.REPORT_DEVICE}
            onClick={() => {
              navigate(PATH.REPORT_DEVICE);
              handleClose();
            }}
          >
            {i18n.t('app.navbar.devices')}
          </MenuItem>
          {/* <MenuItem disabled sx={style}>
            {i18n.t('app.navbar.alerts')}
          </MenuItem> */}
          <MenuItem
            sx={style}
            selected={location.pathname === PATH.REPORT_ACTIVITY}
            onClick={() => {
              navigate(PATH.REPORT_ACTIVITY);
              handleClose();
            }}
          >
            {i18n.t('app.navbar.activity')}
          </MenuItem>
          <MenuItem
            sx={style}
            selected={location.pathname === PATH.REPORT_STATE_STOCK}
            onClick={() => {
              navigate(PATH.REPORT_STATE_STOCK);
              handleClose();
            }}
          >
            {i18n.t('app.navbar.state_stock')}
          </MenuItem>
          <MenuItem
            sx={style}
            selected={location.pathname === PATH.REPORT_SAT_SILOBAGS_EXISTENCE}
            onClick={() => {
              navigate(PATH.REPORT_SAT_SILOBAGS_EXISTENCE);
              handleClose();
            }}
          >
            {i18n.t('app.navbar.existence_stock')}
          </MenuItem>
          <MenuItem
            sx={style}
            onMouseEnter={() => setOpenCustomOptions(true)}
            onClick={() => setOpenCustomOptions(!openCustomOptions)}
          >
            {i18n.t('app.navbar.personalized')}
          </MenuItem>
          <Collapse in={openCustomOptions} timeout="auto" unmountOnExit>
            <MenuItem
              onClick={() => {
                navigate(PATH.REPORT_POSITION_GENERAL);
                handleClose();
              }}
              sx={{ ...style, pl: 2 }}
            >
              {i18n.t('app.navbar.general_position')}
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ ...style, pl: 2 }} disabled>
              {i18n.t('app.navbar.commercial_position')}
            </MenuItem>
            <MenuItem onClick={handleClose} sx={{ ...style, pl: 2 }} disabled>
              {i18n.t('app.navbar.stock_withdrawals')}
            </MenuItem>
          </Collapse>
        </Menu>
      </Box>
      {isAdmin ? (
        <Link component={NavLink} sx={style} to={PATH.ADMINISTRATOR}>
          {i18n.t('app.navbar.administrator')}
        </Link>
      ) : null}
    </Stack>
  );
}

import {
  Box,
  Button,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material';
import siloMetrixIcon from '@assets/svg/SiloBagPage/silo-metrix-active.svg';
import { formatNumberWithSeparators } from '@utils/helper';
import { InsuranceRusModal } from './insurance-modals/rus-modal';
import { InsuranceLaSegundaModal } from './insurance-modals/lasegunda-modal';
import { useState } from 'react';
import i18n from '../../libs/language';

// TODO: esta interfaz se movió de otro lugar acá por comodidad para borrar archivos, pero habría que revisarla y migrarla al nuevo tipado.
export interface InsuranceCoverage_DEPRECATED {
  code: string;
  description: string;
}

// TODO: esta interfaz se movió de otro lugar acá por comodidad para borrar archivos, pero habría que revisarla y migrarla al nuevo tipado.
export interface InsuranceQuote_DEPRECATED {
  background_color: string;
  enabled: boolean;
  logo_url: string;
  logo_url_white: string;
  includes: InsuranceCoverage_DEPRECATED[];
  price: number;
  sum_assured: number;
  duration: number;
  description: string;
  siloMetrix: boolean;
  service_id: number;
  form_url: string;
}

type TotalsType = {
  summ_assured: number;
  tons: number;
};
type CardSiloInsuranceProps = {
  logoUrl: string;
  item: InsuranceQuote_DEPRECATED;
  silobagIds?: string[];
  vigency: number;
  totals: TotalsType;
  validationPrices: boolean;
  typeCoin: number;
};

export function CardSiloInsurance(props: CardSiloInsuranceProps) {
  const [openRus, setOpenRus] = useState(false);
  const [openLaSegunda, setOpenLaSegunda] = useState(false);
  const { logoUrl, item, silobagIds, vigency, totals, validationPrices, typeCoin } = props;
  const serviceId = item.service_id;

  const handleOpenInsurance = () => {
    if (serviceId === 5) {
      setOpenRus(true);
    } else {
      setOpenLaSegunda(true);
    }
  };
  return (
    <Box>
      <Typography
        variant="h6"
        style={{ fontWeight: 500, textAlign: 'center', display: 'flex', alignItems: 'center' }}
      >
        {i18n.t('app.silobag_insurance.with')}{' '}
        {item.siloMetrix ? (
          <div style={{ color: 'orange', fontSize: '14px', marginLeft: '5px' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <img src={siloMetrixIcon} alt="SiloMetrixicon" style={{ marginRight: '3px', width: '18px' }} />
              SILO<span style={{ fontWeight: 600 }}>METRIX</span>{' '}
              <Chip
                label="50%"
                color="success"
                sx={{
                  marginLeft: '5px',
                  fontSize: '12px',
                  height: '20px',
                  width: '60px',
                  backgroundColor: '#D9EFB4',
                  color: '#6baa00',
                  fontWeight: 600,
                  borderRadius: '10px',
                }}
              />
            </span>
          </div>
        ) : (
          i18n.t('app.silobag_insurance.your_plan')
        )}
      </Typography>
      <Card sx={{ width: 280 }}>
        <Box
          sx={{
            backgroundColor: item.enabled ? item.background_color : '#C4C3C4',
            height: 70,
            paddingTop: '15px',
          }}
        >
          <CardMedia
            sx={{
              height: 35,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            image={logoUrl}
            title="Logo-Insurance"
            style={{ width: 'auto', maxHeight: '100%' }}
          />
        </Box>
        <CardContent sx={{ height: '150px' }}>
          {serviceId == 6 ? (
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                style={{ color: item.enabled ? 'black' : '#C4C3C4', textAlign: 'center' }}
              >
                <span
                  style={{
                    display: 'block',
                    fontSize: '15px',
                    color: item.enabled ? item.background_color : '#C4C3C4',
                    fontWeight: 500,
                    marginBottom: '10px',
                  }}
                >
                  {i18n.t('app.silobag_insurance.thirds_complete')}
                </span>
                $ {formatNumberWithSeparators(item.price)}
              </Typography>
              <hr />
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ color: item.enabled ? 'black' : '#C4C3C4', textAlign: 'center' }}
              >
                <span style={{ display: 'block' }}>{i18n.t('app.silobag_insurance.sum_assured')}</span>${' '}
                {formatNumberWithSeparators(item.sum_assured)}
              </Typography>
            </Box>
          ) : (
            <Box>
              {' '}
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{
                  color: item.enabled ? 'black' : '#C4C3C4',
                  textAlign: 'center',
                  fontSize: '18px',
                  marginTop: '10px',
                }}
              >
                Brindanos tus datos para armar una cotización a tu medida
              </Typography>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Tooltip title={validationPrices ? '' : 'Ingresa todos los precios pizarra para avanzar'}>
            <Box sx={{ width: '100%' }}>
              <Button
                variant="outlined"
                color="info"
                sx={{ borderRadius: 100, width: '95%' }}
                disabled={!item.enabled || !validationPrices}
                onClick={handleOpenInsurance}
              >
                {i18n.t('app.silobag_insurance.view_detail')}
              </Button>
            </Box>
          </Tooltip>
        </CardActions>
      </Card>
      {openRus && (
        <InsuranceLaSegundaModal
          open={openRus}
          setOpen={setOpenRus}
          silobagIds={silobagIds}
          vigencyProps={vigency}
          totalSumAssured={totals.summ_assured}
          tons={totals.tons}
          typeCoin={typeCoin}
        />
      )}
      {openLaSegunda && (
        <InsuranceRusModal
          open={openLaSegunda}
          setOpen={setOpenLaSegunda}
          data={item}
          silobagId={silobagIds}
        />
      )}
    </Box>
  );
}

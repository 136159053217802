import { BaseTable } from '@features/ui';
import { ModalIOF } from '@ui/modal/modal';
import { configButtonStylesContained } from '@styles/generic-styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatDateTime } from '@utils/helper';
import downloadPdfIcon from '@assets/svg/downloadIcon.svg';
import DeleteIcon from '@assets/svg/core/trash-green.svg';
import DeleteDisabledIcon from '@assets/svg/core/trash-disabled.svg';
import { IconButton } from '@mui/material';
import i18n from '../../../../../libs/language';
import { removeFileService } from '@services/domain/base/silobag/file/file-deletion';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SummariDetailSb = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];
type FileSummary = SummariDetailSb['files'];
type DeleteFileParams = Parameters<typeof removeFileService>[0];

type FileModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: FileSummary | undefined;
  writePermission?: boolean;
  deleteFile: (params: DeleteFileParams) => void;
};

export const FileModal = ({ open, setOpen, data, deleteFile, writePermission }: FileModalProps) => {
  const rows =
    data !== undefined
      ? [...data].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      : [];

  const columns: GridColDef[] = [
    {
      field: 'file_path',
      headerName: i18n.t('app.name'),
      flex: 2,
      renderCell: (params) => {
        const filePath = params.value;
        const fileName = filePath.split('/').pop();

        let fileClean = fileName.replace(/.*?Z\./, '').trim();
        fileClean = fileClean.replace(/(\.[a-zA-Z0-9]+)\1+$/, '$1');
        fileClean = decodeURIComponent(fileClean).replace(/[%()]/g, '').replace(/\s+/g, ' ');
        return <span>{fileClean}</span>;
      },
    },
    {
      field: 'created_at',
      headerName: 'Subido',
      flex: 1,
      renderCell: (params) => {
        return <span>{formatDateTime(params.value, 3)}</span>;
      },
    },
    {
      field: 'id',
      headerName: i18n.t('app.actions'),
      flex: 1,
      renderCell: (params) => {
        const fileId = params.value;
        return (
          <div>
            <IconButton>
              <a href={params.row.file_path} download>
                <img src={downloadPdfIcon} alt="download" />
              </a>
            </IconButton>
            <IconButton
              onClick={() => deleteFile({ query: fileId })}
              disabled={writePermission ? false : true}
            >
              <img src={writePermission ? DeleteIcon : DeleteDisabledIcon} alt="delete" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <ModalIOF open={open} ModalIsOpen={setOpen}>
      <ModalIOF.Header>
        <ModalIOF.Title> {i18n.t('app.view_files_attached')}</ModalIOF.Title>
        <ModalIOF.Helper>
          <ModalIOF.Button
            close
            variant="contained"
            size="medium"
            sx={configButtonStylesContained}
            onClick={() => {}}
          >
            {i18n.t('app.close')}
          </ModalIOF.Button>
        </ModalIOF.Helper>
      </ModalIOF.Header>
      <ModalIOF.Body>
        <BaseTable rows={rows ?? []} initialPageSize={10} columns={columns} rowHeight={80} />
      </ModalIOF.Body>
    </ModalIOF>
  );
};

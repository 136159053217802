import { TextField, Button, Typography, Stack, Box } from '@mui/material';
import { styledTextField } from '@styles/generic-styles';
import { useNavigate } from 'react-router';
import { PATH } from '@data/core/paths';
import { useGlobalStore } from '@global-store/use-store';
import { useState } from 'react';
import { useService } from '@hooks/use-service';
import { ToastAction } from '@ui/toast/toast-action';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from '../../../libs/language';
import { verifyCredentialService } from '@services/domain/auth/verify-auth-credential';
import { recoverCredentialService } from '@services/domain/auth/recover-auth-credential';

type VerifyCrendentialBody = Parameters<typeof verifyCredentialService>[0]['body'];

export function VerifyRegisterForm() {
  const { email } = useGlobalStore();

  const [formVerifyRegister, setFormVerifyRegister] = useState<VerifyCrendentialBody>({
    username: email !== undefined ? email : '',
    verification_code: null,
  });

  const navigate = useNavigate();

  const {
    trigger: sendVerify,
    isError: isErrorVerify,
    isLoading,
  } = useService(verifyCredentialService, {
    onSuccess: () => navigate(PATH.COMPLETE_REGISTER),
  });

  const { trigger: resendCode, isSuccess, isError } = useService(recoverCredentialService);

  const handleVerify = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (formVerifyRegister.verification_code !== undefined) {
      sendVerify({ body: formVerifyRegister });
    }
  };

  const resendCodeData = {
    email: formVerifyRegister.username,
  };

  const handleResendCode = () => {
    resendCode({ body: resendCodeData });
  };
  return (
    <Stack direction={'column'} display={'flex'} alignContent={'center'} width={{ md: '50%' }}>
      <Box textAlign={{ xs: 'center', md: 'start' }} m={0}>
        <Typography
          fontSize={{ xs: '20px', md: '30px' }}
          mr={{ xs: 5, md: 0 }}
          ml={{ xs: 3, md: 0 }}
          fontWeight={'bold'}
        >
          {i18n.t('app.public_pages.verify_register_title')}
        </Typography>
        <p style={{ maxWidth: '390px' }}>{i18n.t('app.public_pages.verify_register_description')}</p>
      </Box>
      <Stack spacing={2}>
        <TextField
          required
          id="verification_code"
          label={i18n.t('app.public_pages.verify_register_code')}
          variant="outlined"
          placeholder="Ingresa el codigo de verificación"
          size="small"
          sx={styledTextField}
          onChange={(e) => {
            setFormVerifyRegister((formVerifyRegister) => ({
              ...formVerifyRegister,
              verification_code: +e.target.value,
            }));
          }}
        />
        <Button
          variant="contained"
          sx={{ width: '400px', color: 'white' }}
          disableElevation
          onClick={handleVerify}
        >
          {isLoading ? (
            <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} />
          ) : (
            'Enviar Codigo'
          )}
        </Button>
      </Stack>
      <Typography variant="body2" color="text.secondary" sx={{ width: '400px' }} m={2}>
        <span style={{ fontWeight: '600' }}>{i18n.t('app.public_pages.no_received_code')}</span>
        {i18n.t('app.public_pages.no_received_code_decription')}
      </Typography>
      <Stack direction="row">
        <TextField
          id="email"
          label={i18n.t('app.public_pages.email')}
          variant="outlined"
          size="small"
          disabled={email == undefined ? false : true}
          defaultValue={email !== undefined ? email : ''}
          style={{
            width: '200px',
            marginRight: '10px',
          }}
          sx={styledTextField}
          onChange={(e) => {
            setFormVerifyRegister((formVerifyRegister) => ({
              ...formVerifyRegister,
              username: email !== undefined ? email : e.target.value,
            }));
          }}
        />
        <Button
          variant="outlined"
          sx={{ width: '200px' }}
          size="medium"
          disableElevation
          onClick={handleResendCode}
        >
          Reenviar Codigo
        </Button>
      </Stack>
      {isSuccess && <ToastAction message="Se ha enviado el codigo de verificación" severity="success" />}
      {isError && <ToastAction message="Reenvia el codigo a un email valido" severity="error" />}
      {isErrorVerify && (
        <ToastAction
          message="Error al verificar el codigo de verificación, Verifica que sea el correcto"
          severity="error"
        />
      )}
    </Stack>
  );
}

import {
  Paper,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Stack,
  Tooltip,
  Chip,
  Modal,
  Fade,
  TextField,
} from '@mui/material';
import { textDisabledTablet, buttonDataDetail, configButtonStylesContained } from '@styles/generic-styles';
import CircularProgress from '@mui/material/CircularProgress';
import { formatDate } from '@utils/helper';
import { useToggle } from '@hooks/core';
import { ObservationModal } from './modal/observation-modal';
import imgIcon from '@assets/svg/img.svg';
import imgDisabledIcon from '@assets/svg/imgDisabled.svg';
import ObservationIcon from '@assets/svg/SiloBagPage/view/observations.svg';
import ObservationDisabledIcon from '@assets/svg/SiloBagPage/view/observation-disabled.svg';
import EditIcon from '@assets/svg/SiloBagPage/view/edit.svg';
import GreyEditIcon from '@assets/svg/SiloBagPage/view/edit-disabled.svg';
import ReloadIcon from '@assets/svg/SiloBagPage/view/reload.svg';
import { FileModal } from './modal/file-modal';
import FileIcon from '@assets/svg/SiloBagPage/view/fileView.svg';
import FileDisabledIcon from '@assets/svg/SiloBagPage/view/fileDisabled.svg';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/core/paths';
import copyIcon from '@assets/svg/copy.svg';
import ChevronDown from '@assets/svg/chevron-down.svg';
import { removeFileService } from '@services/domain/base/silobag/file/file-deletion';
import { useService } from '@hooks/use-service';
import { ToastAction } from '@ui/toast/toast-action';
import i18n from '../../../../libs/language';
import NewReleaseIcon from '@assets/svg/SiloBagPage/new_releases.svg';
import warningIcon from '@assets/svg/warning.svg';
import { PropsWithChildren, useEffect, useState } from 'react';
import { findWorstTAS } from '@utils/helper';
import { ModalContent } from './edit-silobag-modal';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';
import MapIcon from '@assets/svg/SiloBagPage/view/map.svg';
import DisabledMapIcon from '@assets/svg/SiloBagPage/view/map-disabled.svg';
import dayjs from 'dayjs';
import { updateSilobagService } from '@services/domain/base/silobag/base/silobag-edition';
import { ImagesGalery } from '../modal/images-galery/images-galery';

type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];
type SummariDetailSb = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];
type SatViewSummary = Awaited<ReturnType<typeof silobagDataDetailService>>['summary']['sat_view'];

type detailProps = {
  data: SummariDetailSb;
  isLoading?: boolean;
  isClosed?: boolean;
  getData: (query: SilobagDetailParams) => void;
  siloListId?: string[];
  siloID: string;
};

export function DataSection({ data, isLoading, isClosed, getData, siloListId, siloID }: detailProps) {
  const [modalObservation, setModalObservation] = useToggle(false);
  const [modalImage, setModalImage] = useToggle(false);
  const [modalFiles, setModalFiles] = useToggle(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useToggle(false);
  const [sizeModal, setSizeModal] = useToggle(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectSize, SetSelectSize] = useState('');
  const [speciesName, setSpeciesName] = useState('');

  const isDisabledSB = data?.silobag_data_status_id === 1 || !data.active;

  const handleModalObervationOpen = () => {
    setModalObservation(true);
  };
  //datos con destructuring extensos
  const created_at = formatDate(data?.created_at);
  const specie_type = data?.silobag_species_types;
  const product_type = data?.silobag_product_types.name;
  const countrysideName = data?.lots?.countrysides.name;

  const mainImage = data?.main_image;
  const initImage = data?.init_image;
  const endImage = data?.end_image;
  const handleModalImageOpen = () => {
    setModalImage(true);
  };

  const { trigger: editSilobag } = useService(updateSilobagService, {
    onSuccess: () => {
      getData({ query: siloID });
    },
  });

  const silobag_actions = data?.silobags_actions;
  const imagesSilo = [
    {
      url: initImage,
      label: initImage !== null ? `Imagen inicial` : '',
      init_azimuth: data?.init_azimuth,
      init_timestamp: data?.init_timestamp,
      init_lat: data?.init_lat,
      init_lon: data?.init_lon,
      init_accuracy: data?.init_accuracy,
    },
    {
      url: mainImage,
      label: mainImage !== null ? `Imagen principal` : '',
      main_azimuth: data?.main_azimuth,
      main_timestamp: data?.main_timestamp,
      main_lat: data?.main_lat,
      main_lon: data?.main_lon,
      main_accuracy: data?.main_accuracy,
    },
    {
      url: endImage,
      label: endImage !== null ? `Imagen final` : '',
      end_azimuth: data?.end_azimuth,
      end_timestamp: data?.end_timestamp,
      end_lat: data?.end_lat,
      end_lon: data?.end_lon,
      end_accuracy: data?.end_accuracy,
    },
  ].filter((image) => image.url != null);

  if (mainImage !== null || initImage !== null || endImage !== null) {
    if (silobag_actions?.length > 0) {
      silobag_actions.map((action) => {
        if (action?.image !== null) {
          imagesSilo.push({
            url: action?.image,
            label: 'Fotos Extras',
            init_azimuth: action?.azimuth,
            init_timestamp: action?.timestamp,
            init_lat: action?.lat,
            init_lon: action?.lon,
            init_accuracy: action?.accuracy,
          });
        }
      });
    }
  }
  const files = data?.files;

  const handleModalFilesOpen = () => {
    setModalFiles(true);
  };

  const copyId = (id: string) => navigator.clipboard.writeText(id);
  const [idCopied, setIdCopied] = useState(false);

  const originLots = data?.silobags_origin_lots?.map((originLot) => originLot);

  const { trigger: deleteFile, isSuccess } = useService(removeFileService, {
    onSuccess: () => {
      setTimeout(() => {
        getData({ query: siloID });
      }, 1000);
    },
  });

  const silosatVerify = data?.v_silobags_bagging_date_check[0]?.bagging_date_check;
  const dateClient = data?.bagging_date;

  const validSatViewsSize =
    data?.sat_view?.filter(
      (view: SatViewSummary[0]) =>
        view.status_id === 1 && view.size !== null && view.sat_view_providers.id === 3
    ) || [];
  const firstValidSatViewSize = validSatViewsSize.length > 0 ? validSatViewsSize[0].size : null;
  const sizeToShow = firstValidSatViewSize;
  // Conserva los cambios de la rama DEV
  const validSatViews =
    (data &&
      data.sat_view?.filter(
        (view: SatViewSummary[0]) => view.status_id === 1 && view.sat_view_providers.id === 3
      )) ||
    [];
  validSatViews.sort((a, b) => {
    const dateA = new Date(a.timestamp ?? '').getTime();
    const dateB = new Date(b.timestamp ?? '').getTime();
    return dateA - dateB;
  });
  const firstValidSatView = validSatViews[0];
  const dateToShow = firstValidSatView?.timestamp ?? data?.bagging_date ?? '';
  const dateToShowDate = new Date(dateToShow);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const daysToShow = dateToShow
    ? Math.round(Math.abs((dateToShowDate.getTime() - now.getTime()) / oneDay))
    : '-';
  //TODO Revisar esta logica con el 0 como valor por defecto
  const sizeClient = data?.size ? parseFloat(data?.size) : 0;
  const sizeDifference = sizeToShow !== null ? Math.abs(parseFloat(sizeToShow) - sizeClient) : null;
  const sizeVerify = sizeDifference !== null && sizeDifference <= 0.2 * sizeClient;

  const isGuest: boolean = data?.is_guest;
  //Logica para mostrar el TAS en el card principal
  const worstTas = findWorstTAS(data);

  const tasDecorators: Record<string, string> = {
    '<1': '<1 mes',
    '1-6': '1-6 meses',
    '>6': '>6 meses',
    NA: '―',
  };

  const TAS = tasDecorators[worstTas] || '―';
  const handleCloseModal = () => setOpenModal(false);

  const handleUpdateDate = () => {
    if (data && selectedDate) {
      const silobagToUpdate = {
        id: data.id,
        bagging_date: selectedDate,
      };
      editSilobag({ query: siloID, body: silobagToUpdate });
    }
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleCloseSizeModal = () => {
    setSizeModal(false);
  };
  const handleSizeChange = () => {
    if (data && selectSize) {
      const silobagToUpdate = {
        id: data.id,
        size: selectSize,
      };
      editSilobag({ query: siloID, body: silobagToUpdate });
    }
    setSizeModal(false);
  };

  const { image_url, name, silobag_species_types_translations } = specie_type || {};
  useEffect(() => {
    const newSpeciesName =
      i18n.locale === 'pt' && silobag_species_types_translations?.length
        ? silobag_species_types_translations[0].description
        : name;
    setSpeciesName(newSpeciesName);
  }, [i18n.locale, silobag_species_types_translations, name]);

  const dataSatBaggingDate = data?.sat_view_events;
  const firstVisibleDate = dayjs(dataSatBaggingDate?.[0]?.sat_view_id_1_image?.timestamp).format(
    'DD/MM/YYYY'
  );
  const lastVisibleDate = dayjs(dataSatBaggingDate?.[0]?.sat_view_id_2_image?.timestamp).format('DD/MM/YYYY');
  const certificationMessage = i18n.t('app.silobag_detail.certification_silosat', {
    lastVisibleDate,
    firstVisibleDate,
  });

  return (
    <Paper
      elevation={0}
      sx={{
        textAlign: 'left',
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'green' }} size={60} />
        </div>
      ) : (
        <Box>
          {/* Condicionales de Modales */}
          {modalObservation && (
            <ObservationModal
              open={modalObservation}
              setOpen={setModalObservation}
              observations={data?.observations ?? ''}
            />
          )}
          {modalImage && <ImagesGalery open={modalImage} onClose={setModalImage} />}
          {modalFiles && (
            <FileModal
              open={modalFiles}
              setOpen={setModalFiles}
              data={files}
              deleteFile={deleteFile}
              writePermission={data?.write_permission}
            />
          )}
          {isClosed ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              mb={'2%'}
              style={{ backgroundColor: '#e4e4e4' }}
              borderRadius={'10px'}
            >
              <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_detail.silo_close')}</Typography>
            </Box>
          ) : null}

          <Typography component="div" sx={textDisabledTablet}>
            {i18n.t('app.organization')}{' '}
            <span style={{ fontWeight: 600, fontSize: '15px', color: 'black' }}>
              {data?.organizations?.name}
            </span>
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={10} md={10} mt={2}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'start', md: 'center' }}
                justifyContent={'space-around'}
              >
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  width={{ xs: '100%', md: '40%' }}
                  mt={1}
                  alignItems={'start'}
                  display={'flex'}
                  justifyContent={'space-evenly'}
                >
                  <Typography component="div" sx={textDisabledTablet} display={'flex'} alignItems={'center'}>
                    <Typography component="span" mr={1} fontSize={'14px'}>
                      {i18n.t('app.silobag_data.register_date')}
                    </Typography>
                    <Typography
                      component="span"
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      fontSize={'16px'}
                    >
                      {created_at}
                    </Typography>
                  </Typography>
                  {/* ID */}
                  <Stack
                    sx={textDisabledTablet}
                    display={'flex'}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                    ml={{ xs: 0, md: 14 }}
                  >
                    <Typography mr={1} fontSize={'14px'}>
                      ID
                    </Typography>
                    <Typography color={'#49454F'} fontWeight={600} display={'block'} fontSize={'16px'}>
                      {data?.id}
                    </Typography>
                    <Tooltip title={i18n.t('app.copy') + ' ID'} placement="top">
                      <Button
                        sx={{ padding: '3px', minWidth: 30, minHeight: 25 }}
                        onClick={() => {
                          setIdCopied(false);
                          copyId(data?.id).then(() => {
                            setTimeout(() => {
                              setIdCopied(true);
                            }, 100);
                          });
                        }}
                      >
                        <img src={copyIcon} style={{ width: 16, height: 16 }} />
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  width={{ xs: '100%', md: '90%' }}
                  display={'flex'}
                  justifyContent={'space-around'}
                  alignItems={'center'}
                  mt={1}
                >
                  {/* Establecimento */}
                  <Typography
                    component="div"
                    sx={{ ...textDisabledTablet, width: { xs: '100%', md: '60%' } }}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Typography component="span" mr={1} fontSize={'14px'}>
                      {i18n.t('app.countryside')}
                    </Typography>
                    <Typography
                      component="span"
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      sx={{ xs: '118px', md: '100%' }}
                      fontSize={'16px'}
                    >
                      {countrysideName}
                    </Typography>
                  </Typography>
                  {/* Lote Stock */}
                  <Typography
                    component="div"
                    sx={textDisabledTablet}
                    width={{ xs: '100%', md: '28%' }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ fontSize: '14px' }}
                  >
                    {i18n.t('app.silobag_data.lot_stock')}
                    <Typography
                      component="span"
                      style={{ color: '#49454F', fontWeight: 600, fontSize: '16px', marginLeft: '5px' }}
                    >
                      {data?.lots?.name}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                display={'flex'}
                justifyContent={'space-between'}
                mt={3}
              >
                <Stack mb={3}>
                  {/* ESPECIE */}
                  <Stack direction={'row'} spacing={1} mb={3} alignItems="center">
                    <img
                      src={image_url}
                      alt={'image for silobag species type'}
                      style={{ width: 40, height: 40 }}
                    />
                    <Stack direction={{ xs: 'column' }}>
                      <Typography
                        color={'#49454F'}
                        fontWeight={600}
                        display={'block'}
                        width={'56px'}
                        fontSize={{ xs: '15px', md: '20px' }}
                      >
                        {speciesName}
                      </Typography>
                      <Typography sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                        {i18n.t('app.silobag_data.specie')}
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* LARGO SILOBOLSA */}
                  <Stack mb={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        color={'#49454F'}
                        fontWeight={600}
                        display={'block'}
                        fontSize={{ xs: '15px', md: '20px' }}
                      >
                        <Box component="span">{sizeClient ? `${sizeClient}m` : '―'}</Box>
                      </Typography>
                      {sizeVerify && sizeToShow && (
                        <Tooltip title={i18n.t('app.silobag_detail.validated_silosat')}>
                          <img src={NewReleaseIcon} alt="release-icon" />
                        </Tooltip>
                      )}
                      {sizeToShow && !sizeVerify && (
                        <Tooltip title={i18n.t('app.silobag_detail.warning_silosat')}>
                          <Button
                            size="small"
                            sx={{ padding: '0px', minWidth: 30, minHeight: 25 }}
                            onClick={() => setSizeModal(true)}
                          >
                            <img src={warningIcon} alt="release-icon" />
                          </Button>
                        </Tooltip>
                      )}
                    </Stack>
                    <Typography sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                      {i18n.t('app.silobag_data.silobag_size')}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack mb={3}>
                  {/* Producto  */}
                  <Typography component="div" sx={textDisabledTablet} mb={3} style={{ fontSize: '14px' }}>
                    <Typography
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      width={'56px'}
                      fontSize={{ xs: '15px', md: '20px' }}
                    >
                      {product_type || '―'}
                    </Typography>
                    <Typography component="span" sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                      {i18n.t('app.silobag_data.product')}{' '}
                    </Typography>
                  </Typography>
                  {/* PESO EMBOLSADO */}
                  <Typography component="div" sx={{ fontSize: '14px' }} mb={3}>
                    <Stack direction={'row'} spacing={7}>
                      <Typography
                        color={'#49454F'}
                        fontWeight={600}
                        display={'block'}
                        width={'56px'}
                        fontSize={{ xs: '15px', md: '20px' }}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {data?.input_weight
                          ? i18n.numberToDelimited(parseFloat(data?.input_weight), {
                              delimiter: i18n.t('config.delimiter'),
                            }) + ' kg'
                          : '―'}
                      </Typography>
                      {data?.is_corvus_weight && (
                        <Tooltip
                          title={
                            <div>
                              {i18n.t('app.integration_corvus.corvus_weight_certified')}
                              <br />
                              {i18n.t('app.integration_corvus.bagging_weight_original')}{' '}
                              {data?.weight_before_corvus} kg
                            </div>
                          }
                        >
                          <img src={NewReleaseIcon} alt="release-icon" />
                        </Tooltip>
                      )}
                    </Stack>
                    {i18n.t('app.silobag_data.weight_bagging')}{' '}
                  </Typography>
                </Stack>
                <Stack mb={3} marginLeft={{ md: '50px' }}>
                  {/* VARIEDAD */}
                  <Typography component="div" sx={textDisabledTablet} style={{ fontSize: '14px' }} mb={3}>
                    <Typography
                      component="span"
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      width={'56px'}
                      fontSize={{ xs: '15px', md: '20px' }}
                    >
                      {data?.variety || '―'}
                    </Typography>
                    {i18n.t('app.silobag_data.variety')}{' '}
                  </Typography>
                  {/* HUMEDAD INICIAL */}
                  <Typography component="div" sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                    <Typography
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      width={'100px'}
                      fontSize={{ xs: '15px', md: '20px' }}
                    >
                      {data?.initial_humidity || '―'} %
                    </Typography>
                    {i18n.t('app.silobag_data.humidity_bagging')}
                  </Typography>
                </Stack>
                <Stack mb={3}>
                  {/* COSECHA */}
                  <Typography component="div" sx={textDisabledTablet} style={{ fontSize: '14px' }} mb={3}>
                    <Typography
                      component="span"
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      width={'100px'}
                      fontSize={{ xs: '15px', md: '20px' }}
                    >
                      {data?.silobag_harvests?.name || '―'}
                    </Typography>
                    {i18n.t('app.silobag_data.harvest')}{' '}
                  </Typography>
                  {/* TAS */}
                  <Typography component="div" sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                    <Typography
                      component="span"
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      width={'100px'}
                      fontSize={{ xs: '15px', md: '20px' }}
                    >
                      <span style={{ fontSize: 18 }}>{TAS}</span>
                    </Typography>
                    TAS{' '}
                  </Typography>
                </Stack>
                <Stack mb={3}>
                  {/* FECHA EMBOLSADO */}
                  <Stack mb={3}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                        component="div"
                        color={'#49454F'}
                        fontWeight={600}
                        display={'block'}
                        fontSize={{ xs: '15px', md: '20px' }}
                      >
                        <Box component="span">{dateClient ? formatDate(dateClient) : '―'}</Box>
                      </Typography>
                      {silosatVerify === true ? (
                        <Tooltip title={certificationMessage}>
                          <Box>
                            <img src={NewReleaseIcon} alt="release-icon" />
                          </Box>
                        </Tooltip>
                      ) : silosatVerify === false ? (
                        <Tooltip title={i18n.t('app.silobag_detail.different_date')}>
                          <Button
                            size="small"
                            sx={{ padding: '0px', minWidth: 30, minHeight: 25 }}
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            <img src={warningIcon} alt="release-icon" />
                          </Button>
                        </Tooltip>
                      ) : null}
                    </Stack>
                    <Typography sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                      {i18n.t('app.silobag_data.bagging_date2')}{' '}
                    </Typography>
                  </Stack>
                  {/* DESDE EMBOLSADO */}
                  <Typography
                    sx={textDisabledTablet}
                    mb={3}
                    style={{ fontSize: '14px' }}
                    marginRight={{ md: '40px' }}
                  >
                    <Typography
                      component="span"
                      color={'#49454F'}
                      fontWeight={600}
                      display={'block'}
                      width={'100px'}
                      fontSize={{ xs: '15px', md: '20px' }}
                    >
                      {daysToShow !== '-' ? `${daysToShow} días` : '―'}
                    </Typography>
                    Desde embolsado
                  </Typography>
                </Stack>
              </Stack>
              {/* ORIGIN LOTS */}
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'start', md: 'center' }}
                flexWrap={'wrap'}
                display={'flex'}
              >
                {originLots?.map((originLot) => (
                  <Stack mr={'30px'} direction={'row'} alignItems={'center'} key={originLot.id}>
                    <Typography sx={textDisabledTablet} style={{ fontSize: '14px' }}>
                      {i18n.t('app.silobag_data.lot_origin')}
                    </Typography>
                    <Typography
                      key={originLot.lots.id}
                      style={{ marginRight: '5px', fontWeight: 600, marginLeft: '5px' }}
                    >
                      {originLot.lots.name}
                    </Typography>
                    <Typography sx={textDisabledTablet} style={{ fontSize: '14px', marginLeft: '10px' }}>
                      Peso {i18n.t('app.silobag_data.lot_origin')}{' '}
                      <span style={{ fontWeight: 600, fontSize: '16px', color: 'black' }}>
                        {originLot.weight || '―'} kg
                      </span>
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Grid>
            {/* Acciones */}
            <Grid item xs={12} sm={2} marginTop={{ xs: '20px', md: '-10px' }} md={2}>
              <Typography component="div" sx={{ marginTop: '5px' }}>
                <Button
                  // DisabledMapIcon
                  startIcon={
                    <img src={isDisabledSB ? DisabledMapIcon : MapIcon} style={{ width: 16, height: 13 }} />
                  }
                  variant="text"
                  sx={buttonDataDetail}
                  disabled={isDisabledSB}
                  onClick={() => navigate(`${PATH.MAP}/${data.id}`)}
                >
                  {i18n.t('app.silobag_detail.view_in_map')}
                </Button>
              </Typography>
              <Button
                startIcon={
                  <img
                    src={imagesSilo?.length > 0 ? imgIcon : imgDisabledIcon}
                    style={{ width: 16, height: 13 }}
                  />
                }
                variant="text"
                disabled={imagesSilo.length <= 0 ? true : false}
                sx={buttonDataDetail}
                onClick={handleModalImageOpen}
              >
                {i18n.t('app.silobag_detail.galery_images')}({imagesSilo.length})
              </Button>
              <Tooltip
                title={i18n.t('app.silobag_detail.files_tooltip')}
                disableHoverListener={files.length > 0}
              >
                <div>
                  <Button
                    startIcon={
                      <img
                        src={files?.length > 0 ? FileIcon : FileDisabledIcon}
                        style={{ width: 16, height: 13 }}
                      />
                    }
                    variant="text"
                    disabled={files?.length > 0 ? false : true}
                    sx={buttonDataDetail}
                    onClick={handleModalFilesOpen}
                  >
                    {i18n.t('app.silobag_detail.view_files')}
                    {files?.length > 0 ? `(${files?.length})` : ''}
                  </Button>
                </div>
              </Tooltip>
              <Tooltip
                title={i18n.t('app.silobag_detail.observation_tooltip')}
                disableHoverListener={data?.observations !== null && data?.observations.length > 0}
              >
                <Box>
                  <Button
                    startIcon={
                      <img
                        src={data?.observations === '' ? ObservationDisabledIcon : ObservationIcon}
                        style={{ width: 16, height: 13 }}
                      />
                    }
                    variant="text"
                    disabled={data?.observations === ''}
                    sx={buttonDataDetail}
                    onClick={handleModalObervationOpen}
                  >
                    {i18n.t('app.observations')}
                  </Button>
                </Box>
              </Tooltip>
              <Typography component="div">
                <Button
                  startIcon={
                    <img
                      src={data?.write_permission === false ? GreyEditIcon : EditIcon}
                      style={{ width: 16, height: 13 }}
                    />
                  }
                  variant="text"
                  sx={buttonDataDetail}
                  onClick={() =>
                    navigate(`${PATH.SILOBAG}/${data?.id}/edit`, {
                      state: {
                        from: `${PATH.SILOBAG}/${data?.id}`,
                        isGuest,
                        isCorvus: data?.is_corvus_weight,
                        siloListId: siloListId,
                      },
                    })
                  }
                  disabled={data?.write_permission === false}
                >
                  Editar
                </Button>
              </Typography>
              <Button
                startIcon={<img src={ReloadIcon} style={{ width: 16, height: 13 }} />}
                variant="text"
                sx={buttonDataDetail}
                onClick={() => location.reload()}
              >
                {i18n.t('app.silobag_detail.refresh')}
              </Button>
            </Grid>
          </Grid>
          {/* DATOS ADICIONALES */}
          <Accordion
            disableGutters
            sx={{
              boxShadow: 'none',
              backgroundColor: 'transparent',
              marginTop: '20px',
              '&:before': { height: '0px' },
            }}
          >
            <AccordionSummary
              expandIcon={<img src={ChevronDown} style={{ width: 10, height: 10 }} />}
              style={{ width: 'max-content' }}
            >
              <Typography
                sx={{ fontWeight: '600', fontSize: '14px', marginLeft: '-10px', marginRight: '10px' }}
              >
                {i18n.t('app.silobag_data.additional_data')} <span> </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ maxHeight: '250px', overflow: 'auto' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontWeight: 600, fontSize: '18px', color: 'black' }}>
                    {data?.responsible_for_bagging || '―'}
                  </Typography>
                  <Typography sx={textDisabledTablet}>
                    {i18n.t('app.silobag_data.bagging_responsible')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.silobag_brand || '―'}
                    </span>
                    % {i18n.t('app.silobag_data.silobag_brand')}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.varietal_purity || '―'}
                    </span>
                    {i18n.t('app.silobag_data.varietal_purity')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.fillying_coefficient || '―'}
                    </span>
                    {i18n.t('app.silobag_data.fillying_coefficient')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.humidity_sources?.humidity_sources_translations?.[0]?.description || '―'}
                    </span>
                    {i18n.t('app.silobag_data.source_humidity')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.split_grain_percentage || '―'} %
                    </span>
                    {i18n.t('app.silobag_data.defective')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.weight_sources?.weight_sources_translations?.[0]?.description || '―'}
                    </span>
                    {i18n.t('app.silobag_data.source_weight')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {data?.side_charge?.side_charge_translations?.[0]?.description || '―'}
                    </span>
                    {i18n.t('app.silobag_data.side_charge')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography sx={textDisabledTablet}>
                    <span style={{ fontWeight: 600, fontSize: '18px', color: 'black', display: 'block' }}>
                      {/* TODO: hasta que se defina en otro Ticket*/} ―
                    </span>
                    {i18n.t('app.silobag_data.have_breaks')}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          {/* Balance */}
          {((data?.silobag_extractions && data?.silobag_extractions.length > 0) || !data?.active) && (
            <>
              <Box sx={{ my: 2, height: '50px', borderBottom: 1, borderColor: 'grey.500' }} />
              <Grid item xs={12} sm={12} sx={{ marginTop: '-20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.2rem' }}>Balance</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography sx={textDisabledTablet} style={{ marginTop: '30px' }}>
                      <span style={{ fontWeight: 600, fontSize: '15px', color: 'black', display: 'block' }}>
                        {(data?.total_extracted_weight || '―').toLocaleString().replace(/,/g, '.') + ' kg'}
                      </span>
                      {i18n.t('app.silobag_detail.weight_extractions')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography component="div" sx={textDisabledTablet} style={{ marginTop: '30px' }}>
                      <span style={{ fontWeight: 600, fontSize: '15px', color: 'black', display: 'block' }}>
                        {(data?.remaining_stock || '0')?.toLocaleString().replace(/,/g, '.') + ' kg'}
                      </span>
                      {i18n.t('app.silobag_data.remaining_stock')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography component="div" sx={textDisabledTablet} style={{ marginTop: '30px' }}>
                      <span style={{ fontWeight: 600, fontSize: '15px', color: 'black', display: 'block' }}>
                        {!isClosed
                          ? '0 kg'
                          : //TODO para revisar el ? del data  y revisar la conversion a int de total_extracted_weight
                            ((+data?.total_extracted_weight || 0) - (+data?.input_weight || 0))
                              .toLocaleString('en-US')
                              .replace(/,/g, '.') + ' kg'}
                      </span>
                      {i18n.t('app.silobag_detail.difference')}
                      {!isClosed ? null : (
                        <Chip
                          //TODO para revisar el ? del data  y revisar la conversion a int de total_extracted_weight
                          label={
                            parseFloat(data?.input_weight) > parseFloat(data?.total_extracted_weight)
                              ? 'Deficit'
                              : 'Superávit'
                          }
                          sx={{
                            //TODO para revisar el ? del data  y revisar la conversion a int de total_extracted_weight
                            backgroundColor:
                              parseFloat(data?.input_weight) > parseFloat(data?.total_extracted_weight)
                                ? 'red'
                                : 'green',
                            color: 'white',
                            fontSize: '9px',
                            fontWeight: 'bold',
                            padding: '2px -15px',
                            height: '20px',
                            width: '80px',
                            marginLeft: '5px',
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography component="div" sx={textDisabledTablet} style={{ marginTop: '30px' }}>
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: '15px',
                          color: 'black',
                          display: 'block',
                        }}
                      >
                        {!isClosed
                          ? '0.00' + '%'
                          : // TODO: para revisar el ? del data  y no es necesario el nullish coalescing porque el parsefloat no devuele null
                            (
                              (((parseFloat(data?.total_extracted_weight) ?? 0) -
                                (parseFloat(data?.input_weight) ?? 0)) /
                                (parseFloat(data?.input_weight) ?? 1)) *
                              100
                            ).toFixed(2) + '%'}
                      </span>
                      {i18n.t('app.silobag_detail.difference')} en %
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
      {isSuccess && <ToastAction message="Archivo eliminado correctamente." severity="success" />}
      {idCopied && (
        <ToastAction
          message="ID copiado satisfactoriamente."
          severity="success"
          onClose={() => setIdCopied(false)}
        />
      )}
      <MyModal open={openModal} onClose={handleCloseModal}>
        <ModalContent
          dateToShow={dateToShow!}
          silobagStatus={data?.active}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleClose={handleClose}
          handleUpdateDate={handleUpdateDate}
          startDate={dataSatBaggingDate?.[0]?.sat_view_id_1_image?.timestamp}
          endDate={dataSatBaggingDate?.[0]?.sat_view_id_2_image?.timestamp}
        />
      </MyModal>
      <SizeModal open={sizeModal} onClose={handleCloseSizeModal}>
        <Box component={'div'}>
          <Typography variant="h5">{i18n.t('app.silobag_detail.different_size')}</Typography>
        </Box>
        <Box component={'div'}>
          <Typography variant="body1">{i18n.t('app.silobag_detail.different_context')}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1">{i18n.t('app.silobag_detail.silosat_size')}</Typography>
            <Typography variant="body1">{sizeToShow}m</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={i18n.t('app.silobag_data.silobag_size')}
              value={selectSize}
              onChange={(e) => SetSelectSize(e.target.value)}
            />
          </Grid>
        </Grid>

        <Box width={'100%'} display={'flex'} justifyContent={'end'} component={'div'}>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button variant="text" onClick={handleCloseSizeModal}>
              {i18n.t('app.close')}
            </Button>
            <Button sx={configButtonStylesContained} variant="contained" onClick={handleSizeChange}>
              {i18n.t('app.save')}
            </Button>
          </Box>
        </Box>
      </SizeModal>
    </Paper>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

export function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 600,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style} component={'div'}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

function SizeModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 600,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style} component={'div'}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

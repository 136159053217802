import { Box, Button, Chip, CircularProgress, Fade, Grid, Modal, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

import { useService } from '@hooks/use-service';
import { silobagImagesService } from '@services/domain/base/silobag/images/silobag-images';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import i18n from '../../../../../libs/language';
import { useEffectOnUpdate } from '@hooks/core';
import DownloadIcon from '@assets/svg/core/download-alt-blue.svg';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

type CarouselProps = {
  open: boolean;
  onClose: () => void;
};

type ImageSelectedProps = {
  url: string | undefined | null;
  lon: string | undefined | null;
  lat: string | undefined | null;
  date: string | undefined | null;
  azimuth: string | undefined | null;
  accuracy: string | undefined | null;
};

type ImageGaleryData = Awaited<
  ReturnType<typeof silobagImagesService>
>['summary']['response'][0]['images'][0];

export function ImagesGalery({ open, onClose }: CarouselProps) {
  const { siloID } = useParams();

  const { summary, isLoading, trigger } = useService(silobagImagesService, {
    fetchOnMount: [{ query: siloID as string }],
  });

  useEffectOnUpdate(() => {
    resetValues();
    setSelectedImageIndex(null);
    trigger({ query: siloID as string });
  }, [siloID]);

  const [imageSelectedData, setImageSelectedData] = useState<ImageSelectedProps>({
    url: undefined,
    lon: '-',
    lat: '-',
    date: '-',
    azimuth: '-',
    accuracy: '-',
  });

  const [selectedImageIndex, setSelectedImageIndex] = useState<string | null>(null);
  const [pascalCaseLabel, setPascalCaseLabel] = useState('');

  const totalImages = summary?.response.reduce((count, item) => count + item.images.length, 0);

  const handleImageClick = (image: ImageGaleryData) => {
    if (selectedImageIndex === image.image_url) {
      // Si ya está seleccionada, deseleccionarla
      setSelectedImageIndex(null);
      resetValues();
    } else {
      // Seleccionar nueva imagen
      setSelectedImageIndex(image.image_url);

      const imageDate = image.timestamp
        ? dayjs(image.timestamp).format('DD/MM/YYYY HH:mm')
        : i18n.t('app.water_mark.missing_date');

      const imageAccuracy =
        image.accuracy != null ? parseFloat((image.accuracy as string) ?? '').toFixed(2) : '0.00';
      const imageAzimuth = image.azimuth != null ? parseFloat(image.azimuth as string).toFixed(2) : '0.00';

      setImageSelectedData({
        url: image.image_url,
        lon: image.lon ?? i18n.t('app.image_gallery.coords_non_disponible'),
        lat: image.lat ?? '',
        date: imageDate,
        azimuth: `${imageAzimuth} °`,
        accuracy: `${imageAccuracy} m`,
      });

      const pascalCaseLabel = (image.description.match(/[a-zA-Z0-9]+/g) ?? [])
        .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
        .join('');

      setPascalCaseLabel(pascalCaseLabel);
    }
  };

  const resetValues = () => {
    setImageSelectedData({
      url: undefined,
      lon: '-',
      lat: '-',
      date: '-',
      azimuth: '-',
      accuracy: '-',
    });
  };

  return (
    <MyModal open={open} onClose={onClose}>
      {isLoading ? (
        <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <CircularProgress style={{ width: 24, height: 24 }} />
        </Box>
      ) : (
        <Box>
          <Stack direction={'row'} alignItems={'center'} spacing={1} marginBottom={1}>
            <Typography variant="h5">{i18n.t('app.image_gallery.title')}</Typography>
            <Chip label={totalImages} style={{ backgroundColor: '#e1eecc', color: '#6baa00' }} size="small" />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={3}>
            {/* IMAGENES Y EVENTOS */}
            <Box maxWidth={'60%'} maxHeight={'75vh'}>
              <Stack spacing={1} height={'100%'} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
                {summary?.response.map((summary) => {
                  return (
                    <>
                      {/* TITULO DE EVENTO */}
                      <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
                        <Chip
                          label={summary.description}
                          style={{
                            backgroundColor: `${summary.background_color}`,
                            color: `${summary.text_color}`,
                            borderRadius: '10px',
                            fontSize: '13px',
                            fontWeight: 'bold',
                            padding: '0 8px',
                            width: '50%',
                            height: '30px',
                          }}
                        />
                        <Typography marginRight={1}>
                          {dayjs(summary.created_at).format('DD/MM/YYYY')}
                        </Typography>
                      </Stack>

                      {/* IMAGENES */}
                      <Grid container spacing={0.5} marginTop={0.2}>
                        {summary.images.map((image, index) => (
                          <Grid item xs={3} key={index}>
                            <img
                              src={image.image_url}
                              onClick={() => handleImageClick(image)}
                              style={{
                                width: '95%',
                                height: 'auto',
                                border:
                                  selectedImageIndex === image.image_url
                                    ? '3px solid #22a7f0'
                                    : '2px solid transparent',
                                cursor: 'pointer',
                                transition: '0.3s ease',
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </>
                  );
                })}
              </Stack>
            </Box>
            {/* IMAGEN SELECCIONADA */}
            <Stack height={'70vh'} width={'40%'} justifyContent={'start'} spacing={1}>
              {/* IMAGEN */}
              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'end'}
                position={'relative'}
                marginBottom={1}
              >
                {imageSelectedData && (
                  <DownloadLink
                    url={imageSelectedData.url as string}
                    fileName={`${siloID}-${pascalCaseLabel}${imageSelectedData.date}`}
                  />
                )}
              </Box>
              <Box height={'80%'} width={'100%'} borderRadius={4}>
                {imageSelectedData.url ? (
                  <Box style={{ width: '100%', height: '100%', objectFit: 'cover' }} borderRadius={4}>
                    <img
                      src={imageSelectedData.url}
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 4 }}
                    />
                  </Box>
                ) : (
                  <Box
                    style={{ width: '100%', height: '100%', backgroundColor: '#f3f2f7' }}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    borderRadius={4}
                  >
                    <Typography variant="body2">{i18n.t('app.silobag_history.no_images')}</Typography>
                  </Box>
                )}
              </Box>
              {/* COORDENADAS Y DATOS */}
              <Box height={'10%'} width={'50%'}>
                <Typography fontSize={13}>{`${imageSelectedData.lon}; ${imageSelectedData.lat}`}</Typography>
                <Typography fontSize={13}>
                  {i18n.t('app.date')}: {imageSelectedData.date}
                </Typography>
                <Typography fontSize={13}>
                  {i18n.t('app.silobag_history.gps_accuracy')}: {imageSelectedData.accuracy}
                </Typography>
                <Typography fontSize={13}>
                  {i18n.t('app.silobag_history.compass')}: {imageSelectedData.azimuth}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box width={'100%'} justifyContent={'end'} display={'flex'} position={'relative'}>
            <Button onClick={onClose}>{i18n.t('app.close')}</Button>
          </Box>
        </Box>
      )}
    </MyModal>
  );
}

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 1000,
    maxHeight: '100%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = () => onClose();

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

type DownloadLinkProps = {
  url: string;
  fileName?: string;
};
export function DownloadLink({ url, fileName = 'siloreal-image' }: DownloadLinkProps) {
  if (!url) return;
  const ext = url?.match(/\.([^./?#]+)($|\?|#)/)?.at(1);

  if (!ext) throw new Error('[SiloReal][DOWNLOAD-FILE] No extension found in the URL: ' + url);

  const handleDownload = () => {
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.${ext}`;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
        console.warn('Refetching...');
        fetch(url, {
          method: 'GET',
          cache: 'no-cache',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.${ext}`;
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            console.info('Refetched successfully.');
          })
          .catch((error) => {
            console.error('Error (again) fetching the file:', error);
          });
      });
  };

  return (
    <Box>
      <Button
        sx={{
          color: '#22A7F0',
          textTransform: 'none',
          px: 2,
          '&:hover': { background: '#22a7f02a' },
        }}
        startIcon={<img src={DownloadIcon} />}
        onClick={handleDownload}
      />
    </Box>
  );
}

import { AppBar, Box, Stack, Toolbar } from '@mui/material';

import { MainLogo } from './logo';
import { AlertSidePanel } from './alert-panel';
import { MainMenu } from './menu';
import { UserOrganizationSelector } from './org-selector';
import { UserProfileDropdown } from './profile';
import { SearchBar } from './searchbar';
import { LearnSiloReal } from './learn-silo-real';
import { useEffect, useRef, useState } from 'react';

import i18n from '../../libs/language';
import { useConnection } from '@hooks/use-connection';
import { useGlobalStore } from '@global-store/use-store';

import { useLocation, useNavigate } from 'react-router-dom';
import { PATH } from '@data/core/paths';

export function GlobalNav() {
  useEffect(() => {
    const storedName = localStorage.getItem('LANGUAGE_PREFERENCE');
    if (storedName) {
      i18n.locale = storedName;
    }
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const isOnline = useConnection();

  const email = useGlobalStore.getState().email;
  const navigate = useNavigate();
  const location = useLocation();
  const previousPathRef = useRef(location.pathname);

  useEffect(() => {
    if (!isOnline) {
      previousPathRef.current = location.pathname;
      return navigate(PATH.NO_CONNECTION);
    } else if (isOnline && email) {
      return navigate(previousPathRef.current);
    } else if (isOnline && !email) {
      return navigate(PATH.LOGIN);
    }
  }, [isOnline]);

  return (
    <>
      <Box>
        <AppBar
          component="header"
          position="static"
          elevation={0}
          sx={{
            color: '#000',
            backgroundColor: '#FFF',
            height: '64px',
            borderBottom: '1px solid #ccc',
            paddingRight: { xs: 0, md: '15px' },
          }}
        >
          <Toolbar disableGutters sx={{ padding: '0 20px' }}>
            {windowSize.width > 1025 ? (
              <>
                {' '}
                <MainLogo />
                <SearchBar />
                <UserOrganizationSelector />
                <MainMenu />
                <AlertSidePanel />
                <LearnSiloReal />
                <UserProfileDropdown isMobile={false} />
              </>
            ) : (
              <>
                <Stack direction={'row'} spacing={2} display={'flex'} width={'100%'} alignItems={'center'}>
                  <MainLogo />
                  <SearchBar />
                  <AlertSidePanel />
                  <UserProfileDropdown isMobile={true} />
                </Stack>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper, SelectChangeEvent } from '@mui/material';
import { customBgWhite, stylesInput } from '@styles/generic-styles';
import { useService } from '@hooks/use-service';
import { Metrics } from './charts/metric';
import { useState, useEffect } from 'react';
import { Select, MenuItem, OutlinedInput, FormControl, InputLabel, Stack } from '@mui/material';
import { calculatePastDays } from '@utils/helper';
import i18n from '../../../../libs/language';
import { silobagMeasurementDataService } from '@services/domain/base/silobag/base/measurement-data';
import dayjs from 'dayjs';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Custom loading component
const LoadingCircle = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '500px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="loading-circle" />
      {/* Add your custom loading circle CSS here */}
      <style>{`
        .loading-circle {
          border: 4px solid #f3f3f3;
          border-radius: 50%;
          border-top-color: #6BAA00;
          width: 50px;
          height: 50px;
          -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const periodos = [
  { name: '1 día', id: 1 },
  { name: '3 días', id: 3 },
  { name: '7 días', id: 7 },
  { name: '15 días', id: 15 },
  { name: '30 días', id: 30 },
  { name: '60 días', id: 60 },
  { name: '180 días', id: 180 },
  { name: '365 días', id: 365 },
];

type SilobagDataDetail = Awaited<ReturnType<typeof silobagDataDetailService>>['summary'];

type SilobagMetricsProps = {
  siloId: string;
  silobag?: SilobagDataDetail;
};
export const SilobagMetrics = (props: SilobagMetricsProps) => {
  const [value, setValue] = useState(1);
  const { siloId, silobag } = props;
  const [startDate, setStarDate] = useState<number>(60);
  const [totalFields, setTotalFields] = useState(0);
  const [loading, setLoading] = useState(false);
  const today = new Date();

  const fromDate = dayjs(calculatePastDays(startDate)).format('YYYY-MM-DD');
  const toDate = dayjs(today).format('YYYY-MM-DD');

  const { trigger: getSiloMeasurement, summary: measurements } = useService(silobagMeasurementDataService, {
    fetchOnMount: [{ query: { id: siloId, from: fromDate, to: toDate } }],
  });

  type measurementsDataType = Awaited<ReturnType<typeof silobagMeasurementDataService>>['summary'];

  type countNonNullFieldsType = {
    eui_id: measurementsDataType[0]['eui_id'];
    heg: measurementsDataType[0]['heg'];
    co2_percent: measurementsDataType[0]['co2_percent'];
    battery_percent: measurementsDataType[0]['battery_percent'];
    temperature_celsius: measurementsDataType[0]['temperature_celsius'];
    humidity_percent: measurementsDataType[0]['humidity_percent'];
  };

  const countNonNullFields = (data: countNonNullFieldsType[]): void => {
    const fieldsCount = data.reduce((count: number, metric: countNonNullFieldsType) => {
      if (metric.eui_id !== null) count++;
      if (metric.heg !== null) count++;
      if (metric.co2_percent !== null) count++;
      if (metric.battery_percent !== null) count++;
      if (metric.temperature_celsius !== null) count++;
      if (metric.humidity_percent !== null) count++;
      return count;
    }, 0);
    setTotalFields(fieldsCount);
  };

  useEffect(() => {
    if (measurements) {
      countNonNullFields(measurements);
    }
  }, [measurements]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onChange = (e: SelectChangeEvent<number>) => {
    setStarDate(e.target.value as number);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const start = dayjs(calculatePastDays(startDate)).format('YYYY-MM-DD');
      await getSiloMeasurement({ query: { id: siloId, from: start, to: toDate } });
      setLoading(false);
    };

    fetchData();
  }, [startDate]);
  return (
    <Paper sx={customBgWhite} elevation={0}>
      <Box component="h1" margin={0} mb={3}>
        {i18n.t('app.silobag_data.metrics')}
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stack direction="column" alignItems={'center'} justifyContent={'center'} spacing={1}>
          <FormControl variant="outlined" size="small" sx={stylesInput}>
            <InputLabel id="demo-multiple-name-label">Periodos</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              input={<OutlinedInput label="Periodos" />}
              sx={{ width: '200px' }}
              value={startDate}
              defaultValue={60}
              onChange={onChange}
            >
              {periodos.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {loading ? (
            <div>...</div>
          ) : (
            <Typography variant="h6" component="div">
              <span style={{ fontWeight: 600 }}>{totalFields} </span>
              {i18n.t('app.silobag_metric.meditions_registered')}
            </Typography>
          )}
        </Stack>

        {totalFields === 0 ? (
          <div style={{ textAlign: 'center', padding: '20px', fontSize: '16px', color: '#888' }}>
            No data available.
          </div>
        ) : (
          <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab
                  label={i18n.t('app.silobag_metric.co2')}
                  {...a11yProps(0)}
                  sx={{ textTransform: 'none' }}
                />
                <Tab
                  label={i18n.t('app.silobag_metric.heg')}
                  {...a11yProps(1)}
                  sx={{ textTransform: 'none' }}
                />
                <Tab label="Bateria" {...a11yProps(2)} sx={{ textTransform: 'none' }} />
              </Tabs>
            </Box>
            <Box>
              <CustomTabPanel value={value} index={0}>
                {loading ? (
                  <LoadingCircle />
                ) : (
                  <Metrics
                    silobag={silobag}
                    dataToRender={measurements ?? []}
                    shortName="CO2"
                    period={startDate}
                  />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {loading ? (
                  <LoadingCircle />
                ) : (
                  <>
                    <Metrics
                      silobag={silobag}
                      dataToRender={measurements ?? []}
                      shortName="HEG"
                      period={startDate}
                    />
                    <Metrics
                      silobag={silobag}
                      dataToRender={measurements ?? []}
                      shortName="HUM"
                      period={startDate}
                    />
                    <Metrics
                      silobag={silobag}
                      dataToRender={measurements ?? []}
                      shortName="TEMP"
                      period={startDate}
                    />
                  </>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {loading ? (
                  <LoadingCircle />
                ) : (
                  <Metrics
                    silobag={silobag}
                    dataToRender={measurements ?? []}
                    shortName="BAT"
                    period={startDate}
                  />
                )}
              </CustomTabPanel>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

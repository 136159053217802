import { Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import i18n from '../../libs/language';
import IconSiloID from '@assets/svg/siloreal/product-silo-id.svg';
import IconSiloSat from '@assets/svg/siloreal/product-silo-sat.svg';
import IconSiloMetrix from '@assets/svg/siloreal/product-silo-metrix.svg';
import { GridColDef, GridPaginationModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/core/paths';
import BlueViewIcon from '@assets/svg/SiloBagPage/view/eye-blue.svg';
import { BaseTable } from '@ui/table';
import EyeIcon from '@assets/svg/core/eye-green.svg';
import CrossEyeIcon from '@assets/svg/core/visibility_off.svg';
import CheckIcon from '@assets/svg/check.svg';
import { formatDate, formatDateTime } from '@utils/helper';
import { useState } from 'react';
import { useToggle } from '@hooks/core';
import { PoeModal } from '@features/silobags/silobag-detail/section-history/poe-silobag-modal';
import ChevronRight from '@assets/svg/chevron-right.svg';
import { stockExistenceListService } from '@services/domain/page/report/existence-stock/report-existence-stock-info';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type StockExistenceList = Awaited<ReturnType<typeof stockExistenceListService>>['summary'];
type StockRowsType = StockExistenceList['rows'];
type PaginationModel = { page: number; pageSize: number };

type PoeData = StockExistenceList['rows'][0]['poe'];
type PoeSummary = Awaited<ReturnType<typeof silobagDataDetailService>>['summary']['poe'];

type ExistenceStockProps = {
  data: StockRowsType;
  isLoading: boolean;
  count: number;
  paginationModel: PaginationModel;
  setPaginationModel: (fn: (model: GridPaginationModel) => GridPaginationModel) => void;
};

interface SilosatStatusItem {
  status: string;
  color: string;
  img?: JSX.Element; // o React.ReactNode dependiendo de cómo se renderice
}

interface SilosatStatus {
  [key: number]: SilosatStatusItem;
  default: SilosatStatusItem;
}

interface SilosatImagesStatusItem {
  color: string;
}

interface SilosatImagesStatus {
  [key: number]: SilosatImagesStatusItem;
  default: SilosatImagesStatusItem;
}

const CertaintyHeader = () => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
      {i18n.t('app.existence_report_stock.certainty')}
    </Typography>
    <Chip
      label="Beta"
      sx={{
        color: 'white',
        background: '#42D296',
        fontSize: 10,
        width: 40,
        height: 14,
        borderRadius: 6,
        '& .MuiChip-label': { padding: 0 },
      }}
    />
  </Stack>
);

export function TabletExistenceStock({
  data,
  isLoading,
  count,
  paginationModel,
  setPaginationModel,
}: ExistenceStockProps) {
  const navigate = useNavigate();
  const [modalPoe, setModalPoe] = useToggle(false);
  const [eventId, setEventId] = useState<number>();
  const [dataPoe, setDataPoe] = useState<PoeData[]>();

  // ES NECESARIO HACER ESTO YA QUE LOS DATOS DEL BACK NO TRAEN ID Y LA TABLA DE MUI REQUIERE DE UN ID (AUNQUE NO SE USE)
  const dataWithId = data?.map((quote, index) => ({
    ...quote,
    id: index + 1,
  }));

  const SILOSAT_STATUS: SilosatStatus = {
    1: {
      status: i18n.t('app.existence_report_stock.is_visualized'),
      color: '#6BAA00',
      img: <img src={EyeIcon} alt="visualize-icon" style={{ marginRight: 5, height: 10 }} />,
    },
    2: {
      status: i18n.t('app.existence_report_stock.not_visualized'),
      color: '#FF0000',
      img: <img src={CrossEyeIcon} alt="not-visualize-icon" style={{ marginRight: 5, height: 15 }} />,
    },
    default: { status: i18n.t('app.existence_report_stock.without_results'), color: '#49454F' },
  };

  const SILOSAT_IMAGES_STATUS: SilosatImagesStatus = {
    1: { color: '#6baa00' },
    2: { color: 'red' },
    default: { color: '#e8e6f0' },
  };

  const handleOpenPoe = (event_id: number, data: PoeData[]) => {
    setModalPoe(true);
    setEventId(event_id);
    setDataPoe(data);
  };

  const columns: GridColDef[] = [
    {
      field: 'silobag_id',
      sortable: false,
      flex: 0.8,
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          ID Silo
        </Typography>
      ),
      renderCell: (params) => {
        const siloId = params.value;
        return (
          <Box component="div" style={{ display: 'flex', alignItems: 'center' }}>
            <Stack
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'row'}
              spacing={1}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  color: '#05AADF',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`${PATH.SILOBAG}/${siloId}`)}
              >
                {siloId}
              </Typography>
              <img src={ChevronRight} alt="go-to-silo" style={{ paddingTop: 5 }} />
            </Stack>
          </Box>
        );
      },
    },
    {
      field: 'silobag_name',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.silobag')}
        </Typography>
      ),
      renderCell: (params) => {
        const silobagName = params.value;
        return (
          <Typography color={'#49454F'} fontSize={13}>
            {silobagName}
          </Typography>
        );
      },
      flex: 1,
    },
    {
      field: 'countryside_name',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.countryside')}
        </Typography>
      ),
      renderCell: (params) => {
        const countrysideName = params.value;
        return (
          <Typography color={'#49454F'} fontSize={13}>
            {countrysideName}
          </Typography>
        );
      },
      flex: 2,
    },
    {
      field: 'silobag_species_type',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.silobag_data.specie')}
        </Typography>
      ),
      renderCell: (params) => {
        const silobagSpeciesType = params.value;
        return (
          <Typography color={'#49454F'} fontSize={13}>
            {silobagSpeciesType}
          </Typography>
        );
      },
      cellClassName: 'end-grouping-cell',
      headerClassName: 'end-grouping-cell',
    },
    {
      field: 'days_since_last_poe',
      flex: 1.2,
      renderHeader: () => {
        const [fst, snd, ...rest] = i18n.t('app.existence_report_stock.days_since_last_poe').split(' ');
        return (
          <Stack>
            <Typography color={'#49454F'} fontSize={13} fontWeight={600} style={{ width: 'min-content' }}>
              {fst + ' ' + snd}
            </Typography>
            <Typography color={'#49454F'} fontSize={13} fontWeight={600} style={{ width: 'min-content' }}>
              {rest.join(' ')}
            </Typography>
          </Stack>
        );
      },
      renderCell: (params) => {
        const daysSinceLastPoe = params.value;
        const isExpired = params.row.poe_expired;
        return (
          <Typography color={isExpired ? '#FF0000' : '#49454F'} fontSize={13}>
            {daysSinceLastPoe} {i18n.t('app.days')}
          </Typography>
        );
      },
    },
    {
      field: 'poe_id',
      sortable: false,
      align: 'center',
      flex: 0.7,
      cellClassName: 'end-grouping-cell',
      headerClassName: 'end-grouping-cell',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.detail')}
        </Typography>
      ),
      renderCell: (params) => {
        const poeID = params.value as number;
        const poeData = poeID !== null ? [params.row.poe] : null;

        return (
          <Box>
            {poeID !== null && poeData !== null ? (
              <IconButton
                style={{ padding: '5px' }}
                onClick={() => handleOpenPoe(poeID, poeData as PoeData[])}
              >
                <img src={BlueViewIcon} alt="view-icon" style={{ width: '18px' }} />
              </IconButton>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: 'status_id',
      minWidth: 135,
      flex: 1.2,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.existence_report_stock.result')}
        </Typography>
      ),
      renderCell: (params) => {
        const silosatResult = params.value;

        if (!silosatResult) return null;

        return (
          <Stack
            direction="row"
            alignItems="center"
            px={1}
            py={0.5}
            style={{ background: silosatResult === 1 ? '#73BD0033' : '#F003', borderRadius: '4px' }}
          >
            {silosatResult !== null && SILOSAT_STATUS[silosatResult]?.img}
            <Typography
              fontSize={12}
              fontWeight={400}
              color={SILOSAT_STATUS[silosatResult ?? 'default']?.color}
            >
              {SILOSAT_STATUS[silosatResult ?? 'default']?.status}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'suggested_status_id_certainty',
      renderHeader: () => <CertaintyHeader />,
      flex: 1.2,
      minWidth: 160,
      renderCell: (params) => {
        const certainty = params.value;

        if (!certainty) return null;

        return (
          <Typography color="#49454F" fontSize={13}>
            {Math.round(certainty)}
          </Typography>
        );
      },
    },
    {
      field: 'last_seven_days',
      flex: 1.2,
      cellClassName: 'end-grouping-cell',
      headerClassName: 'end-grouping-cell',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.existence_report_stock.last_seven_days')}
        </Typography>
      ),
      minWidth: 130,
      renderCell: (params) => {
        if (!params.row.suggested_status_id_certainty) return null;

        const imagesStates = [
          {
            date: params?.row?.images_7_date,
            status_id: params?.row?.last_image_7_status_id,
            count: params?.row?.images_7_count,
          },
          {
            date: params?.row?.images_6_date,
            status_id: params?.row?.last_image_6_status_id,
            count: params?.row?.images_6_count,
          },
          {
            date: params?.row?.images_5_date,
            status_id: params?.row?.last_image_5_status_id,
            count: params?.row?.images_5_count,
          },
          {
            date: params?.row?.images_4_date,
            status_id: params?.row?.last_image_4_status_id,
            count: params?.row?.images_4_count,
          },
          {
            date: params?.row?.images_3_date,
            status_id: params?.row?.last_image_3_status_id,
            count: params?.row?.images_3_count,
          },
          {
            date: params?.row?.images_yesterday_date,
            status_id: params?.row?.last_image_yesterday_status_id,
            count: params?.row?.images_yesterday_count,
          },
          {
            date: params?.row?.images_today_date,
            status_id: params?.row?.last_image_today_status_id,
            count: params?.row?.images_today_count,
          },
        ];

        return (
          <Stack direction="row" spacing={0.3}>
            {imagesStates?.map((image, index) => {
              const colorBgr = SILOSAT_IMAGES_STATUS[image.status_id ?? 'default']?.color;
              const imagesCount = image.count;
              const imageDate = image.date;
              return (
                <Tooltip title={`Imágenes: ${imagesCount}, Dia: ${formatDate(imageDate)}`} key={index}>
                  <Box
                    key={index}
                    style={{
                      width: '10px',
                      height: '10px',
                      backgroundColor: colorBgr,
                      border: '1px solid',
                      borderColor: '#C5BFBF',
                      borderRadius: '2px',
                    }}
                  />
                </Tooltip>
              );
            })}
          </Stack>
        );
      },
    },
    {
      field: 'last_movement_alert_date',
      flex: 1,
      align: 'center',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.existence_report_stock.movement')}
        </Typography>
      ),
      renderCell: (params) => {
        const hasAlert = params.row.last_measurement_date != null;

        if (!hasAlert) return null;

        const lastActiveAlertDate = params.value;

        const alertColor = lastActiveAlertDate ? '#FF0000' : '#6BAA00';
        const alertLabel = lastActiveAlertDate ? 'MOV' : <img src={CheckIcon} alt="Check Icon" />;
        const alertLastDateRender = lastActiveAlertDate ? (
          <Typography color="#49454F" fontSize={10}>
            {formatDateTime(lastActiveAlertDate, 4)}
          </Typography>
        ) : null;

        return (
          <Stack spacing={0.3} justifyContent="center" alignItems="center">
            <Chip
              size="small"
              sx={{
                color: '#ffff',
                backgroundColor: alertColor,
                fontSize: '11px',
                height: '24px',
                width: '58px',
                borderRadius: '8px',
              }}
              label={alertLabel}
            />
            {alertLastDateRender}
          </Stack>
        );
      },
    },
    {
      field: 'last_measurement_date',
      flex: 1.2,
      align: 'center',
      renderHeader: () => (
        <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
          {i18n.t('app.existence_report_stock.last_measurement')}
        </Typography>
      ),
      renderCell: (params) => {
        const lastMeasurementDate = params.value;

        if (!lastMeasurementDate) return null;

        return (
          <Typography color="#49454F" fontSize={13}>
            {formatDateTime(lastMeasurementDate, 4)}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box style={{ marginTop: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
      <BaseTable
        loading={isLoading}
        rows={dataWithId}
        columns={columns}
        initialPageSize={paginationModel?.pageSize ?? 50}
        paginationMode="server"
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        rowCount={count}
        experimentalFeatures={{ columnGrouping: true }} // TODO: En MUI 7.x.x ya no es experimental
        columnGroupingModel={[
          {
            groupId: 'silobag',
            description: 'Silobag',
            children: [
              { field: 'silobag_id' },
              { field: 'silobag_name' },
              { field: 'countryside_name' },
              { field: 'silobag_species_type' },
            ],
            headerClassName: 'end-grouping-cell',
            renderHeaderGroup: () => (
              <Typography color={'#49454F'} fontSize={13} fontWeight={600}>
                {i18n.t('app.silobags')}
              </Typography>
            ),
          },
          {
            groupId: 'silo-id',
            description: 'Silo ID',
            children: [{ field: 'days_since_last_poe' }, { field: 'poe_id' }],
            headerClassName: 'end-grouping-cell',
            renderHeaderGroup: () => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                  <img src={IconSiloID} alt="siloID-icon" style={{ width: 18, height: 18 }} />
                  <Typography color="#6baa00" display="flex" alignItems="center">
                    SILO <b>ID</b>
                  </Typography>
                </Stack>
                <Typography color={'#49454F'} fontSize={13}>
                  {i18n.t('app.poe_round')}
                </Typography>
              </Stack>
            ),
          },
          {
            groupId: 'silo-sat',
            description: 'Silo Sat',
            children: [
              { field: 'status_id' },
              { field: 'suggested_status_id_certainty' },
              { field: 'last_seven_days' },
            ],
            headerClassName: 'end-grouping-cell',
            renderHeaderGroup: () => (
              <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                <img src={IconSiloSat} alt="SiloSat-icon" style={{ width: 18, height: 18 }} />
                <Typography color="#1481BC" display="flex" alignItems="center">
                  SILO <b>SAT</b>
                </Typography>
              </Stack>
            ),
          },
          {
            groupId: 'silo-metrix',
            description: 'Silo Metrix',
            children: [{ field: 'last_movement_alert_date' }, { field: 'last_measurement_date' }],
            renderHeaderGroup: () => (
              <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                <img src={IconSiloMetrix} alt="siloID-icon" style={{ width: 18, height: 18 }} />
                <Typography color="#F19300" display="flex" alignItems="center">
                  SILO <b>METRIX</b>
                </Typography>
              </Stack>
            ),
          },
        ]}
        sx={{
          '& .end-grouping-cell': { borderRight: '1px solid rgba(224, 224, 224, 1)' },
          '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
            border: 'none', // remove header grouping bottom border
          },
        }}
      />
      {PoeModal && (
        <PoeModal open={modalPoe} setOpen={setModalPoe} data={dataPoe as PoeSummary} poeId={eventId} />
      )}
    </Box>
  );
}

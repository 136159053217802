import { Box, Paper, IconButton } from '@mui/material';
import { customBgWhite } from '@styles/generic-styles';
import { formatDate } from '@utils/helper';
import { BaseTable } from '@ui/table';
import { GridColDef } from '@mui/x-data-grid-pro';
import viewIcon from '@assets/svg/administrators/view.svg';
import editIcon from '@assets/svg/administrators/edit.svg';
import { useToggle } from '@hooks/core';
import { useState } from 'react';
import { ExtractionAction } from '../exctraction-modal';
import { DeleteExtraction } from '../exctraction-modal/deleteExtraction';
import DeleteIcon from '@assets/svg/core/trash-green.svg';
import DeleteDisabledIcon from '@assets/svg/core/trash-disabled.svg';
import editDisabledIcon from '@assets/svg/SiloBagPage/view/edit-disabled.svg';
import i18n from '../../../../libs/language';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SummarySilobagExtractions = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['silobag_extractions'][0];

type SummaryRemainingStock = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['remaining_stock'];

type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];

type SilobagExtractionsProps = {
  data?: SummarySilobagExtractions;
  isLoading?: boolean;
  siloName?: string;
  writePermission?: boolean;
  active?: boolean;
  siloTrigger: (query: SilobagDetailParams) => void;
  setTypeSnackbar: (type: 'success' | 'error') => void;
  setOpenSnackbar: (val: boolean) => void;
  setSnackbarMessage: (msg: string) => void;
  remainingStock?: SummaryRemainingStock;
  setExtractionSuccess?: (success: boolean) => void;
  siloID: string;
};

export function ExtractionSection({
  data,
  isLoading,
  siloName,
  siloTrigger,
  writePermission,
  setTypeSnackbar,
  setOpenSnackbar,
  setSnackbarMessage,
  remainingStock,
  setExtractionSuccess,
  active = false,
  siloID,
}: SilobagExtractionsProps) {
  const [modalExtraction, setModalExtraction] = useToggle(false);
  const [extractionID, setExtractionID] = useState<number>();
  const [type, setType] = useState<'new' | 'view' | 'edit'>('edit');
  const [extractionData, setExtractionData] = useState<SummarySilobagExtractions>();
  const [deleteExtraction, setDeleteExtraction] = useState(false);
  const [extractionIdToDelete, setExtractionIdToDelete] = useState<number>();
  const [localRemainingStock, setLocalRemainingStock] = useState<null | string | number>('0');
  const enableEditMode = () => setType('edit');
  const handleOpenExtraction = (type: 'new' | 'view' | 'edit', params: SummarySilobagExtractions) => {
    setModalExtraction(true);
    setLocalRemainingStock(remainingStock ?? '');
    setType(type);
    setExtractionData(params);
    setExtractionID(params.id);
  };

  const handleDeleteExtraction = (extractionID: number) => {
    setDeleteExtraction(true);
    setExtractionIdToDelete(extractionID);
  };

  const rows = data !== undefined ? data : [];

  const columns: GridColDef[] = [
    {
      field: 'extraction_date',
      headerName: i18n.t('app.date'),
      flex: 1.1,
      minWidth: 100,
      renderCell: (params) => <span>{formatDate(params.value)}</span>,
    },
    {
      field: 'freight_transport',
      headerName: 'Int. flete',
      flex: 1,
      minWidth: 80,

      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'carrier',
      headerName: i18n.t('app.silobags_extractions.carrier'),
      flex: 1,
      minWidth: 120,

      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'driver',
      headerName: i18n.t('app.silobags_extractions.driver'),
      flex: 1,
      minWidth: 90,

      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'back_plate',
      headerName: i18n.t('app.silobags_extractions.chassis_patent'),
      flex: 1.8,
      minWidth: 130,
      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'trailer_back_plate',
      headerName: i18n.t('app.silobags_extractions.chassis_attached'),
      flex: 1.8,
      minWidth: 147,

      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'ctg_document',
      headerName: 'CTG',
      flex: 0.5,
      minWidth: 70,
      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'waybill',
      headerName: 'C.Porte',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'contract',
      headerName: i18n.t('app.silobags_extractions.contract'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'destination',
      headerName: 'Destino',
      flex: 1,
      minWidth: 90,

      renderCell: (params) => <span>{params.value || '―'}</span>,
    },
    {
      field: 'gross_weight',
      headerName: i18n.t('app.silobags_extractions.gross') + '(kg)',
      flex: 1.1,
      minWidth: 90,

      renderCell: (params) => (
        <span>
          {params.value !== null
            ? i18n.numberToDelimited(params.value, {
                delimiter: i18n.t('config.delimiter'),
              })
            : '―'}
        </span>
      ),
    },
    {
      field: 'truck_weight',
      headerName: 'Tara (kg)',
      flex: 1,
      minWidth: 90,

      renderCell: (params) => (
        <span>
          {params.value !== null
            ? i18n.numberToDelimited(params.value, {
                delimiter: i18n.t('config.delimiter'),
              })
            : '―'}
        </span>
      ),
    },
    {
      field: 'weight',
      headerName: i18n.t('app.silobags_extractions.liquid') + '(kg)',
      flex: 1.2,
      minWidth: 90,

      renderCell: (params) => (
        <span>
          {i18n.numberToDelimited(params.value, {
            delimiter: i18n.t('config.delimiter'),
          }) || '―'}
        </span>
      ),
    },
    {
      field: 'Acciones',
      headerName: i18n.t('app.actions'),
      flex: 1.5,
      minWidth: 100,
      renderCell: (params) => {
        const disableButton = !active || !writePermission;
        return (
          <Box>
            <div>
              <IconButton onClick={() => handleOpenExtraction('view', params.row)}>
                <img src={viewIcon} alt="eye" />
              </IconButton>
              <IconButton onClick={() => handleOpenExtraction('edit', params.row)} disabled={disableButton}>
                <img src={!disableButton ? editIcon : editDisabledIcon} alt="edit" />
              </IconButton>
              <IconButton disabled={disableButton} onClick={() => handleDeleteExtraction(params.row.id)}>
                <img src={!disableButton ? DeleteIcon : DeleteDisabledIcon} alt="delete" />
              </IconButton>
            </div>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {modalExtraction && (
        <ExtractionAction
          active={active}
          extractionData={extractionData}
          silobagOrExtractionId={extractionID}
          type={type}
          open={modalExtraction}
          setOpen={setModalExtraction}
          siloName={siloName}
          remainingStock={localRemainingStock as string}
          setRemainingStock={setLocalRemainingStock}
          setSnackbarMessage={setSnackbarMessage}
          setTypeSnackbar={setTypeSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          enableEditMode={enableEditMode}
          writePermission={writePermission}
          siloTrigger={siloTrigger}
          setExtractionSuccess={setExtractionSuccess}
        />
      )}
      <DeleteExtraction
        open={deleteExtraction}
        setOpen={setDeleteExtraction}
        extractionId={extractionIdToDelete}
        setSnackbarMessage={setSnackbarMessage}
        setTypeSnackbar={setTypeSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        siloTrigger={siloTrigger}
        siloID={siloID}
      />
      <Paper elevation={0} sx={customBgWhite} style={{ marginTop: 0 }}>
        <Box component="h1" margin={0}>
          {i18n.t('app.silobag_data.extractions')}
        </Box>
        {/* TODO: Se parseo como number[] para safar --> Arreglar */}
        {/* el rows puede ser un objeto cuando es necesario que siempre sea arreglo (posible bug oculto) */}
        <BaseTable
          rows={(rows as number[]) ?? []}
          columns={columns}
          initialPageSize={5}
          loading={isLoading}
          noBorders
        />
      </Paper>
    </>
  );
}
